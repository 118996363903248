import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import isToday from 'dayjs/plugin/isToday';
import isTomorrow from 'dayjs/plugin/isTomorrow';
import isYesterday from 'dayjs/plugin/isYesterday';
import tz from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';

import { DEFAULT_IMAGE } from '../defaults/default-data-types';
import { AppConfig } from '../types/configs/app-config';
import { DeepPartial } from 'utility-types';
import { Maybe, ChampionshipGame, MmlEvent, CurrentScoresQuery, StatusCode } from 'types/generated-types';
import { VideoLinkType } from 'types/types';
import { Entry } from 'types/bcgTypes';
import { enableMultiGameStore, liveVideoOfGameStore, multiGameActiveStore } from 'apolloGraphql/mml-apollo-cache';
import { useReactiveVar } from '@apollo/client';
import { useCallback } from 'react';
import { _DeepPartialObject } from 'utility-types/dist/mapped-types';

dayjs.extend(utc);
dayjs.extend(tz);
dayjs.extend(isToday);
dayjs.extend(isTomorrow);
dayjs.extend(isYesterday);

export enum GameStateCode {
  upcoming = 1,
  pregame = 2,
  live = 3,
  final = 4,
}

export enum BroadcasterID {
  MML = 18,
  HLN = 137,
  truTV = 40715,
  TNT = 40720,
  CBS = 40723,
  TBS = 40738,
}

export enum EventID {
  SS = 901,
}

export const placeholder = {
  bracketId: 0,
  gameStateCode: GameStateCode.upcoming,
  victorBracketPositionId: 0,
  mmlVideo: false,
  region: {
    abbreviation: '',
    title: '',
  },
  round: { title: '' },
  teams: [],
};

export const readableStatusCode = {
  pregame: 'P',
  postponed: 'O',
  canceled: 'C',
  final: 'F',
  inprogress: 'I',
  deleted: 'D',
  delayed: 'H',
  suspended: 'S',
  forfeit: 'A',
  nocontest: 'N',
};

export function convertTrademark(input: string | null | undefined): string {
  const trademarkString = input ?? '';
  return trademarkString.replace(/&#174;/g, '®');
}

export function getTeamLogo(
  school: string | null | undefined,
  size: number,
  config: AppConfig | undefined,
  isLight = false,
): string {
  const teamLogos = `${config?.image_endpoints.team_logos.href}?resize=${size}:*`;
  if (typeof school !== 'string' || school === '' || !config) {
    return DEFAULT_IMAGE;
  }
  return teamLogos.replace('[TEAM_NAME_SAFE]', school).replace('[BG]', isLight ? 'bgl' : 'bgd');
}

export function getPicks(game: ChampionshipGame, bracketId: number, mbcgEntries: Entry[]) {
  const entry =
    mbcgEntries &&
    mbcgEntries.length > 0 &&
    mbcgEntries.filter((entry) => {
      return entry.isDefault === true;
    });
  const picks = entry && entry.length > 0 ? entry[0].picks : [];
  let pick = '';
  picks.map((thisPick) => {
    if (parseInt(thisPick.gameId) === bracketId) {
      if (thisPick.teamId === game?.visitorChampionshipTeam?.ncaaOrgId?.toString()) {
        pick = 'top';
      } else if (thisPick.teamId === game?.homeChampionshipTeam?.ncaaOrgId?.toString()) {
        pick = 'bottom';
      }
    }
  });

  return pick;
}

export const timezoneAbbreviations: { [key: string]: string } = {
  'America/New_York': 'ET',
  'America/Chicago': 'CT',
  'America/Denver': 'MT',
  'America/Los_Angeles': 'PT',
};

export const userTimeZone = timezoneAbbreviations.hasOwnProperty(dayjs.tz.guess())
  ? dayjs.tz.guess()
  : 'America/New_York';

export const gameTimeFormat = new Intl.DateTimeFormat('en-US', {
  timeZone: userTimeZone,
  hour: 'numeric',
  minute: '2-digit',
});

export function getFormattedGameTime(epoch: number): string {
  const date = new Date(epoch * 1000);
  return gameTimeFormat.format(date) + ' ' + timezoneAbbreviations[userTimeZone];
}

export function getFormattedEpisodeTime(epoch: number): string {
  const date = new Date(epoch * 1000);
  return gameTimeFormat.format(date).replace(/ /g, '');
}

export function getFormattedGameDay(date: number): string {
  const format = 'dddd, MMMM D';
  const dayjsDate = dayjs.unix(date);
  if (dayjsDate.isToday()) {
    return 'today';
  } else if (dayjsDate.isTomorrow()) {
    return 'tomorrow';
  }
  return dayjsDate.format(format);
}

export enum EpochFormats {
  Prelaunch = 'MMMM Do',
  Scoreboard = 'MM/DD/YYYY',
}

export function getFormattedEpoch(epoch: number, format: string): string {
  dayjs.extend(advancedFormat);
  const dayjsDate = dayjs.unix(epoch);
  return dayjsDate.format(format);
}

export function getFormattedScoreboardDay(date: string): string {
  const dayjsDate = dayjs(date, 'MM/DD/YYYY');
  if (dayjsDate.isToday()) {
    return 'today';
  } else if (dayjsDate.isTomorrow()) {
    return 'tomorrow';
  } else if (dayjsDate.isYesterday()) {
    return 'yesterday';
  }
  return dayjsDate.format('dddd, MMMM D');
}

export const daySeconds = 86400; // 24hrs * 60min * 60sec
export function getRelativeTime(epoch: number, offset?: number): { epoch: number; formatted: string } {
  const hms = epoch % daySeconds;
  const now = dayjs().startOf('day').unix();
  const newEpoch = now + hms + (offset || 0);
  return { epoch: newEpoch, formatted: getFormattedEpoch(newEpoch, EpochFormats.Scoreboard) };
}

export function getFormattedEditorialTime(epoch: number): string {
  const convert = dayjs.unix(epoch);
  const now = dayjs();
  const days = Math.floor(now.diff(convert, 'day', true));
  const hours = Math.floor(now.diff(convert, 'hour', true));
  const minutes = Math.ceil(now.diff(convert, 'minute', true));
  const months = Math.round(days / 30);

  if (now.isBefore(convert)) {
    return getFormattedGameDay(epoch);
  } else if (days > 30) {
    return months + ' month' + (months === 1 ? '' : 's') + ' ago';
  } else if (hours >= 24) {
    return days + ' day' + (days === 1 ? '' : 's') + ' ago';
  } else if (hours > 0) {
    return hours + ' hour' + (hours === 1 ? '' : 's') + ' ago';
  } else if (minutes === 60) {
    return '1 hour ago';
  } else {
    return minutes + ' minute' + (minutes === 1 ? '' : 's') + ' ago';
  }
}

export function getDayOfWeek(epoch: number): string {
  const format = 'dddd';
  const dayjsDate = dayjs.unix(epoch);
  if (dayjsDate.isToday()) {
    return 'today';
  } else if (dayjsDate.isTomorrow()) {
    return 'tomorrow';
  }
  return dayjsDate.format(format);
}

export function getFormattedEventDateTime(epoch: number): string {
  let format = 'dddd, MMMM DD, h:mm';
  const dayjsDate = dayjs.unix(epoch);
  if (dayjsDate.minute() === 0) {
    format = 'dddd, MMMM DD, h';
  }
  const ampm = dayjsDate.tz(userTimeZone).format('A');
  return (
    dayjsDate.tz(userTimeZone).format(format) +
    (ampm === 'AM' ? ' A.M.' : ' P.M.') +
    ' ' +
    timezoneAbbreviations[userTimeZone]
  );
}

export function getFormattedEventDaylessDateTime(epoch: number): string {
  let format = 'MMMM DD - h:mm';
  const dayjsDate = dayjs.unix(epoch);
  if (dayjsDate.minute() === 0) {
    format = 'MMMM DD - h';
  }
  const ampm = dayjsDate.tz(userTimeZone).format('A');
  return (
    dayjsDate.tz(userTimeZone).format(format) +
    (ampm === 'AM' ? ' AM' : ' PM') +
    ' ' +
    timezoneAbbreviations[userTimeZone]
  );
}

export function translateStatusCode(code: string, onlyAbnormal: boolean): string {
  switch (code) {
    case readableStatusCode.canceled:
      return 'Canceled';
    case readableStatusCode.postponed:
      return 'Postponed';
    case readableStatusCode.deleted:
      return 'Deleted';
    case readableStatusCode.delayed:
      return 'Delayed';
    case readableStatusCode.suspended:
      return 'Suspended';
    case readableStatusCode.forfeit:
      return 'Forfeit';
    case readableStatusCode.nocontest:
      return 'No Contest';
    default:
      if (!onlyAbnormal) {
        switch (code) {
          case readableStatusCode.pregame:
            return 'Pre-Game';
          case readableStatusCode.final:
            return 'Final';
          case readableStatusCode.inprogress:
            return 'In-Progress';
        }
      }
      return '';
  }
}

export function isAfterGivenEpoch(epoch: number): boolean {
  return dayjs().isAfter(dayjs.unix(epoch));
}

export function translateUpsetCode(code: string): string {
  switch (code) {
    case 'upset':
      return 'Upset Alert';
    case 'close':
      return 'Close Game';
    default:
      return '';
  }
}

export function humanReadableNumber(value: number): string {
  if (value >= 1000000) return Math.floor(value / 1000000) + 'M';
  else if (value >= 1000) return Math.floor(value / 1000) + 'K';
  else return value + '';
}

export function isCBS(broadcasterName: Maybe<string> | undefined): boolean {
  return broadcasterName?.toUpperCase() === 'CBS';
}

export function isGCVod(type: VideoLinkType): boolean {
  if (type === VideoLinkType.Preview || type === VideoLinkType.Recap || type === VideoLinkType.Condensed) {
    return true;
  }
  return false;
}

export function isEvent(data: Maybe<DeepPartial<MmlEvent> | DeepPartial<ChampionshipGame>>): data is MmlEvent {
  return data?.__typename === 'MMLEvent';
}

export function isGame(data: Maybe<DeepPartial<MmlEvent> | DeepPartial<ChampionshipGame>>): data is ChampionshipGame {
  return data?.__typename === 'ChampionshipGame';
}

export function useIsMGAvailableForGame() {
  const enableMultiGame = useReactiveVar(enableMultiGameStore);
  const multiGameActive = useReactiveVar(multiGameActiveStore);
  const liveVideoOfGames = useReactiveVar(liveVideoOfGameStore);

  return useCallback(
    (game?: ChampionshipGame) => {
      if (isCBS(game?.broadcaster?.name) || !game?.mmlVideo) return false;
      return !!enableMultiGame && !!multiGameActive && !!game && liveVideoOfGames.length > 1;
    },
    [enableMultiGame, liveVideoOfGames.length, multiGameActive],
  );
}

export function sortGamesByStartTimeEpoch(games: CurrentScoresQuery['mmlContests']) {
  return games.sort((a, b) => {
    if (!a?.startTimeEpoch && !b?.startTimeEpoch) {
      return 0;
    }
    if (!a?.startTimeEpoch) {
      return 1;
    }
    if (!b?.startTimeEpoch) {
      return -1;
    }
    return a.startTimeEpoch - b.startTimeEpoch;
  });
}

export const overrideWithGameStateDisplay = (
  game: _DeepPartialObject<ChampionshipGame> | null,
  fallback: string,
): string => {
  return !!game?.gamestateDisplay ? (game.gamestateDisplay as string) : fallback;
};

export function isAbnormallyFinishedGame(code: StatusCode): boolean {
  return (
    code === readableStatusCode.canceled || code === readableStatusCode.forfeit || code === readableStatusCode.nocontest
  );
}

export function isAbnormallyStartedGame(code: string): boolean {
  return (
    code === readableStatusCode.canceled ||
    code === readableStatusCode.nocontest ||
    code === readableStatusCode.postponed ||
    code === readableStatusCode.suspended
  );
}
