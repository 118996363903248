import {
  Boxscore,
  BoxscoreDetails,
  Championship,
  ChampionshipGame,
  ChampionshipTeam,
  ContestTeam,
  Round,
  SportCode,
  StatusCode,
  TeamsOnDeck,
  TeamStatsBasketball,
} from 'types/generated-types';
import { asset } from 'utils/assets';
import { TeamInfo } from 'types/slimDataTypes';

export const DEFAULT_TEAM_NAME = 'N/A';
export const DEFAULT_COLOR = '#FF0000';
export const DEFAULT_IMAGE = asset('/assets/logo_placeholder.svg');
export const DEFAULT_ERROR = 'NO DATA';

export const DEFAULT_TEAM_INFO: TeamInfo = {
  color: DEFAULT_COLOR,
  name: 'team',
  logoUri: DEFAULT_IMAGE,
};

export const DEFAULT_HOME_TEAM: ContestTeam = {
  __typename: 'ContestTeam',
  color: '',
  seoname: '',
  teamName: '',
  teamId: '',
  roster: [],
  isHome: true,
};
export const DEFAULT_VISITOR_TEAM: ContestTeam = {
  __typename: 'ContestTeam',
  color: '',
  seoname: '',
  teamName: '',
  teamId: '',
  roster: [],
  isHome: true,
};

export const DEFAULT_CONTEXT_TEAM: ContestTeam = {
  __typename: 'ContestTeam',
  teamId: '',
  isHome: false,
  score: 0,
  gameRank: 0,
  wins: 0,
  losses: 0,
  ties: 0,
  confWins: 0,
  confLosses: 0,
  confTies: 0,
  lineScore: '',
  collegeNameFull: '',
  collegeNameShort: '',
  collegeName6char: '',
  ncaaOrgId: 0,
  teamName: '',
  division: 0,
  divisionName: '',
  sportCode: SportCode.Mbb,
  teamRank: 0,
  seoname: '',
  color: '',
  record: '',
  conferenceRecord: '',
  roster: [],
};

export const DEFAULT_TEAM_STATS: TeamStatsBasketball = {
  tmo: '',
  bonus: '',
  pos: false,
  fgm: '',
  fga: '',
  fgp: '',
  pt3m: '',
  pt3a: '',
  pt3p: '',
  ftm: '',
  fta: '',
  ftp: '',
  rbo: '',
  rbd: '',
  reb: '',
  ass: '',
  fls: '',
  tno: '',
  blk: '',
  stl: '',
};

export const DEFAULT_BOXSCORE: Boxscore = {
  __typename: 'Boxscore',

  title: '',
  description: '',
  sportCode: SportCode.Mbb,
  division: 0,
  divisionName: '',
  gameType: '',
  status: '',
  period: '',
  minutes: 0,
  seconds: 0,
  contestId: 0,
  teams: [DEFAULT_HOME_TEAM, DEFAULT_VISITOR_TEAM],
  teamBoxscore: [],
  visitorBoxscore: {},
  homeBoxscore: {},
};

export const DEFAULT_CHAMPIONSHIP_TEAM: ChampionshipTeam = {
  isTop: null,
  isHome: null,
  score: null,
  color: null,
  ncaaOrgId: null,
  seed: null,
  name6Char: null,
  seoname: null,
  nickname: null,
  nameShort: null,
  isWinner: null,
  textOverride: null,
  wins: null,
  losses: null,
  roster: [],
};

export const DEFAULT_EMPTY_CHAMPIONSHIP_TEAM: ChampionshipTeam = {
  roster: [],
  isTop: false,
  isHome: false,
  score: -1,
  color: '',
  ncaaOrgId: -1,
  seed: -1,
  name6Char: '',
  seoname: '',
  nickname: '',
  nameShort: '',
  isWinner: false,
  textOverride: '',
  wins: -1,
  losses: -1,
};

export const DEFAULT_EMPTY_CHAMPIONSHIP_GAME: ChampionshipGame = {
  homeTeamLogo: {
    name: '',
    logoUri: '',
    color: '',
  },
  visitorTeamLogo: {
    name: '',
    logoUri: '',
    color: '',
  },
  condensedVideo: [],
  teamsOnDeck: TeamsOnDeck.None,
  bracketId: null,
  broadcaster: null,
  currentPeriod: '',
  gamestateDisplay: '',
  gameState: StatusCode.P,
  gameStateCode: 1,
  hasPreviewVideo: false,
  hasRecapVideo: false,
  hasReplayVideo: false,
  hasStartTime: false,
  location: null,
  mmlRadio: false,
  mmlVideo: false,
  region: { id: '', __typename: 'RegionSection', sectionId: null, position: '', title: '', abbreviation: '' },
  round: { id: '', __typename: 'Round', title: '', subtitle: '', roundNumber: null },
  startDate: '',
  startTimeEpoch: null,
  teams: [],
  victorBracketPositionId: null,
  victorGamePosition: '',
  winnerOf: [],
  excitementAlerts: [],
  links: [],
  mmlEvents: [],
  mmlStreams: [],
  previewVideo: [],
  recapVideo: [],
  wscCatchup: [],
  __typename: 'ChampionshipGame',
};

export const DEFAULT_EMPTY_CHAMPIONSHIP: Championship = {
  currentRound: '',
  regions: [],
  rounds: [],
  links: [],
  sections: [],
  games: [],
  mmlEvents: [],
  mmlStreams: [],
  locations: [],
  broadcasters: [],
  teams: [],
  year: null,
  __typename: 'Championship',
};

export const DEFAULT_EMPTY_ROUND: Round = {
  id: '',
  roundNumber: null,
  subtitle: '',
  title: '',
  __typename: 'Round',
};

export const DEFAULT_EMPTY_CONTEST_TEAM: ContestTeam = {
  roster: [],
  teamId: '',
};

export const DEFAULT_EMPTY_BOXSCORE_DETAIL: BoxscoreDetails = {};

export const DEFAULT_EMPTY_TEAM_STATS: TeamStatsBasketball = {};

export const DEFAULT_EMPTY_ROUNDS: Round[] = [];
export const DEFAULT_EMPTY_CHAMPTIONSHIP_GAMES: ChampionshipGame[] = [];
