"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ContentEntryOptionsBuilder = void 0;
/**
 * Builder to create an options data object which defines information that is optional for a play such as play configuration and auth tokens
 *
 * @public
 */
var ContentEntryOptionsBuilder = /** @class */ (function () {
    /** @hidden */
    function ContentEntryOptionsBuilder() {
        this._data = {};
    }
    ContentEntryOptionsBuilder.create = function () {
        return new ContentEntryOptionsBuilder();
    };
    /**
     * Get a builder instance populated with data from passed in `PlaylistItem`
     * @param item The `PlaylistItem` used to initially populate the builder with
     * @return The pre-populated builder instance
     */
    // static fromPlaylistItem(item: PlaylistItem): ContentEntryOptionsBuilder;
    /**
     * Override data with values from the specified options.
     */
    ContentEntryOptionsBuilder.prototype.withContentEntryOptions = function (value) {
        this._data = value !== null && value !== void 0 ? value : {}; //TODO:  probably need to merge this
        return this;
    };
    /**
     * Specify Adobe’s ID for the TVE provider, e.g. for Optimum, it’s “Cablevision”
     * @param mvpdId The Adobe ID to send
     */
    ContentEntryOptionsBuilder.prototype.withMVPDId = function (mvpdId) {
        this._data.mvpdId = mvpdId;
        return this;
    };
    /**
     * Set the access token type required to play authenticated content
     * @param accessTokenType The auth token type to use
     */
    ContentEntryOptionsBuilder.prototype.withAccessTokenType = function (accessTokenType) {
        this._data.accessTokenType = accessTokenType;
        return this;
    };
    /**
     * The auth token required to play authenticated content
     * @param accessToken The auth token to use
     */
    ContentEntryOptionsBuilder.prototype.withAccessToken = function (accessToken) {
        this._data.accessToken = accessToken;
        return this;
    };
    /**
     * Specify optional parameters used when making the token service request
     * @param params List of values to send to token service
     */
    ContentEntryOptionsBuilder.prototype.withTokenParams = function (params) {
        this._data.tokenParams = this._data.tokenParams || {};
        for (var key in params) {
            this._data.tokenParams[key] = params[key];
        }
        return this;
    };
    /**
     * Override the assetType, in case there is an issue with the automatically detected one.
     * 0 = Live
     * 1 = DVR Live
     * 2 = VOD
     * 3 = NLSO (Non-Linear Start Over)
     *
     * @param assetType number (0-3) matching intended asset type
     */
    ContentEntryOptionsBuilder.prototype.withAssetType = function (assetType) {
        this._data.assetType = assetType;
        return this;
    };
    /**
     * Specify configuration data for the specific play
     * @param overrides Contains configuration data used to override default / init configuration
     *
     * @example Configuring Conviva analytics, **Note:** Configuration also required on {@link SetupConfigBuilder.withConviva}
     *
     * ```js
     * // Create player config with override values
     * const playConfig = PlayerConfigBuilder.forPlay()
     *     .withConviva({
     *         assetName: "<YOUR-ASSET-NAME>",
     *         viewerId: "<YOUR-VIEWER-ID>",
     *         tags: {
     *             // Add optional brand specific key/value pairs here
     *         }
     *     })
     *     .build()
     *
     * // Build a content entry options object to pass in Conviva play data
     * const entryOptions = ContentEntryBuilder.forEntryOptions()
     *     .withPlayConfigOverrides(playConfig)
     *     .build()
     * ```
     *
     * @see {@link src/ConvivaAnalyticsConfig.assetName}
     * @see {@link src/ConvivaAnalyticsConfig.tags}
     * @see {@link src/ConvivaAnalyticsConfig.viewerId}
     */
    ContentEntryOptionsBuilder.prototype.withPlayConfigOverrides = function (overrides) {
        this._data.config = overrides;
        return this;
    };
    /**
     * Build the options data
     */
    ContentEntryOptionsBuilder.prototype.build = function () {
        return this._data;
    };
    return ContentEntryOptionsBuilder;
}());
exports.ContentEntryOptionsBuilder = ContentEntryOptionsBuilder;
