import { GraphQLResolveInfo, GraphQLScalarType, GraphQLScalarTypeConfig } from 'graphql';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type RequireFields<T, K extends keyof T> = Omit<T, K> & { [P in K]-?: NonNullable<T[P]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
  JSON: { input: any; output: any };
  _FieldSet: { input: any; output: any };
};

export type Alert = {
  __typename?: 'Alert';
  UAId?: Maybe<Scalars['String']['output']>;
  alertTypeId?: Maybe<Scalars['Int']['output']>;
  alertTypeName?: Maybe<Scalars['String']['output']>;
  bracketId?: Maybe<Scalars['Int']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  payload?: Maybe<Scalars['JSON']['output']>;
  project?: Maybe<Scalars['String']['output']>;
  published?: Maybe<Scalars['Boolean']['output']>;
  publishedTime?: Maybe<Scalars['String']['output']>;
  response?: Maybe<Scalars['String']['output']>;
  scheduled?: Maybe<Scalars['Boolean']['output']>;
  tags?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
};

export enum AlertCategory {
  All = 'all',
  Excitement = 'excitement',
  LiveActivity = 'liveActivity',
  Montage = 'montage',
  Scoring = 'scoring',
}

/** APP Config Details */
export type AppConfig = {
  __typename?: 'AppConfig';
  endpointName?: Maybe<Scalars['String']['output']>;
  endpointType?: Maybe<Scalars['String']['output']>;
  endpointUrl?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  interval?: Maybe<Scalars['Int']['output']>;
  platform?: Maybe<Scalars['String']['output']>;
  seasonYear?: Maybe<Scalars['Int']['output']>;
  style?: Maybe<Scalars['String']['output']>;
  ttl?: Maybe<Scalars['String']['output']>;
};

export type AppFeatureContentInput = {
  awayAdvances?: InputMaybe<Scalars['String']['input']>;
  bracketId?: InputMaybe<Scalars['Int']['input']>;
  breakingNews?: InputMaybe<Scalars['Boolean']['input']>;
  byLine?: InputMaybe<Scalars['String']['input']>;
  closeGame?: InputMaybe<Scalars['Boolean']['input']>;
  contentType: Scalars['String']['input'];
  countdownTimestamp?: InputMaybe<Scalars['String']['input']>;
  countdownTitle?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  duration?: InputMaybe<Scalars['String']['input']>;
  finalStateDesc?: InputMaybe<Scalars['String']['input']>;
  homeAdvances?: InputMaybe<Scalars['String']['input']>;
  label?: InputMaybe<Scalars['String']['input']>;
  link?: InputMaybe<Scalars['String']['input']>;
  logo?: InputMaybe<Scalars['String']['input']>;
  mediaId?: InputMaybe<Scalars['String']['input']>;
  mmlVideo?: InputMaybe<Scalars['Boolean']['input']>;
  perfectBracketsRemaining?: InputMaybe<Scalars['String']['input']>;
  playlistTitle?: InputMaybe<Scalars['String']['input']>;
  playlistUrl?: InputMaybe<Scalars['String']['input']>;
  removedPlayWidgetFromFeaturedOnly?: InputMaybe<Scalars['Boolean']['input']>;
  sort?: InputMaybe<Scalars['Int']['input']>;
  sponsorCopy?: InputMaybe<Scalars['String']['input']>;
  sponsorImage?: InputMaybe<Scalars['String']['input']>;
  startTimeEpoch?: InputMaybe<Scalars['String']['input']>;
  streamUrl?: InputMaybe<Scalars['String']['input']>;
  thumbnail?: InputMaybe<Scalars['String']['input']>;
  thumbnailMobile?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  updatedTimestamp?: InputMaybe<Scalars['String']['input']>;
  upsetGame?: InputMaybe<Scalars['Boolean']['input']>;
  url?: InputMaybe<Scalars['String']['input']>;
  /** added for multigamePromo */
  webUrl?: InputMaybe<Scalars['String']['input']>;
};

export type AppFeatured = {
  __typename?: 'AppFeatured';
  /** catchup games that are live or final */
  catchupLiveFinalGames?: Maybe<FeaturedcatchupLiveFinalGames>;
  /** featured article */
  featuredArticles?: Maybe<Article>;
  /** headline article */
  headlineArticles?: Maybe<Article>;
  /** list of playWidget */
  playWidget?: Maybe<FeaturedPlayWidget>;
  /** season year */
  season?: Maybe<Scalars['Int']['output']>;
  /** shop promo */
  shopPromo?: Maybe<FeaturedShopPromo>;
  /** spotlight featured */
  spotlight?: Maybe<FeaturedSpotlight>;
  /** standard article */
  standardArticles?: Maybe<Article>;
  /** story featured */
  story?: Maybe<Array<Maybe<FeaturedStory>>>;
  /** trending featured */
  trending?: Maybe<FeaturedTrending>;
  /** list of vod */
  vodPlaylist?: Maybe<Array<Maybe<VodList>>>;
};

export enum AppFeaturedType {
  CatchupLiveFinalGames = 'catchupLiveFinalGames',
  FeaturedArticles = 'featuredArticles',
  HeadlineArticles = 'headlineArticles',
  PlayWidget = 'playWidget',
  ShopPromo = 'shopPromo',
  Spotlight = 'spotlight',
  StandardArticles = 'standardArticles',
  Story = 'story',
  Trending = 'trending',
  VodPlaylist = 'vodPlaylist',
}

export enum AppFeaturedView {
  /** articlet type can be: featuredArticles, standardArticles or headlineArticles */
  Article = 'article',
  /** featured type can be: spotlight, trending, catchupLiveFinalGames, shopPromo, vodPlaylist or playWidget */
  Featured = 'featured',
  /** video type can be vodPlaylist */
  Video = 'video',
}

export type Article = {
  __typename?: 'Article';
  /** list of article content */
  content: Array<Maybe<ArticleContent>>;
  /** the type of the content: article */
  contentType?: Maybe<Scalars['String']['output']>;
  /** short description of the article */
  description?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  /** sort order */
  sortObject?: Maybe<Scalars['Int']['output']>;
  /** title of the article */
  title?: Maybe<Scalars['String']['output']>;
  /** the view it belong to */
  view?: Maybe<Scalars['String']['output']>;
};

export type ArticleContent = {
  __typename?: 'ArticleContent';
  /** arthor name */
  byLine?: Maybe<Scalars['String']['output']>;
  /** the type of the content: article */
  contentType?: Maybe<Scalars['String']['output']>;
  /** short description of the article */
  description?: Maybe<Scalars['String']['output']>;
  /** sort order */
  sort?: Maybe<Scalars['Int']['output']>;
  /** url for the thumbnail */
  thumbnail?: Maybe<Scalars['String']['output']>;
  /** title of the article */
  title?: Maybe<Scalars['String']['output']>;
  /** timestamp it was updated */
  updatedTimestamp?: Maybe<Scalars['Float']['output']>;
  /** url for the article */
  url?: Maybe<Scalars['String']['output']>;
};

/** Field Hockey specific team stats */
export type AttackGame = {
  __typename?: 'AttackGame';
  attackAttempts?: Maybe<Scalars['String']['output']>;
  attackErrors?: Maybe<Scalars['String']['output']>;
  game?: Maybe<Scalars['String']['output']>;
  hittingPercentage?: Maybe<Scalars['String']['output']>;
  kills?: Maybe<Scalars['String']['output']>;
};

export type AutoProgression = {
  __typename?: 'AutoProgression';
  epoch?: Maybe<Scalars['Int']['output']>;
  message?: Maybe<Scalars['String']['output']>;
  success: Scalars['Boolean']['output'];
  timestamp?: Maybe<Scalars['String']['output']>;
};

/** Baseball/Softball specific player stats */
export type BatterStat = {
  __typename?: 'BatterStat';
  atBats?: Maybe<Scalars['String']['output']>;
  battingAverage?: Maybe<Scalars['String']['output']>;
  doubles?: Maybe<Scalars['String']['output']>;
  hits?: Maybe<Scalars['String']['output']>;
  homeRuns?: Maybe<Scalars['String']['output']>;
  onBasePercentage?: Maybe<Scalars['String']['output']>;
  runsBattedIn?: Maybe<Scalars['String']['output']>;
  runsScored?: Maybe<Scalars['String']['output']>;
  strikeouts?: Maybe<Scalars['String']['output']>;
  triples?: Maybe<Scalars['String']['output']>;
  walks?: Maybe<Scalars['String']['output']>;
};

/** Baseball/Softball specific team stats */
export type BatterTotal = {
  __typename?: 'BatterTotal';
  atBats?: Maybe<Scalars['String']['output']>;
  hits?: Maybe<Scalars['String']['output']>;
  runsBattedIn?: Maybe<Scalars['String']['output']>;
  runsScored?: Maybe<Scalars['String']['output']>;
  strikeouts?: Maybe<Scalars['String']['output']>;
  walks?: Maybe<Scalars['String']['output']>;
};

/** Baseball/Softball specific team stats */
export type BattingStat = {
  __typename?: 'BattingStat';
  doubles?: Maybe<Array<Maybe<StatBreakout>>>;
  homeRuns?: Maybe<Array<Maybe<StatBreakout>>>;
  leftOnBase?: Maybe<Scalars['String']['output']>;
  runsBattedIn?: Maybe<Array<Maybe<StatBreakout>>>;
  triples?: Maybe<Array<Maybe<StatBreakout>>>;
};

export type Boxscore = {
  __typename?: 'Boxscore';
  contestId?: Maybe<Scalars['Int']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  division?: Maybe<Scalars['Int']['output']>;
  divisionName?: Maybe<Scalars['String']['output']>;
  gameType?: Maybe<Scalars['String']['output']>;
  homeBoxscore?: Maybe<BoxscoreDetails>;
  minutes?: Maybe<Scalars['Int']['output']>;
  period?: Maybe<Scalars['String']['output']>;
  seconds?: Maybe<Scalars['Int']['output']>;
  sportCode?: Maybe<SportCode>;
  status?: Maybe<Scalars['String']['output']>;
  /** team info such as teamId, name, seed score, players stats and team stats */
  teamBoxscore?: Maybe<Array<Maybe<BoxscoreDetails>>>;
  teams?: Maybe<Array<Maybe<ContestTeam>>>;
  title?: Maybe<Scalars['String']['output']>;
  visitorBoxscore?: Maybe<BoxscoreDetails>;
};

export type BoxscoreDetails = {
  __typename?: 'BoxscoreDetails';
  /** team color, could be different if game is MML */
  color?: Maybe<Scalars['String']['output']>;
  /** boolean value of if team is home team */
  isHome?: Maybe<Scalars['Boolean']['output']>;
  /** overall losses */
  losses?: Maybe<Scalars['Int']['output']>;
  /** 6 char representation of team name */
  name6Char?: Maybe<Scalars['String']['output']>;
  /** 8 char representation of team name */
  name8Char?: Maybe<Scalars['String']['output']>;
  /** 10 char representation of team name */
  name10Char?: Maybe<Scalars['String']['output']>;
  /** team full name */
  nameFull?: Maybe<Scalars['String']['output']>;
  /** team short name */
  nameShort?: Maybe<Scalars['String']['output']>;
  ncaaOrgId?: Maybe<Scalars['Int']['output']>;
  /** team nickname or mascot name */
  nickname?: Maybe<Scalars['String']['output']>;
  /** list of players stats, must specific sport type, supported, see Union type PlayerStats */
  playerStats?: Maybe<Array<Maybe<PlayerStats>>>;
  score?: Maybe<Scalars['Int']['output']>;
  /** seed if it is tournament */
  seed?: Maybe<Scalars['Int']['output']>;
  seoname?: Maybe<Scalars['String']['output']>;
  teamId?: Maybe<Scalars['Int']['output']>;
  /** list of team stats */
  teamStats?: Maybe<TeamStats>;
  /** overall ties */
  ties?: Maybe<Scalars['Int']['output']>;
  /** overall wins */
  wins?: Maybe<Scalars['Int']['output']>;
};

export type BracketIds = {
  __typename?: 'BracketIds';
  bracketId?: Maybe<Scalars['Int']['output']>;
};

export type Briefing = {
  __typename?: 'Briefing';
  updated?: Maybe<Scalars['String']['output']>;
  url?: Maybe<Scalars['String']['output']>;
};

export type Broadcaster = {
  __typename?: 'Broadcaster';
  /** Database unique ID */
  id?: Maybe<Scalars['ID']['output']>;
  /** Broadcaster Name */
  name?: Maybe<Scalars['String']['output']>;
};

export type CpuUsage = {
  __typename?: 'CPUUsage';
  system?: Maybe<Scalars['String']['output']>;
  user?: Maybe<Scalars['String']['output']>;
};

export type CatchupLiveFinalGamesContent = {
  __typename?: 'CatchupLiveFinalGamesContent';
  awayColor?: Maybe<Scalars['String']['output']>;
  awaySeed?: Maybe<Scalars['Int']['output']>;
  awaySeoName?: Maybe<Scalars['String']['output']>;
  awayname6char?: Maybe<Scalars['String']['output']>;
  awaynameShort?: Maybe<Scalars['String']['output']>;
  bracketId?: Maybe<Scalars['Int']['output']>;
  duration?: Maybe<Scalars['String']['output']>;
  homeColor?: Maybe<Scalars['String']['output']>;
  homeSeed?: Maybe<Scalars['Int']['output']>;
  homeSeoName?: Maybe<Scalars['String']['output']>;
  homename6char?: Maybe<Scalars['String']['output']>;
  homenameShort?: Maybe<Scalars['String']['output']>;
  sort?: Maybe<Scalars['Int']['output']>;
  url?: Maybe<Scalars['String']['output']>;
};

export type Championship = {
  __typename?: 'Championship';
  bracketTemplate?: Maybe<Scalars['String']['output']>;
  broadcasters: Array<Maybe<Broadcaster>>;
  championshipId?: Maybe<Scalars['Int']['output']>;
  currentRound?: Maybe<Scalars['String']['output']>;
  dataPath?: Maybe<Scalars['String']['output']>;
  division?: Maybe<Scalars['Int']['output']>;
  divisionName?: Maybe<Scalars['String']['output']>;
  endDate?: Maybe<Scalars['String']['output']>;
  endDateEpoch?: Maybe<Scalars['Float']['output']>;
  games: Array<Maybe<ChampionshipGame>>;
  isMML?: Maybe<Scalars['Boolean']['output']>;
  links: Array<Maybe<Link>>;
  locations: Array<Maybe<Location>>;
  mmlEvents: Array<Maybe<MmlEvent>>;
  mmlStreams: Array<Maybe<MmlStream>>;
  orgDivisionId?: Maybe<Scalars['Int']['output']>;
  regions: Array<Maybe<RegionSection>>;
  rounds: Array<Maybe<Round>>;
  season?: Maybe<Scalars['Int']['output']>;
  /** only baseball and softball has section info */
  sections: Array<Maybe<RegionSection>>;
  sportCode?: Maybe<SportCode>;
  sportName?: Maybe<Scalars['String']['output']>;
  sportUrl?: Maybe<Scalars['String']['output']>;
  startDate?: Maybe<Scalars['String']['output']>;
  startDateEpoch?: Maybe<Scalars['Float']['output']>;
  teams: Array<Maybe<ChampionshipTeam>>;
  title?: Maybe<Scalars['String']['output']>;
  year?: Maybe<Scalars['Int']['output']>;
};

export type ChampionshipGame = {
  __typename?: 'ChampionshipGame';
  bottomChampionshipTeam?: Maybe<ChampionshipTeam>;
  /** boxscore for a championship game */
  boxscore?: Maybe<Boxscore>;
  bracketId?: Maybe<Scalars['Int']['output']>;
  /** This is a duplicate to bracketId, for webdev use */
  bracketPositionId?: Maybe<Scalars['Int']['output']>;
  bracketTemplate?: Maybe<Scalars['String']['output']>;
  broadcaster?: Maybe<Broadcaster>;
  broadcasterId?: Maybe<Scalars['Int']['output']>;
  broadcasterName?: Maybe<Scalars['String']['output']>;
  championshipId?: Maybe<Scalars['Int']['output']>;
  condensedVideo: Array<Maybe<WscContent>>;
  /** special display of conference name without the word conference */
  confDisplay?: Maybe<Scalars['String']['output']>;
  /** conference id */
  conferenceId?: Maybe<Scalars['Int']['output']>;
  /** conference name of the home team, non championship game only */
  conferenceName?: Maybe<Scalars['String']['output']>;
  contestClock?: Maybe<Scalars['String']['output']>;
  contestId?: Maybe<Scalars['Int']['output']>;
  currentAuthority?: Maybe<Scalars['String']['output']>;
  currentPeriod?: Maybe<Scalars['String']['output']>;
  dataPath?: Maybe<Scalars['String']['output']>;
  division?: Maybe<Scalars['Int']['output']>;
  divisionName?: Maybe<Scalars['String']['output']>;
  /** if game/contest is enabled */
  enabled?: Maybe<Scalars['Boolean']['output']>;
  excitementAlerts: Array<Maybe<Alert>>;
  externalWatchLink?: Maybe<Scalars['String']['output']>;
  finalMessage?: Maybe<Scalars['String']['output']>;
  footballWeek?: Maybe<Scalars['Int']['output']>;
  formattedClock?: Maybe<Scalars['String']['output']>;
  formattedRound?: Maybe<Scalars['String']['output']>;
  formattedVenue?: Maybe<Scalars['String']['output']>;
  gameId?: Maybe<Scalars['String']['output']>;
  gameState?: Maybe<Scalars['String']['output']>;
  gameStateCode?: Maybe<Scalars['Int']['output']>;
  gameWinner?: Maybe<Scalars['Int']['output']>;
  gamestateDisplay?: Maybe<Scalars['String']['output']>;
  geniusGameDetails?: Maybe<GeniusGameDetail>;
  halftimeClock?: Maybe<Scalars['String']['output']>;
  hasCondensedVideo?: Maybe<Scalars['Boolean']['output']>;
  hasExcitementAlert?: Maybe<Scalars['Boolean']['output']>;
  hasPreviewVideo?: Maybe<Scalars['Boolean']['output']>;
  hasRecapVideo?: Maybe<Scalars['Boolean']['output']>;
  hasReplayVideo?: Maybe<Scalars['Boolean']['output']>;
  hasStartTime?: Maybe<Scalars['Boolean']['output']>;
  hasWscCatchup?: Maybe<Scalars['Boolean']['output']>;
  homeChampionshipTeam?: Maybe<ChampionshipTeam>;
  homeSeasonAverage?: Maybe<TeamStatsBasketball>;
  homeSeed?: Maybe<Scalars['Int']['output']>;
  homeTB?: Maybe<Scalars['String']['output']>;
  homeTeamLogo?: Maybe<TeamLogoConfig>;
  isBottomVisible?: Maybe<Scalars['Boolean']['output']>;
  isChampionship?: Maybe<Scalars['Boolean']['output']>;
  isConferenceTournament?: Maybe<Scalars['Boolean']['output']>;
  isIfNecessary?: Maybe<Scalars['Boolean']['output']>;
  isMML?: Maybe<Scalars['Boolean']['output']>;
  isTopVisible?: Maybe<Scalars['Boolean']['output']>;
  links: Array<Maybe<Link>>;
  liveStat?: Maybe<Scalars['Boolean']['output']>;
  /** if stream is enabled, see stream query */
  liveVideoEnabled?: Maybe<Scalars['Boolean']['output']>;
  location?: Maybe<Location>;
  locationId?: Maybe<Scalars['Int']['output']>;
  loserBracketPositionId?: Maybe<Scalars['Int']['output']>;
  loserGamePosition?: Maybe<Scalars['String']['output']>;
  /** mediaId for MML, not the same as mediaId in Stream */
  mediaId?: Maybe<Scalars['String']['output']>;
  mmlEvents: Array<Maybe<MmlEvent>>;
  mmlRadio?: Maybe<Scalars['Boolean']['output']>;
  mmlRadioStreams?: Maybe<MmlRadioStream>;
  mmlStreams: Array<Maybe<MmlStream>>;
  mmlVideo?: Maybe<Scalars['Boolean']['output']>;
  ncaaGameId?: Maybe<Scalars['String']['output']>;
  period?: Maybe<Scalars['Int']['output']>;
  periodDisplay?: Maybe<Scalars['String']['output']>;
  playbyplay?: Maybe<PlayByPlay>;
  preGameAlertEnabled?: Maybe<Scalars['Boolean']['output']>;
  previewVideo: Array<Maybe<Stream>>;
  providerCode?: Maybe<Scalars['String']['output']>;
  providerId?: Maybe<Scalars['Int']['output']>;
  recapVideo: Array<Maybe<Stream>>;
  region?: Maybe<RegionSection>;
  regionId?: Maybe<Scalars['Int']['output']>;
  round?: Maybe<Round>;
  roundId?: Maybe<Scalars['Int']['output']>;
  season?: Maybe<Scalars['Int']['output']>;
  section?: Maybe<RegionSection>;
  sectionId?: Maybe<Scalars['Int']['output']>;
  seriesNumber?: Maybe<Scalars['Int']['output']>;
  sportCode?: Maybe<SportCode>;
  sportUrl?: Maybe<Scalars['String']['output']>;
  startDate?: Maybe<Scalars['String']['output']>;
  startTime?: Maybe<Scalars['String']['output']>;
  startTimeEpoch?: Maybe<Scalars['Float']['output']>;
  statusCode?: Maybe<StatusCode>;
  statusCodeDisplay?: Maybe<Scalars['String']['output']>;
  stream?: Maybe<Stream>;
  streamId?: Maybe<Scalars['Int']['output']>;
  teams: Array<Maybe<ChampionshipTeam>>;
  teamsOnDeck: TeamsOnDeck;
  title?: Maybe<Scalars['String']['output']>;
  topChampionshipTeam?: Maybe<ChampionshipTeam>;
  url?: Maybe<Scalars['String']['output']>;
  venue?: Maybe<Scalars['String']['output']>;
  victorBracketPositionId?: Maybe<Scalars['Int']['output']>;
  victorGamePosition?: Maybe<Scalars['String']['output']>;
  /** don't think it is used anymore, remove later */
  videoTime?: Maybe<Scalars['String']['output']>;
  visible?: Maybe<Scalars['Boolean']['output']>;
  visitSeed?: Maybe<Scalars['Int']['output']>;
  visitorChampionshipTeam?: Maybe<ChampionshipTeam>;
  visitorSeasonAverage?: Maybe<TeamStatsBasketball>;
  visitorTeamLogo?: Maybe<TeamLogoConfig>;
  watchLiveUrl?: Maybe<Scalars['String']['output']>;
  winner?: Maybe<Scalars['Int']['output']>;
  winnerOf: Array<Maybe<PreviousGameWinner>>;
  wscCatchup: Array<Maybe<WscContent>>;
  year?: Maybe<Scalars['Int']['output']>;
};

export type ChampionshipGameFinder = {
  __typename?: 'ChampionshipGameFinder';
  game?: Maybe<ChampionshipGame>;
  message?: Maybe<Scalars['String']['output']>;
};

export type ChampionshipRoundsFinder = {
  __typename?: 'ChampionshipRoundsFinder';
  message?: Maybe<Scalars['String']['output']>;
  round?: Maybe<Array<Maybe<Round>>>;
};

export type ChampionshipTeam = {
  __typename?: 'ChampionshipTeam';
  apRank?: Maybe<Scalars['Int']['output']>;
  bracketId?: Maybe<Scalars['Int']['output']>;
  color?: Maybe<Scalars['String']['output']>;
  conferenceId?: Maybe<Scalars['Int']['output']>;
  conferenceName?: Maybe<Scalars['String']['output']>;
  conferenceNames?: Maybe<Conference>;
  conferenceRecord?: Maybe<Scalars['String']['output']>;
  conferenceSeo?: Maybe<Scalars['String']['output']>;
  contestId?: Maybe<Scalars['Int']['output']>;
  division?: Maybe<Scalars['Int']['output']>;
  divisionName?: Maybe<Scalars['String']['output']>;
  eliminated?: Maybe<Scalars['Boolean']['output']>;
  /** MML only - All the games that the team played against in the entire season, including conference tournament and all the champ games played */
  games?: Maybe<Array<Maybe<MatchupAnalysis>>>;
  hashtag?: Maybe<Scalars['String']['output']>;
  highlight?: Maybe<Array<Maybe<MmlRadioHighlight>>>;
  isHome?: Maybe<Scalars['Boolean']['output']>;
  isMML?: Maybe<Scalars['Boolean']['output']>;
  isTop?: Maybe<Scalars['Boolean']['output']>;
  isVisible?: Maybe<Scalars['Boolean']['output']>;
  isWinner?: Maybe<Scalars['Boolean']['output']>;
  logoUrl?: Maybe<Scalars['String']['output']>;
  losses?: Maybe<Scalars['Int']['output']>;
  momentum?: Maybe<Array<Maybe<Momentum>>>;
  name6Char?: Maybe<Scalars['String']['output']>;
  name8Char?: Maybe<Scalars['String']['output']>;
  name10Char?: Maybe<Scalars['String']['output']>;
  nameFull?: Maybe<Scalars['String']['output']>;
  nameShort?: Maybe<Scalars['String']['output']>;
  ncaaOrgId?: Maybe<Scalars['Int']['output']>;
  nickname?: Maybe<Scalars['String']['output']>;
  providerTeamCode?: Maybe<Scalars['String']['output']>;
  rank?: Maybe<Scalars['Int']['output']>;
  /** The win lose record */
  record?: Maybe<Scalars['String']['output']>;
  roster: Array<Maybe<Player>>;
  score?: Maybe<Scalars['Int']['output']>;
  /** Team's regular season statistics */
  seasonAverage?: Maybe<TeamStats>;
  sectionRecord?: Maybe<Scalars['String']['output']>;
  seed?: Maybe<Scalars['Int']['output']>;
  seoname?: Maybe<Scalars['String']['output']>;
  sportCode?: Maybe<SportCode>;
  standings?: Maybe<ConferenceTeamStanding>;
  /** MML/wMML only, team statistic analysis for regular season */
  teamAnalysis?: Maybe<TeamAnalysis>;
  teamId?: Maybe<Scalars['Int']['output']>;
  textOverride?: Maybe<Scalars['String']['output']>;
  ties?: Maybe<Scalars['Int']['output']>;
  vsTop25?: Maybe<Scalars['String']['output']>;
  wins?: Maybe<Scalars['Int']['output']>;
};

export type Conference = {
  __typename?: 'Conference';
  conferenceDisplay?: Maybe<Scalars['String']['output']>;
  conferenceDivision?: Maybe<Scalars['String']['output']>;
  conferenceId?: Maybe<Scalars['Int']['output']>;
  conferenceName?: Maybe<Scalars['String']['output']>;
  conferenceNcaaOrgId?: Maybe<Scalars['Int']['output']>;
  conferenceSeo?: Maybe<Scalars['String']['output']>;
  /** Must have corresponding gametool champ for the same seasonYear to return any values */
  conferenceTournament: Array<Maybe<Contest>>;
  division?: Maybe<Scalars['Int']['output']>;
  sportCode?: Maybe<Scalars['String']['output']>;
  teams: Array<Maybe<ContestTeam>>;
};

export type ConferenceSubDivision = {
  __typename?: 'ConferenceSubDivision';
  conferenceTeams: Array<Maybe<ConferenceTeamStanding>>;
  subDivisionName?: Maybe<Scalars['String']['output']>;
};

export type ConferenceTeamStanding = {
  __typename?: 'ConferenceTeamStanding';
  conferenceLosses?: Maybe<Scalars['Int']['output']>;
  conferencePercentage?: Maybe<Scalars['String']['output']>;
  /** based on the overall percentage to assign a ranking */
  conferenceRank?: Maybe<Scalars['Int']['output']>;
  conferenceWins?: Maybe<Scalars['Int']['output']>;
  confernceTies?: Maybe<Scalars['Int']['output']>;
  homeLosses?: Maybe<Scalars['Int']['output']>;
  homeTies?: Maybe<Scalars['Int']['output']>;
  homeWins?: Maybe<Scalars['Int']['output']>;
  losses?: Maybe<Scalars['Int']['output']>;
  nameFull?: Maybe<Scalars['String']['output']>;
  nameShort?: Maybe<Scalars['String']['output']>;
  overallPercentage?: Maybe<Scalars['String']['output']>;
  pointsAgainst?: Maybe<Scalars['Int']['output']>;
  pointsAgainstConference?: Maybe<Scalars['Int']['output']>;
  pointsFor?: Maybe<Scalars['Int']['output']>;
  /** below are new from statsbroadcast */
  pointsForConference?: Maybe<Scalars['Int']['output']>;
  seoname?: Maybe<Scalars['String']['output']>;
  streak?: Maybe<Scalars['String']['output']>;
  teamId?: Maybe<Scalars['Int']['output']>;
  ties?: Maybe<Scalars['Int']['output']>;
  top25Losses?: Maybe<Scalars['Int']['output']>;
  top25Ties?: Maybe<Scalars['Int']['output']>;
  top25Wins?: Maybe<Scalars['Int']['output']>;
  visitLosses?: Maybe<Scalars['Int']['output']>;
  visitTies?: Maybe<Scalars['Int']['output']>;
  visitWins?: Maybe<Scalars['Int']['output']>;
  wins?: Maybe<Scalars['Int']['output']>;
};

export type Configuration = {
  __typename?: 'Configuration';
  configId: Scalars['ID']['output'];
  lastUpdated?: Maybe<Scalars['String']['output']>;
  parameterName?: Maybe<Scalars['String']['output']>;
  parameterValue?: Maybe<Scalars['String']['output']>;
  stageId?: Maybe<Scalars['String']['output']>;
};

export type Contest = {
  __typename?: 'Contest';
  /** boxscore for any game */
  boxscore?: Maybe<Boxscore>;
  bracketId?: Maybe<Scalars['Int']['output']>;
  broadcasterId?: Maybe<Scalars['Int']['output']>;
  broadcasterName?: Maybe<Scalars['String']['output']>;
  championship?: Maybe<Championship>;
  championshipGame?: Maybe<ChampionshipGame>;
  championshipId?: Maybe<Scalars['Int']['output']>;
  clock?: Maybe<Scalars['String']['output']>;
  /** special display of conference name without the word conference */
  confDisplay?: Maybe<Scalars['String']['output']>;
  /** conference name of the home team, non championship game only */
  conferenceName?: Maybe<Scalars['String']['output']>;
  contestClock?: Maybe<Scalars['String']['output']>;
  contestDate?: Maybe<Scalars['String']['output']>;
  contestId?: Maybe<Scalars['Int']['output']>;
  contestTeams?: Maybe<Array<Maybe<ContestTeam>>>;
  coverage?: Maybe<Scalars['String']['output']>;
  currentPeriod?: Maybe<Scalars['String']['output']>;
  division?: Maybe<Scalars['Int']['output']>;
  divisionName?: Maybe<Scalars['String']['output']>;
  exhibition?: Maybe<Scalars['Boolean']['output']>;
  externalWatchLink?: Maybe<Scalars['String']['output']>;
  finalMessage?: Maybe<Scalars['String']['output']>;
  gameId?: Maybe<Scalars['String']['output']>;
  gameState?: Maybe<Scalars['String']['output']>;
  gameStateCode?: Maybe<Scalars['Int']['output']>;
  gamestateDisplay?: Maybe<Scalars['String']['output']>;
  geniusGameId?: Maybe<Scalars['String']['output']>;
  hasBoxscore?: Maybe<Scalars['Boolean']['output']>;
  hasPbp?: Maybe<Scalars['Boolean']['output']>;
  hasPreview?: Maybe<Scalars['Boolean']['output']>;
  hasRecap?: Maybe<Scalars['Boolean']['output']>;
  hasScoringSummary?: Maybe<Scalars['Boolean']['output']>;
  hasStartTime?: Maybe<Scalars['Boolean']['output']>;
  hasTeamStats?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['ID']['output'];
  isChampionship?: Maybe<Scalars['Boolean']['output']>;
  isConferenceTournament?: Maybe<Scalars['Boolean']['output']>;
  linescores: Array<Maybe<Linescore>>;
  links: Array<Maybe<Link>>;
  liveVideos: Array<Maybe<Stream>>;
  location?: Maybe<Location>;
  locationFull?: Maybe<Scalars['String']['output']>;
  locationId?: Maybe<Scalars['Int']['output']>;
  locationNeutral?: Maybe<Scalars['Boolean']['output']>;
  ncaaGameId?: Maybe<Scalars['String']['output']>;
  network?: Maybe<Scalars['String']['output']>;
  period?: Maybe<Scalars['Int']['output']>;
  periodDisplay?: Maybe<Scalars['String']['output']>;
  playbyplay?: Maybe<PlayByPlay>;
  providerCode?: Maybe<Scalars['String']['output']>;
  roundDescription?: Maybe<Scalars['String']['output']>;
  roundNumber?: Maybe<Scalars['Int']['output']>;
  seasonYear?: Maybe<Scalars['Int']['output']>;
  seriesNumber?: Maybe<Scalars['Int']['output']>;
  source?: Maybe<Scalars['String']['output']>;
  sportCode?: Maybe<SportCode>;
  sportUrl?: Maybe<Scalars['String']['output']>;
  stage?: Maybe<Scalars['Int']['output']>;
  startDate?: Maybe<Scalars['String']['output']>;
  startTime?: Maybe<Scalars['String']['output']>;
  startTimeEpoch?: Maybe<Scalars['Float']['output']>;
  stats?: Maybe<LinescoreStats>;
  statusCode?: Maybe<StatusCode>;
  statusCodeDisplay?: Maybe<Scalars['String']['output']>;
  tba?: Maybe<Scalars['Boolean']['output']>;
  teams: Array<Maybe<ContestTeam>>;
  url?: Maybe<Scalars['String']['output']>;
  week?: Maybe<Scalars['String']['output']>;
  winner?: Maybe<Scalars['Int']['output']>;
};

export type ContestTeam = {
  __typename?: 'ContestTeam';
  collegeName6char?: Maybe<Scalars['String']['output']>;
  collegeName8char?: Maybe<Scalars['String']['output']>;
  collegeName10char?: Maybe<Scalars['String']['output']>;
  collegeNameFull?: Maybe<Scalars['String']['output']>;
  collegeNameShort?: Maybe<Scalars['String']['output']>;
  color?: Maybe<Scalars['String']['output']>;
  confLosses?: Maybe<Scalars['Int']['output']>;
  confTies?: Maybe<Scalars['Int']['output']>;
  confWins?: Maybe<Scalars['Int']['output']>;
  conferenceId?: Maybe<Scalars['Int']['output']>;
  conferenceName?: Maybe<Scalars['String']['output']>;
  conferenceRecord?: Maybe<Scalars['String']['output']>;
  conferenceSeo?: Maybe<Scalars['String']['output']>;
  division?: Maybe<Scalars['Int']['output']>;
  divisionName?: Maybe<Scalars['String']['output']>;
  gameRank?: Maybe<Scalars['Int']['output']>;
  geniusTeamId?: Maybe<Scalars['Int']['output']>;
  isHome?: Maybe<Scalars['Boolean']['output']>;
  isWinner?: Maybe<Scalars['Boolean']['output']>;
  lineScore?: Maybe<Scalars['String']['output']>;
  losses?: Maybe<Scalars['Int']['output']>;
  name6Char?: Maybe<Scalars['String']['output']>;
  /** added back for compatibility */
  name6char?: Maybe<Scalars['String']['output']>;
  name8Char?: Maybe<Scalars['String']['output']>;
  name10Char?: Maybe<Scalars['String']['output']>;
  nameFull?: Maybe<Scalars['String']['output']>;
  nameShort?: Maybe<Scalars['String']['output']>;
  ncaaOrgId?: Maybe<Scalars['Int']['output']>;
  nickname?: Maybe<Scalars['String']['output']>;
  record?: Maybe<Scalars['String']['output']>;
  roster: Array<Maybe<Player>>;
  score?: Maybe<Scalars['Int']['output']>;
  seasonYear?: Maybe<Scalars['Int']['output']>;
  seed?: Maybe<Scalars['Int']['output']>;
  seoname?: Maybe<Scalars['String']['output']>;
  sportCode?: Maybe<SportCode>;
  standings?: Maybe<ConferenceTeamStanding>;
  teamId: Scalars['ID']['output'];
  teamName?: Maybe<Scalars['String']['output']>;
  teamRank?: Maybe<Scalars['Int']['output']>;
  ties?: Maybe<Scalars['Int']['output']>;
  wins?: Maybe<Scalars['Int']['output']>;
};

export type CountdownDetail = {
  __typename?: 'CountdownDetail';
  epoch?: Maybe<Scalars['Float']['output']>;
  title?: Maybe<Scalars['String']['output']>;
};

export type CurrentShow = {
  __typename?: 'CurrentShow';
  feedId?: Maybe<Scalars['String']['output']>;
  isCurrentShow?: Maybe<Scalars['Boolean']['output']>;
  pushTag?: Maybe<Scalars['String']['output']>;
  showDuration?: Maybe<Scalars['String']['output']>;
  showTimestamp?: Maybe<Scalars['String']['output']>;
  summary?: Maybe<Scalars['String']['output']>;
  thumbnail_1x1?: Maybe<Scalars['String']['output']>;
  thumbnail_2x3?: Maybe<Scalars['String']['output']>;
  thumbnail_4x3?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  titleId?: Maybe<Scalars['String']['output']>;
};

export enum Device {
  /** andriod phone */
  Androidphone = 'androidphone',
  /** andriod tablet */
  Androidtab = 'androidtab',
  /** fire tablet */
  Firetab = 'firetab',
  /** ipad */
  Ipad = 'ipad',
  /** iphone */
  Iphone = 'iphone',
  /** mac */
  Mac = 'mac',
}

/** Drupal video details */
export type DrupalVideo = {
  __typename?: 'DrupalVideo';
  bracketId?: Maybe<Scalars['Int']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  division?: Maybe<Scalars['String']['output']>;
  duration?: Maybe<Scalars['String']['output']>;
  files?: Maybe<Scalars['JSON']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  image?: Maybe<Scalars['String']['output']>;
  sport?: Maybe<Scalars['String']['output']>;
  teams?: Maybe<Scalars['JSON']['output']>;
  timestamp?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  year?: Maybe<Scalars['Int']['output']>;
};

export type EmbedVideo = {
  __typename?: 'EmbedVideo';
  awayId?: Maybe<Scalars['Int']['output']>;
  awayLink?: Maybe<Scalars['String']['output']>;
  awayNickname?: Maybe<Scalars['String']['output']>;
  awaySchoolName?: Maybe<Scalars['String']['output']>;
  awayScore?: Maybe<Scalars['Int']['output']>;
  bracketId?: Maybe<Scalars['Int']['output']>;
  duration?: Maybe<Scalars['String']['output']>;
  gameStateCode?: Maybe<Scalars['Int']['output']>;
  homeId?: Maybe<Scalars['Int']['output']>;
  homeLink?: Maybe<Scalars['String']['output']>;
  homeNickname?: Maybe<Scalars['String']['output']>;
  homeSchoolName?: Maybe<Scalars['String']['output']>;
  homeScore?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  period?: Maybe<Scalars['String']['output']>;
  thumbnail?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  videoUrl?: Maybe<Scalars['String']['output']>;
  videoVttUrl?: Maybe<Scalars['String']['output']>;
};

export enum EndpointType {
  Feature = 'Feature',
  Image = 'Image',
  Web = 'Web',
}

export type Event = {
  __typename?: 'Event';
  description?: Maybe<Scalars['String']['output']>;
  division?: Maybe<Scalars['Int']['output']>;
  divisionName?: Maybe<Scalars['String']['output']>;
  eventId?: Maybe<Scalars['Int']['output']>;
  eventParent?: Maybe<Scalars['String']['output']>;
  hasStartTime?: Maybe<Scalars['Boolean']['output']>;
  link: Array<Maybe<Link>>;
  location: Array<Maybe<Location>>;
  locationId?: Maybe<Scalars['Int']['output']>;
  relatedEvents: Array<Maybe<EventRelated>>;
  sport?: Maybe<Scalars['String']['output']>;
  sportCode?: Maybe<SportCode>;
  startDate?: Maybe<Scalars['String']['output']>;
  startTime?: Maybe<Scalars['String']['output']>;
  startTimeEpoch?: Maybe<Scalars['String']['output']>;
  stream: Array<Maybe<Stream>>;
  streamId?: Maybe<Scalars['Int']['output']>;
  thumbnail?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  url?: Maybe<Scalars['String']['output']>;
  videoTime?: Maybe<Scalars['String']['output']>;
  videoTimeEpoch?: Maybe<Scalars['Float']['output']>;
};

export type EventRelated = {
  __typename?: 'EventRelated';
  name?: Maybe<Scalars['String']['output']>;
  url?: Maybe<Scalars['String']['output']>;
};

export type FeaturedPlayWidget = {
  __typename?: 'FeaturedPlayWidget';
  /** list of playWidget content */
  content: Array<Maybe<PlayWidgetContent>>;
  /** content type is playWidget */
  contentType?: Maybe<Scalars['String']['output']>;
  /** description of this playWidget group */
  description?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  /** sort order */
  sortObject?: Maybe<Scalars['Int']['output']>;
  /** title of the playWidget */
  title?: Maybe<Scalars['String']['output']>;
  /** view is featured */
  view?: Maybe<Scalars['String']['output']>;
};

export type FeaturedShopPromo = {
  __typename?: 'FeaturedShopPromo';
  /** a list of shopPromo */
  content: Array<Maybe<ShopPromoContent>>;
  /** content type is */
  contentType?: Maybe<Scalars['String']['output']>;
  /** description of this group */
  description?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  /** sort order */
  sortObject?: Maybe<Scalars['Int']['output']>;
  /** title of this shop promo group */
  title?: Maybe<Scalars['String']['output']>;
  /** view is featured */
  view?: Maybe<Scalars['String']['output']>;
};

export type FeaturedSpotlight = {
  __typename?: 'FeaturedSpotlight';
  /** list of spotlight content */
  content: Array<Maybe<SpotlightContent>>;
  /** content type could be: article or vod */
  contentType?: Maybe<Scalars['String']['output']>;
  /** description of this spotlight group */
  description?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  /** sort order */
  sortObject?: Maybe<Scalars['Int']['output']>;
  /** title of the spotlight */
  title?: Maybe<Scalars['String']['output']>;
  /** view is featured */
  view?: Maybe<Scalars['String']['output']>;
};

export type FeaturedStory = {
  __typename?: 'FeaturedStory';
  content: Array<Maybe<StoryContent>>;
  contentType?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  /** sort order */
  sortObject?: Maybe<Scalars['Int']['output']>;
  /** Widget Title - Display Value */
  title?: Maybe<Scalars['String']['output']>;
  view?: Maybe<Scalars['String']['output']>;
};

export type FeaturedTrending = {
  __typename?: 'FeaturedTrending';
  /** list of trending content */
  content: Array<Maybe<TrendingContent>>;
  /** content type is vod */
  contentType?: Maybe<Scalars['String']['output']>;
  /** description of this trending group */
  description?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  /** sort order */
  sortObject?: Maybe<Scalars['Int']['output']>;
  /** title of trending group */
  title?: Maybe<Scalars['String']['output']>;
  /** view is featured */
  view?: Maybe<Scalars['String']['output']>;
};

export type FeaturedcatchupLiveFinalGames = {
  __typename?: 'FeaturedcatchupLiveFinalGames';
  /** list of catchupLiveFinal games content */
  content: Array<Maybe<CatchupLiveFinalGamesContent>>;
  contentType?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  /** sort order */
  sortObject?: Maybe<Scalars['Int']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  view?: Maybe<Scalars['String']['output']>;
};

/** Baseball/Softball specific player stats */
export type FieldStat = {
  __typename?: 'FieldStat';
  assists?: Maybe<Scalars['String']['output']>;
  catcherInterference?: Maybe<Scalars['String']['output']>;
  caughtStealingBy?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Scalars['String']['output']>;
  involvedInDoublePlays?: Maybe<Scalars['String']['output']>;
  involvedInTriplePlays?: Maybe<Scalars['String']['output']>;
  passedBalls?: Maybe<Scalars['String']['output']>;
  putouts?: Maybe<Scalars['String']['output']>;
  stolenBasesAgainst?: Maybe<Scalars['String']['output']>;
};

/** Baseball/Softball specific team stats */
export type FieldingStat = {
  __typename?: 'FieldingStat';
  errors?: Maybe<Array<Maybe<StatBreakout>>>;
};

/** Football play info */
export type FootballPlay = {
  __typename?: 'FootballPlay';
  clock?: Maybe<Scalars['String']['output']>;
  driveText?: Maybe<Scalars['String']['output']>;
  homeScore?: Maybe<Scalars['Int']['output']>;
  playText?: Maybe<Scalars['String']['output']>;
  visitorScore?: Maybe<Scalars['Int']['output']>;
};

export enum GeniusScheduleType {
  Champ = 'CHAMP',
  RegularSeason = 'REGULAR_SEASON',
}

export type Game = {
  __typename?: 'Game';
  arena?: Maybe<Scalars['String']['output']>;
  home?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  start?: Maybe<Scalars['String']['output']>;
  visitor?: Maybe<Scalars['String']['output']>;
};

export type GametoolAudit = {
  __typename?: 'GametoolAudit';
  data?: Maybe<Scalars['JSON']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  logTime?: Maybe<Scalars['String']['output']>;
  mutationName?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  user?: Maybe<Scalars['String']['output']>;
};

export type GametoolResponse = {
  __typename?: 'GametoolResponse';
  appFeatured?: Maybe<AppFeatured>;
  broadcaster?: Maybe<Broadcaster>;
  championship?: Maybe<Championship>;
  epoch?: Maybe<Scalars['Int']['output']>;
  event?: Maybe<Event>;
  game?: Maybe<ChampionshipGame>;
  location?: Maybe<Location>;
  message?: Maybe<Scalars['String']['output']>;
  mmlEvent?: Maybe<MmlEvent>;
  mmlStream?: Maybe<MmlStream>;
  sport?: Maybe<SportInfo>;
  stream?: Maybe<Stream>;
  success: Scalars['Boolean']['output'];
  teams?: Maybe<Array<Maybe<ChampionshipTeam>>>;
  timestamp?: Maybe<Scalars['String']['output']>;
};

export enum Gender {
  F = 'F',
  M = 'M',
}

/** Generic play info */
export type GenericPlay = {
  __typename?: 'GenericPlay';
  clock?: Maybe<Scalars['String']['output']>;
  homeScore?: Maybe<Scalars['Int']['output']>;
  playText?: Maybe<Scalars['String']['output']>;
  visitorScore?: Maybe<Scalars['Int']['output']>;
};

/** Generic playbyplay scoring summary info */
export type GenericSummaryInfo = {
  __typename?: 'GenericSummaryInfo';
  homeScore?: Maybe<Scalars['String']['output']>;
  scoreText?: Maybe<Scalars['String']['output']>;
  scoreType?: Maybe<Scalars['String']['output']>;
  teamId?: Maybe<Scalars['String']['output']>;
  time?: Maybe<Scalars['String']['output']>;
  visitScore?: Maybe<Scalars['String']['output']>;
};

export type GeniusContestStatus = {
  __typename?: 'GeniusContestStatus';
  bracketId?: Maybe<Scalars['Int']['output']>;
  clock?: Maybe<Scalars['String']['output']>;
  geniusGameId?: Maybe<Scalars['Int']['output']>;
  homeScore?: Maybe<Scalars['String']['output']>;
  period?: Maybe<Scalars['String']['output']>;
  pqClock?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  visitScore?: Maybe<Scalars['String']['output']>;
};

export type GeniusGameDetail = {
  __typename?: 'GeniusGameDetail';
  homeName?: Maybe<Scalars['String']['output']>;
  homeTeamIdGS?: Maybe<Scalars['Int']['output']>;
  homeTeamOrgId?: Maybe<Scalars['Int']['output']>;
  liveStream?: Maybe<Scalars['Boolean']['output']>;
  matchId?: Maybe<Scalars['Int']['output']>;
  matchStatus?: Maybe<Scalars['String']['output']>;
  matchTime?: Maybe<Scalars['String']['output']>;
  matchTimeUTC?: Maybe<Scalars['String']['output']>;
  roundNumber?: Maybe<Scalars['Int']['output']>;
  timezone?: Maybe<Scalars['String']['output']>;
  venueName?: Maybe<Scalars['String']['output']>;
  visitName?: Maybe<Scalars['String']['output']>;
  visitTeamIdGS?: Maybe<Scalars['Int']['output']>;
  visitTeamOrgId?: Maybe<Scalars['Int']['output']>;
};

export type Heartbeat = {
  __typename?: 'Heartbeat';
  dbDate?: Maybe<Scalars['String']['output']>;
};

/** Baseball/Softball specific player stats */
export type HittingSeason = {
  __typename?: 'HittingSeason';
  atBats?: Maybe<Scalars['String']['output']>;
  battingAverage?: Maybe<Scalars['String']['output']>;
  doubles?: Maybe<Scalars['String']['output']>;
  hits?: Maybe<Scalars['String']['output']>;
  homeRuns?: Maybe<Scalars['String']['output']>;
  onBasePercentage?: Maybe<Scalars['String']['output']>;
  runsBattedIn?: Maybe<Scalars['String']['output']>;
  runsScored?: Maybe<Scalars['String']['output']>;
  strikeouts?: Maybe<Scalars['String']['output']>;
  triples?: Maybe<Scalars['String']['output']>;
  walks?: Maybe<Scalars['String']['output']>;
};

/** Lacrosse goalie specific player stats */
export type LacrosseGoalie = {
  __typename?: 'LacrosseGoalie';
  combinedShutouts?: Maybe<Scalars['String']['output']>;
  emptyNetGoalsAllowed?: Maybe<Scalars['String']['output']>;
  gamesPlayed?: Maybe<Scalars['String']['output']>;
  gamesStarted?: Maybe<Scalars['String']['output']>;
  goalsAllowed?: Maybe<Scalars['String']['output']>;
  losses?: Maybe<Scalars['String']['output']>;
  minutesPlayed?: Maybe<Scalars['String']['output']>;
  powerplayGoalsAllowed?: Maybe<Scalars['String']['output']>;
  saves?: Maybe<Scalars['String']['output']>;
  shootoutGoalsAllowed?: Maybe<Scalars['String']['output']>;
  shortHandedGoalsAllowed?: Maybe<Scalars['String']['output']>;
  shutouts?: Maybe<Scalars['String']['output']>;
};

/** Lacrosse goal specific player stats */
export type LacrosseGoals = {
  __typename?: 'LacrosseGoals';
  emptyNetGoals?: Maybe<Scalars['String']['output']>;
  firstGoals?: Maybe<Scalars['String']['output']>;
  freePositionGoals?: Maybe<Scalars['String']['output']>;
  gameTyingGoals?: Maybe<Scalars['String']['output']>;
  gameWinningGoals?: Maybe<Scalars['String']['output']>;
  overtimeGoals?: Maybe<Scalars['String']['output']>;
  powerplayGoals?: Maybe<Scalars['String']['output']>;
  shootoutGoals?: Maybe<Scalars['String']['output']>;
  shortHandedGoals?: Maybe<Scalars['String']['output']>;
  unassistedGoals?: Maybe<Scalars['String']['output']>;
};

/** Lacrosse penaly specific player stats */
export type LacrossePenalty = {
  __typename?: 'LacrossePenalty';
  count?: Maybe<Scalars['String']['output']>;
  major?: Maybe<Scalars['String']['output']>;
  match?: Maybe<Scalars['String']['output']>;
  minor?: Maybe<Scalars['String']['output']>;
  minutes?: Maybe<Scalars['String']['output']>;
  seconds?: Maybe<Scalars['String']['output']>;
};

/** Leader stats */
export type Leaders = {
  __typename?: 'Leaders';
  color?: Maybe<Scalars['String']['output']>;
  name6Char?: Maybe<Scalars['String']['output']>;
  nameShort?: Maybe<Scalars['String']['output']>;
  ncaaOrgId?: Maybe<Scalars['Int']['output']>;
  orgLegacyId?: Maybe<Scalars['Int']['output']>;
  seoname?: Maybe<Scalars['String']['output']>;
  statDisplay?: Maybe<Scalars['String']['output']>;
  statName?: Maybe<Scalars['String']['output']>;
  statValue?: Maybe<Scalars['String']['output']>;
};

export type Linescore = {
  __typename?: 'Linescore';
  home?: Maybe<Scalars['String']['output']>;
  period?: Maybe<Scalars['String']['output']>;
  visit?: Maybe<Scalars['String']['output']>;
};

export type LinescoreStats = LinescoreStatsBaseball | LinescoreStatsFootball;

export type LinescoreStatsBaseball = {
  __typename?: 'LinescoreStatsBaseball';
  balls?: Maybe<Scalars['Int']['output']>;
  outs?: Maybe<Scalars['Int']['output']>;
  playerOnFirst?: Maybe<Scalars['Boolean']['output']>;
  playerOnSecond?: Maybe<Scalars['Boolean']['output']>;
  playerOnThird?: Maybe<Scalars['Boolean']['output']>;
  strikes?: Maybe<Scalars['Int']['output']>;
};

export type LinescoreStatsFootball = {
  __typename?: 'LinescoreStatsFootball';
  distance?: Maybe<Scalars['Int']['output']>;
  down?: Maybe<Scalars['Int']['output']>;
  possession?: Maybe<Scalars['String']['output']>;
  yardline?: Maybe<Scalars['String']['output']>;
  yardsFromGoal?: Maybe<Scalars['Int']['output']>;
};

export type Link = {
  __typename?: 'Link';
  championshipId?: Maybe<Scalars['Int']['output']>;
  contestId?: Maybe<Scalars['Int']['output']>;
  eventId?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  linkKey?: Maybe<Scalars['String']['output']>;
  linkLevel?: Maybe<Scalars['String']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

/** Collection of games/events with Live video details */
export type LiveVideo = {
  __typename?: 'LiveVideo';
  /** events with live video */
  events: Array<Maybe<Event>>;
  /** games with live video */
  games: Array<Maybe<ChampionshipGame>>;
  type?: Maybe<Scalars['String']['output']>;
};

export type Location = {
  __typename?: 'Location';
  city?: Maybe<Scalars['String']['output']>;
  /** Database unique ID */
  id?: Maybe<Scalars['ID']['output']>;
  stateAp?: Maybe<Scalars['String']['output']>;
  stateId?: Maybe<Scalars['Int']['output']>;
  stateName?: Maybe<Scalars['String']['output']>;
  stateUsps?: Maybe<Scalars['String']['output']>;
  venue?: Maybe<Scalars['String']['output']>;
};

export type LogMessageModel = {
  __typename?: 'LogMessageModel';
  level: LoggerLevel;
  message: Scalars['String']['output'];
};

export enum LoggerLevel {
  All = 'ALL',
  Debug = 'DEBUG',
  Error = 'ERROR',
  Fatal = 'FATAL',
  Info = 'INFO',
  Warn = 'WARN',
}

export type MmlEvent = {
  __typename?: 'MMLEvent';
  awayName?: Maybe<Scalars['String']['output']>;
  bracketId?: Maybe<Scalars['Int']['output']>;
  broadcaster?: Maybe<Broadcaster>;
  broadcasterId?: Maybe<Scalars['Int']['output']>;
  championshipId?: Maybe<Scalars['Int']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  encoderStatus?: Maybe<Scalars['String']['output']>;
  /** id returned by iStream */
  eventId?: Maybe<Scalars['Int']['output']>;
  eventType?: Maybe<MmlEventType>;
  homeName?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  isPlanned?: Maybe<Scalars['Boolean']['output']>;
  latestAvailableGameIds?: Maybe<Array<Maybe<Scalars['Int']['output']>>>;
  location?: Maybe<Location>;
  locationId?: Maybe<Scalars['Int']['output']>;
  mediaId?: Maybe<Scalars['String']['output']>;
  mediaPhone?: Maybe<Scalars['String']['output']>;
  mediaTablet?: Maybe<Scalars['String']['output']>;
  mediaTv?: Maybe<Scalars['String']['output']>;
  mediaWeb?: Maybe<Scalars['String']['output']>;
  mmlVideo?: Maybe<Scalars['Boolean']['output']>;
  parentBracketId?: Maybe<Scalars['Int']['output']>;
  sportCode?: Maybe<SportCode>;
  startDate?: Maybe<Scalars['String']['output']>;
  startTime?: Maybe<Scalars['String']['output']>;
  startTimeEpoch?: Maybe<Scalars['Float']['output']>;
  streamUrl?: Maybe<Scalars['String']['output']>;
  thumbnailUrl?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  videoStatus?: Maybe<Scalars['String']['output']>;
  videoTime?: Maybe<Scalars['String']['output']>;
  videoTimeEpoch?: Maybe<Scalars['Float']['output']>;
  videoType?: Maybe<Scalars['String']['output']>;
  vodPlaybackUrl?: Maybe<Scalars['String']['output']>;
};

export enum MmlEventType {
  Event = 'event',
  Fastbreak = 'fastbreak',
  Montage = 'montage',
  Secondspectrum = 'secondspectrum',
  Teamcast = 'teamcast',
}

/** MML Onboading details */
export type MmlOnboarding = {
  __typename?: 'MMLOnboarding';
  /** Content of each onboading type */
  content: Array<Maybe<MmlOnboardingContent>>;
  /** content type could be: home/play/notifications/remote video/home_swimlane */
  contentType?: Maybe<Scalars['String']['output']>;
  /** type of device */
  deviceType?: Maybe<Array<Maybe<Device>>>;
  /** sort order */
  sort?: Maybe<Scalars['Int']['output']>;
  /** type could be: permission/feature */
  type?: Maybe<Scalars['String']['output']>;
  /** version number */
  version?: Maybe<Scalars['String']['output']>;
};

/** Content of each onboading type */
export type MmlOnboardingContent = {
  __typename?: 'MMLOnboardingContent';
  /** text of the button */
  button?: Maybe<Scalars['String']['output']>;
  /** description of the content */
  description?: Maybe<Scalars['String']['output']>;
  /** url of the video */
  video?: Maybe<Scalars['String']['output']>;
};

export type MmlRadioHighlight = {
  __typename?: 'MMLRadioHighlight';
  bracketId?: Maybe<Scalars['Int']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  url?: Maybe<Scalars['String']['output']>;
};

export type MmlRadioStream = {
  __typename?: 'MMLRadioStream';
  hlsUrl?: Maybe<Scalars['String']['output']>;
  mp3Url?: Maybe<Scalars['String']['output']>;
  spanishUrl?: Maybe<Scalars['String']['output']>;
};

export enum MmlSeasonType {
  Regular = 'regular',
  Tournament = 'tournament',
}

export type MmlStream = {
  __typename?: 'MMLStream';
  /** check to see if still used */
  action?: Maybe<Scalars['String']['output']>;
  alexaRadioUrl?: Maybe<Scalars['String']['output']>;
  archiveUrl?: Maybe<Scalars['String']['output']>;
  backupUrl?: Maybe<Scalars['String']['output']>;
  bracketId?: Maybe<Scalars['Int']['output']>;
  broadcaster?: Maybe<Broadcaster>;
  broadcasterId?: Maybe<Scalars['Int']['output']>;
  broadcasterName?: Maybe<Scalars['String']['output']>;
  championshipId?: Maybe<Scalars['Int']['output']>;
  contestId?: Maybe<Scalars['Int']['output']>;
  continuousPlayOverride?: Maybe<Scalars['Int']['output']>;
  createdAt?: Maybe<Scalars['String']['output']>;
  disasterRecoveryUrl?: Maybe<Scalars['String']['output']>;
  drupalEncoderStatus?: Maybe<Scalars['String']['output']>;
  drupalRadioStatus?: Maybe<Scalars['String']['output']>;
  drupalVideoStatus?: Maybe<Scalars['String']['output']>;
  /** id returned by iStream */
  eventId?: Maybe<Scalars['Int']['output']>;
  gameDate?: Maybe<Scalars['String']['output']>;
  gameOrder?: Maybe<Scalars['Int']['output']>;
  googleRadioUrl?: Maybe<Scalars['String']['output']>;
  homeTeamId?: Maybe<Scalars['Int']['output']>;
  /** check to see if still used */
  host?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  location?: Maybe<Location>;
  locationId?: Maybe<Scalars['Int']['output']>;
  mediaId?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  playbackUrl?: Maybe<Scalars['String']['output']>;
  primaryRadioUrl?: Maybe<Scalars['String']['output']>;
  /** check to see if still used */
  season?: Maybe<Scalars['Int']['output']>;
  spanishRadioUrl?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  /** check to see if still used */
  streamId?: Maybe<Scalars['String']['output']>;
  streamType?: Maybe<Scalars['String']['output']>;
  tipTime?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['String']['output']>;
  visitTeamId?: Maybe<Scalars['Int']['output']>;
  vodPlaybackUrl?: Maybe<Scalars['String']['output']>;
};

/** MML Video details */
export type MmlVideo = {
  __typename?: 'MMLVideo';
  connected1?: Maybe<Scalars['String']['output']>;
  connected2?: Maybe<Scalars['String']['output']>;
  desktop?: Maybe<Scalars['String']['output']>;
  feedType?: Maybe<Scalars['String']['output']>;
  lastUpdate?: Maybe<Scalars['Int']['output']>;
  media?: Maybe<MediaDevice>;
  mediaId?: Maybe<Scalars['String']['output']>;
  metadata?: Maybe<Scalars['JSON']['output']>;
  mobile?: Maybe<Scalars['String']['output']>;
  network?: Maybe<Scalars['String']['output']>;
  subType?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
  updatedTimestamp?: Maybe<Scalars['String']['output']>;
  validation?: Maybe<Scalars['JSON']['output']>;
};

export enum MmlVideoType {
  AkBk = 'ak_bk',
  AkBksec = 'ak_bksec',
  AkPr = 'ak_pr',
  AkPrsec = 'ak_prsec',
  Bksec = 'bksec',
  CtlBk = 'ctl_bk',
  CtlBksec = 'ctl_bksec',
  CtlPr = 'ctl_pr',
  CtlPrsec = 'ctl_prsec',
  Sec = 'sec',
}

export type MatchupAnalysis = {
  __typename?: 'MatchupAnalysis';
  /** MM/DD/YYYY */
  date?: Maybe<Scalars['String']['output']>;
  isConferenceTournament?: Maybe<Scalars['Boolean']['output']>;
  isMML?: Maybe<Scalars['Boolean']['output']>;
  nameShort?: Maybe<Scalars['String']['output']>;
  ncaaOrgId?: Maybe<Scalars['Int']['output']>;
  opponentTeamScore?: Maybe<Scalars['Int']['output']>;
  opponentTeamWon?: Maybe<Scalars['Boolean']['output']>;
  teamScore?: Maybe<Scalars['Int']['output']>;
  teamWon?: Maybe<Scalars['Boolean']['output']>;
};

export type MediaDevice = {
  __typename?: 'MediaDevice';
  desktop?: Maybe<Scalars['JSON']['output']>;
  phone?: Maybe<Scalars['JSON']['output']>;
  tablet?: Maybe<Scalars['JSON']['output']>;
  tv?: Maybe<Scalars['JSON']['output']>;
};

export type MemoryUsage = {
  __typename?: 'MemoryUsage';
  arrayBuffers?: Maybe<Scalars['String']['output']>;
  external?: Maybe<Scalars['String']['output']>;
  heapTotal?: Maybe<Scalars['String']['output']>;
  heapUsed?: Maybe<Scalars['String']['output']>;
  rss?: Maybe<Scalars['String']['output']>;
};

export type Momentum = {
  __typename?: 'Momentum';
  color?: Maybe<Scalars['String']['output']>;
  conferenceGame?: Maybe<Scalars['Boolean']['output']>;
  dateOfGame?: Maybe<Scalars['String']['output']>;
  gameCount?: Maybe<Scalars['Int']['output']>;
  home?: Maybe<Scalars['Boolean']['output']>;
  name6Char?: Maybe<Scalars['String']['output']>;
  ncaaOrgId?: Maybe<Scalars['Int']['output']>;
  pointsDiff?: Maybe<Scalars['Int']['output']>;
  scoreRecord?: Maybe<Scalars['String']['output']>;
  seoname?: Maybe<Scalars['String']['output']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  /** Add a broadcaster, such as broadcaster name */
  addBroadcaster: GametoolResponse;
  /** Add a new championship bracket, including rounds, region or section(MBA and WSB only) and games */
  addChampionship: GametoolResponse;
  /** Add a NCAA event */
  addEvent: GametoolResponse;
  /** Add a location, such as venue name */
  addLocation: GametoolResponse;
  /** Add a MML Event such as planned adhoc video such as fastbreak/selection sunday or adhoc video */
  addMMLEvent: GametoolResponse;
  /** Add a broadcaster with known id, only to be called by Production. For normal add, use addBroadcaster instead */
  addUpdateBroadcasterWithKnownId: GametoolResponse;
  /** Add a location with known id, only to be called by Production. For normal add, use addLocation instead */
  addUpdateLocationWithKnownId: GametoolResponse;
  /** copy conference tournament data - for static env testing only */
  copyConferenceTournament: GametoolResponse;
  /** Delete an App Config */
  deleteAppConfig: GametoolResponse;
  /** Delete a broadcaster by id or name */
  deleteBroadcaster: GametoolResponse;
  /** Delete a championship and all associated info. Adding sportCode and division to prevent fat finger the wrong championshipId */
  deleteChampionship: GametoolResponse;
  /** Delete a NCAA event */
  deleteEvent: GametoolResponse;
  /** Delete a location */
  deleteLocation: GametoolResponse;
  /** Delete a MML Event */
  deleteMMLEvent: GametoolResponse;
  /** Delete a MML Stream */
  deleteMMLStream: GametoolResponse;
  /** Delete preview or recaps video */
  deleteVideoStream: GametoolResponse;
  /** Set staging information for game[s] in a championship */
  setChampionshipStaging: GametoolResponse;
  /** update App Config */
  updateAppConfig: GametoolResponse;
  /** MML Voice Briefing */
  updateBriefing: GametoolResponse;
  /** Update broadcaster details */
  updateBroadcaster: GametoolResponse;
  /** Update a specific championship details, including start/end date, round region or section(MBA and WSB only) information */
  updateChampionship: GametoolResponse;
  /** Update a specific game bracket info */
  updateChampionshipGame: GametoolResponse;
  /** update wsc condensed video info (add if it does not exists) */
  updateCondensedVideo: GametoolResponse;
  /** update conference tournament */
  updateConferenceTournament: GametoolResponse;
  /** Autoprogress Contest by Boxscore */
  updateContestProgression: AutoProgression;
  /** Update a NCAA event */
  updateEvent: GametoolResponse;
  /** update Featured: view, article or video */
  updateFeatured: GametoolResponse;
  /** Update location details */
  updateLocation: GametoolResponse;
  /** Update a MML Event */
  updateMMLEvent: GametoolResponse;
  /**
   * 	update mmlOnboarding.
   * 	Existing onbroading type will be deleted and replaced with the new ones.
   *
   * - Sending an empty mmlOnboarding array will clear all onboarding for the given seasonYear.
   */
  updateMMLOnboarding: GametoolResponse;
  /** Update a MML Stream, no add mutation, adding is done by having game set with teams and broadcaster, then call Athena to create video */
  updateMMLStream: GametoolResponse;
  /** Update sport specific info such as season start/end date */
  updateSport: GametoolResponse;
  /**
   * Update or Create video stream. If video not found for update, will create the video stream.
   * These are use by Drupal to send video to SD for persisting video stream information
   */
  updateVideoStream: GametoolResponse;
  /** update Play Widget for WBB */
  updateWPlayWidget: GametoolResponse;
};

export type MutationAddBroadcasterArgs = {
  broadcaster: BroadcasterInput;
  staticTestEnv?: InputMaybe<StaticTestEnv>;
};

export type MutationAddChampionshipArgs = {
  championship: NewChampionshipInput;
  staticTestEnv?: InputMaybe<StaticTestEnv>;
};

export type MutationAddEventArgs = {
  event: EventInput;
  staticTestEnv?: InputMaybe<StaticTestEnv>;
};

export type MutationAddLocationArgs = {
  location: LocationInput;
  staticTestEnv?: InputMaybe<StaticTestEnv>;
};

export type MutationAddMmlEventArgs = {
  mmlEvent: MmlEventInput;
  staticTestEnv?: InputMaybe<StaticTestEnv>;
};

export type MutationAddUpdateBroadcasterWithKnownIdArgs = {
  broadcaster: BroadcasterInput;
  staticTestEnv?: InputMaybe<StaticTestEnv>;
};

export type MutationAddUpdateLocationWithKnownIdArgs = {
  location: LocationInput;
  staticTestEnv?: InputMaybe<StaticTestEnv>;
};

export type MutationCopyConferenceTournamentArgs = {
  division: Scalars['Int']['input'];
  fromSeason: Scalars['Int']['input'];
  sportCode: SportCode;
  toSeason: Scalars['Int']['input'];
  user: UserInput;
};

export type MutationDeleteAppConfigArgs = {
  appConfig: Array<InputMaybe<AppConfigInput>>;
  staticTestEnv?: InputMaybe<StaticTestEnv>;
  user: UserInput;
};

export type MutationDeleteBroadcasterArgs = {
  broadcaster: BroadcasterInput;
  staticTestEnv?: InputMaybe<StaticTestEnv>;
};

export type MutationDeleteChampionshipArgs = {
  championshipId: Scalars['ID']['input'];
  division: Scalars['Int']['input'];
  sportCode: SportCode;
  staticTestEnv?: InputMaybe<StaticTestEnv>;
  user: UserInput;
};

export type MutationDeleteEventArgs = {
  id: Scalars['ID']['input'];
  staticTestEnv?: InputMaybe<StaticTestEnv>;
  user: UserInput;
};

export type MutationDeleteLocationArgs = {
  location: LocationInput;
  staticTestEnv?: InputMaybe<StaticTestEnv>;
};

export type MutationDeleteMmlEventArgs = {
  mmlEvent: MmlEventInput;
  staticTestEnv?: InputMaybe<StaticTestEnv>;
};

export type MutationDeleteMmlStreamArgs = {
  mmlStream: MmlStreamInput;
  staticTestEnv?: InputMaybe<StaticTestEnv>;
};

export type MutationDeleteVideoStreamArgs = {
  staticTestEnv?: InputMaybe<StaticTestEnv>;
  videoStream?: InputMaybe<VideoStreamInput>;
};

export type MutationSetChampionshipStagingArgs = {
  stageGames: StagingGameInput;
  staticTestEnv?: InputMaybe<StaticTestEnv>;
};

export type MutationUpdateAppConfigArgs = {
  appConfig: Array<InputMaybe<AppConfigInput>>;
  staticTestEnv?: InputMaybe<StaticTestEnv>;
  user: UserInput;
};

export type MutationUpdateBriefingArgs = {
  briefDetail: Array<InputMaybe<BriefInput>>;
  seasonYear: Scalars['Int']['input'];
  staticTestEnv?: InputMaybe<StaticTestEnv>;
  user: UserInput;
};

export type MutationUpdateBroadcasterArgs = {
  broadcaster: BroadcasterInput;
  staticTestEnv?: InputMaybe<StaticTestEnv>;
};

export type MutationUpdateChampionshipArgs = {
  championship: ChampionshipInput;
  staticTestEnv?: InputMaybe<StaticTestEnv>;
};

export type MutationUpdateChampionshipGameArgs = {
  championshipGame: ChampionshipGameInput;
  staticTestEnv?: InputMaybe<StaticTestEnv>;
};

export type MutationUpdateCondensedVideoArgs = {
  condensedVideo: CondensedVideoInput;
  seasonYear: Scalars['Int']['input'];
  staticTestEnv?: InputMaybe<StaticTestEnv>;
  user: UserInput;
};

export type MutationUpdateConferenceTournamentArgs = {
  conferenceTournament: Array<InputMaybe<ConferenceTournamentInput>>;
  staticTestEnv?: InputMaybe<StaticTestEnv>;
  user: UserInput;
};

export type MutationUpdateContestProgressionArgs = {
  progressionGame: AutoprogressionInput;
  staticTestEnv?: InputMaybe<StaticTestEnv>;
};

export type MutationUpdateEventArgs = {
  event: EventInput;
  staticTestEnv?: InputMaybe<StaticTestEnv>;
};

export type MutationUpdateFeaturedArgs = {
  featuredDetail: Array<InputMaybe<FeaturedInput>>;
  seasonYear: Scalars['Int']['input'];
  staticTestEnv?: InputMaybe<StaticTestEnv>;
  user: UserInput;
};

export type MutationUpdateLocationArgs = {
  location: LocationInput;
  staticTestEnv?: InputMaybe<StaticTestEnv>;
};

export type MutationUpdateMmlEventArgs = {
  mmlEvent: MmlEventInput;
  staticTestEnv?: InputMaybe<StaticTestEnv>;
};

export type MutationUpdateMmlOnboardingArgs = {
  mmlOnboarding: Array<InputMaybe<MmlOnboardingInput>>;
  seasonYear: Scalars['Int']['input'];
  staticTestEnv?: InputMaybe<StaticTestEnv>;
  user: UserInput;
};

export type MutationUpdateMmlStreamArgs = {
  mmlStream: MmlStreamInput;
  staticTestEnv?: InputMaybe<StaticTestEnv>;
};

export type MutationUpdateSportArgs = {
  sportInfo: SportInfoInput;
  staticTestEnv?: InputMaybe<StaticTestEnv>;
};

export type MutationUpdateVideoStreamArgs = {
  staticTestEnv?: InputMaybe<StaticTestEnv>;
  videoStream?: InputMaybe<VideoStreamInput>;
};

export type MutationUpdateWPlayWidgetArgs = {
  featuredDetail: Array<InputMaybe<FeaturedInput>>;
  seasonYear: Scalars['Int']['input'];
  staticTestEnv?: InputMaybe<StaticTestEnv>;
  user: UserInput;
};

export enum OnboardingType {
  Feature = 'feature',
  Permissions = 'permissions',
}

export enum PeriodType {
  Games = 'Games',
  Halves = 'Halves',
  Innings = 'Innings',
  Periods = 'Periods',
  Quarters = 'Quarters',
  Sets = 'Sets',
}

/** Baseball/Softball specific player stats */
export type PitcherStat = {
  __typename?: 'PitcherStat';
  battersFaced?: Maybe<Scalars['String']['output']>;
  earnedRunAverage?: Maybe<Scalars['String']['output']>;
  earnedRunsAllowed?: Maybe<Scalars['String']['output']>;
  hitsAllowed?: Maybe<Scalars['String']['output']>;
  inningsPitched?: Maybe<Scalars['String']['output']>;
  loss?: Maybe<Scalars['String']['output']>;
  runsAllowed?: Maybe<Scalars['String']['output']>;
  save?: Maybe<Scalars['String']['output']>;
  strikeouts?: Maybe<Scalars['String']['output']>;
  strikes?: Maybe<Scalars['String']['output']>;
  walksAllowed?: Maybe<Scalars['String']['output']>;
  win?: Maybe<Scalars['String']['output']>;
};

/** Baseball/Softball specific team stats */
export type PitcherTotal = {
  __typename?: 'PitcherTotal';
  battersFaced?: Maybe<Scalars['String']['output']>;
  earnedRunsAllowed?: Maybe<Scalars['String']['output']>;
  hitsAllowed?: Maybe<Scalars['String']['output']>;
  inningsPitched?: Maybe<Scalars['String']['output']>;
  runsAllowed?: Maybe<Scalars['String']['output']>;
  strikeouts?: Maybe<Scalars['String']['output']>;
  walksAllowed?: Maybe<Scalars['String']['output']>;
};

/** Baseball/Softball specific team stats */
export type PitchingStat = {
  __typename?: 'PitchingStat';
  loss?: Maybe<Scalars['String']['output']>;
  numberOfPitches?: Maybe<Scalars['String']['output']>;
  pitchers?: Maybe<Array<Maybe<StatBreakout>>>;
  save?: Maybe<Scalars['String']['output']>;
  win?: Maybe<Scalars['String']['output']>;
};

export enum Platform {
  Connected = 'connected',
  Mobile = 'mobile',
  Web = 'web',
}

export enum PlatformType {
  Androidphone = 'androidphone',
  Androidtab = 'androidtab',
  Androidtv = 'androidtv',
  Appletv = 'appletv',
  Firetab = 'firetab',
  Firetv = 'firetv',
  Ipad = 'ipad',
  Iphone = 'iphone',
  Lgtv = 'lgtv',
  Macos = 'macos',
  Roku = 'roku',
  Web = 'web',
  Xbox = 'xbox',
}

/** General playbyplay info */
export type PlayByPlay = {
  __typename?: 'PlayByPlay';
  contestId?: Maybe<Scalars['Int']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  division?: Maybe<Scalars['Int']['output']>;
  divisionName?: Maybe<Scalars['String']['output']>;
  homeFouls?: Maybe<Scalars['Int']['output']>;
  homeTimeoutsDisplay?: Maybe<Scalars['String']['output']>;
  homeTotalTimeoutsP1?: Maybe<Scalars['Int']['output']>;
  homeTotalTimouts?: Maybe<Scalars['Int']['output']>;
  minutes?: Maybe<Scalars['String']['output']>;
  period?: Maybe<Scalars['Int']['output']>;
  periods?: Maybe<Array<Maybe<PlayByPlayDetails>>>;
  seconds?: Maybe<Scalars['String']['output']>;
  sportCode?: Maybe<SportCode>;
  status?: Maybe<Scalars['String']['output']>;
  teams?: Maybe<Array<Maybe<ContestTeam>>>;
  title?: Maybe<Scalars['String']['output']>;
  visitorFouls?: Maybe<Scalars['Int']['output']>;
  visitorTimeoutsDisplay?: Maybe<Scalars['String']['output']>;
  visitorTotalTimeouts?: Maybe<Scalars['Int']['output']>;
  visitorTotalTimeoutsP1?: Maybe<Scalars['Int']['output']>;
};

/** Basketball specific playbyplay info */
export type PlayByPlayBasketball = {
  __typename?: 'PlayByPlayBasketball';
  clock?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  /** not sure if this is ever populated */
  distance?: Maybe<Scalars['Int']['output']>;
  eventDescription?: Maybe<Scalars['String']['output']>;
  eventId?: Maybe<Scalars['Int']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  homeScore?: Maybe<Scalars['Int']['output']>;
  homeText?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  /** was the tm attribute to indicate H or V or in case flipped game */
  isHome?: Maybe<Scalars['Boolean']['output']>;
  lastName?: Maybe<Scalars['String']['output']>;
  score?: Maybe<Scalars['String']['output']>;
  /** Left or Right */
  teamBasket?: Maybe<Scalars['String']['output']>;
  teamId?: Maybe<Scalars['Int']['output']>;
  visitorScore?: Maybe<Scalars['Int']['output']>;
  visitorText?: Maybe<Scalars['String']['output']>;
  xCoord?: Maybe<Scalars['String']['output']>;
  yCoord?: Maybe<Scalars['String']['output']>;
};

/** General playbyplay info */
export type PlayByPlayDetails = {
  __typename?: 'PlayByPlayDetails';
  periodDisplay?: Maybe<Scalars['String']['output']>;
  periodNumber?: Maybe<Scalars['Int']['output']>;
  playbyplayStats?: Maybe<Array<Maybe<PlayByPlayStats>>>;
};

/** Football specific playbyplay info */
export type PlayByPlayFootball = {
  __typename?: 'PlayByPlayFootball';
  clock?: Maybe<Scalars['String']['output']>;
  plays?: Maybe<Array<Maybe<FootballPlay>>>;
  teamId?: Maybe<Scalars['Int']['output']>;
};

/** Generic play info */
export type PlayByPlayGenericSport = {
  __typename?: 'PlayByPlayGenericSport';
  clock?: Maybe<Scalars['String']['output']>;
  plays?: Maybe<Array<Maybe<GenericPlay>>>;
  teamId?: Maybe<Scalars['Int']['output']>;
};

export type PlayByPlayStats = PlayByPlayBasketball | PlayByPlayFootball | PlayByPlayGenericSport;

export type PlayWidgetContent = {
  __typename?: 'PlayWidgetContent';
  /** Projected display value impact of how many perfect brackets would be remaining after the away team wins in a matchup */
  awayAdvances?: Maybe<Scalars['String']['output']>;
  /** bracketId for the matchup tile (display corresponding team name, team logo, seed, live scores) */
  bracketId?: Maybe<Scalars['Int']['output']>;
  /** Widget Description Value eg: Brackets Remaining */
  description?: Maybe<Scalars['String']['output']>;
  /** Description of the state of the brackets */
  finalStateDesc?: Maybe<Scalars['String']['output']>;
  /** Projected display value impact of how many perfect brackets would be remaining after the home team wins in a matchup */
  homeAdvances?: Maybe<Scalars['String']['output']>;
  /** provides the link to the Editorial superpage */
  link?: Maybe<Scalars['String']['output']>;
  /** total number of perfect brackets remaining across all bracket games. eg: 171,000+ */
  perfectBracketsRemaining?: Maybe<Scalars['String']['output']>;
  /** if set to true, playWidget will not returned from featured, but still return by playWidget query */
  removedPlayWidgetFromFeaturedOnly?: Maybe<Scalars['Boolean']['output']>;
  /** display value if feature gets sold */
  sponsorCopy?: Maybe<Scalars['String']['output']>;
  /** Title Display Value eg: Perfect Bracket Tracker */
  title?: Maybe<Scalars['String']['output']>;
  updatedTimestamp?: Maybe<Scalars['String']['output']>;
};

export type Player = {
  __typename?: 'Player';
  collegeNameFull?: Maybe<Scalars['String']['output']>;
  collegeNameShort?: Maybe<Scalars['String']['output']>;
  conference?: Maybe<Scalars['String']['output']>;
  division?: Maybe<Scalars['Int']['output']>;
  divisionName?: Maybe<Scalars['String']['output']>;
  eligibilityYear?: Maybe<Scalars['String']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  height?: Maybe<Scalars['Int']['output']>;
  hometown?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  jerseyNumber?: Maybe<Scalars['String']['output']>;
  lastName?: Maybe<Scalars['String']['output']>;
  ncaaOrgId?: Maybe<Scalars['String']['output']>;
  position?: Maybe<Scalars['String']['output']>;
  schoolYear?: Maybe<Scalars['String']['output']>;
  seasonAverage?: Maybe<PlayerStats>;
  sportsDataPlayerCode?: Maybe<Scalars['String']['output']>;
  teamName?: Maybe<Scalars['String']['output']>;
  teamRank?: Maybe<Scalars['Int']['output']>;
  weight?: Maybe<Scalars['Int']['output']>;
};

export type PlayerStats =
  | PlayerStatsBaseball
  | PlayerStatsBasketball
  | PlayerStatsFieldhockey
  | PlayerStatsFootball
  | PlayerStatsIcehockey
  | PlayerStatsLacrosse
  | PlayerStatsSoccer
  | PlayerStatsSoftball
  | PlayerStatsVolleyball;

/** Baseball specific player stats */
export type PlayerStatsBaseball = {
  __typename?: 'PlayerStatsBaseball';
  batterStats?: Maybe<BatterStat>;
  fieldStats?: Maybe<FieldStat>;
  firstName?: Maybe<Scalars['String']['output']>;
  hittingSeason?: Maybe<HittingSeason>;
  lastName?: Maybe<Scalars['String']['output']>;
  number?: Maybe<Scalars['String']['output']>;
  participated?: Maybe<Scalars['Boolean']['output']>;
  pitcherStats?: Maybe<PitcherStat>;
  position?: Maybe<Scalars['String']['output']>;
  starter?: Maybe<Scalars['Boolean']['output']>;
  substitute?: Maybe<Scalars['String']['output']>;
};

/** Basketball specific player stats */
export type PlayerStatsBasketball = {
  __typename?: 'PlayerStatsBasketball';
  /** short form for assists */
  ass?: Maybe<Scalars['String']['output']>;
  assists?: Maybe<Scalars['String']['output']>;
  /** short form for blockedshots */
  blk?: Maybe<Scalars['String']['output']>;
  blockedShots?: Maybe<Scalars['String']['output']>;
  defensiveRebounds?: Maybe<Scalars['String']['output']>;
  elig?: Maybe<Scalars['String']['output']>;
  /** short form for fieldGoalsAttempted */
  fga?: Maybe<Scalars['String']['output']>;
  /** short form for fieldGoalsMade */
  fgm?: Maybe<Scalars['String']['output']>;
  /** short form for fieldGoalPercentage */
  fgp?: Maybe<Scalars['String']['output']>;
  fieldGoalPercentage?: Maybe<Scalars['String']['output']>;
  fieldGoalsAttempted?: Maybe<Scalars['String']['output']>;
  fieldGoalsMade?: Maybe<Scalars['String']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  /** short form for personalFouls */
  fls?: Maybe<Scalars['String']['output']>;
  /** short form for firstname */
  fname?: Maybe<Scalars['String']['output']>;
  freeThrowsAttempted?: Maybe<Scalars['String']['output']>;
  freeThrowsMade?: Maybe<Scalars['String']['output']>;
  /** short form for freeThrowsAttempted */
  fta?: Maybe<Scalars['String']['output']>;
  /** short form for freeThrowsMade */
  ftm?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  lastName?: Maybe<Scalars['String']['output']>;
  /** short form for lastname */
  lname?: Maybe<Scalars['String']['output']>;
  minutesPlayed?: Maybe<Scalars['String']['output']>;
  /** short form for minutesPlayed */
  mpy?: Maybe<Scalars['String']['output']>;
  /** short form for number */
  num?: Maybe<Scalars['Int']['output']>;
  number?: Maybe<Scalars['Int']['output']>;
  offensiveRebounds?: Maybe<Scalars['String']['output']>;
  personalFouls?: Maybe<Scalars['String']['output']>;
  points?: Maybe<Scalars['String']['output']>;
  /** short form for position */
  pos?: Maybe<Scalars['String']['output']>;
  position?: Maybe<Scalars['String']['output']>;
  /** short form for threePointsAttempted */
  pt3a?: Maybe<Scalars['String']['output']>;
  /** short form for threePointsMade */
  pt3m?: Maybe<Scalars['String']['output']>;
  /** short form for threePointPercentage */
  pt3p?: Maybe<Scalars['String']['output']>;
  /** short form for points */
  pts?: Maybe<Scalars['String']['output']>;
  /** short form for defensiveRebounds */
  rbd?: Maybe<Scalars['String']['output']>;
  /** short form for offensiveRebounds */
  rbo?: Maybe<Scalars['String']['output']>;
  /** short form for totalRebounds */
  reb?: Maybe<Scalars['String']['output']>;
  starter?: Maybe<Scalars['Boolean']['output']>;
  steals?: Maybe<Scalars['String']['output']>;
  /** short form for steals */
  stl?: Maybe<Scalars['String']['output']>;
  threePointPercentage?: Maybe<Scalars['String']['output']>;
  threePointsAttempted?: Maybe<Scalars['String']['output']>;
  threePointsMade?: Maybe<Scalars['String']['output']>;
  /** short form for turnovers */
  tno?: Maybe<Scalars['String']['output']>;
  totalRebounds?: Maybe<Scalars['String']['output']>;
  turnovers?: Maybe<Scalars['String']['output']>;
  year?: Maybe<Scalars['String']['output']>;
};

/** Field Hockey specific player stats */
export type PlayerStatsFieldhockey = {
  __typename?: 'PlayerStatsFieldhockey';
  assists?: Maybe<Scalars['String']['output']>;
  count?: Maybe<Scalars['String']['output']>;
  dsave?: Maybe<Scalars['String']['output']>;
  emptyNetGoals?: Maybe<Scalars['String']['output']>;
  firstGoals?: Maybe<Scalars['String']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  fouls?: Maybe<Scalars['String']['output']>;
  gameTyingGoals?: Maybe<Scalars['String']['output']>;
  gameWinningGoals?: Maybe<Scalars['String']['output']>;
  gamesPlayedAtGoalie?: Maybe<Scalars['Boolean']['output']>;
  gamesStartedAtGoalie?: Maybe<Scalars['Boolean']['output']>;
  goalieMinutes?: Maybe<Scalars['String']['output']>;
  goalieShots?: Maybe<Scalars['String']['output']>;
  goals?: Maybe<Scalars['String']['output']>;
  goalsAllowed?: Maybe<Scalars['String']['output']>;
  green?: Maybe<Scalars['String']['output']>;
  hatTricks?: Maybe<Scalars['String']['output']>;
  lastName?: Maybe<Scalars['String']['output']>;
  loss?: Maybe<Scalars['String']['output']>;
  minutes?: Maybe<Scalars['String']['output']>;
  number?: Maybe<Scalars['String']['output']>;
  overtimeGoals?: Maybe<Scalars['String']['output']>;
  participated?: Maybe<Scalars['Boolean']['output']>;
  penaltyShotGoals?: Maybe<Scalars['String']['output']>;
  penaltyShotsAttempted?: Maybe<Scalars['String']['output']>;
  position?: Maybe<Scalars['String']['output']>;
  red?: Maybe<Scalars['String']['output']>;
  saveByPeriod?: Maybe<Scalars['String']['output']>;
  saves?: Maybe<Scalars['String']['output']>;
  shootoutGoals?: Maybe<Scalars['String']['output']>;
  shots?: Maybe<Scalars['String']['output']>;
  shotsOnGoal?: Maybe<Scalars['String']['output']>;
  shutouts?: Maybe<Scalars['String']['output']>;
  starter?: Maybe<Scalars['Boolean']['output']>;
  tie?: Maybe<Scalars['String']['output']>;
  unassistedGoals?: Maybe<Scalars['String']['output']>;
  win?: Maybe<Scalars['String']['output']>;
  yellow?: Maybe<Scalars['String']['output']>;
};

/** Football/American Football specific player stats */
export type PlayerStatsFootball = {
  __typename?: 'PlayerStatsFootball';
  defenseInterceptions?: Maybe<Scalars['String']['output']>;
  fieldGoalsAttempted?: Maybe<Scalars['String']['output']>;
  fieldGoalsLong?: Maybe<Scalars['String']['output']>;
  fieldGoalsMade?: Maybe<Scalars['String']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  fumblesForced?: Maybe<Scalars['String']['output']>;
  fumblesRecovered?: Maybe<Scalars['String']['output']>;
  kickReturns?: Maybe<Scalars['String']['output']>;
  kickReturnsAverage?: Maybe<Scalars['String']['output']>;
  kickReturnsLong?: Maybe<Scalars['String']['output']>;
  kickReturnsYards?: Maybe<Scalars['String']['output']>;
  kickingPts?: Maybe<Scalars['String']['output']>;
  lastName?: Maybe<Scalars['String']['output']>;
  lossTackles?: Maybe<Scalars['String']['output']>;
  number?: Maybe<Scalars['String']['output']>;
  passingAttempts?: Maybe<Scalars['String']['output']>;
  passingCompletions?: Maybe<Scalars['String']['output']>;
  passingInterceptions?: Maybe<Scalars['String']['output']>;
  passingLong?: Maybe<Scalars['String']['output']>;
  passingTDs?: Maybe<Scalars['String']['output']>;
  passingYards?: Maybe<Scalars['String']['output']>;
  patMade?: Maybe<Scalars['String']['output']>;
  puntReturns?: Maybe<Scalars['String']['output']>;
  puntReturnsAverage?: Maybe<Scalars['String']['output']>;
  puntReturnsLong?: Maybe<Scalars['String']['output']>;
  puntReturnsYards?: Maybe<Scalars['String']['output']>;
  puntingAverage?: Maybe<Scalars['String']['output']>;
  puntingLong?: Maybe<Scalars['String']['output']>;
  puntingPunts?: Maybe<Scalars['String']['output']>;
  puntingYards?: Maybe<Scalars['String']['output']>;
  receivingLong?: Maybe<Scalars['String']['output']>;
  receivingReceptions?: Maybe<Scalars['String']['output']>;
  receivingTDs?: Maybe<Scalars['String']['output']>;
  receivingYards?: Maybe<Scalars['String']['output']>;
  rushingAttempts?: Maybe<Scalars['String']['output']>;
  rushingLong?: Maybe<Scalars['String']['output']>;
  rushingTDs?: Maybe<Scalars['String']['output']>;
  rushingYards?: Maybe<Scalars['String']['output']>;
  sacks?: Maybe<Scalars['String']['output']>;
  soloTackles?: Maybe<Scalars['String']['output']>;
  totalTackles?: Maybe<Scalars['String']['output']>;
};

/** Ice Hockey specific player stats */
export type PlayerStatsIcehockey = {
  __typename?: 'PlayerStatsIcehockey';
  assists?: Maybe<Scalars['String']['output']>;
  blk?: Maybe<Scalars['String']['output']>;
  count?: Maybe<Scalars['String']['output']>;
  emptyNetGoals?: Maybe<Scalars['String']['output']>;
  emptyNetGoalsAllowed?: Maybe<Scalars['String']['output']>;
  facelost?: Maybe<Scalars['String']['output']>;
  facewon?: Maybe<Scalars['String']['output']>;
  firstGoals?: Maybe<Scalars['String']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  gameTyingGoals?: Maybe<Scalars['String']['output']>;
  gameWinningGoals?: Maybe<Scalars['String']['output']>;
  goalieMinutes?: Maybe<Scalars['String']['output']>;
  goaliePowerPlayGoalsAllowed?: Maybe<Scalars['String']['output']>;
  goals?: Maybe<Scalars['String']['output']>;
  goalsAllowed?: Maybe<Scalars['String']['output']>;
  hattricks?: Maybe<Scalars['String']['output']>;
  lastName?: Maybe<Scalars['String']['output']>;
  major?: Maybe<Scalars['String']['output']>;
  match?: Maybe<Scalars['String']['output']>;
  minor?: Maybe<Scalars['String']['output']>;
  minus?: Maybe<Scalars['String']['output']>;
  minutes?: Maybe<Scalars['String']['output']>;
  misc10?: Maybe<Scalars['String']['output']>;
  miscgame?: Maybe<Scalars['String']['output']>;
  miscgross?: Maybe<Scalars['String']['output']>;
  number?: Maybe<Scalars['String']['output']>;
  overtimeGoals?: Maybe<Scalars['String']['output']>;
  participated?: Maybe<Scalars['Boolean']['output']>;
  penaltyShotGoals?: Maybe<Scalars['String']['output']>;
  penaltyShotGoalsAlowed?: Maybe<Scalars['String']['output']>;
  penaltyShotsAttempted?: Maybe<Scalars['String']['output']>;
  plus?: Maybe<Scalars['String']['output']>;
  plusminus?: Maybe<Scalars['String']['output']>;
  position?: Maybe<Scalars['String']['output']>;
  powerPlayGoals?: Maybe<Scalars['String']['output']>;
  powerPlayGoalsAllowed?: Maybe<Scalars['String']['output']>;
  powerPlayOpportunities?: Maybe<Scalars['String']['output']>;
  saveByPeriod?: Maybe<Scalars['String']['output']>;
  saves?: Maybe<Scalars['String']['output']>;
  shootoutGoals?: Maybe<Scalars['String']['output']>;
  shootoutGoalsAllowed?: Maybe<Scalars['String']['output']>;
  shortHandedGoals?: Maybe<Scalars['String']['output']>;
  shortHandedGoalsAllowed?: Maybe<Scalars['String']['output']>;
  shots?: Maybe<Scalars['String']['output']>;
  shutouts?: Maybe<Scalars['String']['output']>;
  starter?: Maybe<Scalars['Boolean']['output']>;
  unassistedGoals?: Maybe<Scalars['String']['output']>;
};

/** Lacrosse specific player stats */
export type PlayerStatsLacrosse = {
  __typename?: 'PlayerStatsLacrosse';
  assists?: Maybe<Scalars['String']['output']>;
  causedTurnovers?: Maybe<Scalars['String']['output']>;
  drawControls?: Maybe<Scalars['String']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  freePositionShots?: Maybe<Scalars['String']['output']>;
  goalTypes?: Maybe<LacrosseGoals>;
  goalie?: Maybe<LacrosseGoalie>;
  goals?: Maybe<Scalars['String']['output']>;
  groundBalls?: Maybe<Scalars['String']['output']>;
  lastName?: Maybe<Scalars['String']['output']>;
  minutesPlayed?: Maybe<Scalars['String']['output']>;
  number?: Maybe<Scalars['String']['output']>;
  participated?: Maybe<Scalars['Boolean']['output']>;
  penalties?: Maybe<LacrossePenalty>;
  position?: Maybe<Scalars['String']['output']>;
  secondsPlayed?: Maybe<Scalars['String']['output']>;
  shootoutShots?: Maybe<Scalars['String']['output']>;
  shots?: Maybe<Scalars['String']['output']>;
  shotsOnGoal?: Maybe<Scalars['String']['output']>;
  starter?: Maybe<Scalars['Boolean']['output']>;
  turnovers?: Maybe<Scalars['String']['output']>;
};

/** Soccer specific player stats */
export type PlayerStatsSoccer = {
  __typename?: 'PlayerStatsSoccer';
  assists?: Maybe<Scalars['String']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  goalTypes?: Maybe<SoccerGoals>;
  goalie?: Maybe<SoccerGoalie>;
  goals?: Maybe<Scalars['String']['output']>;
  goalsAllowed?: Maybe<Scalars['String']['output']>;
  lastName?: Maybe<Scalars['String']['output']>;
  minutesPlayed?: Maybe<Scalars['String']['output']>;
  number?: Maybe<Scalars['String']['output']>;
  participated?: Maybe<Scalars['Boolean']['output']>;
  penalties?: Maybe<SoccerPenalty>;
  penaltyShotAttempts?: Maybe<Scalars['String']['output']>;
  penaltyShotGoals?: Maybe<Scalars['String']['output']>;
  points?: Maybe<Scalars['String']['output']>;
  position?: Maybe<Scalars['String']['output']>;
  saves?: Maybe<Scalars['String']['output']>;
  shots?: Maybe<Scalars['String']['output']>;
  shotsOnGoal?: Maybe<Scalars['String']['output']>;
  starter?: Maybe<Scalars['Boolean']['output']>;
};

/** Softball specific player stats */
export type PlayerStatsSoftball = {
  __typename?: 'PlayerStatsSoftball';
  batterStats?: Maybe<BatterStat>;
  fieldStats?: Maybe<FieldStat>;
  firstName?: Maybe<Scalars['String']['output']>;
  hittingSeason?: Maybe<HittingSeason>;
  lastName?: Maybe<Scalars['String']['output']>;
  number?: Maybe<Scalars['String']['output']>;
  participated?: Maybe<Scalars['Boolean']['output']>;
  pitcherStats?: Maybe<PitcherStat>;
  position?: Maybe<Scalars['String']['output']>;
  starter?: Maybe<Scalars['Boolean']['output']>;
  substitute?: Maybe<Scalars['String']['output']>;
};

/** Volleryball specific player stats */
export type PlayerStatsVolleyball = {
  __typename?: 'PlayerStatsVolleyball';
  assists?: Maybe<Scalars['String']['output']>;
  attackAttempts?: Maybe<Scalars['String']['output']>;
  attackErrors?: Maybe<Scalars['String']['output']>;
  ballHandlingErrors?: Maybe<Scalars['String']['output']>;
  blockAssists?: Maybe<Scalars['String']['output']>;
  blockSolos?: Maybe<Scalars['String']['output']>;
  blockingErrors?: Maybe<Scalars['String']['output']>;
  digs?: Maybe<Scalars['String']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  gamesPlayed?: Maybe<Scalars['String']['output']>;
  hittingPercentage?: Maybe<Scalars['String']['output']>;
  kills?: Maybe<Scalars['String']['output']>;
  lastName?: Maybe<Scalars['String']['output']>;
  number?: Maybe<Scalars['String']['output']>;
  participated?: Maybe<Scalars['Boolean']['output']>;
  points?: Maybe<Scalars['String']['output']>;
  position?: Maybe<Scalars['String']['output']>;
  receptionAttempts?: Maybe<Scalars['String']['output']>;
  receptionErrors?: Maybe<Scalars['String']['output']>;
  serveAttempts?: Maybe<Scalars['String']['output']>;
  serviceAces?: Maybe<Scalars['String']['output']>;
  serviceErrors?: Maybe<Scalars['String']['output']>;
  setAttempts?: Maybe<Scalars['String']['output']>;
  setErrors?: Maybe<Scalars['String']['output']>;
  starter?: Maybe<Scalars['Boolean']['output']>;
  totalBlocks?: Maybe<Scalars['String']['output']>;
};

/** Lacrosse/Ice Hockey specific player stats */
export type PowerPlay = {
  __typename?: 'PowerPlay';
  goals?: Maybe<Scalars['String']['output']>;
  opportunities?: Maybe<Scalars['String']['output']>;
};

export type PreviewArticle = {
  __typename?: 'PreviewArticle';
  content?: Maybe<Scalars['String']['output']>;
  lastUpdated?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
};

export type PreviousGameWinner = {
  __typename?: 'PreviousGameWinner';
  bracketId?: Maybe<Scalars['Int']['output']>;
  homeSeed?: Maybe<Scalars['Int']['output']>;
  homeTeam?: Maybe<Scalars['String']['output']>;
  isHome?: Maybe<Scalars['Boolean']['output']>;
  isTop?: Maybe<Scalars['Boolean']['output']>;
  visitSeed?: Maybe<Scalars['Int']['output']>;
  visitTeam?: Maybe<Scalars['String']['output']>;
};

export type Provider = {
  __typename?: 'Provider';
  /** Provider Code */
  code?: Maybe<Scalars['String']['output']>;
  /** Database unique ID */
  id?: Maybe<Scalars['ID']['output']>;
  /** Provider Name */
  name?: Maybe<Scalars['String']['output']>;
  /** Source of Schedule load */
  source?: Maybe<Scalars['String']['output']>;
};

export type Query = {
  __typename?: 'Query';
  /**
   * 	Get a list of Airship push alert details send, default to last 50.
   * 	Currently, push alerts are sent for MML games only.
   * 	And starting 2023, division 1 men's basketball conference tounament games too
   * 	Example query parameters combinations:
   *
   * - no parameter set: return the last 50 alerts
   *
   * - category is defaulted to scoring
   *
   * - By championshipId: (championshipId:1234) returns all alerts sent for that the championship
   *
   * - By championshipId and bracketId: (championshipId:1233, bracketId: 101) returns all alerts sent for that bracket
   *
   * - By seasonYear" (seasonYear:2022) - optional bracketId, category, returns all MML alerts sent
   *
   * - By seasonYear for montage alerts" (seasonYear:2023, category: montage, last: 100) - list of montage alert sent, limit default to 100
   */
  alerts: Array<Maybe<Alert>>;
  /** Get App Config By Season Year */
  appConfig: Array<Maybe<AppConfig>>;
  /**
   * 	Get the featured feed data by featuredView
   * 	(featured, article or video, or all if featuredView is not specified).
   * 	Required parmater: seasonYear
   * 	Example query parameters combinations:
   *
   * - By featured view (seasonYear: 2021, view: featured)
   *
   * - All view (seasonYear: 2021)
   *
   * - see reference requirement: https://docsprod.turner.com/pages/viewpage.action?pageId=210049538
   */
  appFeatured?: Maybe<AppFeatured>;
  /**
   * get boxscore info by contestId.
   * It can also be part of other queries when return type is one of championshipGame/contests/mmlContests
   */
  boxscore?: Maybe<Boxscore>;
  /** List of Broadcaster information or query by Broadcaster id or by championshipId */
  broadcasters: Array<Maybe<Broadcaster>>;
  championship?: Maybe<Championship>;
  championshipGame: ChampionshipGame;
  /** Look for championship game details such as gameId, startDate, location, teamRecord info */
  championshipGameFinder?: Maybe<ChampionshipGameFinder>;
  /**
   * 	Get a list of ChampionshipGames for a sport, division, and season (showUnstaged true/false optional on each query)
   * 	Example query parameters combinations:
   *
   * - By championshipId (championshipId: 6128) - championshipId is a unique id in gametool
   *
   * - By sportCode/division/season ((sportCode: MSO, season:2022, division:1) ) - get all champ games for season 2020, division 1 soccer
   *
   * - By sportCode/division/season/bracketId ((sportCode: MSO, season:2022, division:1, bracketId:101) ) - get only bracket 101 for season 2020, division 1 soccer
   *
   * - By championshipId and roundNumber (championshipId: 6128 roundNumber:2 ) - get all games in round 2 for championshipId 6128
   *
   * - By championshipId and sectionId (championshipId: 6128 sectionId:101 )  - sectionId is unique id to group games into sections, only used in Baseball/Softball. This get all games in section 101 for championshipId 6128
   */
  championshipGames: Array<Maybe<ChampionshipGame>>;
  /** Look for championship round details such as startDate, endDate */
  championshipRoundsFinder?: Maybe<ChampionshipRoundsFinder>;
  /**
   * List of teams by contestId in a championship, return teams that are known and defined in Gametool
   * or Query by sesonYear, sportCode, division
   */
  championshipTeams: Array<Maybe<ChampionshipTeam>>;
  /**
   * List of Championships or query by Championship id (showUnstaged true/false optional on each query)
   * or query by sportUrl, division and year (champ year)
   * When querying all Championships, regions, rounds, links, and games will not be returned for performance purposes
   */
  championships: Array<Maybe<Championship>>;
  /** Get Conference Record by seasonYear, sportCode */
  conferenceRecords: Array<Maybe<ConferenceRecord>>;
  /**
   * Get Conference information by TeamId or by sportCode/seasonYear/division and/or (Month or week)
   * If conferenceId or conferenceName is passed together with tournamentData set to true, it will return conference tournament data (if useApi is true it will attempt to get data from ncaa api instead of from DB)
   */
  conferences?: Maybe<Array<Maybe<Conference>>>;
  /** Get team info by contestId, each contest have 2 contestTeam (home and visit) */
  contestTeams?: Maybe<Array<Maybe<ContestTeam>>>;
  /**
   * 	Get contest/game info by id
   * 	Example query parameters combinations:
   *
   * - By contest id (id: 56789)
   *
   * - By sportCode and seasonYear and division and contestDate (MM/DD/YYYY): (sportCode: MBB, seasonYear: 2022, division:1, contestDate: "03/01/2023")
   *
   * - By sportCode and seasonYear and division and week for football (divison 11 for fbs, 12 for fcs): (sportCode: MBB, seasonYear: 2022, division:11, week:2)
   *
   * - By sportCode and seasonYear and division and seoname to get all the games for a specific team: (sportCode: MBB, seasonYear: 2022, division:1, seoname: "duke")
   *
   * - By contestDate: (contestDate: "03/01/2023")
   *
   * - By contestDate and sportCode: (sportCode: MBB, contestDate: "03/01/2023")
   *
   * - By seasonYear and sportCode: (sportCode: MBB, seasonYear: 2022) - limited to 100
   *
   * - By seasonYear, sportCode and division: (sportCode: MBB, seasonYear: 2022, division: 1) - limited to 100
   *
   * - By array of contest ids (contestIds: [12345,12346,12347])
   *
   * - Find team and opponent in a season:
   *
   * --	All games in season for a team: (sportCode: MBB, seasonYear: 2022, division:1, seoname: "duke")
   *
   * --	All games for 2 teams in a season: (sportCode: MBB, seasonYear: 2022, division:1, seoname: "duke", opponentSeoname: "north-carolina")
   *
   * -- A date when a team has a game: (sportCode: MBB, seasonYear: 2022, division:1, seoname: "duke", contestDate: "03/01/2023")
   */
  contests: Array<Maybe<Contest>>;
  /** Get a list of embed Videos for MML */
  embedMMLVideos: Array<Maybe<EmbedVideo>>;
  /**
   * 	Get list of events
   * 	Example query parameters combinations:
   *
   * - By event id (id: 4999)
   *
   * - By eventDate (eventDate: "12/31/2023")
   *
   * - By sportCode
   *
   * - By month(1-12) and year(YYYY) and/or sportCode
   *
   * - By array of event ids (eventIds: [12345,12346,12347])
   */
  events: Array<Maybe<Event>>;
  /** Get list of gametool audit for mutation, by hour to get the last x hours(default is 2) and the mutationName(can be null) */
  gametoolAudit: Array<Maybe<GametoolAudit>>;
  /** Get Genius game(s) for championship, to show the game details or get the game id for gametool */
  geniusGameDetails?: Maybe<Array<Maybe<GeniusGameDetail>>>;
  /** Get Genius Sports live game detailed status by sportCode and gameId (only for sport current season and when the game is in-progress) */
  geniusLiveGameDetailedStatus?: Maybe<GeniusContestStatus>;
  getLogs?: Maybe<Array<Maybe<LogMessageModel>>>;
  /** Get latest timestamp from db */
  heartbeat: Array<Maybe<Heartbeat>>;
  /** get linescore by contestId */
  linescores: Array<Maybe<Linescore>>;
  /** Get Link info by Id */
  link: Array<Maybe<Link>>;
  /** Get games/events with live video */
  liveVideos?: Maybe<LiveVideo>;
  /** List of Location information or query by location id or by championshipId */
  locations: Array<Maybe<Location>>;
  /**
   * Get MML conference information for MBB-WBB by teamId or by seasonYear and/or (month, conferenceId, conferenceName).
   * If conferenceId or conferenceName is passed together with tournamentData set to true, it will return conference tournament data (if useApi is true it will attempt to get data from ncaa api instead of from DB)
   */
  mmlConferences?: Maybe<Array<Maybe<Conference>>>;
  /**
   * 	Get MML games for a particular season, it is an alias to championshipGames query
   * 	Example query parameters combinations:
   *
   * - By seasonYear (seasonYear: 2019) - get all 67 games for season 2019, champYear 2020
   *
   * - By seasonYear and bracketId (seasonYear:2019, bracketId: 101) - get only one bracket
   *
   * - By seasonYear and current flag (seasonYear:2019, current:true) - get all games that are current, meaning in-progress or video on-air
   *
   * - By seasonYear and roundNumber (seasonYear:2019, roundNumber: 3) - get all games in the 3rd round (roundNumber are from 1-6)
   *
   * - By contestId (contestId: 123456) - contestId is the unique id found for a game, this works for conference tournament game only
   */
  mmlContests: Array<Maybe<ChampionshipGame>>;
  /**
   * 	Return the next bracketId for continuous video play, given the following conditions:
   *
   * - mobile and web: fastbreak id if available(onAir), then try next game with earliest start time (mmlVideo true, pregame or live), then try event (not fastbreak and not montage), then montage, then []
   *
   * - connected: same as mobile/web but ignore CBS games
   */
  mmlContinuousPlay: Array<Maybe<BracketIds>>;
  /** Get latest voice briefing by seasonYear */
  mmlDailyBriefing: Array<Maybe<Briefing>>;
  /**
   * get a list of mml event by championshipId or (championshipId and bracketId)
   * or by seasonYear only
   * or championshipId and eventType to get list of next bracketId for eventType
   */
  mmlEvents: Array<Maybe<MmlEvent>>;
  /** Get latest mml leaders by seasonYear, seasonType */
  mmlLeaders: Array<Maybe<Leaders>>;
  mmlNetRankings: Array<Maybe<Rankings>>;
  /**
   * 	Get MML onboarding by seasonYear
   * 	Example query parameters combinations:
   *
   * - By seasonYear and all devices (seasonYear: 2021, deviceType: null) or (seasonYear:2021)
   *
   * - By seasonYear and a specific device (seasonYear: 2021, deviceType: "iphone")
   *
   * - deviceType could be: iphone,ipad,mac,androidphone,androidtab or firetab
   */
  mmlOnboarding: Array<Maybe<MmlOnboarding>>;
  /**
   * Get a list of MML stream by championshiopId or (championshipId and bracketId)
   * or by seasonYear only
   * or by seasonYear and gameDate (MM/DD/YYYY)
   * or by seasonYear and bracketId
   */
  mmlStreams: Array<Maybe<MmlStream>>;
  /** Get all MML Team info */
  mmlTeams: Array<Maybe<ChampionshipTeam>>;
  /** same as champinships but by seasonYear, for MML only */
  mmlTournament: Array<Maybe<Championship>>;
  /** Get MML video by seasonYear, bracketId and videoType */
  mmlVideos?: Maybe<MmlVideo>;
  /**
   * Get all 67 contests by seasonYear - season year 2019 is for 2020 MML (champYear) or with bracketId
   * optional current flag for current games
   * or by seasonYear and roundNumber
   */
  mmlWContests: Array<Maybe<ChampionshipGame>>;
  mmlWNetRankings: Array<Maybe<Rankings>>;
  /** Get all women MML Team info */
  mmlWTeams: Array<Maybe<ChampionshipTeam>>;
  /** same as champinships but by seasonYear, for MML(Women) only */
  mmlWTournament: Array<Maybe<Championship>>;
  /** get list of rundown/currentshow entries for gametool events by montageId */
  ncaaTimemachine: Array<Maybe<CurrentShow>>;
  /** get play widget */
  playWidget?: Maybe<FeaturedPlayWidget>;
  /** get playbyplay info by contestId */
  playbyplay?: Maybe<PlayByPlay>;
  playerReady?: Maybe<Scalars['Boolean']['output']>;
  /**
   * get a list of players by teamId
   * or get a list of players by teamId and season
   * or get a single player by Id
   * or get a list of players by ncaaOrgId, sportCode and division
   */
  players: Array<Maybe<Player>>;
  /** Get preview by id */
  previewArticle?: Maybe<PreviewArticle>;
  /** List of Provider information or query by provider id */
  providers: Array<Maybe<Provider>>;
  /** Get latest rankings by sportCode, seasonYear, division */
  rankings: Array<Maybe<Rankings>>;
  /** Get recap by contestid */
  recapArticle?: Maybe<RecapArticle>;
  round?: Maybe<Round>;
  rounds?: Maybe<Array<Maybe<Round>>>;
  /**
   * 		Get schedules by sportCode, division, year, optional: type, month, date (DD/MM/YYYY) or week, or conference (case insensitive).
   * 		Return a array of games group by date.
   * 		Example query parameters combinations:
   *
   *  - List all MML games for the month of march: (sportCode:MBB, seasonYear:2022, division:1, type:CHAMP, month:3)
   *
   *  - List all MML games: (sportCode:MBB, seasonYear:2022, division:1, type:CHAMP)
   *
   *  - List all conference tournament games: (sportCode:MBB, seasonYear:2022, division:1, type: CONF)
   *
   *  - List all conference tournament games for the ACC conference: (sportCode:MBB, seasonYear:2022, division:1, type: CONF, conference: "acc")
   *
   *  - List all post season games (CHAMP and CONF) with conference ACC: (sportCode:MBB, seasonYear:2022, division:1, type: POST, conference: "acc")
   *
   *  - List all post season games by date: (sportCode:MBB, seasonYear:2022, division:1, type: POST, date: "03/14/2023")
   *
   *  If type is CHAMP, conference will be ignored if set as parameter and returned all CHAMP games.
   *     	A champ game on the game level has no association with a conference, you can get the conference at the team level.
   *     	Use POST type and conference filter instead.
   *
   * About current and roundNumber (only apply to type POST):
   *
   *  - if current is true and round is false, return the current day’s games only sorted by live games followed by upcoming games and final games in the order of game start time
   *
   *  - if both current and round are true, return the current round’s games sorted by live games followed by upcoming games and final games in the order of game start time
   *
   *  - if current is false and round is true, return the current round’s games by game start time
   */
  schedules?: Maybe<Schedule>;
  /** Get school info by ncaaOrgId or by seoName with gender */
  schools?: Maybe<School>;
  /** get scoring summary info by contestId */
  scoringSummary?: Maybe<ScoringSummary>;
  /** get service state */
  service?: Maybe<Service>;
  /** Get a list of snapshot databases avaliable */
  snapshots: Array<Maybe<Snapshot>>;
  /** List of Sport information such as season start/end date or query by sportCode and/or division id */
  sports: Array<Maybe<SportInfo>>;
  /** Get conference standings by sportCode, seasonYear, division, conferenceId(if not provided, get all) or conferenceName */
  standings: Array<Maybe<Standing>>;
  /** List of state information or query by state id */
  states: Array<Maybe<State>>;
  /**
   * List of all statistics type for a given sportCode - by sportCode and type"
   * List of all stats for a specific stats type - by sportCode, type, division, seasonYear and statsTypeId
   */
  statistics: Array<Maybe<Statistics>>;
  /** Get List of all game status or by status Code */
  status: Array<Maybe<Status>>;
  /** Get Stream info by Id */
  stream: Array<Maybe<Stream>>;
  /** Get List of teams by sportcode and division */
  teams: Array<Maybe<Team>>;
  /** get list of rundown/currentshow entries for mml montage events by seasonYear */
  timemachine: Array<Maybe<CurrentShow>>;
  /** Get a list of tournaments for a specifc sport and division, type could be CONF (Conference tournament) or CHAMP (Championship) */
  tournaments: Array<Maybe<Tournament>>;
  /** get current running version and build info */
  version?: Maybe<Version>;
  /**
   * Get a list of wsc catchup entries by type
   * or by type and bracketId
   */
  wscCatchup: Array<Maybe<WscContent>>;
  /** Get a list of wsc top play entries by type and season */
  wscTopPlays: Array<Maybe<WscContent>>;
  /** Get a list of wsc video entries by type or date(MM/DD/YYYY) and season */
  wscVideo: Array<Maybe<DrupalVideo>>;
  wscWVideo: Array<Maybe<DrupalVideo>>;
};

export type QueryAlertsArgs = {
  bracketId?: InputMaybe<Scalars['ID']['input']>;
  category?: InputMaybe<AlertCategory>;
  championshipId?: InputMaybe<Scalars['ID']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  project?: InputMaybe<Scalars['String']['input']>;
  seasonYear?: InputMaybe<Scalars['Int']['input']>;
  staticTestEnv?: InputMaybe<StaticTestEnv>;
};

export type QueryAppConfigArgs = {
  endpointType?: InputMaybe<Scalars['String']['input']>;
  platform?: InputMaybe<Scalars['String']['input']>;
  seasonYear: Scalars['Int']['input'];
  staticTestEnv?: InputMaybe<StaticTestEnv>;
};

export type QueryAppFeaturedArgs = {
  seasonYear: Scalars['Int']['input'];
  staticTestEnv?: InputMaybe<StaticTestEnv>;
  view?: InputMaybe<AppFeaturedView>;
};

export type QueryBoxscoreArgs = {
  contestId: Scalars['ID']['input'];
  staticTestEnv?: InputMaybe<StaticTestEnv>;
};

export type QueryBroadcastersArgs = {
  championshipId?: InputMaybe<Scalars['ID']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  staticTestEnv?: InputMaybe<StaticTestEnv>;
};

export type QueryChampionshipGameArgs = {
  bracketId?: InputMaybe<Scalars['Int']['input']>;
  seasonYear?: InputMaybe<Scalars['Int']['input']>;
  staticTestEnv?: InputMaybe<StaticTestEnv>;
};

export type QueryChampionshipGameFinderArgs = {
  bracketId: Scalars['Int']['input'];
  championshipId: Scalars['Int']['input'];
  contestId?: InputMaybe<Scalars['Int']['input']>;
  staticTestEnv?: InputMaybe<StaticTestEnv>;
  teams?: InputMaybe<Array<InputMaybe<TeamInput>>>;
};

export type QueryChampionshipGamesArgs = {
  bracketId?: InputMaybe<Scalars['Int']['input']>;
  championshipId?: InputMaybe<Scalars['Int']['input']>;
  division?: InputMaybe<Scalars['Int']['input']>;
  roundNumber?: InputMaybe<Scalars['Int']['input']>;
  season?: InputMaybe<Scalars['Int']['input']>;
  sectionId?: InputMaybe<Scalars['Int']['input']>;
  showUnstaged?: InputMaybe<Scalars['Boolean']['input']>;
  sportCode?: InputMaybe<SportCode>;
  staticTestEnv?: InputMaybe<StaticTestEnv>;
};

export type QueryChampionshipRoundsFinderArgs = {
  championshipId?: InputMaybe<Scalars['Int']['input']>;
  staticTestEnv?: InputMaybe<StaticTestEnv>;
};

export type QueryChampionshipTeamsArgs = {
  contestId?: InputMaybe<Scalars['Int']['input']>;
  division?: InputMaybe<Scalars['Int']['input']>;
  seasonYear?: InputMaybe<Scalars['Int']['input']>;
  showUnstaged?: InputMaybe<Scalars['Boolean']['input']>;
  sportCode?: InputMaybe<SportCode>;
  staticTestEnv?: InputMaybe<StaticTestEnv>;
};

export type QueryChampionshipsArgs = {
  championshipId?: InputMaybe<Scalars['ID']['input']>;
  division?: InputMaybe<Scalars['Int']['input']>;
  seasonYear?: InputMaybe<Scalars['Int']['input']>;
  showUnstaged?: InputMaybe<Scalars['Boolean']['input']>;
  sportUrl?: InputMaybe<Scalars['String']['input']>;
  staticTestEnv?: InputMaybe<StaticTestEnv>;
  year?: InputMaybe<Scalars['Int']['input']>;
};

export type QueryConferenceRecordsArgs = {
  division: Scalars['Int']['input'];
  seasonYear: Scalars['Int']['input'];
  sportCode?: InputMaybe<Scalars['String']['input']>;
  staticTestEnv?: InputMaybe<StaticTestEnv>;
};

export type QueryConferencesArgs = {
  conferenceId?: InputMaybe<Scalars['Int']['input']>;
  conferenceName?: InputMaybe<Scalars['String']['input']>;
  division: Scalars['Int']['input'];
  month?: InputMaybe<Scalars['Int']['input']>;
  seasonYear: Scalars['Int']['input'];
  sportCode: SportCode;
  staticTestEnv?: InputMaybe<StaticTestEnv>;
  teamId?: InputMaybe<Scalars['Int']['input']>;
  tournamentData?: InputMaybe<Scalars['Boolean']['input']>;
  useApi?: InputMaybe<Scalars['Boolean']['input']>;
  week?: InputMaybe<Scalars['Int']['input']>;
};

export type QueryContestTeamsArgs = {
  contestId: Scalars['ID']['input'];
  staticTestEnv?: InputMaybe<StaticTestEnv>;
};

export type QueryContestsArgs = {
  contestDate?: InputMaybe<Scalars['String']['input']>;
  contestIds?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  daysBack?: InputMaybe<Scalars['Int']['input']>;
  division?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  opponentSeoname?: InputMaybe<Scalars['String']['input']>;
  seasonYear?: InputMaybe<Scalars['Int']['input']>;
  seoname?: InputMaybe<Scalars['String']['input']>;
  sportCode?: InputMaybe<SportCode>;
  staticTestEnv?: InputMaybe<StaticTestEnv>;
  week?: InputMaybe<Scalars['Int']['input']>;
};

export type QueryEmbedMmlVideosArgs = {
  seasonYear: Scalars['Int']['input'];
  staticTestEnv?: InputMaybe<StaticTestEnv>;
};

export type QueryEventsArgs = {
  eventDate?: InputMaybe<Scalars['String']['input']>;
  eventIds?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  id?: InputMaybe<Scalars['ID']['input']>;
  month?: InputMaybe<Scalars['Int']['input']>;
  sportCode?: InputMaybe<SportCode>;
  staticTestEnv?: InputMaybe<StaticTestEnv>;
  year?: InputMaybe<Scalars['Int']['input']>;
};

export type QueryGametoolAuditArgs = {
  hour?: InputMaybe<Scalars['Int']['input']>;
  mutationName?: InputMaybe<Scalars['String']['input']>;
  staticTestEnv?: InputMaybe<StaticTestEnv>;
  user: UserInput;
};

export type QueryGeniusGameDetailsArgs = {
  division?: InputMaybe<Scalars['Int']['input']>;
  gameDate?: InputMaybe<Scalars['String']['input']>;
  roundNumber?: InputMaybe<Scalars['Int']['input']>;
  season?: InputMaybe<Scalars['Int']['input']>;
  sportCode?: InputMaybe<SportCode>;
  type?: InputMaybe<GeniusScheduleType>;
};

export type QueryGeniusLiveGameDetailedStatusArgs = {
  bracketId?: InputMaybe<Scalars['Int']['input']>;
  environment: Scalars['String']['input'];
  gameId: Scalars['String']['input'];
  killSocket?: InputMaybe<Scalars['Boolean']['input']>;
  seasonYear?: InputMaybe<Scalars['Int']['input']>;
  sportCode: SportCode;
};

export type QueryLinescoresArgs = {
  contestId: Scalars['ID']['input'];
  staticTestEnv?: InputMaybe<StaticTestEnv>;
};

export type QueryLinkArgs = {
  id: Scalars['ID']['input'];
  staticTestEnv?: InputMaybe<StaticTestEnv>;
};

export type QueryLiveVideosArgs = {
  contestDate?: InputMaybe<Scalars['String']['input']>;
  monthly?: InputMaybe<Scalars['Boolean']['input']>;
  staticTestEnv?: InputMaybe<StaticTestEnv>;
  today?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueryLocationsArgs = {
  championshipId?: InputMaybe<Scalars['ID']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  staticTestEnv?: InputMaybe<StaticTestEnv>;
};

export type QueryMmlConferencesArgs = {
  conferenceId?: InputMaybe<Scalars['Int']['input']>;
  conferenceName?: InputMaybe<Scalars['String']['input']>;
  month?: InputMaybe<Scalars['Int']['input']>;
  seasonYear: Scalars['Int']['input'];
  staticTestEnv?: InputMaybe<StaticTestEnv>;
  teamId?: InputMaybe<Scalars['Int']['input']>;
  tournamentData?: InputMaybe<Scalars['Boolean']['input']>;
  useApi?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueryMmlContestsArgs = {
  bracketId?: InputMaybe<Scalars['Int']['input']>;
  contestId?: InputMaybe<Scalars['Int']['input']>;
  current?: InputMaybe<Scalars['Boolean']['input']>;
  roundNumber?: InputMaybe<Scalars['Int']['input']>;
  seasonYear?: InputMaybe<Scalars['Int']['input']>;
  staticTestEnv?: InputMaybe<StaticTestEnv>;
};

export type QueryMmlContinuousPlayArgs = {
  bracketId: Scalars['Int']['input'];
  platformType: Platform;
  seasonYear: Scalars['Int']['input'];
  staticTestEnv?: InputMaybe<StaticTestEnv>;
};

export type QueryMmlDailyBriefingArgs = {
  seasonYear: Scalars['Int']['input'];
  staticTestEnv?: InputMaybe<StaticTestEnv>;
};

export type QueryMmlEventsArgs = {
  bracketId?: InputMaybe<Scalars['Int']['input']>;
  championshipId?: InputMaybe<Scalars['Int']['input']>;
  current?: InputMaybe<Scalars['Boolean']['input']>;
  eventType?: InputMaybe<MmlEventType>;
  seasonYear?: InputMaybe<Scalars['Int']['input']>;
  staticTestEnv?: InputMaybe<StaticTestEnv>;
};

export type QueryMmlLeadersArgs = {
  seasonType: MmlSeasonType;
  seasonYear: Scalars['Int']['input'];
  sportCode?: InputMaybe<SportCode>;
  staticTestEnv?: InputMaybe<StaticTestEnv>;
};

export type QueryMmlNetRankingsArgs = {
  seasonYear: Scalars['Int']['input'];
  staticTestEnv?: InputMaybe<StaticTestEnv>;
};

export type QueryMmlOnboardingArgs = {
  deviceType?: InputMaybe<Device>;
  seasonYear: Scalars['Int']['input'];
  staticTestEnv?: InputMaybe<StaticTestEnv>;
};

export type QueryMmlStreamsArgs = {
  bracketId?: InputMaybe<Scalars['Int']['input']>;
  championshipId?: InputMaybe<Scalars['Int']['input']>;
  gameDate?: InputMaybe<Scalars['String']['input']>;
  seasonYear?: InputMaybe<Scalars['Int']['input']>;
  staticTestEnv?: InputMaybe<StaticTestEnv>;
};

export type QueryMmlTeamsArgs = {
  seasonYear: Scalars['Int']['input'];
  staticTestEnv?: InputMaybe<StaticTestEnv>;
};

export type QueryMmlTournamentArgs = {
  seasonYear?: InputMaybe<Scalars['Int']['input']>;
  staticTestEnv?: InputMaybe<StaticTestEnv>;
};

export type QueryMmlVideosArgs = {
  bracketId?: InputMaybe<Scalars['Int']['input']>;
  seasonYear: Scalars['Int']['input'];
  staticTestEnv?: InputMaybe<StaticTestEnv>;
  videoType: MmlVideoType;
};

export type QueryMmlWContestsArgs = {
  bracketId?: InputMaybe<Scalars['Int']['input']>;
  current?: InputMaybe<Scalars['Boolean']['input']>;
  roundNumber?: InputMaybe<Scalars['Int']['input']>;
  seasonYear?: InputMaybe<Scalars['Int']['input']>;
  staticTestEnv?: InputMaybe<StaticTestEnv>;
};

export type QueryMmlWNetRankingsArgs = {
  seasonYear: Scalars['Int']['input'];
  staticTestEnv?: InputMaybe<StaticTestEnv>;
};

export type QueryMmlWTeamsArgs = {
  seasonYear: Scalars['Int']['input'];
  staticTestEnv?: InputMaybe<StaticTestEnv>;
};

export type QueryMmlWTournamentArgs = {
  seasonYear?: InputMaybe<Scalars['Int']['input']>;
  staticTestEnv?: InputMaybe<StaticTestEnv>;
};

export type QueryNcaaTimemachineArgs = {
  montageMediaId: Scalars['String']['input'];
  staticTestEnv?: InputMaybe<StaticTestEnv>;
};

export type QueryPlayWidgetArgs = {
  seasonYear: Scalars['Int']['input'];
  sportCode: SportCode;
  staticTestEnv?: InputMaybe<StaticTestEnv>;
};

export type QueryPlaybyplayArgs = {
  contestId: Scalars['ID']['input'];
  staticTestEnv?: InputMaybe<StaticTestEnv>;
};

export type QueryPlayersArgs = {
  division?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  ncaaOrgId?: InputMaybe<Scalars['Int']['input']>;
  season?: InputMaybe<Scalars['Int']['input']>;
  sportCode?: InputMaybe<SportCode>;
  staticTestEnv?: InputMaybe<StaticTestEnv>;
  teamId?: InputMaybe<Scalars['Int']['input']>;
};

export type QueryPreviewArticleArgs = {
  contestId: Scalars['ID']['input'];
};

export type QueryProvidersArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
  staticTestEnv?: InputMaybe<StaticTestEnv>;
};

export type QueryRankingsArgs = {
  division: Scalars['Int']['input'];
  seasonYear?: InputMaybe<Scalars['Int']['input']>;
  sportCode: SportCode;
  staticTestEnv?: InputMaybe<StaticTestEnv>;
};

export type QueryRecapArticleArgs = {
  contestId: Scalars['ID']['input'];
};

export type QueryRoundArgs = {
  roundNumber: Scalars['Int']['input'];
};

export type QuerySchedulesArgs = {
  conference?: InputMaybe<Scalars['String']['input']>;
  current?: InputMaybe<Scalars['Boolean']['input']>;
  date?: InputMaybe<Scalars['String']['input']>;
  division: Scalars['Int']['input'];
  month?: InputMaybe<Scalars['Int']['input']>;
  round?: InputMaybe<Scalars['Boolean']['input']>;
  seasonYear: Scalars['Int']['input'];
  sportCode: SportCode;
  staticTestEnv?: InputMaybe<StaticTestEnv>;
  type?: InputMaybe<TournamentType>;
};

export type QuerySchoolsArgs = {
  gender?: InputMaybe<Gender>;
  ncaaOrgId?: InputMaybe<Scalars['Int']['input']>;
  seoName?: InputMaybe<Scalars['String']['input']>;
  staticTestEnv?: InputMaybe<StaticTestEnv>;
};

export type QueryScoringSummaryArgs = {
  contestId: Scalars['ID']['input'];
  staticTestEnv?: InputMaybe<StaticTestEnv>;
};

export type QueryServiceArgs = {
  staticTestEnv?: InputMaybe<StaticTestEnv>;
};

export type QuerySportsArgs = {
  division?: InputMaybe<Scalars['Int']['input']>;
  sportCode?: InputMaybe<SportCode>;
  staticTestEnv?: InputMaybe<StaticTestEnv>;
};

export type QueryStandingsArgs = {
  conferenceId?: InputMaybe<Scalars['Int']['input']>;
  conferenceName?: InputMaybe<Scalars['String']['input']>;
  division?: InputMaybe<Scalars['Int']['input']>;
  seasonYear?: InputMaybe<Scalars['Int']['input']>;
  sportCode?: InputMaybe<SportCode>;
  staticTestEnv?: InputMaybe<StaticTestEnv>;
};

export type QueryStatesArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
  staticTestEnv?: InputMaybe<StaticTestEnv>;
};

export type QueryStatisticsArgs = {
  division: Scalars['Int']['input'];
  seasonYear: Scalars['Int']['input'];
  sportCode: SportCode;
  staticTestEnv?: InputMaybe<StaticTestEnv>;
  statsTypeId?: InputMaybe<Scalars['Int']['input']>;
  type?: InputMaybe<StatisticsType>;
};

export type QueryStatusArgs = {
  code?: InputMaybe<StatusCode>;
  staticTestEnv?: InputMaybe<StaticTestEnv>;
};

export type QueryStreamArgs = {
  id: Scalars['ID']['input'];
  staticTestEnv?: InputMaybe<StaticTestEnv>;
};

export type QueryTeamsArgs = {
  division?: InputMaybe<Scalars['Int']['input']>;
  ncaaOrgId?: InputMaybe<Scalars['Int']['input']>;
  sportCode?: InputMaybe<SportCode>;
  staticTestEnv?: InputMaybe<StaticTestEnv>;
  teamId?: InputMaybe<Scalars['Int']['input']>;
};

export type QueryTimemachineArgs = {
  bracketId: Scalars['Int']['input'];
  seasonYear: Scalars['Int']['input'];
  staticTestEnv?: InputMaybe<StaticTestEnv>;
};

export type QueryTournamentsArgs = {
  division: Scalars['Int']['input'];
  seasonYear: Scalars['Int']['input'];
  sportCode: SportCode;
  staticTestEnv?: InputMaybe<StaticTestEnv>;
  type: TournamentType;
};

export type QueryVersionArgs = {
  staticTestEnv?: InputMaybe<StaticTestEnv>;
};

export type QueryWscCatchupArgs = {
  bracketId: Scalars['Int']['input'];
  seasonYear: Scalars['Int']['input'];
  staticTestEnv?: InputMaybe<StaticTestEnv>;
  type?: InputMaybe<Scalars['String']['input']>;
};

export type QueryWscTopPlaysArgs = {
  bracketId: Scalars['Int']['input'];
  seasonYear: Scalars['Int']['input'];
  staticTestEnv?: InputMaybe<StaticTestEnv>;
  type?: InputMaybe<Scalars['String']['input']>;
};

export type QueryWscVideoArgs = {
  date?: InputMaybe<Scalars['String']['input']>;
  seasonYear: Scalars['Int']['input'];
  staticTestEnv?: InputMaybe<StaticTestEnv>;
  type?: InputMaybe<Scalars['String']['input']>;
};

export type QueryWscWVideoArgs = {
  date?: InputMaybe<Scalars['String']['input']>;
  seasonYear: Scalars['Int']['input'];
  staticTestEnv?: InputMaybe<StaticTestEnv>;
  type?: InputMaybe<Scalars['String']['input']>;
};

export type RankedBasketball = {
  __typename?: 'RankedBasketball';
  assists?: Maybe<Scalars['String']['output']>;
  assistsPerGame?: Maybe<Scalars['String']['output']>;
  average?: Maybe<Scalars['String']['output']>;
  blockedShots?: Maybe<Scalars['String']['output']>;
  blockedShotsPerGame?: Maybe<Scalars['String']['output']>;
  class?: Maybe<Scalars['String']['output']>;
  defensiveRebounds?: Maybe<Scalars['String']['output']>;
  disqualifications?: Maybe<Scalars['String']['output']>;
  doubleDouble?: Maybe<Scalars['String']['output']>;
  fieldGoalPercentage?: Maybe<Scalars['String']['output']>;
  fieldGoals?: Maybe<Scalars['String']['output']>;
  fieldGoalsAttempted?: Maybe<Scalars['String']['output']>;
  finalPoints?: Maybe<Scalars['String']['output']>;
  fouls?: Maybe<Scalars['String']['output']>;
  freeThrowPercentage?: Maybe<Scalars['String']['output']>;
  freeThrows?: Maybe<Scalars['String']['output']>;
  freeThrowsAttempted?: Maybe<Scalars['String']['output']>;
  games?: Maybe<Scalars['String']['output']>;
  height?: Maybe<Scalars['String']['output']>;
  lost?: Maybe<Scalars['String']['output']>;
  margin?: Maybe<Scalars['String']['output']>;
  minutesPlayed?: Maybe<Scalars['String']['output']>;
  minutesPlayerPerGame?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  offensiveRebounds?: Maybe<Scalars['String']['output']>;
  opponentFieldGoalPercentage?: Maybe<Scalars['String']['output']>;
  opponentFieldGoals?: Maybe<Scalars['String']['output']>;
  opponentFieldGoalsAttempted?: Maybe<Scalars['String']['output']>;
  opponentPoints?: Maybe<Scalars['String']['output']>;
  opponentPointsPerGame?: Maybe<Scalars['String']['output']>;
  opponentRebounds?: Maybe<Scalars['String']['output']>;
  opponentReboundsPerGame?: Maybe<Scalars['String']['output']>;
  opponentThreePointFieldGoalPercentage?: Maybe<Scalars['String']['output']>;
  opponentThreePointFieldGoals?: Maybe<Scalars['String']['output']>;
  opponentThreePointFieldGoalsAttempted?: Maybe<Scalars['String']['output']>;
  opponentTurnovers?: Maybe<Scalars['String']['output']>;
  percentage?: Maybe<Scalars['String']['output']>;
  personalFoulsPerGame?: Maybe<Scalars['String']['output']>;
  points?: Maybe<Scalars['String']['output']>;
  pointsPerGame?: Maybe<Scalars['String']['output']>;
  position?: Maybe<Scalars['String']['output']>;
  rank?: Maybe<Scalars['Int']['output']>;
  ratio?: Maybe<Scalars['String']['output']>;
  reboundMargin?: Maybe<Scalars['String']['output']>;
  rebounds?: Maybe<Scalars['String']['output']>;
  reboundsPerGame?: Maybe<Scalars['String']['output']>;
  scoringMargin?: Maybe<Scalars['String']['output']>;
  sportCode?: Maybe<Scalars['String']['output']>;
  steals?: Maybe<Scalars['String']['output']>;
  stealsPerGame?: Maybe<Scalars['String']['output']>;
  team?: Maybe<Scalars['String']['output']>;
  teamGames?: Maybe<Scalars['String']['output']>;
  threePointFieldGoal?: Maybe<Scalars['String']['output']>;
  threePointFieldGoalAttempted?: Maybe<Scalars['String']['output']>;
  threePointFieldGoalPercentage?: Maybe<Scalars['String']['output']>;
  threePointFieldGoalsPerGame?: Maybe<Scalars['String']['output']>;
  tripleDoubles?: Maybe<Scalars['String']['output']>;
  turnovers?: Maybe<Scalars['String']['output']>;
  turnoversPerGame?: Maybe<Scalars['String']['output']>;
  won?: Maybe<Scalars['String']['output']>;
};

export type RankedFieldHockey = {
  __typename?: 'RankedFieldHockey';
  assists?: Maybe<Scalars['String']['output']>;
  average?: Maybe<Scalars['String']['output']>;
  class?: Maybe<Scalars['String']['output']>;
  defensiveSaves?: Maybe<Scalars['String']['output']>;
  games?: Maybe<Scalars['String']['output']>;
  goalKeeperMinutes?: Maybe<Scalars['String']['output']>;
  goals?: Maybe<Scalars['String']['output']>;
  goalsAgainstAverage?: Maybe<Scalars['String']['output']>;
  goalsAllowed?: Maybe<Scalars['String']['output']>;
  height?: Maybe<Scalars['String']['output']>;
  loss?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  penaltyCorners?: Maybe<Scalars['String']['output']>;
  perGame?: Maybe<Scalars['String']['output']>;
  percentage?: Maybe<Scalars['String']['output']>;
  points?: Maybe<Scalars['String']['output']>;
  position?: Maybe<Scalars['String']['output']>;
  rank?: Maybe<Scalars['Int']['output']>;
  saves?: Maybe<Scalars['String']['output']>;
  shutouts?: Maybe<Scalars['String']['output']>;
  sportCode?: Maybe<Scalars['String']['output']>;
  team?: Maybe<Scalars['String']['output']>;
  teamMinutes?: Maybe<Scalars['String']['output']>;
  won?: Maybe<Scalars['String']['output']>;
};

export type RankedFootball = {
  __typename?: 'RankedFootball';
  assisstedPassSack?: Maybe<Scalars['String']['output']>;
  assisstedTackles?: Maybe<Scalars['String']['output']>;
  assistedTackleForLoss?: Maybe<Scalars['String']['output']>;
  averageTimeOfPossession?: Maybe<Scalars['String']['output']>;
  blockedKicks?: Maybe<Scalars['String']['output']>;
  class?: Maybe<Scalars['String']['output']>;
  defensiveExtraPoints?: Maybe<Scalars['String']['output']>;
  extraPointsAttempted?: Maybe<Scalars['String']['output']>;
  fieldGoalAttempts?: Maybe<Scalars['String']['output']>;
  fieldGoals?: Maybe<Scalars['String']['output']>;
  fieldGoalsPerGame?: Maybe<Scalars['String']['output']>;
  firstDowns?: Maybe<Scalars['String']['output']>;
  forcedFumbles?: Maybe<Scalars['String']['output']>;
  forcedFumblesPerGame?: Maybe<Scalars['String']['output']>;
  fourthDownConversion?: Maybe<Scalars['String']['output']>;
  fourthDownConversionAttempt?: Maybe<Scalars['String']['output']>;
  fumbleReturnTds?: Maybe<Scalars['String']['output']>;
  fumblesGained?: Maybe<Scalars['String']['output']>;
  fumblesLost?: Maybe<Scalars['String']['output']>;
  games?: Maybe<Scalars['String']['output']>;
  height?: Maybe<Scalars['String']['output']>;
  interceptionPerGame?: Maybe<Scalars['String']['output']>;
  interceptionReturnTds?: Maybe<Scalars['String']['output']>;
  interceptionYards?: Maybe<Scalars['String']['output']>;
  kickReturnTds?: Maybe<Scalars['String']['output']>;
  kickoffReturnYards?: Maybe<Scalars['String']['output']>;
  kickoffReturns?: Maybe<Scalars['String']['output']>;
  kickoffTouchbacks?: Maybe<Scalars['String']['output']>;
  losses?: Maybe<Scalars['String']['output']>;
  margin?: Maybe<Scalars['String']['output']>;
  miscYards?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  netYards?: Maybe<Scalars['String']['output']>;
  offensiveTds?: Maybe<Scalars['String']['output']>;
  oppDXP?: Maybe<Scalars['String']['output']>;
  oppFGM?: Maybe<Scalars['String']['output']>;
  oppSaf?: Maybe<Scalars['String']['output']>;
  oppXP?: Maybe<Scalars['String']['output']>;
  opponentAssistedTacklesForLoss?: Maybe<Scalars['String']['output']>;
  opponentBlockedKicks?: Maybe<Scalars['String']['output']>;
  opponentFirstDowns?: Maybe<Scalars['String']['output']>;
  opponentFourthDownConversion?: Maybe<Scalars['String']['output']>;
  opponentFourthDownConversionAttempt?: Maybe<Scalars['String']['output']>;
  opponentInterceptions?: Maybe<Scalars['String']['output']>;
  opponentKickoffReturn?: Maybe<Scalars['String']['output']>;
  opponentKickoffReturnTds?: Maybe<Scalars['String']['output']>;
  opponentKickoffReturnYards?: Maybe<Scalars['String']['output']>;
  opponentPassTds?: Maybe<Scalars['String']['output']>;
  opponentPassYards?: Maybe<Scalars['String']['output']>;
  opponentPasses?: Maybe<Scalars['String']['output']>;
  opponentPassesComplete?: Maybe<Scalars['String']['output']>;
  opponentPassingFirstDowns?: Maybe<Scalars['String']['output']>;
  opponentPenaltyFirstDowns?: Maybe<Scalars['String']['output']>;
  opponentPuntReturn?: Maybe<Scalars['String']['output']>;
  opponentPuntReturnTds?: Maybe<Scalars['String']['output']>;
  opponentPuntReturnYards?: Maybe<Scalars['String']['output']>;
  opponentPunts?: Maybe<Scalars['String']['output']>;
  opponentRedZoneAttempts?: Maybe<Scalars['String']['output']>;
  opponentRedZoneFGMade?: Maybe<Scalars['String']['output']>;
  opponentRedZoneScores?: Maybe<Scalars['String']['output']>;
  opponentRedZoneTdPasses?: Maybe<Scalars['String']['output']>;
  opponentRedZoneTdRushes?: Maybe<Scalars['String']['output']>;
  opponentRushTds?: Maybe<Scalars['String']['output']>;
  opponentRushYards?: Maybe<Scalars['String']['output']>;
  opponentRushes?: Maybe<Scalars['String']['output']>;
  opponentRushingFirstDowns?: Maybe<Scalars['String']['output']>;
  opponentSackYards?: Maybe<Scalars['String']['output']>;
  opponentSacks?: Maybe<Scalars['String']['output']>;
  opponentSoloTacklesForLoss?: Maybe<Scalars['String']['output']>;
  opponentTackleYards?: Maybe<Scalars['String']['output']>;
  opponentTacklesForLoss?: Maybe<Scalars['String']['output']>;
  opponentThirdDownConversion?: Maybe<Scalars['String']['output']>;
  opponentThirdDownConversionAttempt?: Maybe<Scalars['String']['output']>;
  opponentTotalTds?: Maybe<Scalars['String']['output']>;
  passAttempts?: Maybe<Scalars['String']['output']>;
  passCompletions?: Maybe<Scalars['String']['output']>;
  passSackYards?: Maybe<Scalars['String']['output']>;
  passSacksPerGame?: Maybe<Scalars['String']['output']>;
  passesBrokenUp?: Maybe<Scalars['String']['output']>;
  passesDefendedPerGame?: Maybe<Scalars['String']['output']>;
  passingEfficiency?: Maybe<Scalars['String']['output']>;
  passingFirstDowns?: Maybe<Scalars['String']['output']>;
  passingTds?: Maybe<Scalars['String']['output']>;
  passingTwoPtConversions?: Maybe<Scalars['String']['output']>;
  passingYards?: Maybe<Scalars['String']['output']>;
  pat?: Maybe<Scalars['String']['output']>;
  penalties?: Maybe<Scalars['String']['output']>;
  penaltiesPerGame?: Maybe<Scalars['String']['output']>;
  penaltyFirstDowns?: Maybe<Scalars['String']['output']>;
  penaltyYards?: Maybe<Scalars['String']['output']>;
  percentage?: Maybe<Scalars['String']['output']>;
  plays?: Maybe<Scalars['String']['output']>;
  pointsPerGame?: Maybe<Scalars['String']['output']>;
  position?: Maybe<Scalars['String']['output']>;
  puntBlocksAllowed?: Maybe<Scalars['String']['output']>;
  puntReturnTds?: Maybe<Scalars['String']['output']>;
  puntReturnYards?: Maybe<Scalars['String']['output']>;
  puntReturns?: Maybe<Scalars['String']['output']>;
  punts?: Maybe<Scalars['String']['output']>;
  puntsBlocked?: Maybe<Scalars['String']['output']>;
  puntsYards?: Maybe<Scalars['String']['output']>;
  rank?: Maybe<Scalars['Int']['output']>;
  receivingTwoPtConversions?: Maybe<Scalars['String']['output']>;
  receivingYards?: Maybe<Scalars['String']['output']>;
  receivingYardsPerGame?: Maybe<Scalars['String']['output']>;
  receptions?: Maybe<Scalars['String']['output']>;
  receptionsPerGame?: Maybe<Scalars['String']['output']>;
  redZoneAttempts?: Maybe<Scalars['String']['output']>;
  redZoneFGMade?: Maybe<Scalars['String']['output']>;
  redZoneScores?: Maybe<Scalars['String']['output']>;
  redZoneTdPasses?: Maybe<Scalars['String']['output']>;
  redZoneTdRushes?: Maybe<Scalars['String']['output']>;
  rushTwoPtConversions?: Maybe<Scalars['String']['output']>;
  rushes?: Maybe<Scalars['String']['output']>;
  rushingFirstDowns?: Maybe<Scalars['String']['output']>;
  rushingTds?: Maybe<Scalars['String']['output']>;
  rushingYards?: Maybe<Scalars['String']['output']>;
  sackYards?: Maybe<Scalars['String']['output']>;
  sacks?: Maybe<Scalars['String']['output']>;
  safties?: Maybe<Scalars['String']['output']>;
  soloPassSack?: Maybe<Scalars['String']['output']>;
  soloTackleForLoss?: Maybe<Scalars['String']['output']>;
  soloTackles?: Maybe<Scalars['String']['output']>;
  soloTacklesPerGame?: Maybe<Scalars['String']['output']>;
  sportCode?: Maybe<Scalars['String']['output']>;
  tackleYards?: Maybe<Scalars['String']['output']>;
  tacklesForLossPerGame?: Maybe<Scalars['String']['output']>;
  tacklesPerGame?: Maybe<Scalars['String']['output']>;
  team?: Maybe<Scalars['String']['output']>;
  teamGames?: Maybe<Scalars['String']['output']>;
  thirdDownConversion?: Maybe<Scalars['String']['output']>;
  thirdDownConversionAttempt?: Maybe<Scalars['String']['output']>;
  ties?: Maybe<Scalars['String']['output']>;
  timeOfPossesion?: Maybe<Scalars['String']['output']>;
  totalOffensiveYards?: Maybe<Scalars['String']['output']>;
  totalPassSacks?: Maybe<Scalars['String']['output']>;
  totalPassesDefended?: Maybe<Scalars['String']['output']>;
  totalTackles?: Maybe<Scalars['String']['output']>;
  totalTacklesForLoss?: Maybe<Scalars['String']['output']>;
  touchbacks?: Maybe<Scalars['String']['output']>;
  touchdown?: Maybe<Scalars['String']['output']>;
  turnoverLost?: Maybe<Scalars['String']['output']>;
  turnoversGained?: Maybe<Scalars['String']['output']>;
  twoPtConversions?: Maybe<Scalars['String']['output']>;
  wins?: Maybe<Scalars['String']['output']>;
  yards?: Maybe<Scalars['String']['output']>;
  yardsPerAttempt?: Maybe<Scalars['String']['output']>;
  yardsPerCarry?: Maybe<Scalars['String']['output']>;
  yardsPerCompletion?: Maybe<Scalars['String']['output']>;
  yardsPerGame?: Maybe<Scalars['String']['output']>;
  yardsPerPlay?: Maybe<Scalars['String']['output']>;
};

export type RankedIceHockey = {
  __typename?: 'RankedIceHockey';
  assists?: Maybe<Scalars['String']['output']>;
  average?: Maybe<Scalars['String']['output']>;
  class?: Maybe<Scalars['String']['output']>;
  faceoffsLost?: Maybe<Scalars['String']['output']>;
  faceoffsWon?: Maybe<Scalars['String']['output']>;
  gameWinningGoals?: Maybe<Scalars['String']['output']>;
  games?: Maybe<Scalars['String']['output']>;
  gamesPlayed?: Maybe<Scalars['String']['output']>;
  goalieLoss?: Maybe<Scalars['String']['output']>;
  goalieMinutes?: Maybe<Scalars['String']['output']>;
  goalieTied?: Maybe<Scalars['String']['output']>;
  goalieWon?: Maybe<Scalars['String']['output']>;
  goals?: Maybe<Scalars['String']['output']>;
  goalsAgainstAverage?: Maybe<Scalars['String']['output']>;
  goalsAllowed?: Maybe<Scalars['String']['output']>;
  hatTricks?: Maybe<Scalars['String']['output']>;
  height?: Maybe<Scalars['String']['output']>;
  loss?: Maybe<Scalars['String']['output']>;
  margin?: Maybe<Scalars['String']['output']>;
  minutes?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  opponentGoals?: Maybe<Scalars['String']['output']>;
  opponentPowerPlayGoals?: Maybe<Scalars['String']['output']>;
  opponentPowerPlaysAttempted?: Maybe<Scalars['String']['output']>;
  penalties?: Maybe<Scalars['String']['output']>;
  penaltyMinutes?: Maybe<Scalars['String']['output']>;
  percentage?: Maybe<Scalars['String']['output']>;
  points?: Maybe<Scalars['String']['output']>;
  position?: Maybe<Scalars['String']['output']>;
  powerPlayGoals?: Maybe<Scalars['String']['output']>;
  powerPlayGoalsAllowed?: Maybe<Scalars['String']['output']>;
  powerPlaysAttempted?: Maybe<Scalars['String']['output']>;
  rank?: Maybe<Scalars['Int']['output']>;
  saves?: Maybe<Scalars['String']['output']>;
  shortHandedGoals?: Maybe<Scalars['String']['output']>;
  shutouts?: Maybe<Scalars['String']['output']>;
  sportCode?: Maybe<Scalars['String']['output']>;
  team?: Maybe<Scalars['String']['output']>;
  tie?: Maybe<Scalars['String']['output']>;
  wins?: Maybe<Scalars['String']['output']>;
};

export type RankedLacrosse = {
  __typename?: 'RankedLacrosse';
  assists?: Maybe<Scalars['String']['output']>;
  causedTurnovers?: Maybe<Scalars['String']['output']>;
  class?: Maybe<Scalars['String']['output']>;
  clearAttempts?: Maybe<Scalars['String']['output']>;
  clears?: Maybe<Scalars['String']['output']>;
  emoAttempts?: Maybe<Scalars['String']['output']>;
  emoGoals?: Maybe<Scalars['String']['output']>;
  emoGoalsAllowed?: Maybe<Scalars['String']['output']>;
  faceoffsLost?: Maybe<Scalars['String']['output']>;
  faceoffsTaken?: Maybe<Scalars['String']['output']>;
  faceoffsWon?: Maybe<Scalars['String']['output']>;
  games?: Maybe<Scalars['String']['output']>;
  goals?: Maybe<Scalars['String']['output']>;
  goalsAgainstAverage?: Maybe<Scalars['String']['output']>;
  goalsAllowed?: Maybe<Scalars['String']['output']>;
  groundBalls?: Maybe<Scalars['String']['output']>;
  height?: Maybe<Scalars['String']['output']>;
  lost?: Maybe<Scalars['String']['output']>;
  margin?: Maybe<Scalars['String']['output']>;
  minutesPlayed?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  opponentClearAttempts?: Maybe<Scalars['String']['output']>;
  opponentClears?: Maybe<Scalars['String']['output']>;
  opponentEmoAttempts?: Maybe<Scalars['String']['output']>;
  perGame?: Maybe<Scalars['String']['output']>;
  percentage?: Maybe<Scalars['String']['output']>;
  points?: Maybe<Scalars['String']['output']>;
  position?: Maybe<Scalars['String']['output']>;
  rank?: Maybe<Scalars['Int']['output']>;
  saves?: Maybe<Scalars['String']['output']>;
  shots?: Maybe<Scalars['String']['output']>;
  sportCode?: Maybe<Scalars['String']['output']>;
  team?: Maybe<Scalars['String']['output']>;
  teamFaceoffsTaken?: Maybe<Scalars['String']['output']>;
  teamMinutes?: Maybe<Scalars['String']['output']>;
  turnovers?: Maybe<Scalars['String']['output']>;
  won?: Maybe<Scalars['String']['output']>;
};

export type RankedSeries = {
  __typename?: 'RankedSeries';
  atBats?: Maybe<Scalars['String']['output']>;
  basesOnBallsAllowed?: Maybe<Scalars['String']['output']>;
  battingAverage?: Maybe<Scalars['String']['output']>;
  caughtStealing?: Maybe<Scalars['String']['output']>;
  class?: Maybe<Scalars['String']['output']>;
  completeGames?: Maybe<Scalars['String']['output']>;
  doubles?: Maybe<Scalars['String']['output']>;
  earnedRunAverage?: Maybe<Scalars['String']['output']>;
  earnedRuns?: Maybe<Scalars['String']['output']>;
  games?: Maybe<Scalars['String']['output']>;
  gamesStarted?: Maybe<Scalars['String']['output']>;
  height?: Maybe<Scalars['String']['output']>;
  hitBatters?: Maybe<Scalars['String']['output']>;
  hitByPitch?: Maybe<Scalars['String']['output']>;
  hits?: Maybe<Scalars['String']['output']>;
  hitsAllowed?: Maybe<Scalars['String']['output']>;
  hittingStrikeouts?: Maybe<Scalars['String']['output']>;
  hittingStrikoutAverage?: Maybe<Scalars['String']['output']>;
  homeRuns?: Maybe<Scalars['String']['output']>;
  inningsPitched?: Maybe<Scalars['String']['output']>;
  lost?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  perGame?: Maybe<Scalars['String']['output']>;
  percentage?: Maybe<Scalars['String']['output']>;
  pitchingApperances?: Maybe<Scalars['String']['output']>;
  position?: Maybe<Scalars['String']['output']>;
  rank?: Maybe<Scalars['Int']['output']>;
  runs?: Maybe<Scalars['String']['output']>;
  runsBattedIn?: Maybe<Scalars['String']['output']>;
  sacrificeBunts?: Maybe<Scalars['String']['output']>;
  sacrificeFlies?: Maybe<Scalars['String']['output']>;
  sacrificeHits?: Maybe<Scalars['String']['output']>;
  saves?: Maybe<Scalars['String']['output']>;
  shutouts?: Maybe<Scalars['String']['output']>;
  sluggingPercentage?: Maybe<Scalars['String']['output']>;
  sportCode?: Maybe<Scalars['String']['output']>;
  stolenBaseAttempts?: Maybe<Scalars['String']['output']>;
  stolenBases?: Maybe<Scalars['String']['output']>;
  strikeoutToWalkRatio?: Maybe<Scalars['String']['output']>;
  strikeouts?: Maybe<Scalars['String']['output']>;
  strikeoutsPerNineInnings?: Maybe<Scalars['String']['output']>;
  strikeoutsPerSevenInnings?: Maybe<Scalars['String']['output']>;
  team?: Maybe<Scalars['String']['output']>;
  totalBases?: Maybe<Scalars['String']['output']>;
  triples?: Maybe<Scalars['String']['output']>;
  walks?: Maybe<Scalars['String']['output']>;
  whip?: Maybe<Scalars['String']['output']>;
  won?: Maybe<Scalars['String']['output']>;
};

export type RankedSoccer = {
  __typename?: 'RankedSoccer';
  assists?: Maybe<Scalars['String']['output']>;
  class?: Maybe<Scalars['String']['output']>;
  cornerKicks?: Maybe<Scalars['String']['output']>;
  fouls?: Maybe<Scalars['String']['output']>;
  gameWinningGoals?: Maybe<Scalars['String']['output']>;
  games?: Maybe<Scalars['String']['output']>;
  goalieGamesPlayed?: Maybe<Scalars['String']['output']>;
  goalieMinutesPlayed?: Maybe<Scalars['String']['output']>;
  goals?: Maybe<Scalars['String']['output']>;
  goalsAgainstAverage?: Maybe<Scalars['String']['output']>;
  goalsAllowed?: Maybe<Scalars['String']['output']>;
  height?: Maybe<Scalars['String']['output']>;
  loss?: Maybe<Scalars['String']['output']>;
  lost?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  penaltyStrokeAttempts?: Maybe<Scalars['String']['output']>;
  penaltyStrokes?: Maybe<Scalars['String']['output']>;
  perGame?: Maybe<Scalars['String']['output']>;
  percentage?: Maybe<Scalars['String']['output']>;
  points?: Maybe<Scalars['String']['output']>;
  position?: Maybe<Scalars['String']['output']>;
  rank?: Maybe<Scalars['Int']['output']>;
  redCards?: Maybe<Scalars['String']['output']>;
  saves?: Maybe<Scalars['String']['output']>;
  shotAttempts?: Maybe<Scalars['String']['output']>;
  shotsOnGoals?: Maybe<Scalars['String']['output']>;
  shutouts?: Maybe<Scalars['String']['output']>;
  sportCode?: Maybe<Scalars['String']['output']>;
  team?: Maybe<Scalars['String']['output']>;
  teamMinutes?: Maybe<Scalars['String']['output']>;
  tied?: Maybe<Scalars['String']['output']>;
  won?: Maybe<Scalars['String']['output']>;
  yellowCards?: Maybe<Scalars['String']['output']>;
};

export type RankedStats =
  | RankedBasketball
  | RankedFieldHockey
  | RankedFootball
  | RankedIceHockey
  | RankedLacrosse
  | RankedSeries
  | RankedSoccer
  | RankedVolleyball;

export type RankedTypes = {
  __typename?: 'RankedTypes';
  description?: Maybe<Scalars['String']['output']>;
  headers?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  statId?: Maybe<Scalars['Int']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
};

export type RankedVolleyball = {
  __typename?: 'RankedVolleyball';
  aces?: Maybe<Scalars['String']['output']>;
  assists?: Maybe<Scalars['String']['output']>;
  blockAssists?: Maybe<Scalars['String']['output']>;
  blockSolos?: Maybe<Scalars['String']['output']>;
  class?: Maybe<Scalars['String']['output']>;
  digs?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Scalars['String']['output']>;
  height?: Maybe<Scalars['String']['output']>;
  kills?: Maybe<Scalars['String']['output']>;
  lost?: Maybe<Scalars['String']['output']>;
  matchesPlayed?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  opponentAttacks?: Maybe<Scalars['String']['output']>;
  opponentErrors?: Maybe<Scalars['String']['output']>;
  opponentKills?: Maybe<Scalars['String']['output']>;
  opponentPercentage?: Maybe<Scalars['String']['output']>;
  perSet?: Maybe<Scalars['String']['output']>;
  percentage?: Maybe<Scalars['String']['output']>;
  points?: Maybe<Scalars['String']['output']>;
  position?: Maybe<Scalars['String']['output']>;
  rank?: Maybe<Scalars['Int']['output']>;
  sets?: Maybe<Scalars['String']['output']>;
  site?: Maybe<Scalars['String']['output']>;
  sportCode?: Maybe<Scalars['String']['output']>;
  team?: Maybe<Scalars['String']['output']>;
  total?: Maybe<Scalars['String']['output']>;
  totalAttacks?: Maybe<Scalars['String']['output']>;
  totalBlocks?: Maybe<Scalars['String']['output']>;
  tripleDoubles?: Maybe<Scalars['String']['output']>;
  won?: Maybe<Scalars['String']['output']>;
};

/** Team Ranking details */
export type Ranking = {
  __typename?: 'Ranking';
  conference?: Maybe<Scalars['String']['output']>;
  home_wl?: Maybe<Scalars['String']['output']>;
  nameShort?: Maybe<Scalars['String']['output']>;
  neutral_wl?: Maybe<Scalars['String']['output']>;
  non_div_wl?: Maybe<Scalars['String']['output']>;
  previous?: Maybe<Scalars['String']['output']>;
  quad_1?: Maybe<Scalars['String']['output']>;
  quad_2?: Maybe<Scalars['String']['output']>;
  quad_3?: Maybe<Scalars['String']['output']>;
  quad_4?: Maybe<Scalars['String']['output']>;
  rank?: Maybe<Scalars['String']['output']>;
  road_wl?: Maybe<Scalars['String']['output']>;
  wl?: Maybe<Scalars['String']['output']>;
};

/** Team Ranking details */
export type Rankings = {
  __typename?: 'Rankings';
  description?: Maybe<Scalars['String']['output']>;
  division?: Maybe<Scalars['Int']['output']>;
  divisionName?: Maybe<Scalars['String']['output']>;
  gender?: Maybe<Scalars['String']['output']>;
  ranking: Array<Maybe<Ranking>>;
  season?: Maybe<Scalars['String']['output']>;
  sportCode?: Maybe<Scalars['String']['output']>;
  sportUrl?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  updated?: Maybe<Scalars['String']['output']>;
  year?: Maybe<Scalars['String']['output']>;
};

export type RecapArticle = {
  __typename?: 'RecapArticle';
  content?: Maybe<Scalars['String']['output']>;
  lastUpdated?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
};

export type RegionSection = {
  __typename?: 'RegionSection';
  abbreviation?: Maybe<Scalars['String']['output']>;
  championshipId?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  position?: Maybe<Scalars['String']['output']>;
  /** This is a duplicate to position, for webdev use */
  regionCode?: Maybe<Scalars['String']['output']>;
  sectionId?: Maybe<Scalars['Int']['output']>;
  subtitle?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
};

export type Round = {
  __typename?: 'Round';
  championshipId?: Maybe<Scalars['Int']['output']>;
  endDate?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  label?: Maybe<Scalars['String']['output']>;
  roundNumber?: Maybe<Scalars['Int']['output']>;
  staged?: Maybe<Scalars['Boolean']['output']>;
  startDate?: Maybe<Scalars['String']['output']>;
  subtitle?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
};

/** Games schedule by sport/division/season */
export type Schedule = {
  __typename?: 'Schedule';
  /** conference name */
  conference?: Maybe<Scalars['String']['output']>;
  division?: Maybe<Scalars['Int']['output']>;
  /** d1, d2, d3, nc, etc */
  divisionName?: Maybe<Scalars['String']['output']>;
  games: Array<Maybe<ScheduleDetail>>;
  month?: Maybe<Scalars['Int']['output']>;
  monthName?: Maybe<Scalars['String']['output']>;
  season?: Maybe<Scalars['Int']['output']>;
  sportCode?: Maybe<SportCode>;
  sportName?: Maybe<Scalars['String']['output']>;
  sportUrl?: Maybe<Scalars['String']['output']>;
  today?: Maybe<Today>;
  type?: Maybe<TournamentType>;
  updatedTimestamp?: Maybe<Scalars['String']['output']>;
  year?: Maybe<Scalars['Int']['output']>;
};

/** Games schedule details */
export type ScheduleDetail = {
  __typename?: 'ScheduleDetail';
  contestDate?: Maybe<Scalars['String']['output']>;
  count?: Maybe<Scalars['Int']['output']>;
  day?: Maybe<Scalars['Int']['output']>;
  /** gameDetail is of type ChampionshipGame, so it inherits everything from ChampionshipGame */
  gameDetails?: Maybe<Array<Maybe<ChampionshipGame>>>;
  /** set to true only after 3am of current day and in the window of (pre tournament(first game date) or inside tournament or post tournament(champ game date) */
  isActiveDate?: Maybe<Scalars['Boolean']['output']>;
  postSeason?: Maybe<Scalars['Boolean']['output']>;
  /** compare contestDate with today, values is YESTERDAY, TODAY or TOMORROW, otherwise the full weekday name */
  relativeDate?: Maybe<Scalars['String']['output']>;
  /** the weekday of the week by default. It should be left as an empty string if the contestDate value matches today’s date and the current time is past 3AM ET. */
  relativeDateShort?: Maybe<Scalars['String']['output']>;
  /** week number, for Football only */
  week?: Maybe<Scalars['Int']['output']>;
  weekEndDate?: Maybe<Scalars['String']['output']>;
  weekStartDate?: Maybe<Scalars['String']['output']>;
  /** Name of the day: Mon/Tue/Wed etc. */
  weekday?: Maybe<Scalars['String']['output']>;
};

export type School = {
  __typename?: 'School';
  address1?: Maybe<Scalars['String']['output']>;
  address2?: Maybe<Scalars['String']['output']>;
  athleticDeptUrl?: Maybe<Scalars['String']['output']>;
  backgroundColor?: Maybe<Scalars['String']['output']>;
  city?: Maybe<Scalars['String']['output']>;
  country?: Maybe<Scalars['String']['output']>;
  foregroundColor?: Maybe<Scalars['String']['output']>;
  gender?: Maybe<Scalars['String']['output']>;
  mascotName?: Maybe<Scalars['String']['output']>;
  mmlName6char?: Maybe<Scalars['String']['output']>;
  mmlNameShort?: Maybe<Scalars['String']['output']>;
  name4char?: Maybe<Scalars['String']['output']>;
  name6char?: Maybe<Scalars['String']['output']>;
  name8char?: Maybe<Scalars['String']['output']>;
  name10char?: Maybe<Scalars['String']['output']>;
  nameAlt?: Maybe<Scalars['String']['output']>;
  nameFull?: Maybe<Scalars['String']['output']>;
  nameShort?: Maybe<Scalars['String']['output']>;
  ncaaOrgId?: Maybe<Scalars['Int']['output']>;
  schoolColors?: Maybe<Scalars['String']['output']>;
  schoolUrl?: Maybe<Scalars['String']['output']>;
  seoname?: Maybe<Scalars['String']['output']>;
  socialHashtag?: Maybe<Scalars['String']['output']>;
  sports: Array<Maybe<Team>>;
  state?: Maybe<Scalars['String']['output']>;
  zipCode?: Maybe<Scalars['String']['output']>;
};

/** Generic play info for scoring summary */
export type ScoringSummary = {
  __typename?: 'ScoringSummary';
  periods?: Maybe<Array<Maybe<ScoringSummaryDetails>>>;
  teams?: Maybe<Array<Maybe<ContestTeam>>>;
};

/** Generic playbyplay scoring summary info */
export type ScoringSummaryDetails = {
  __typename?: 'ScoringSummaryDetails';
  summary?: Maybe<Array<Maybe<GenericSummaryInfo>>>;
  title?: Maybe<Scalars['String']['output']>;
};

export enum SeasonCode {
  /** Fall */
  F = 'F',
  /** Spring */
  S = 'S',
  /** Winter */
  W = 'W',
}

export type Service = {
  __typename?: 'Service';
  OSVersion?: Maybe<Scalars['String']['output']>;
  cpuUsage?: Maybe<CpuUsage>;
  database?: Maybe<Heartbeat>;
  memoryUsage?: Maybe<MemoryUsage>;
  nodeJSVersion?: Maybe<Scalars['String']['output']>;
  sdataGraphQLReadWrite?: Maybe<Scalars['Boolean']['output']>;
  sdataGraphQLRelease?: Maybe<Version>;
  sqlCacheHits?: Maybe<Scalars['Int']['output']>;
  sqlCacheMisses?: Maybe<Scalars['Int']['output']>;
  sqlCacheQueries?: Maybe<Scalars['Int']['output']>;
  sqlCacheWrites?: Maybe<Scalars['Int']['output']>;
  uptime?: Maybe<Scalars['Float']['output']>;
};

export type ShopPromoContent = {
  __typename?: 'ShopPromoContent';
  /** content type is: */
  contentType?: Maybe<Scalars['String']['output']>;
  /** description of the shipPromo */
  description?: Maybe<Scalars['String']['output']>;
  /** sort order */
  sort?: Maybe<Scalars['Int']['output']>;
  /** url for the thumbnail */
  thumbnail?: Maybe<Scalars['String']['output']>;
  /** title of the shopPromo */
  title?: Maybe<Scalars['String']['output']>;
  /** url for the promo */
  url?: Maybe<Scalars['String']['output']>;
};

export type Snapshot = {
  __typename?: 'Snapshot';
  name?: Maybe<Scalars['String']['output']>;
};

/** Soccer specific goal stats */
export type SoccerGoalInfo = {
  __typename?: 'SoccerGoalInfo';
  firstName?: Maybe<Scalars['String']['output']>;
  lastName?: Maybe<Scalars['String']['output']>;
  time?: Maybe<Scalars['String']['output']>;
};

/** Soccer Goalie specific player stats */
export type SoccerGoalie = {
  __typename?: 'SoccerGoalie';
  gamesPlayed?: Maybe<Scalars['Boolean']['output']>;
  gamesStarted?: Maybe<Scalars['Boolean']['output']>;
  goalsAllowed?: Maybe<Scalars['String']['output']>;
  losses?: Maybe<Scalars['String']['output']>;
  minutesPlayed?: Maybe<Scalars['String']['output']>;
  saves?: Maybe<Scalars['String']['output']>;
  savesByPeriod?: Maybe<Scalars['String']['output']>;
  shutouts?: Maybe<Scalars['String']['output']>;
  ties?: Maybe<Scalars['String']['output']>;
  wins?: Maybe<Scalars['String']['output']>;
};

/** Soccer Goal specific player stats */
export type SoccerGoals = {
  __typename?: 'SoccerGoals';
  emptyNetGoals?: Maybe<Scalars['String']['output']>;
  firstGoals?: Maybe<Scalars['String']['output']>;
  gameTyingGoals?: Maybe<Scalars['String']['output']>;
  gameWinningGoals?: Maybe<Scalars['String']['output']>;
  hattricks?: Maybe<Scalars['String']['output']>;
  overtimeGoals?: Maybe<Scalars['String']['output']>;
  shootoutGoals?: Maybe<Scalars['String']['output']>;
  unassistedGoals?: Maybe<Scalars['String']['output']>;
};

/** Soccer Penalty specific player stats */
export type SoccerPenalty = {
  __typename?: 'SoccerPenalty';
  count?: Maybe<Scalars['String']['output']>;
  fouls?: Maybe<Scalars['String']['output']>;
  greenCards?: Maybe<Scalars['String']['output']>;
  redCards?: Maybe<Scalars['String']['output']>;
  yellowCards?: Maybe<Scalars['String']['output']>;
};

export enum SportCode {
  /** Baseball */
  Mba = 'MBA',
  /** Men's Basketball */
  Mbb = 'MBB',
  /** Men's Cross Country */
  Mcc = 'MCC',
  /** Football */
  Mfb = 'MFB',
  /** Men's Fencing */
  Mfe = 'MFE',
  /** Men's Golf */
  Mgo = 'MGO',
  /** Men's Gymnastics */
  Mgy = 'MGY',
  /** Men's Ice Hockey */
  Mih = 'MIH',
  /** Men's Lacrosse */
  Mla = 'MLA',
  /** Men's Rifle */
  Mri = 'MRI',
  /** Men's Skiing */
  Msk = 'MSK',
  /** Men's Soccer */
  Mso = 'MSO',
  /** Men's Swimming and Diving */
  Msw = 'MSW',
  /** Men's Tennis */
  Mte = 'MTE',
  /** Men's Track, Indoor */
  Mti = 'MTI',
  /** Men's Track, Outdoor */
  Mto = 'MTO',
  /** Men's Volleyball */
  Mvb = 'MVB',
  /** Men's Water Polo */
  Mwp = 'MWP',
  /** Wrestling */
  Mwr = 'MWR',
  /** Women's Basketball */
  Wbb = 'WBB',
  /** Bowling */
  Wbw = 'WBW',
  /** Women's Cross Country */
  Wcc = 'WCC',
  /** Rowing */
  Wcr = 'WCR',
  /** Women's Fencing */
  Wfe = 'WFE',
  /** Field Hockey */
  Wfh = 'WFH',
  /** Women's Golf */
  Wgo = 'WGO',
  /** Women's Gymnastics */
  Wgy = 'WGY',
  /** Women's Ice Hockey */
  Wih = 'WIH',
  /** Women's Lacrosse */
  Wla = 'WLA',
  /** Women's Rifle */
  Wri = 'WRI',
  /** Softball */
  Wsb = 'WSB',
  /** Women's Skiing */
  Wsk = 'WSK',
  /** Women's Soccer */
  Wso = 'WSO',
  /** Women's Beach Volleyball */
  Wsv = 'WSV',
  /** Women's Swimming and Diving */
  Wsw = 'WSW',
  /** Women's Tennis */
  Wte = 'WTE',
  /** Women's Track, Indoor */
  Wti = 'WTI',
  /** Women's Track, Outdoor */
  Wto = 'WTO',
  /** Women's Volleyball */
  Wvb = 'WVB',
  /** Women's Water Polo */
  Wwp = 'WWP',
  /** Cross Country */
  Xcc = 'XCC',
  /** Fencing */
  Xfe = 'XFE',
  /** Gymnastics */
  Xgy = 'XGY',
  /** Rifle */
  Xri = 'XRI',
  /** Skiing */
  Xsk = 'XSK',
  /** Swimming */
  Xsw = 'XSW',
  /** Tennis */
  Xte = 'XTE',
  /** Indoor Track & Field */
  Xti = 'XTI',
  /** Outdoor Track & Field */
  Xto = 'XTO',
}

export type SportInfo = {
  __typename?: 'SportInfo';
  /** academic year for the sport, season year and academic year are not always the same */
  academicYear?: Maybe<Scalars['Int']['output']>;
  /** championship year, used by GameTool to create championship bracket */
  champYear?: Maybe<Scalars['Int']['output']>;
  /** the latest season schedule info we have in the Database, for example MBB currentSeason could be 2023 until we have data for 2024 */
  currentSeason?: Maybe<Scalars['Int']['output']>;
  /** Integer representation of division: 1, 2, 3 or 11 for FBS and 12 for FCS */
  division?: Maybe<Scalars['Int']['output']>;
  /** display name for division: D1, D2, D3, FBS, FCS, etc. */
  divisionName?: Maybe<Scalars['String']['output']>;
  /** Full description of sport */
  name?: Maybe<Scalars['String']['output']>;
  /** base on the seasonCode: S for Spring, F for Fall, W for Winter or Unknown */
  season?: Maybe<Scalars['String']['output']>;
  /** F for Fall, W for Winter, S for Spring */
  seasonCode?: Maybe<SeasonCode>;
  /** season end date for current season */
  seasonEnd?: Maybe<Scalars['String']['output']>;
  /** season start date for current season */
  seasonStart?: Maybe<Scalars['String']['output']>;
  /** the same value as currentSeason until we have data for the next season */
  seasonYear?: Maybe<Scalars['Int']['output']>;
  sportCode?: Maybe<SportCode>;
  /** url name for ncaa.com for the sport */
  urlName?: Maybe<Scalars['String']['output']>;
  /** deprecated, use currentSeason */
  year?: Maybe<Scalars['Int']['output']>;
};

export type SpotlightContent = {
  __typename?: 'SpotlightContent';
  bracketId?: Maybe<Scalars['Int']['output']>;
  breakingNews?: Maybe<Scalars['Boolean']['output']>;
  broadcasterId?: Maybe<Scalars['Int']['output']>;
  byLine?: Maybe<Scalars['String']['output']>;
  closeGame?: Maybe<Scalars['Boolean']['output']>;
  contentType?: Maybe<Scalars['String']['output']>;
  countdownClock?: Maybe<CountdownDetail>;
  description?: Maybe<Scalars['String']['output']>;
  duration?: Maybe<Scalars['String']['output']>;
  highlight?: Maybe<Scalars['String']['output']>;
  highlightMobile?: Maybe<Scalars['String']['output']>;
  logo?: Maybe<Scalars['String']['output']>;
  mediaId?: Maybe<Scalars['String']['output']>;
  mmlVideo?: Maybe<Scalars['Boolean']['output']>;
  playlist: Array<Maybe<VodDetail>>;
  playlistTitle?: Maybe<Scalars['String']['output']>;
  sort?: Maybe<Scalars['Int']['output']>;
  sponsorCopy?: Maybe<Scalars['String']['output']>;
  sponsorImage?: Maybe<Scalars['String']['output']>;
  thumbnail?: Maybe<Scalars['String']['output']>;
  thumbnailMobile?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  updatedTimestamp?: Maybe<Scalars['String']['output']>;
  upsetGame?: Maybe<Scalars['Boolean']['output']>;
  url?: Maybe<Scalars['String']['output']>;
  /** added for multigamePromo */
  webUrl?: Maybe<Scalars['String']['output']>;
};

export type Standing = {
  __typename?: 'Standing';
  conferenceDisplay?: Maybe<Scalars['String']['output']>;
  conferenceId?: Maybe<Scalars['Int']['output']>;
  conferenceName?: Maybe<Scalars['String']['output']>;
  conferenceSubDivision?: Maybe<Array<Maybe<ConferenceSubDivision>>>;
  division?: Maybe<Scalars['Int']['output']>;
  divisionDisplay?: Maybe<Scalars['String']['output']>;
  lastUpdated?: Maybe<Scalars['String']['output']>;
  seasonYear?: Maybe<Scalars['Int']['output']>;
};

/** Baseball/Softball specific team stats */
export type StatBreakout = {
  __typename?: 'StatBreakout';
  lastName?: Maybe<Scalars['String']['output']>;
  numberOfPitches?: Maybe<Scalars['String']['output']>;
  seasonTotal?: Maybe<Scalars['String']['output']>;
  strikes?: Maybe<Scalars['String']['output']>;
  total?: Maybe<Scalars['String']['output']>;
};

export type State = {
  __typename?: 'State';
  /** state ap code */
  ap?: Maybe<Scalars['String']['output']>;
  /** Database unique ID */
  id?: Maybe<Scalars['ID']['output']>;
  /** state name */
  name?: Maybe<Scalars['String']['output']>;
  /** state usps code */
  usps?: Maybe<Scalars['String']['output']>;
};

export enum StaticTestEnv {
  Ncaa_1st_4 = 'ncaa_1st_4',
  NcaaChamp = 'ncaa_champ',
  NcaaConfTournament = 'ncaa_conf_tournament',
  NcaaDemo = 'ncaa_demo',
  NcaaFinal_4 = 'ncaa_final_4',
  NcaaOwner = 'ncaa_owner',
  NcaaPostSs = 'ncaa_post_ss',
  NcaaPreSs = 'ncaa_pre_ss',
  NcaaR1d2 = 'ncaa_r1d2',
  NcaaSmoketest = 'ncaa_smoketest',
  NcaaSs = 'ncaa_ss',
  NcaaSweet_16 = 'ncaa_sweet_16',
}

export type Statistics = {
  __typename?: 'Statistics';
  division?: Maybe<Scalars['Int']['output']>;
  divisionDisplay?: Maybe<Scalars['String']['output']>;
  error?: Maybe<Scalars['String']['output']>;
  games?: Maybe<Scalars['Int']['output']>;
  playerStats?: Maybe<PlayerStats>;
  rankedStats?: Maybe<Array<Maybe<RankedStats>>>;
  rankedTypes?: Maybe<Array<Maybe<RankedTypes>>>;
  seasonYear?: Maybe<Scalars['Int']['output']>;
  sportCode?: Maybe<Scalars['String']['output']>;
  teamStats?: Maybe<TeamStats>;
};

export enum StatisticsType {
  Both = 'both',
  Individual = 'individual',
  Player = 'player',
  Team = 'team',
}

export type Status = {
  __typename?: 'Status';
  code?: Maybe<StatusCode>;
  id?: Maybe<Scalars['ID']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export enum StatusCode {
  /** Forfeit */
  A = 'A',
  /** Canceled */
  C = 'C',
  /** Deleted */
  D = 'D',
  /** Final */
  F = 'F',
  /** Delayed */
  H = 'H',
  /** In-Progress */
  I = 'I',
  /** No-Contest */
  N = 'N',
  /** Postponed */
  O = 'O',
  /** Pre-Game */
  P = 'P',
  /** Suspended */
  S = 'S',
}

export type StoryContent = {
  __typename?: 'StoryContent';
  contentType?: Maybe<Scalars['String']['output']>;
  /** Logical Expression to filter the stories that conform to the specified criteria and display them in the widget */
  label?: Maybe<Scalars['String']['output']>;
};

export type Stream = {
  __typename?: 'Stream';
  athenaId?: Maybe<Scalars['Int']['output']>;
  connectedUrl?: Maybe<Scalars['String']['output']>;
  createdTimestamp?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  doNotSyndicate?: Maybe<Scalars['Boolean']['output']>;
  duration?: Maybe<Scalars['String']['output']>;
  enabled?: Maybe<Scalars['Boolean']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  key?: Maybe<Scalars['String']['output']>;
  mediaId?: Maybe<Scalars['String']['output']>;
  phoneUrl?: Maybe<Scalars['String']['output']>;
  profileId?: Maybe<Scalars['String']['output']>;
  showOnLiveSchedule?: Maybe<Scalars['String']['output']>;
  sourceType?: Maybe<Scalars['String']['output']>;
  state?: Maybe<Scalars['String']['output']>;
  stateCode?: Maybe<Scalars['Int']['output']>;
  streamId?: Maybe<Scalars['String']['output']>;
  streamType?: Maybe<Scalars['String']['output']>;
  tabletUrl?: Maybe<Scalars['String']['output']>;
  thumbnail?: Maybe<Scalars['String']['output']>;
  thumbnailLarge?: Maybe<Scalars['String']['output']>;
  thumbnailMedium?: Maybe<Scalars['String']['output']>;
  thumbnailRaw?: Maybe<Scalars['String']['output']>;
  thumbnailSmall?: Maybe<Scalars['String']['output']>;
  thumbnailXL?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  updatedTimestamp?: Maybe<Scalars['String']['output']>;
  url?: Maybe<Scalars['String']['output']>;
  videoId?: Maybe<Scalars['String']['output']>;
  vodMediaId?: Maybe<Scalars['String']['output']>;
};

export type Team = {
  __typename?: 'Team';
  conferenceId?: Maybe<Scalars['Int']['output']>;
  conferenceName?: Maybe<Scalars['String']['output']>;
  conferenceSeo?: Maybe<Scalars['String']['output']>;
  division?: Maybe<Scalars['Int']['output']>;
  divisionName?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  logoUrl?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  ncaaOrgId?: Maybe<Scalars['Int']['output']>;
  seoname?: Maybe<Scalars['String']['output']>;
  shortName?: Maybe<Scalars['String']['output']>;
  sportCode?: Maybe<Scalars['String']['output']>;
  teamId?: Maybe<Scalars['Int']['output']>;
  urlName?: Maybe<Scalars['String']['output']>;
};

export type TeamAnalysis = {
  __typename?: 'TeamAnalysis';
  adjustedPerformance?: Maybe<Scalars['String']['output']>;
  assistToTurnoverRatio?: Maybe<Scalars['String']['output']>;
  benchPointsPercentage?: Maybe<Scalars['String']['output']>;
  blockedShotsPerGame?: Maybe<Scalars['String']['output']>;
  defensiveEfficiency?: Maybe<Scalars['String']['output']>;
  effectiveFieldGoalPercentage?: Maybe<Scalars['String']['output']>;
  fastBreakPointsPerGame?: Maybe<Scalars['String']['output']>;
  fieldGoalPercentage?: Maybe<Scalars['String']['output']>;
  freeThrowPercentage?: Maybe<Scalars['String']['output']>;
  last10GamesRecord?: Maybe<Scalars['String']['output']>;
  last10GamesWinPercentage?: Maybe<Scalars['String']['output']>;
  netRanking?: Maybe<Scalars['Int']['output']>;
  offensiveEfficiency?: Maybe<Scalars['String']['output']>;
  offensiveReboundsPerGame?: Maybe<Scalars['String']['output']>;
  opponentPointsPerGame?: Maybe<Scalars['String']['output']>;
  personalFoulsPerGame?: Maybe<Scalars['String']['output']>;
  pointsFromTurnovers?: Maybe<Scalars['String']['output']>;
  pointsInThePaintPerGame?: Maybe<Scalars['String']['output']>;
  pointsPerGame?: Maybe<Scalars['String']['output']>;
  reboundsPerGame?: Maybe<Scalars['String']['output']>;
  secondChancePoints?: Maybe<Scalars['String']['output']>;
  seed?: Maybe<Scalars['Int']['output']>;
  stealsPerGame?: Maybe<Scalars['String']['output']>;
  strengthOfSchedule?: Maybe<Scalars['String']['output']>;
  threePointPercentage?: Maybe<Scalars['String']['output']>;
  trueShootingPercentage?: Maybe<Scalars['String']['output']>;
  turnoversPerGame?: Maybe<Scalars['String']['output']>;
  winPercentage?: Maybe<Scalars['String']['output']>;
};

export type TeamLogoConfig = {
  __typename?: 'TeamLogoConfig';
  color: Scalars['String']['output'];
  logoUri: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export type TeamStats =
  | TeamStatsBaseball
  | TeamStatsBasketball
  | TeamStatsFieldhockey
  | TeamStatsFootball
  | TeamStatsIcehockey
  | TeamStatsLacrosse
  | TeamStatsSoccer
  | TeamStatsSoftball
  | TeamStatsVolleyball;

/** Baseball specific team stats */
export type TeamStatsBaseball = {
  __typename?: 'TeamStatsBaseball';
  batterTotals?: Maybe<BatterTotal>;
  battingStats?: Maybe<BattingStat>;
  fieldingStats?: Maybe<FieldingStat>;
  pitcherTotals?: Maybe<PitcherTotal>;
  pitchingStats?: Maybe<PitchingStat>;
};

/** Basketball specific team stats */
export type TeamStatsBasketball = {
  __typename?: 'TeamStatsBasketball';
  /** short form for assists */
  ass?: Maybe<Scalars['String']['output']>;
  assists?: Maybe<Scalars['String']['output']>;
  /** short form for blockedShots */
  blk?: Maybe<Scalars['String']['output']>;
  blockedShots?: Maybe<Scalars['String']['output']>;
  bonus?: Maybe<Scalars['String']['output']>;
  defensiveRebounds?: Maybe<Scalars['String']['output']>;
  /** short form for fieldGoalsAttempted */
  fga?: Maybe<Scalars['String']['output']>;
  /** short form for fieldGoalsMade */
  fgm?: Maybe<Scalars['String']['output']>;
  /** short form for fieldGoalPercentage */
  fgp?: Maybe<Scalars['String']['output']>;
  fieldGoalPercentage?: Maybe<Scalars['String']['output']>;
  fieldGoalsAttempted?: Maybe<Scalars['String']['output']>;
  fieldGoalsMade?: Maybe<Scalars['String']['output']>;
  /** short form for personalFouls */
  fls?: Maybe<Scalars['String']['output']>;
  freeThrowPercentage?: Maybe<Scalars['String']['output']>;
  freeThrowsAttempted?: Maybe<Scalars['String']['output']>;
  freeThrowsMade?: Maybe<Scalars['String']['output']>;
  /** short form for freeThrowsAttempted */
  fta?: Maybe<Scalars['String']['output']>;
  /** short form for freeThrowsMade */
  ftm?: Maybe<Scalars['String']['output']>;
  /** short form for freeThrowPercentage */
  ftp?: Maybe<Scalars['String']['output']>;
  offensiveRebounds?: Maybe<Scalars['String']['output']>;
  personalFouls?: Maybe<Scalars['String']['output']>;
  points?: Maybe<Scalars['String']['output']>;
  /** short form for possession */
  pos?: Maybe<Scalars['Boolean']['output']>;
  possession?: Maybe<Scalars['Boolean']['output']>;
  /** short form for threePointsAttempted */
  pt3a?: Maybe<Scalars['String']['output']>;
  /** short form for threePointsMade */
  pt3m?: Maybe<Scalars['String']['output']>;
  /** short form for threePointPercentage */
  pt3p?: Maybe<Scalars['String']['output']>;
  /** short form for points */
  pts?: Maybe<Scalars['String']['output']>;
  /** short form for defensiveRebounds */
  rbd?: Maybe<Scalars['String']['output']>;
  /** short form for offensiveRebounds, it was rba? */
  rbo?: Maybe<Scalars['String']['output']>;
  /** short form for totalRebounds */
  reb?: Maybe<Scalars['String']['output']>;
  steals?: Maybe<Scalars['String']['output']>;
  /** short form for steals */
  stl?: Maybe<Scalars['String']['output']>;
  threePointPercentage?: Maybe<Scalars['String']['output']>;
  threePointsAttempted?: Maybe<Scalars['String']['output']>;
  threePointsMade?: Maybe<Scalars['String']['output']>;
  timeout?: Maybe<Scalars['String']['output']>;
  /** short form for timeout */
  tmo?: Maybe<Scalars['String']['output']>;
  /** short form for turnovers */
  tno?: Maybe<Scalars['String']['output']>;
  totalRebounds?: Maybe<Scalars['String']['output']>;
  turnovers?: Maybe<Scalars['String']['output']>;
};

/** Field Hockey specific team stats */
export type TeamStatsFieldhockey = {
  __typename?: 'TeamStatsFieldhockey';
  assists?: Maybe<Scalars['String']['output']>;
  corners?: Maybe<Scalars['String']['output']>;
  count?: Maybe<Scalars['String']['output']>;
  dsave?: Maybe<Scalars['String']['output']>;
  emptyNetGoals?: Maybe<Scalars['String']['output']>;
  firstGoals?: Maybe<Scalars['String']['output']>;
  fouls?: Maybe<Scalars['String']['output']>;
  gameTyingGoals?: Maybe<Scalars['String']['output']>;
  gameWinningGoals?: Maybe<Scalars['String']['output']>;
  gamesPlayedAtGoalie?: Maybe<Scalars['Boolean']['output']>;
  gamesStartedAtGoalie?: Maybe<Scalars['Boolean']['output']>;
  goalieMinutes?: Maybe<Scalars['String']['output']>;
  goalieShots?: Maybe<Scalars['String']['output']>;
  goals?: Maybe<Scalars['String']['output']>;
  goalsAllowed?: Maybe<Scalars['String']['output']>;
  green?: Maybe<Scalars['String']['output']>;
  hattricks?: Maybe<Scalars['String']['output']>;
  loss?: Maybe<Scalars['String']['output']>;
  minutes?: Maybe<Scalars['String']['output']>;
  offsides?: Maybe<Scalars['String']['output']>;
  overtimeGoals?: Maybe<Scalars['String']['output']>;
  penaltyShotGoals?: Maybe<Scalars['String']['output']>;
  penaltyShotsAttempted?: Maybe<Scalars['String']['output']>;
  red?: Maybe<Scalars['String']['output']>;
  saveByPeriod?: Maybe<Scalars['String']['output']>;
  saves?: Maybe<Scalars['String']['output']>;
  shootoutGoals?: Maybe<Scalars['String']['output']>;
  shotTotals?: Maybe<Scalars['String']['output']>;
  shots?: Maybe<Scalars['String']['output']>;
  shotsOnGoal?: Maybe<Scalars['String']['output']>;
  shutouts?: Maybe<Scalars['String']['output']>;
  tie?: Maybe<Scalars['String']['output']>;
  unassistedGoals?: Maybe<Scalars['String']['output']>;
  win?: Maybe<Scalars['String']['output']>;
  yellow?: Maybe<Scalars['String']['output']>;
};

/** Football specific team stats */
export type TeamStatsFootball = {
  __typename?: 'TeamStatsFootball';
  firstDowns?: Maybe<Scalars['String']['output']>;
  firstDownsPassing?: Maybe<Scalars['String']['output']>;
  firstDownsPenalty?: Maybe<Scalars['String']['output']>;
  firstDownsRushing?: Maybe<Scalars['String']['output']>;
  fourthDownAttempts?: Maybe<Scalars['String']['output']>;
  fourthDowns?: Maybe<Scalars['String']['output']>;
  fumbles?: Maybe<Scalars['String']['output']>;
  fumblesLost?: Maybe<Scalars['String']['output']>;
  interceptionReturnYards?: Maybe<Scalars['String']['output']>;
  interceptionReturns?: Maybe<Scalars['String']['output']>;
  kickReturnYards?: Maybe<Scalars['String']['output']>;
  kickReturns?: Maybe<Scalars['String']['output']>;
  passingAttempts?: Maybe<Scalars['String']['output']>;
  passingAverage?: Maybe<Scalars['String']['output']>;
  passingCompletions?: Maybe<Scalars['String']['output']>;
  passingInterceptions?: Maybe<Scalars['String']['output']>;
  passingYards?: Maybe<Scalars['String']['output']>;
  penalty?: Maybe<Scalars['String']['output']>;
  penaltyYards?: Maybe<Scalars['String']['output']>;
  puntReturnYards?: Maybe<Scalars['String']['output']>;
  puntReturns?: Maybe<Scalars['String']['output']>;
  puntingAverage?: Maybe<Scalars['String']['output']>;
  puntingPunts?: Maybe<Scalars['String']['output']>;
  puntingYards?: Maybe<Scalars['String']['output']>;
  rushingAttempts?: Maybe<Scalars['String']['output']>;
  rushingAverage?: Maybe<Scalars['String']['output']>;
  rushingYards?: Maybe<Scalars['String']['output']>;
  teamAverage?: Maybe<Scalars['String']['output']>;
  teamPlays?: Maybe<Scalars['String']['output']>;
  teamYards?: Maybe<Scalars['String']['output']>;
  thirdDownAttempts?: Maybe<Scalars['String']['output']>;
  thirdDowns?: Maybe<Scalars['String']['output']>;
};

/** Ice Hockey specific team stats */
export type TeamStatsIcehockey = {
  __typename?: 'TeamStatsIcehockey';
  assists?: Maybe<Scalars['String']['output']>;
  blk?: Maybe<Scalars['String']['output']>;
  count?: Maybe<Scalars['String']['output']>;
  emptyNetGoals?: Maybe<Scalars['String']['output']>;
  emptyNetGoalsAllowed?: Maybe<Scalars['String']['output']>;
  facelost?: Maybe<Scalars['String']['output']>;
  facewon?: Maybe<Scalars['String']['output']>;
  firstGoals?: Maybe<Scalars['String']['output']>;
  gameTyingGoals?: Maybe<Scalars['String']['output']>;
  gameWinningGoals?: Maybe<Scalars['String']['output']>;
  goalieMinutes?: Maybe<Scalars['String']['output']>;
  goaliePowerPlayGoalsAllowed?: Maybe<Scalars['String']['output']>;
  goals?: Maybe<Scalars['String']['output']>;
  goalsAllowed?: Maybe<Scalars['String']['output']>;
  hattricks?: Maybe<Scalars['String']['output']>;
  infractions?: Maybe<Scalars['String']['output']>;
  major?: Maybe<Scalars['String']['output']>;
  match?: Maybe<Scalars['String']['output']>;
  minor?: Maybe<Scalars['String']['output']>;
  minus?: Maybe<Scalars['String']['output']>;
  minutes?: Maybe<Scalars['String']['output']>;
  misc10?: Maybe<Scalars['String']['output']>;
  miscgame?: Maybe<Scalars['String']['output']>;
  miscgross?: Maybe<Scalars['String']['output']>;
  overtimeGoals?: Maybe<Scalars['String']['output']>;
  penaltyShotGoals?: Maybe<Scalars['String']['output']>;
  penaltyShotGoalsAlowed?: Maybe<Scalars['String']['output']>;
  penaltyShotsAttempted?: Maybe<Scalars['String']['output']>;
  plus?: Maybe<Scalars['String']['output']>;
  plusminus?: Maybe<Scalars['String']['output']>;
  powerPlayGoals?: Maybe<Scalars['String']['output']>;
  powerPlayGoalsAllowed?: Maybe<Scalars['String']['output']>;
  powerPlayOpportunities?: Maybe<Scalars['String']['output']>;
  powerPlayPercentage?: Maybe<Scalars['String']['output']>;
  saveByPeriod?: Maybe<Scalars['String']['output']>;
  saves?: Maybe<Scalars['String']['output']>;
  shootoutGoals?: Maybe<Scalars['String']['output']>;
  shootoutGoalsAllowed?: Maybe<Scalars['String']['output']>;
  shortHandedGoals?: Maybe<Scalars['String']['output']>;
  shortHandedGoalsAllowed?: Maybe<Scalars['String']['output']>;
  shots?: Maybe<Scalars['String']['output']>;
  shutouts?: Maybe<Scalars['String']['output']>;
  unassistedGoals?: Maybe<Scalars['String']['output']>;
};

/** Lacrosse specific team stats */
export type TeamStatsLacrosse = {
  __typename?: 'TeamStatsLacrosse';
  assists?: Maybe<Scalars['String']['output']>;
  causedTurnovers?: Maybe<Scalars['String']['output']>;
  drawControls?: Maybe<Scalars['String']['output']>;
  freePositionShots?: Maybe<Scalars['String']['output']>;
  goalTypes?: Maybe<LacrosseGoals>;
  goalie?: Maybe<LacrosseGoalie>;
  goals?: Maybe<Scalars['String']['output']>;
  groundBalls?: Maybe<Scalars['String']['output']>;
  minutesPlayed?: Maybe<Scalars['String']['output']>;
  penalties?: Maybe<LacrossePenalty>;
  powerPlay?: Maybe<PowerPlay>;
  secondsPlayed?: Maybe<Scalars['String']['output']>;
  shootoutShots?: Maybe<Scalars['String']['output']>;
  shots?: Maybe<Scalars['String']['output']>;
  shotsOnGoal?: Maybe<Scalars['String']['output']>;
  turnovers?: Maybe<Scalars['String']['output']>;
};

/** Soccer specific team stats */
export type TeamStatsSoccer = {
  __typename?: 'TeamStatsSoccer';
  assists?: Maybe<Scalars['String']['output']>;
  corners?: Maybe<Scalars['String']['output']>;
  fouls?: Maybe<Scalars['String']['output']>;
  goalTypes?: Maybe<SoccerGoals>;
  goalie?: Maybe<SoccerGoalie>;
  goals?: Maybe<Scalars['String']['output']>;
  minutesPlayed?: Maybe<Scalars['String']['output']>;
  offsides?: Maybe<Scalars['String']['output']>;
  penalties?: Maybe<SoccerPenalty>;
  penaltyShotAttempts?: Maybe<Scalars['String']['output']>;
  penaltyShotGoals?: Maybe<Scalars['String']['output']>;
  saves?: Maybe<Scalars['String']['output']>;
  scores?: Maybe<Array<Maybe<SoccerGoalInfo>>>;
  shots?: Maybe<Scalars['String']['output']>;
  shotsOnGoal?: Maybe<Scalars['String']['output']>;
};

/** Softball specific team stats */
export type TeamStatsSoftball = {
  __typename?: 'TeamStatsSoftball';
  batterTotals?: Maybe<BatterTotal>;
  battingStats?: Maybe<BattingStat>;
  fieldingStats?: Maybe<FieldingStat>;
  pitcherTotals?: Maybe<PitcherTotal>;
  pitchingStats?: Maybe<PitchingStat>;
};

/** Volleyball specific team stats */
export type TeamStatsVolleyball = {
  __typename?: 'TeamStatsVolleyball';
  assists?: Maybe<Scalars['String']['output']>;
  attackAttempts?: Maybe<Scalars['String']['output']>;
  attackErrors?: Maybe<Scalars['String']['output']>;
  ballHandlingErrors?: Maybe<Scalars['String']['output']>;
  blockAssists?: Maybe<Scalars['String']['output']>;
  blockSolos?: Maybe<Scalars['String']['output']>;
  blockingErrors?: Maybe<Scalars['String']['output']>;
  digs?: Maybe<Scalars['String']['output']>;
  gamesPlayed?: Maybe<Scalars['String']['output']>;
  hittingPercentage?: Maybe<Scalars['String']['output']>;
  kills?: Maybe<Scalars['String']['output']>;
  points?: Maybe<Scalars['String']['output']>;
  receptionAttempts?: Maybe<Scalars['String']['output']>;
  receptionErrors?: Maybe<Scalars['String']['output']>;
  serveAttempts?: Maybe<Scalars['String']['output']>;
  serviceAces?: Maybe<Scalars['String']['output']>;
  serviceErrors?: Maybe<Scalars['String']['output']>;
  setAttempts?: Maybe<Scalars['String']['output']>;
  setErrors?: Maybe<Scalars['String']['output']>;
  sets?: Maybe<Array<AttackGame>>;
  totalBlocks?: Maybe<Scalars['String']['output']>;
};

export enum TeamsOnDeck {
  None = 'NONE',
  One = 'ONE',
  Two = 'TWO',
}

export type Today = {
  __typename?: 'Today';
  date?: Maybe<Scalars['String']['output']>;
  day?: Maybe<Scalars['Int']['output']>;
  division?: Maybe<Scalars['Int']['output']>;
  divisionName?: Maybe<Scalars['String']['output']>;
  monthName?: Maybe<Scalars['String']['output']>;
  postSeason?: Maybe<Scalars['Boolean']['output']>;
  season?: Maybe<Scalars['Int']['output']>;
  sportCode?: Maybe<SportCode>;
  sportName?: Maybe<Scalars['String']['output']>;
  sportUrl?: Maybe<Scalars['String']['output']>;
  week?: Maybe<Scalars['Int']['output']>;
  weekday?: Maybe<Scalars['String']['output']>;
  weekdayLong?: Maybe<Scalars['String']['output']>;
  year?: Maybe<Scalars['Int']['output']>;
};

export type Tournament = {
  __typename?: 'Tournament';
  conference?: Maybe<Conference>;
  description?: Maybe<Scalars['String']['output']>;
  division?: Maybe<Scalars['Int']['output']>;
  endDate?: Maybe<Scalars['String']['output']>;
  orgConferenceId?: Maybe<Scalars['Int']['output']>;
  orgTournamentId?: Maybe<Scalars['Int']['output']>;
  seasonYear?: Maybe<Scalars['Int']['output']>;
  sportCode?: Maybe<SportCode>;
  startDate?: Maybe<Scalars['String']['output']>;
  type?: Maybe<TournamentType>;
};

export enum TournamentType {
  /** championship games only */
  Champ = 'CHAMP',
  /** conference tournament games only */
  Conf = 'CONF',
  /** Postseason - Both championship games and conference tournament games */
  Post = 'POST',
}

export type TrendingContent = {
  __typename?: 'TrendingContent';
  contentType?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  duration?: Maybe<Scalars['String']['output']>;
  mediaId?: Maybe<Scalars['String']['output']>;
  sort?: Maybe<Scalars['Int']['output']>;
  thumbnail?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  updatedTimestamp?: Maybe<Scalars['String']['output']>;
  url?: Maybe<Scalars['String']['output']>;
};

export type Version = {
  __typename?: 'Version';
  commitTag?: Maybe<Scalars['String']['output']>;
  revId?: Maybe<Scalars['String']['output']>;
};

export type VodDetail = {
  __typename?: 'VodDetail';
  /** content type is vod */
  contentType?: Maybe<Scalars['String']['output']>;
  /** description of the vod */
  description?: Maybe<Scalars['String']['output']>;
  /** duration in mm:ss */
  duration?: Maybe<Scalars['String']['output']>;
  mediaId?: Maybe<Scalars['String']['output']>;
  /** sort order */
  sort?: Maybe<Scalars['Int']['output']>;
  /** url for the thumbnail */
  thumbnail?: Maybe<Scalars['String']['output']>;
  /** title of the vod */
  title?: Maybe<Scalars['String']['output']>;
  /** last updated time */
  updatedTimestamp?: Maybe<Scalars['String']['output']>;
  /** url for the vod */
  url?: Maybe<Scalars['String']['output']>;
};

export type VodList = {
  __typename?: 'VodList';
  /** list of content for vod */
  content: Array<Maybe<VodDetail>>;
  /** content type is vod */
  contentType?: Maybe<Scalars['String']['output']>;
  /** description of this vodList group */
  description?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  /** sort order */
  sortObject?: Maybe<Scalars['Int']['output']>;
  /** title of this vodlist group */
  title?: Maybe<Scalars['String']['output']>;
  /** view is video */
  view?: Maybe<Scalars['String']['output']>;
};

/** WSC content details */
export type WscContent = {
  __typename?: 'WscContent';
  awayScore?: Maybe<Scalars['Int']['output']>;
  bracketId?: Maybe<Scalars['Int']['output']>;
  clock?: Maybe<Scalars['String']['output']>;
  duration?: Maybe<Scalars['String']['output']>;
  homeScore?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  period?: Maybe<Scalars['String']['output']>;
  playerId?: Maybe<Scalars['Int']['output']>;
  teamId?: Maybe<Scalars['Int']['output']>;
  thumbnailUrl?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  videoUrl?: Maybe<Scalars['String']['output']>;
};

export type AppConfigInput = {
  endpointName?: InputMaybe<Scalars['String']['input']>;
  endpointType?: InputMaybe<EndpointType>;
  endpointUrl?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  interval?: InputMaybe<Scalars['Int']['input']>;
  platform?: InputMaybe<Array<InputMaybe<PlatformType>>>;
  seasonYear?: InputMaybe<Scalars['Int']['input']>;
  style?: InputMaybe<Scalars['String']['input']>;
  ttl?: InputMaybe<Scalars['Int']['input']>;
};

export type AutoprogressionInput = {
  bracketId: Scalars['Int']['input'];
  championshipId: Scalars['Int']['input'];
  contestId: Scalars['Int']['input'];
  enabled: Scalars['Boolean']['input'];
  gameId: Scalars['String']['input'];
  gameState: Scalars['String']['input'];
  gameWinner: Scalars['Int']['input'];
  livestat: Scalars['Boolean']['input'];
  sportCode: Scalars['String']['input'];
  teams: Array<InputMaybe<TeamInput>>;
};

export type BriefInput = {
  updated?: InputMaybe<Scalars['String']['input']>;
  url?: InputMaybe<Scalars['String']['input']>;
  view: Scalars['String']['input'];
};

export type BroadcasterInput = {
  id?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  user: UserInput;
};

export type ChampionshipGameInput = {
  bracketId: Scalars['Int']['input'];
  broadcasterId?: InputMaybe<Scalars['Int']['input']>;
  championshipId: Scalars['Int']['input'];
  contestId: Scalars['Int']['input'];
  currentAuthority?: InputMaybe<Scalars['String']['input']>;
  enabled?: InputMaybe<Scalars['Boolean']['input']>;
  gameId?: InputMaybe<Scalars['String']['input']>;
  gameState?: InputMaybe<StatusCode>;
  gameWinner?: InputMaybe<Scalars['Int']['input']>;
  hasStartTime?: InputMaybe<Scalars['Boolean']['input']>;
  links?: InputMaybe<Array<InputMaybe<LinkInput>>>;
  liveStat?: InputMaybe<Scalars['Boolean']['input']>;
  locationId?: InputMaybe<Scalars['Int']['input']>;
  loserBracketPositionId?: InputMaybe<Scalars['Int']['input']>;
  loserGamePosition?: InputMaybe<Scalars['String']['input']>;
  providerCode?: InputMaybe<Scalars['String']['input']>;
  seriesNumber?: InputMaybe<Scalars['Int']['input']>;
  startDate?: InputMaybe<Scalars['String']['input']>;
  startTime?: InputMaybe<Scalars['String']['input']>;
  stream?: InputMaybe<StreamInput>;
  teams?: InputMaybe<Array<InputMaybe<TeamInput>>>;
  title?: InputMaybe<Scalars['String']['input']>;
  user: UserInput;
  victorBracketPositionId?: InputMaybe<Scalars['Int']['input']>;
  victorGamePosition?: InputMaybe<Scalars['String']['input']>;
  /** tip time */
  videoTime?: InputMaybe<Scalars['String']['input']>;
};

export type ChampionshipInput = {
  /** unique id of a championship */
  championshipId: Scalars['Int']['input'];
  /** end date of the tournament */
  endDate?: InputMaybe<Scalars['String']['input']>;
  regions?: InputMaybe<Array<InputMaybe<RegionInput>>>;
  rounds?: InputMaybe<Array<InputMaybe<RoundInput>>>;
  /** section is use by baseball (MBA) and WSB (softball) */
  sections?: InputMaybe<Array<InputMaybe<SectionInput>>>;
  /** start date of the tournament */
  startDate?: InputMaybe<Scalars['String']['input']>;
  /** Title of the championship */
  title?: InputMaybe<Scalars['String']['input']>;
  user: UserInput;
};

export type CondensedVideoInput = {
  bracketId: Scalars['Int']['input'];
  duration: Scalars['String']['input'];
  thumbnailUrl: Scalars['String']['input'];
  title: Scalars['String']['input'];
  videoUrl: Scalars['String']['input'];
};

/** Conference Record details */
export type ConferenceRecord = {
  __typename?: 'conferenceRecord';
  conferenceWins?: Maybe<Scalars['Int']['output']>;
  confereneLosses?: Maybe<Scalars['Int']['output']>;
  confereneTies?: Maybe<Scalars['Int']['output']>;
  geniusId?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  ncaaOrgId?: Maybe<Scalars['Int']['output']>;
  rank?: Maybe<Scalars['Int']['output']>;
  recordFound?: Maybe<Scalars['Boolean']['output']>;
  year?: Maybe<Scalars['Int']['output']>;
};

export type ConferenceTournamentInput = {
  alexaUrl?: InputMaybe<Scalars['String']['input']>;
  broadcasterId?: InputMaybe<Scalars['Int']['input']>;
  contestId?: InputMaybe<Scalars['Int']['input']>;
  externalWatchLink?: InputMaybe<Scalars['String']['input']>;
  homeSeed?: InputMaybe<Scalars['Int']['input']>;
  locationId?: InputMaybe<Scalars['Int']['input']>;
  ncaaGameId?: InputMaybe<Scalars['Int']['input']>;
  roundDescription?: InputMaybe<Scalars['String']['input']>;
  roundEndDate?: InputMaybe<Scalars['String']['input']>;
  roundNumber?: InputMaybe<Scalars['Int']['input']>;
  roundStartDate?: InputMaybe<Scalars['String']['input']>;
  visitSeed?: InputMaybe<Scalars['Int']['input']>;
};

export type EventInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  division?: InputMaybe<Scalars['Int']['input']>;
  eventId?: InputMaybe<Scalars['Int']['input']>;
  eventParent?: InputMaybe<Scalars['String']['input']>;
  links?: InputMaybe<Array<LinkInput>>;
  locationId?: InputMaybe<Scalars['Int']['input']>;
  sportCode?: InputMaybe<SportCode>;
  startDate?: InputMaybe<Scalars['String']['input']>;
  startTime?: InputMaybe<Scalars['String']['input']>;
  stream?: InputMaybe<StreamInput>;
  thumbnail?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  user: UserInput;
};

export type FeaturedInput = {
  content?: InputMaybe<Array<InputMaybe<AppFeatureContentInput>>>;
  description?: InputMaybe<Scalars['String']['input']>;
  /** used by vodplaylist to group by franchise name */
  franchise?: InputMaybe<Scalars['String']['input']>;
  /** sort order */
  sort: Scalars['Int']['input'];
  title?: InputMaybe<Scalars['String']['input']>;
  type: AppFeaturedType;
  view: AppFeaturedView;
};

export type LinkInput = {
  key?: InputMaybe<Scalars['String']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type LocationInput = {
  city?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  state?: InputMaybe<Scalars['String']['input']>;
  stateId?: InputMaybe<Scalars['Int']['input']>;
  user: UserInput;
  venue?: InputMaybe<Scalars['String']['input']>;
};

export type MmlEventInput = {
  awayName?: InputMaybe<Scalars['String']['input']>;
  bracketId: Scalars['Int']['input'];
  broadcasterId?: InputMaybe<Scalars['Int']['input']>;
  championshipId: Scalars['Int']['input'];
  description?: InputMaybe<Scalars['String']['input']>;
  encoderStatus?: InputMaybe<Scalars['String']['input']>;
  eventType?: InputMaybe<MmlEventType>;
  homeName?: InputMaybe<Scalars['String']['input']>;
  locationId?: InputMaybe<Scalars['Int']['input']>;
  mediaId?: InputMaybe<Scalars['String']['input']>;
  parentBracketId?: InputMaybe<Scalars['Int']['input']>;
  startDate?: InputMaybe<Scalars['String']['input']>;
  startTime?: InputMaybe<Scalars['String']['input']>;
  streamUrl?: InputMaybe<Scalars['String']['input']>;
  thumbnailUrl?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  user: UserInput;
  videoStatus?: InputMaybe<Scalars['String']['input']>;
  videoTime?: InputMaybe<Scalars['String']['input']>;
  videoType?: InputMaybe<Scalars['String']['input']>;
};

export type MmlOnboardingInput = {
  button?: InputMaybe<Scalars['String']['input']>;
  contentType?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  device: Array<InputMaybe<Device>>;
  sort?: InputMaybe<Scalars['Int']['input']>;
  type?: InputMaybe<OnboardingType>;
  version?: InputMaybe<Scalars['String']['input']>;
  video?: InputMaybe<Scalars['String']['input']>;
};

export type MmlStreamInput = {
  /** alexaRadioUrl is same as hlsUrl */
  alexaRadioUrl?: InputMaybe<Scalars['String']['input']>;
  archiveUrl?: InputMaybe<Scalars['String']['input']>;
  backupUrl?: InputMaybe<Scalars['String']['input']>;
  bracketId: Scalars['Int']['input'];
  championshipId: Scalars['Int']['input'];
  /** override value for mmlContinuousPlay */
  continuousPlayOverride?: InputMaybe<Scalars['Int']['input']>;
  disasterRecoveryUrl?: InputMaybe<Scalars['String']['input']>;
  drupalEncoderStatus?: InputMaybe<Scalars['String']['input']>;
  drupalRadioStatus?: InputMaybe<Scalars['String']['input']>;
  drupalVideoStatus?: InputMaybe<Scalars['String']['input']>;
  /** googleRadioUrl is same as mp3Url */
  googleRadioUrl?: InputMaybe<Scalars['String']['input']>;
  hlsUrl?: InputMaybe<Scalars['String']['input']>;
  mediaId?: InputMaybe<Scalars['String']['input']>;
  mp3Url?: InputMaybe<Scalars['String']['input']>;
  playbackUrl?: InputMaybe<Scalars['String']['input']>;
  primaryRadioUrl?: InputMaybe<Scalars['String']['input']>;
  /** spanishRadioUrl is same as spanishUrl */
  spanishRadioUrl?: InputMaybe<Scalars['String']['input']>;
  spanishUrl?: InputMaybe<Scalars['String']['input']>;
  user: UserInput;
};

export type NewChampionshipInput = {
  /** template name to be used for predefined layout and autoprogression details, for example MML: https://www.ncaa.com/modules/custom/casablanca_brackets/bracket-definition-templates/bracket_64.json */
  bracketTemplate: Scalars['String']['input'];
  /** 0 for nc, 1,2,3, division 11 for fbs and 12 for fcs football */
  division: Scalars['Int']['input'];
  /** end date of the tournament */
  endDate: Scalars['String']['input'];
  /** all the games in the tournament */
  games: Array<InputMaybe<NewGameInput>>;
  /** if set to true, create MML champ without using predefined startDate */
  ignorePredefinedDate?: InputMaybe<Scalars['Boolean']['input']>;
  /** currently not use and not set for championship games, set for regular games by schedule load */
  periodType?: InputMaybe<PeriodType>;
  regions?: InputMaybe<Array<InputMaybe<RegionInput>>>;
  rounds: Array<InputMaybe<RoundInput>>;
  /** season year, for example 2023 D1 MBB Championship, season is 2022, champ year is 2023 */
  season: Scalars['Int']['input'];
  /** only applicable for baseball and softball */
  sections?: InputMaybe<Array<InputMaybe<SectionInput>>>;
  sportCode: SportCode;
  /** start date of the tournament */
  startDate: Scalars['String']['input'];
  /** Title of the championship */
  title: Scalars['String']['input'];
  user: UserInput;
  /** champ year, for example 2023 D1 MBB Championship, season is 2022, champ year is 2023 */
  year: Scalars['Int']['input'];
};

export type NewGameInput = {
  /** the bracket id, 101, 102, 201, etc. */
  bracketPositionId?: InputMaybe<Scalars['Int']['input']>;
  /** if not enabled, it will be grey out in gametool for this particular bracket */
  enabled?: InputMaybe<Scalars['Boolean']['input']>;
  /** for best of x series game, last game */
  isIfNecessary?: InputMaybe<Scalars['Boolean']['input']>;
  /** if it is not single-elimination tournament, define the next bracket location for the loser */
  loserBracketPositionId?: InputMaybe<Scalars['Int']['input']>;
  /** (T)Top or (B)bottom */
  loserGamePosition?: InputMaybe<Scalars['String']['input']>;
  /** next bracket location for winner of this bracket */
  victorBracketPositionId?: InputMaybe<Scalars['Int']['input']>;
  /** (T)Top or (B)bottom */
  victorGamePosition?: InputMaybe<Scalars['String']['input']>;
};

export type RegionInput = {
  abbreviation?: InputMaybe<Scalars['String']['input']>;
  regionCode: Scalars['String']['input'];
  subtitle?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type RoundInput = {
  endDate?: InputMaybe<Scalars['String']['input']>;
  label?: InputMaybe<Scalars['String']['input']>;
  roundNumber: Scalars['Int']['input'];
  staged?: InputMaybe<Scalars['Boolean']['input']>;
  startDate?: InputMaybe<Scalars['String']['input']>;
  subtitle?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type SectionInput = {
  sectionId: Scalars['Int']['input'];
  subtitle?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type SportInfoInput = {
  division: Scalars['Int']['input'];
  season?: InputMaybe<SeasonCode>;
  seasonEnd?: InputMaybe<Scalars['String']['input']>;
  seasonStart?: InputMaybe<Scalars['String']['input']>;
  sportCode: SportCode;
  urlName?: InputMaybe<Scalars['String']['input']>;
  user: UserInput;
  year?: InputMaybe<Scalars['Int']['input']>;
};

export type StagingGameInput = {
  championshipId: Scalars['Int']['input'];
  games: Array<InputMaybe<StagingInput>>;
  user: UserInput;
};

export type StagingInput = {
  bracketId: Scalars['Int']['input'];
  isBottomVisible: Scalars['Boolean']['input'];
  isTopVisible: Scalars['Boolean']['input'];
};

export type StreamInput = {
  athenaId?: InputMaybe<Scalars['Int']['input']>;
  doNotSyndicate?: InputMaybe<Scalars['Boolean']['input']>;
  enabled: Scalars['Boolean']['input'];
  mediaId?: InputMaybe<Scalars['String']['input']>;
  profileId?: InputMaybe<Scalars['String']['input']>;
  stateCode: Scalars['Int']['input'];
  streamType?: InputMaybe<Scalars['String']['input']>;
  thumbnail?: InputMaybe<Scalars['String']['input']>;
  url?: InputMaybe<Scalars['String']['input']>;
  vodMediaId?: InputMaybe<Scalars['String']['input']>;
};

export type TeamInput = {
  isHome: Scalars['Boolean']['input'];
  isTop: Scalars['Boolean']['input'];
  losses?: InputMaybe<Scalars['Int']['input']>;
  rank?: InputMaybe<Scalars['Int']['input']>;
  score?: InputMaybe<Scalars['Int']['input']>;
  seed?: InputMaybe<Scalars['Int']['input']>;
  teamId: Scalars['Int']['input'];
  textOverride?: InputMaybe<Scalars['String']['input']>;
  ties?: InputMaybe<Scalars['Int']['input']>;
  wins?: InputMaybe<Scalars['Int']['input']>;
};

export type UserInput = {
  authToken?: InputMaybe<Scalars['String']['input']>;
  caller?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  uid?: InputMaybe<Scalars['Int']['input']>;
  username?: InputMaybe<Scalars['String']['input']>;
};

export type VideoStreamInput = {
  bracketId: Scalars['Int']['input'];
  /** in the format MM/DD/YYYY HH:MM:SS */
  created?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  division: Scalars['Int']['input'];
  duration?: InputMaybe<Scalars['String']['input']>;
  enabled?: InputMaybe<Scalars['Boolean']['input']>;
  franchise: Scalars['String']['input'];
  mediaId?: InputMaybe<Scalars['String']['input']>;
  seasonYear: Scalars['Int']['input'];
  sportCode: SportCode;
  thumbnailRaw?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  /** in the format MM/DD/YYYY HH:MM:SS */
  updated?: InputMaybe<Scalars['String']['input']>;
  user: UserInput;
};

export type ResolverTypeWrapper<T> = Promise<T> | T;

export type ResolverWithResolve<TResult, TParent, TContext, TArgs> = {
  resolve: ResolverFn<TResult, TParent, TContext, TArgs>;
};
export type Resolver<TResult, TParent = {}, TContext = {}, TArgs = {}> =
  | ResolverFn<TResult, TParent, TContext, TArgs>
  | ResolverWithResolve<TResult, TParent, TContext, TArgs>;

export type ResolverFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo,
) => Promise<TResult> | TResult;

export type SubscriptionSubscribeFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo,
) => AsyncIterable<TResult> | Promise<AsyncIterable<TResult>>;

export type SubscriptionResolveFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo,
) => TResult | Promise<TResult>;

export interface SubscriptionSubscriberObject<TResult, TKey extends string, TParent, TContext, TArgs> {
  subscribe: SubscriptionSubscribeFn<{ [key in TKey]: TResult }, TParent, TContext, TArgs>;
  resolve?: SubscriptionResolveFn<TResult, { [key in TKey]: TResult }, TContext, TArgs>;
}

export interface SubscriptionResolverObject<TResult, TParent, TContext, TArgs> {
  subscribe: SubscriptionSubscribeFn<any, TParent, TContext, TArgs>;
  resolve: SubscriptionResolveFn<TResult, any, TContext, TArgs>;
}

export type SubscriptionObject<TResult, TKey extends string, TParent, TContext, TArgs> =
  | SubscriptionSubscriberObject<TResult, TKey, TParent, TContext, TArgs>
  | SubscriptionResolverObject<TResult, TParent, TContext, TArgs>;

export type SubscriptionResolver<TResult, TKey extends string, TParent = {}, TContext = {}, TArgs = {}> =
  | ((...args: any[]) => SubscriptionObject<TResult, TKey, TParent, TContext, TArgs>)
  | SubscriptionObject<TResult, TKey, TParent, TContext, TArgs>;

export type TypeResolveFn<TTypes, TParent = {}, TContext = {}> = (
  parent: TParent,
  context: TContext,
  info: GraphQLResolveInfo,
) => Maybe<TTypes> | Promise<Maybe<TTypes>>;

export type IsTypeOfResolverFn<T = {}, TContext = {}> = (
  obj: T,
  context: TContext,
  info: GraphQLResolveInfo,
) => boolean | Promise<boolean>;

export type NextResolverFn<T> = () => Promise<T>;

export type DirectiveResolverFn<TResult = {}, TParent = {}, TContext = {}, TArgs = {}> = (
  next: NextResolverFn<TResult>,
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo,
) => TResult | Promise<TResult>;

/** Mapping of union types */
export type ResolversUnionTypes<RefType extends Record<string, unknown>> = {
  LinescoreStats: LinescoreStatsBaseball | LinescoreStatsFootball;
  PlayByPlayStats: PlayByPlayBasketball | PlayByPlayFootball | PlayByPlayGenericSport;
  PlayerStats:
    | PlayerStatsBaseball
    | PlayerStatsBasketball
    | PlayerStatsFieldhockey
    | PlayerStatsFootball
    | PlayerStatsIcehockey
    | PlayerStatsLacrosse
    | PlayerStatsSoccer
    | PlayerStatsSoftball
    | PlayerStatsVolleyball;
  RankedStats:
    | RankedBasketball
    | RankedFieldHockey
    | RankedFootball
    | RankedIceHockey
    | RankedLacrosse
    | RankedSeries
    | RankedSoccer
    | RankedVolleyball;
  TeamStats:
    | TeamStatsBaseball
    | TeamStatsBasketball
    | TeamStatsFieldhockey
    | TeamStatsFootball
    | TeamStatsIcehockey
    | TeamStatsLacrosse
    | TeamStatsSoccer
    | TeamStatsSoftball
    | TeamStatsVolleyball;
};

/** Mapping between all available schema types and the resolvers types */
export type ResolversTypes = {
  Alert: ResolverTypeWrapper<Alert>;
  String: ResolverTypeWrapper<Scalars['String']['output']>;
  Int: ResolverTypeWrapper<Scalars['Int']['output']>;
  Boolean: ResolverTypeWrapper<Scalars['Boolean']['output']>;
  AlertCategory: AlertCategory;
  AppConfig: ResolverTypeWrapper<AppConfig>;
  AppFeatureContentInput: AppFeatureContentInput;
  AppFeatured: ResolverTypeWrapper<AppFeatured>;
  AppFeaturedType: AppFeaturedType;
  AppFeaturedView: AppFeaturedView;
  Article: ResolverTypeWrapper<Article>;
  ArticleContent: ResolverTypeWrapper<ArticleContent>;
  Float: ResolverTypeWrapper<Scalars['Float']['output']>;
  AttackGame: ResolverTypeWrapper<AttackGame>;
  AutoProgression: ResolverTypeWrapper<AutoProgression>;
  BatterStat: ResolverTypeWrapper<BatterStat>;
  BatterTotal: ResolverTypeWrapper<BatterTotal>;
  BattingStat: ResolverTypeWrapper<BattingStat>;
  Boxscore: ResolverTypeWrapper<Boxscore>;
  BoxscoreDetails: ResolverTypeWrapper<
    Omit<BoxscoreDetails, 'playerStats' | 'teamStats'> & {
      playerStats?: Maybe<Array<Maybe<ResolversTypes['PlayerStats']>>>;
      teamStats?: Maybe<ResolversTypes['TeamStats']>;
    }
  >;
  BracketIds: ResolverTypeWrapper<BracketIds>;
  Briefing: ResolverTypeWrapper<Briefing>;
  Broadcaster: ResolverTypeWrapper<Broadcaster>;
  ID: ResolverTypeWrapper<Scalars['ID']['output']>;
  CPUUsage: ResolverTypeWrapper<CpuUsage>;
  CatchupLiveFinalGamesContent: ResolverTypeWrapper<CatchupLiveFinalGamesContent>;
  Championship: ResolverTypeWrapper<Championship>;
  ChampionshipGame: ResolverTypeWrapper<ChampionshipGame>;
  ChampionshipGameFinder: ResolverTypeWrapper<ChampionshipGameFinder>;
  ChampionshipRoundsFinder: ResolverTypeWrapper<ChampionshipRoundsFinder>;
  ChampionshipTeam: ResolverTypeWrapper<
    Omit<ChampionshipTeam, 'seasonAverage'> & { seasonAverage?: Maybe<ResolversTypes['TeamStats']> }
  >;
  Conference: ResolverTypeWrapper<Conference>;
  ConferenceSubDivision: ResolverTypeWrapper<ConferenceSubDivision>;
  ConferenceTeamStanding: ResolverTypeWrapper<ConferenceTeamStanding>;
  Configuration: ResolverTypeWrapper<Configuration>;
  Contest: ResolverTypeWrapper<Omit<Contest, 'stats'> & { stats?: Maybe<ResolversTypes['LinescoreStats']> }>;
  ContestTeam: ResolverTypeWrapper<ContestTeam>;
  CountdownDetail: ResolverTypeWrapper<CountdownDetail>;
  CurrentShow: ResolverTypeWrapper<CurrentShow>;
  Device: Device;
  DrupalVideo: ResolverTypeWrapper<DrupalVideo>;
  EmbedVideo: ResolverTypeWrapper<EmbedVideo>;
  EndpointType: EndpointType;
  Event: ResolverTypeWrapper<Event>;
  EventRelated: ResolverTypeWrapper<EventRelated>;
  FeaturedPlayWidget: ResolverTypeWrapper<FeaturedPlayWidget>;
  FeaturedShopPromo: ResolverTypeWrapper<FeaturedShopPromo>;
  FeaturedSpotlight: ResolverTypeWrapper<FeaturedSpotlight>;
  FeaturedStory: ResolverTypeWrapper<FeaturedStory>;
  FeaturedTrending: ResolverTypeWrapper<FeaturedTrending>;
  FeaturedcatchupLiveFinalGames: ResolverTypeWrapper<FeaturedcatchupLiveFinalGames>;
  FieldStat: ResolverTypeWrapper<FieldStat>;
  FieldingStat: ResolverTypeWrapper<FieldingStat>;
  FootballPlay: ResolverTypeWrapper<FootballPlay>;
  GENIUS_SCHEDULE_TYPE: GeniusScheduleType;
  Game: ResolverTypeWrapper<Game>;
  GametoolAudit: ResolverTypeWrapper<GametoolAudit>;
  GametoolResponse: ResolverTypeWrapper<GametoolResponse>;
  Gender: Gender;
  GenericPlay: ResolverTypeWrapper<GenericPlay>;
  GenericSummaryInfo: ResolverTypeWrapper<GenericSummaryInfo>;
  GeniusContestStatus: ResolverTypeWrapper<GeniusContestStatus>;
  GeniusGameDetail: ResolverTypeWrapper<GeniusGameDetail>;
  Heartbeat: ResolverTypeWrapper<Heartbeat>;
  HittingSeason: ResolverTypeWrapper<HittingSeason>;
  JSON: ResolverTypeWrapper<Scalars['JSON']['output']>;
  LacrosseGoalie: ResolverTypeWrapper<LacrosseGoalie>;
  LacrosseGoals: ResolverTypeWrapper<LacrosseGoals>;
  LacrossePenalty: ResolverTypeWrapper<LacrossePenalty>;
  Leaders: ResolverTypeWrapper<Leaders>;
  Linescore: ResolverTypeWrapper<Linescore>;
  LinescoreStats: ResolverTypeWrapper<ResolversUnionTypes<ResolversTypes>['LinescoreStats']>;
  LinescoreStatsBaseball: ResolverTypeWrapper<LinescoreStatsBaseball>;
  LinescoreStatsFootball: ResolverTypeWrapper<LinescoreStatsFootball>;
  Link: ResolverTypeWrapper<Link>;
  LiveVideo: ResolverTypeWrapper<LiveVideo>;
  Location: ResolverTypeWrapper<Location>;
  LogMessageModel: ResolverTypeWrapper<LogMessageModel>;
  LoggerLevel: LoggerLevel;
  MMLEvent: ResolverTypeWrapper<MmlEvent>;
  MMLEventType: MmlEventType;
  MMLOnboarding: ResolverTypeWrapper<MmlOnboarding>;
  MMLOnboardingContent: ResolverTypeWrapper<MmlOnboardingContent>;
  MMLRadioHighlight: ResolverTypeWrapper<MmlRadioHighlight>;
  MMLRadioStream: ResolverTypeWrapper<MmlRadioStream>;
  MMLSeasonType: MmlSeasonType;
  MMLStream: ResolverTypeWrapper<MmlStream>;
  MMLVideo: ResolverTypeWrapper<MmlVideo>;
  MMLVideoType: MmlVideoType;
  MatchupAnalysis: ResolverTypeWrapper<MatchupAnalysis>;
  MediaDevice: ResolverTypeWrapper<MediaDevice>;
  MemoryUsage: ResolverTypeWrapper<MemoryUsage>;
  Momentum: ResolverTypeWrapper<Momentum>;
  Mutation: ResolverTypeWrapper<{}>;
  OnboardingType: OnboardingType;
  PeriodType: PeriodType;
  PitcherStat: ResolverTypeWrapper<PitcherStat>;
  PitcherTotal: ResolverTypeWrapper<PitcherTotal>;
  PitchingStat: ResolverTypeWrapper<PitchingStat>;
  Platform: Platform;
  PlatformType: PlatformType;
  PlayByPlay: ResolverTypeWrapper<PlayByPlay>;
  PlayByPlayBasketball: ResolverTypeWrapper<PlayByPlayBasketball>;
  PlayByPlayDetails: ResolverTypeWrapper<
    Omit<PlayByPlayDetails, 'playbyplayStats'> & {
      playbyplayStats?: Maybe<Array<Maybe<ResolversTypes['PlayByPlayStats']>>>;
    }
  >;
  PlayByPlayFootball: ResolverTypeWrapper<PlayByPlayFootball>;
  PlayByPlayGenericSport: ResolverTypeWrapper<PlayByPlayGenericSport>;
  PlayByPlayStats: ResolverTypeWrapper<ResolversUnionTypes<ResolversTypes>['PlayByPlayStats']>;
  PlayWidgetContent: ResolverTypeWrapper<PlayWidgetContent>;
  Player: ResolverTypeWrapper<Omit<Player, 'seasonAverage'> & { seasonAverage?: Maybe<ResolversTypes['PlayerStats']> }>;
  PlayerStats: ResolverTypeWrapper<ResolversUnionTypes<ResolversTypes>['PlayerStats']>;
  PlayerStatsBaseball: ResolverTypeWrapper<PlayerStatsBaseball>;
  PlayerStatsBasketball: ResolverTypeWrapper<PlayerStatsBasketball>;
  PlayerStatsFieldhockey: ResolverTypeWrapper<PlayerStatsFieldhockey>;
  PlayerStatsFootball: ResolverTypeWrapper<PlayerStatsFootball>;
  PlayerStatsIcehockey: ResolverTypeWrapper<PlayerStatsIcehockey>;
  PlayerStatsLacrosse: ResolverTypeWrapper<PlayerStatsLacrosse>;
  PlayerStatsSoccer: ResolverTypeWrapper<PlayerStatsSoccer>;
  PlayerStatsSoftball: ResolverTypeWrapper<PlayerStatsSoftball>;
  PlayerStatsVolleyball: ResolverTypeWrapper<PlayerStatsVolleyball>;
  PowerPlay: ResolverTypeWrapper<PowerPlay>;
  PreviewArticle: ResolverTypeWrapper<PreviewArticle>;
  PreviousGameWinner: ResolverTypeWrapper<PreviousGameWinner>;
  Provider: ResolverTypeWrapper<Provider>;
  Query: ResolverTypeWrapper<{}>;
  RankedBasketball: ResolverTypeWrapper<RankedBasketball>;
  RankedFieldHockey: ResolverTypeWrapper<RankedFieldHockey>;
  RankedFootball: ResolverTypeWrapper<RankedFootball>;
  RankedIceHockey: ResolverTypeWrapper<RankedIceHockey>;
  RankedLacrosse: ResolverTypeWrapper<RankedLacrosse>;
  RankedSeries: ResolverTypeWrapper<RankedSeries>;
  RankedSoccer: ResolverTypeWrapper<RankedSoccer>;
  RankedStats: ResolverTypeWrapper<ResolversUnionTypes<ResolversTypes>['RankedStats']>;
  RankedTypes: ResolverTypeWrapper<RankedTypes>;
  RankedVolleyball: ResolverTypeWrapper<RankedVolleyball>;
  Ranking: ResolverTypeWrapper<Ranking>;
  Rankings: ResolverTypeWrapper<Rankings>;
  RecapArticle: ResolverTypeWrapper<RecapArticle>;
  RegionSection: ResolverTypeWrapper<RegionSection>;
  Round: ResolverTypeWrapper<Round>;
  Schedule: ResolverTypeWrapper<Schedule>;
  ScheduleDetail: ResolverTypeWrapper<ScheduleDetail>;
  School: ResolverTypeWrapper<School>;
  ScoringSummary: ResolverTypeWrapper<ScoringSummary>;
  ScoringSummaryDetails: ResolverTypeWrapper<ScoringSummaryDetails>;
  SeasonCode: SeasonCode;
  Service: ResolverTypeWrapper<Service>;
  ShopPromoContent: ResolverTypeWrapper<ShopPromoContent>;
  Snapshot: ResolverTypeWrapper<Snapshot>;
  SoccerGoalInfo: ResolverTypeWrapper<SoccerGoalInfo>;
  SoccerGoalie: ResolverTypeWrapper<SoccerGoalie>;
  SoccerGoals: ResolverTypeWrapper<SoccerGoals>;
  SoccerPenalty: ResolverTypeWrapper<SoccerPenalty>;
  SportCode: SportCode;
  SportInfo: ResolverTypeWrapper<SportInfo>;
  SpotlightContent: ResolverTypeWrapper<SpotlightContent>;
  Standing: ResolverTypeWrapper<Standing>;
  StatBreakout: ResolverTypeWrapper<StatBreakout>;
  State: ResolverTypeWrapper<State>;
  StaticTestEnv: StaticTestEnv;
  Statistics: ResolverTypeWrapper<
    Omit<Statistics, 'playerStats' | 'rankedStats' | 'teamStats'> & {
      playerStats?: Maybe<ResolversTypes['PlayerStats']>;
      rankedStats?: Maybe<Array<Maybe<ResolversTypes['RankedStats']>>>;
      teamStats?: Maybe<ResolversTypes['TeamStats']>;
    }
  >;
  StatisticsType: StatisticsType;
  Status: ResolverTypeWrapper<Status>;
  StatusCode: StatusCode;
  StoryContent: ResolverTypeWrapper<StoryContent>;
  Stream: ResolverTypeWrapper<Stream>;
  Team: ResolverTypeWrapper<Team>;
  TeamAnalysis: ResolverTypeWrapper<TeamAnalysis>;
  TeamLogoConfig: ResolverTypeWrapper<TeamLogoConfig>;
  TeamStats: ResolverTypeWrapper<ResolversUnionTypes<ResolversTypes>['TeamStats']>;
  TeamStatsBaseball: ResolverTypeWrapper<TeamStatsBaseball>;
  TeamStatsBasketball: ResolverTypeWrapper<TeamStatsBasketball>;
  TeamStatsFieldhockey: ResolverTypeWrapper<TeamStatsFieldhockey>;
  TeamStatsFootball: ResolverTypeWrapper<TeamStatsFootball>;
  TeamStatsIcehockey: ResolverTypeWrapper<TeamStatsIcehockey>;
  TeamStatsLacrosse: ResolverTypeWrapper<TeamStatsLacrosse>;
  TeamStatsSoccer: ResolverTypeWrapper<TeamStatsSoccer>;
  TeamStatsSoftball: ResolverTypeWrapper<TeamStatsSoftball>;
  TeamStatsVolleyball: ResolverTypeWrapper<TeamStatsVolleyball>;
  TeamsOnDeck: TeamsOnDeck;
  Today: ResolverTypeWrapper<Today>;
  Tournament: ResolverTypeWrapper<Tournament>;
  TournamentType: TournamentType;
  TrendingContent: ResolverTypeWrapper<TrendingContent>;
  Version: ResolverTypeWrapper<Version>;
  VodDetail: ResolverTypeWrapper<VodDetail>;
  VodList: ResolverTypeWrapper<VodList>;
  WscContent: ResolverTypeWrapper<WscContent>;
  appConfigInput: AppConfigInput;
  autoprogressionInput: AutoprogressionInput;
  briefInput: BriefInput;
  broadcasterInput: BroadcasterInput;
  championshipGameInput: ChampionshipGameInput;
  championshipInput: ChampionshipInput;
  condensedVideoInput: CondensedVideoInput;
  conferenceRecord: ResolverTypeWrapper<ConferenceRecord>;
  conferenceTournamentInput: ConferenceTournamentInput;
  eventInput: EventInput;
  featuredInput: FeaturedInput;
  linkInput: LinkInput;
  locationInput: LocationInput;
  mmlEventInput: MmlEventInput;
  mmlOnboardingInput: MmlOnboardingInput;
  mmlStreamInput: MmlStreamInput;
  newChampionshipInput: NewChampionshipInput;
  newGameInput: NewGameInput;
  regionInput: RegionInput;
  roundInput: RoundInput;
  sectionInput: SectionInput;
  sportInfoInput: SportInfoInput;
  stagingGameInput: StagingGameInput;
  stagingInput: StagingInput;
  streamInput: StreamInput;
  teamInput: TeamInput;
  userInput: UserInput;
  videoStreamInput: VideoStreamInput;
};

/** Mapping between all available schema types and the resolvers parents */
export type ResolversParentTypes = {
  Alert: Alert;
  String: Scalars['String']['output'];
  Int: Scalars['Int']['output'];
  Boolean: Scalars['Boolean']['output'];
  AppConfig: AppConfig;
  AppFeatureContentInput: AppFeatureContentInput;
  AppFeatured: AppFeatured;
  Article: Article;
  ArticleContent: ArticleContent;
  Float: Scalars['Float']['output'];
  AttackGame: AttackGame;
  AutoProgression: AutoProgression;
  BatterStat: BatterStat;
  BatterTotal: BatterTotal;
  BattingStat: BattingStat;
  Boxscore: Boxscore;
  BoxscoreDetails: Omit<BoxscoreDetails, 'playerStats' | 'teamStats'> & {
    playerStats?: Maybe<Array<Maybe<ResolversParentTypes['PlayerStats']>>>;
    teamStats?: Maybe<ResolversParentTypes['TeamStats']>;
  };
  BracketIds: BracketIds;
  Briefing: Briefing;
  Broadcaster: Broadcaster;
  ID: Scalars['ID']['output'];
  CPUUsage: CpuUsage;
  CatchupLiveFinalGamesContent: CatchupLiveFinalGamesContent;
  Championship: Championship;
  ChampionshipGame: ChampionshipGame;
  ChampionshipGameFinder: ChampionshipGameFinder;
  ChampionshipRoundsFinder: ChampionshipRoundsFinder;
  ChampionshipTeam: Omit<ChampionshipTeam, 'seasonAverage'> & {
    seasonAverage?: Maybe<ResolversParentTypes['TeamStats']>;
  };
  Conference: Conference;
  ConferenceSubDivision: ConferenceSubDivision;
  ConferenceTeamStanding: ConferenceTeamStanding;
  Configuration: Configuration;
  Contest: Omit<Contest, 'stats'> & { stats?: Maybe<ResolversParentTypes['LinescoreStats']> };
  ContestTeam: ContestTeam;
  CountdownDetail: CountdownDetail;
  CurrentShow: CurrentShow;
  DrupalVideo: DrupalVideo;
  EmbedVideo: EmbedVideo;
  Event: Event;
  EventRelated: EventRelated;
  FeaturedPlayWidget: FeaturedPlayWidget;
  FeaturedShopPromo: FeaturedShopPromo;
  FeaturedSpotlight: FeaturedSpotlight;
  FeaturedStory: FeaturedStory;
  FeaturedTrending: FeaturedTrending;
  FeaturedcatchupLiveFinalGames: FeaturedcatchupLiveFinalGames;
  FieldStat: FieldStat;
  FieldingStat: FieldingStat;
  FootballPlay: FootballPlay;
  Game: Game;
  GametoolAudit: GametoolAudit;
  GametoolResponse: GametoolResponse;
  GenericPlay: GenericPlay;
  GenericSummaryInfo: GenericSummaryInfo;
  GeniusContestStatus: GeniusContestStatus;
  GeniusGameDetail: GeniusGameDetail;
  Heartbeat: Heartbeat;
  HittingSeason: HittingSeason;
  JSON: Scalars['JSON']['output'];
  LacrosseGoalie: LacrosseGoalie;
  LacrosseGoals: LacrosseGoals;
  LacrossePenalty: LacrossePenalty;
  Leaders: Leaders;
  Linescore: Linescore;
  LinescoreStats: ResolversUnionTypes<ResolversParentTypes>['LinescoreStats'];
  LinescoreStatsBaseball: LinescoreStatsBaseball;
  LinescoreStatsFootball: LinescoreStatsFootball;
  Link: Link;
  LiveVideo: LiveVideo;
  Location: Location;
  LogMessageModel: LogMessageModel;
  MMLEvent: MmlEvent;
  MMLOnboarding: MmlOnboarding;
  MMLOnboardingContent: MmlOnboardingContent;
  MMLRadioHighlight: MmlRadioHighlight;
  MMLRadioStream: MmlRadioStream;
  MMLStream: MmlStream;
  MMLVideo: MmlVideo;
  MatchupAnalysis: MatchupAnalysis;
  MediaDevice: MediaDevice;
  MemoryUsage: MemoryUsage;
  Momentum: Momentum;
  Mutation: {};
  PitcherStat: PitcherStat;
  PitcherTotal: PitcherTotal;
  PitchingStat: PitchingStat;
  PlayByPlay: PlayByPlay;
  PlayByPlayBasketball: PlayByPlayBasketball;
  PlayByPlayDetails: Omit<PlayByPlayDetails, 'playbyplayStats'> & {
    playbyplayStats?: Maybe<Array<Maybe<ResolversParentTypes['PlayByPlayStats']>>>;
  };
  PlayByPlayFootball: PlayByPlayFootball;
  PlayByPlayGenericSport: PlayByPlayGenericSport;
  PlayByPlayStats: ResolversUnionTypes<ResolversParentTypes>['PlayByPlayStats'];
  PlayWidgetContent: PlayWidgetContent;
  Player: Omit<Player, 'seasonAverage'> & { seasonAverage?: Maybe<ResolversParentTypes['PlayerStats']> };
  PlayerStats: ResolversUnionTypes<ResolversParentTypes>['PlayerStats'];
  PlayerStatsBaseball: PlayerStatsBaseball;
  PlayerStatsBasketball: PlayerStatsBasketball;
  PlayerStatsFieldhockey: PlayerStatsFieldhockey;
  PlayerStatsFootball: PlayerStatsFootball;
  PlayerStatsIcehockey: PlayerStatsIcehockey;
  PlayerStatsLacrosse: PlayerStatsLacrosse;
  PlayerStatsSoccer: PlayerStatsSoccer;
  PlayerStatsSoftball: PlayerStatsSoftball;
  PlayerStatsVolleyball: PlayerStatsVolleyball;
  PowerPlay: PowerPlay;
  PreviewArticle: PreviewArticle;
  PreviousGameWinner: PreviousGameWinner;
  Provider: Provider;
  Query: {};
  RankedBasketball: RankedBasketball;
  RankedFieldHockey: RankedFieldHockey;
  RankedFootball: RankedFootball;
  RankedIceHockey: RankedIceHockey;
  RankedLacrosse: RankedLacrosse;
  RankedSeries: RankedSeries;
  RankedSoccer: RankedSoccer;
  RankedStats: ResolversUnionTypes<ResolversParentTypes>['RankedStats'];
  RankedTypes: RankedTypes;
  RankedVolleyball: RankedVolleyball;
  Ranking: Ranking;
  Rankings: Rankings;
  RecapArticle: RecapArticle;
  RegionSection: RegionSection;
  Round: Round;
  Schedule: Schedule;
  ScheduleDetail: ScheduleDetail;
  School: School;
  ScoringSummary: ScoringSummary;
  ScoringSummaryDetails: ScoringSummaryDetails;
  Service: Service;
  ShopPromoContent: ShopPromoContent;
  Snapshot: Snapshot;
  SoccerGoalInfo: SoccerGoalInfo;
  SoccerGoalie: SoccerGoalie;
  SoccerGoals: SoccerGoals;
  SoccerPenalty: SoccerPenalty;
  SportInfo: SportInfo;
  SpotlightContent: SpotlightContent;
  Standing: Standing;
  StatBreakout: StatBreakout;
  State: State;
  Statistics: Omit<Statistics, 'playerStats' | 'rankedStats' | 'teamStats'> & {
    playerStats?: Maybe<ResolversParentTypes['PlayerStats']>;
    rankedStats?: Maybe<Array<Maybe<ResolversParentTypes['RankedStats']>>>;
    teamStats?: Maybe<ResolversParentTypes['TeamStats']>;
  };
  Status: Status;
  StoryContent: StoryContent;
  Stream: Stream;
  Team: Team;
  TeamAnalysis: TeamAnalysis;
  TeamLogoConfig: TeamLogoConfig;
  TeamStats: ResolversUnionTypes<ResolversParentTypes>['TeamStats'];
  TeamStatsBaseball: TeamStatsBaseball;
  TeamStatsBasketball: TeamStatsBasketball;
  TeamStatsFieldhockey: TeamStatsFieldhockey;
  TeamStatsFootball: TeamStatsFootball;
  TeamStatsIcehockey: TeamStatsIcehockey;
  TeamStatsLacrosse: TeamStatsLacrosse;
  TeamStatsSoccer: TeamStatsSoccer;
  TeamStatsSoftball: TeamStatsSoftball;
  TeamStatsVolleyball: TeamStatsVolleyball;
  Today: Today;
  Tournament: Tournament;
  TrendingContent: TrendingContent;
  Version: Version;
  VodDetail: VodDetail;
  VodList: VodList;
  WscContent: WscContent;
  appConfigInput: AppConfigInput;
  autoprogressionInput: AutoprogressionInput;
  briefInput: BriefInput;
  broadcasterInput: BroadcasterInput;
  championshipGameInput: ChampionshipGameInput;
  championshipInput: ChampionshipInput;
  condensedVideoInput: CondensedVideoInput;
  conferenceRecord: ConferenceRecord;
  conferenceTournamentInput: ConferenceTournamentInput;
  eventInput: EventInput;
  featuredInput: FeaturedInput;
  linkInput: LinkInput;
  locationInput: LocationInput;
  mmlEventInput: MmlEventInput;
  mmlOnboardingInput: MmlOnboardingInput;
  mmlStreamInput: MmlStreamInput;
  newChampionshipInput: NewChampionshipInput;
  newGameInput: NewGameInput;
  regionInput: RegionInput;
  roundInput: RoundInput;
  sectionInput: SectionInput;
  sportInfoInput: SportInfoInput;
  stagingGameInput: StagingGameInput;
  stagingInput: StagingInput;
  streamInput: StreamInput;
  teamInput: TeamInput;
  userInput: UserInput;
  videoStreamInput: VideoStreamInput;
};

export type OneOfDirectiveArgs = {};

export type OneOfDirectiveResolver<Result, Parent, ContextType = any, Args = OneOfDirectiveArgs> = DirectiveResolverFn<
  Result,
  Parent,
  ContextType,
  Args
>;

export type AlertResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Alert'] = ResolversParentTypes['Alert'],
> = {
  UAId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  alertTypeId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  alertTypeName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  bracketId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  payload?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  project?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  published?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  publishedTime?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  response?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  scheduled?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  tags?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  type?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AppConfigResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['AppConfig'] = ResolversParentTypes['AppConfig'],
> = {
  endpointName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  endpointType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  endpointUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  interval?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  platform?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  seasonYear?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  style?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ttl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AppFeaturedResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['AppFeatured'] = ResolversParentTypes['AppFeatured'],
> = {
  catchupLiveFinalGames?: Resolver<Maybe<ResolversTypes['FeaturedcatchupLiveFinalGames']>, ParentType, ContextType>;
  featuredArticles?: Resolver<Maybe<ResolversTypes['Article']>, ParentType, ContextType>;
  headlineArticles?: Resolver<Maybe<ResolversTypes['Article']>, ParentType, ContextType>;
  playWidget?: Resolver<Maybe<ResolversTypes['FeaturedPlayWidget']>, ParentType, ContextType>;
  season?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  shopPromo?: Resolver<Maybe<ResolversTypes['FeaturedShopPromo']>, ParentType, ContextType>;
  spotlight?: Resolver<Maybe<ResolversTypes['FeaturedSpotlight']>, ParentType, ContextType>;
  standardArticles?: Resolver<Maybe<ResolversTypes['Article']>, ParentType, ContextType>;
  story?: Resolver<Maybe<Array<Maybe<ResolversTypes['FeaturedStory']>>>, ParentType, ContextType>;
  trending?: Resolver<Maybe<ResolversTypes['FeaturedTrending']>, ParentType, ContextType>;
  vodPlaylist?: Resolver<Maybe<Array<Maybe<ResolversTypes['VodList']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ArticleResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Article'] = ResolversParentTypes['Article'],
> = {
  content?: Resolver<Array<Maybe<ResolversTypes['ArticleContent']>>, ParentType, ContextType>;
  contentType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  sortObject?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  title?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  view?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ArticleContentResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ArticleContent'] = ResolversParentTypes['ArticleContent'],
> = {
  byLine?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  contentType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  sort?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  thumbnail?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  title?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updatedTimestamp?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  url?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AttackGameResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['AttackGame'] = ResolversParentTypes['AttackGame'],
> = {
  attackAttempts?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  attackErrors?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  game?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  hittingPercentage?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  kills?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AutoProgressionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['AutoProgression'] = ResolversParentTypes['AutoProgression'],
> = {
  epoch?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  message?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  success?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  timestamp?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type BatterStatResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['BatterStat'] = ResolversParentTypes['BatterStat'],
> = {
  atBats?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  battingAverage?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  doubles?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  hits?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  homeRuns?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  onBasePercentage?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  runsBattedIn?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  runsScored?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  strikeouts?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  triples?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  walks?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type BatterTotalResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['BatterTotal'] = ResolversParentTypes['BatterTotal'],
> = {
  atBats?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  hits?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  runsBattedIn?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  runsScored?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  strikeouts?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  walks?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type BattingStatResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['BattingStat'] = ResolversParentTypes['BattingStat'],
> = {
  doubles?: Resolver<Maybe<Array<Maybe<ResolversTypes['StatBreakout']>>>, ParentType, ContextType>;
  homeRuns?: Resolver<Maybe<Array<Maybe<ResolversTypes['StatBreakout']>>>, ParentType, ContextType>;
  leftOnBase?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  runsBattedIn?: Resolver<Maybe<Array<Maybe<ResolversTypes['StatBreakout']>>>, ParentType, ContextType>;
  triples?: Resolver<Maybe<Array<Maybe<ResolversTypes['StatBreakout']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type BoxscoreResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Boxscore'] = ResolversParentTypes['Boxscore'],
> = {
  contestId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  division?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  divisionName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  gameType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  homeBoxscore?: Resolver<Maybe<ResolversTypes['BoxscoreDetails']>, ParentType, ContextType>;
  minutes?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  period?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  seconds?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  sportCode?: Resolver<Maybe<ResolversTypes['SportCode']>, ParentType, ContextType>;
  status?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  teamBoxscore?: Resolver<Maybe<Array<Maybe<ResolversTypes['BoxscoreDetails']>>>, ParentType, ContextType>;
  teams?: Resolver<Maybe<Array<Maybe<ResolversTypes['ContestTeam']>>>, ParentType, ContextType>;
  title?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  visitorBoxscore?: Resolver<Maybe<ResolversTypes['BoxscoreDetails']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type BoxscoreDetailsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['BoxscoreDetails'] = ResolversParentTypes['BoxscoreDetails'],
> = {
  color?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  isHome?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  losses?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  name6Char?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  name8Char?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  name10Char?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  nameFull?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  nameShort?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ncaaOrgId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  nickname?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  playerStats?: Resolver<Maybe<Array<Maybe<ResolversTypes['PlayerStats']>>>, ParentType, ContextType>;
  score?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  seed?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  seoname?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  teamId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  teamStats?: Resolver<Maybe<ResolversTypes['TeamStats']>, ParentType, ContextType>;
  ties?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  wins?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type BracketIdsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['BracketIds'] = ResolversParentTypes['BracketIds'],
> = {
  bracketId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type BriefingResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Briefing'] = ResolversParentTypes['Briefing'],
> = {
  updated?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  url?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type BroadcasterResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Broadcaster'] = ResolversParentTypes['Broadcaster'],
> = {
  id?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CpuUsageResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CPUUsage'] = ResolversParentTypes['CPUUsage'],
> = {
  system?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  user?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CatchupLiveFinalGamesContentResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['CatchupLiveFinalGamesContent'] = ResolversParentTypes['CatchupLiveFinalGamesContent'],
> = {
  awayColor?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  awaySeed?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  awaySeoName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  awayname6char?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  awaynameShort?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  bracketId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  duration?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  homeColor?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  homeSeed?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  homeSeoName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  homename6char?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  homenameShort?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  sort?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  url?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ChampionshipResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Championship'] = ResolversParentTypes['Championship'],
> = {
  bracketTemplate?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  broadcasters?: Resolver<Array<Maybe<ResolversTypes['Broadcaster']>>, ParentType, ContextType>;
  championshipId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  currentRound?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  dataPath?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  division?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  divisionName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  endDate?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  endDateEpoch?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  games?: Resolver<Array<Maybe<ResolversTypes['ChampionshipGame']>>, ParentType, ContextType>;
  isMML?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  links?: Resolver<Array<Maybe<ResolversTypes['Link']>>, ParentType, ContextType>;
  locations?: Resolver<Array<Maybe<ResolversTypes['Location']>>, ParentType, ContextType>;
  mmlEvents?: Resolver<Array<Maybe<ResolversTypes['MMLEvent']>>, ParentType, ContextType>;
  mmlStreams?: Resolver<Array<Maybe<ResolversTypes['MMLStream']>>, ParentType, ContextType>;
  orgDivisionId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  regions?: Resolver<Array<Maybe<ResolversTypes['RegionSection']>>, ParentType, ContextType>;
  rounds?: Resolver<Array<Maybe<ResolversTypes['Round']>>, ParentType, ContextType>;
  season?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  sections?: Resolver<Array<Maybe<ResolversTypes['RegionSection']>>, ParentType, ContextType>;
  sportCode?: Resolver<Maybe<ResolversTypes['SportCode']>, ParentType, ContextType>;
  sportName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  sportUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  startDate?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  startDateEpoch?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  teams?: Resolver<Array<Maybe<ResolversTypes['ChampionshipTeam']>>, ParentType, ContextType>;
  title?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  year?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ChampionshipGameResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ChampionshipGame'] = ResolversParentTypes['ChampionshipGame'],
> = {
  bottomChampionshipTeam?: Resolver<Maybe<ResolversTypes['ChampionshipTeam']>, ParentType, ContextType>;
  boxscore?: Resolver<Maybe<ResolversTypes['Boxscore']>, ParentType, ContextType>;
  bracketId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  bracketPositionId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  bracketTemplate?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  broadcaster?: Resolver<Maybe<ResolversTypes['Broadcaster']>, ParentType, ContextType>;
  broadcasterId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  broadcasterName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  championshipId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  condensedVideo?: Resolver<Array<Maybe<ResolversTypes['WscContent']>>, ParentType, ContextType>;
  confDisplay?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  conferenceId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  conferenceName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  contestClock?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  contestId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  currentAuthority?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  currentPeriod?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  dataPath?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  division?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  divisionName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  enabled?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  excitementAlerts?: Resolver<Array<Maybe<ResolversTypes['Alert']>>, ParentType, ContextType>;
  externalWatchLink?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  finalMessage?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  footballWeek?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  formattedClock?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  formattedRound?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  formattedVenue?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  gameId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  gameState?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  gameStateCode?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  gameWinner?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  gamestateDisplay?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  geniusGameDetails?: Resolver<Maybe<ResolversTypes['GeniusGameDetail']>, ParentType, ContextType>;
  halftimeClock?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  hasCondensedVideo?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  hasExcitementAlert?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  hasPreviewVideo?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  hasRecapVideo?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  hasReplayVideo?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  hasStartTime?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  hasWscCatchup?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  homeChampionshipTeam?: Resolver<Maybe<ResolversTypes['ChampionshipTeam']>, ParentType, ContextType>;
  homeSeasonAverage?: Resolver<Maybe<ResolversTypes['TeamStatsBasketball']>, ParentType, ContextType>;
  homeSeed?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  homeTB?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  homeTeamLogo?: Resolver<Maybe<ResolversTypes['TeamLogoConfig']>, ParentType, ContextType>;
  isBottomVisible?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  isChampionship?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  isConferenceTournament?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  isIfNecessary?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  isMML?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  isTopVisible?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  links?: Resolver<Array<Maybe<ResolversTypes['Link']>>, ParentType, ContextType>;
  liveStat?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  liveVideoEnabled?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  location?: Resolver<Maybe<ResolversTypes['Location']>, ParentType, ContextType>;
  locationId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  loserBracketPositionId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  loserGamePosition?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  mediaId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  mmlEvents?: Resolver<Array<Maybe<ResolversTypes['MMLEvent']>>, ParentType, ContextType>;
  mmlRadio?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  mmlRadioStreams?: Resolver<Maybe<ResolversTypes['MMLRadioStream']>, ParentType, ContextType>;
  mmlStreams?: Resolver<Array<Maybe<ResolversTypes['MMLStream']>>, ParentType, ContextType>;
  mmlVideo?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  ncaaGameId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  period?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  periodDisplay?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  playbyplay?: Resolver<Maybe<ResolversTypes['PlayByPlay']>, ParentType, ContextType>;
  preGameAlertEnabled?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  previewVideo?: Resolver<Array<Maybe<ResolversTypes['Stream']>>, ParentType, ContextType>;
  providerCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  providerId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  recapVideo?: Resolver<Array<Maybe<ResolversTypes['Stream']>>, ParentType, ContextType>;
  region?: Resolver<Maybe<ResolversTypes['RegionSection']>, ParentType, ContextType>;
  regionId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  round?: Resolver<Maybe<ResolversTypes['Round']>, ParentType, ContextType>;
  roundId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  season?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  section?: Resolver<Maybe<ResolversTypes['RegionSection']>, ParentType, ContextType>;
  sectionId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  seriesNumber?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  sportCode?: Resolver<Maybe<ResolversTypes['SportCode']>, ParentType, ContextType>;
  sportUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  startDate?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  startTime?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  startTimeEpoch?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  statusCode?: Resolver<Maybe<ResolversTypes['StatusCode']>, ParentType, ContextType>;
  statusCodeDisplay?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  stream?: Resolver<Maybe<ResolversTypes['Stream']>, ParentType, ContextType>;
  streamId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  teams?: Resolver<Array<Maybe<ResolversTypes['ChampionshipTeam']>>, ParentType, ContextType>;
  teamsOnDeck?: Resolver<ResolversTypes['TeamsOnDeck'], ParentType, ContextType>;
  title?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  topChampionshipTeam?: Resolver<Maybe<ResolversTypes['ChampionshipTeam']>, ParentType, ContextType>;
  url?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  venue?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  victorBracketPositionId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  victorGamePosition?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  videoTime?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  visible?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  visitSeed?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  visitorChampionshipTeam?: Resolver<Maybe<ResolversTypes['ChampionshipTeam']>, ParentType, ContextType>;
  visitorSeasonAverage?: Resolver<Maybe<ResolversTypes['TeamStatsBasketball']>, ParentType, ContextType>;
  visitorTeamLogo?: Resolver<Maybe<ResolversTypes['TeamLogoConfig']>, ParentType, ContextType>;
  watchLiveUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  winner?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  winnerOf?: Resolver<Array<Maybe<ResolversTypes['PreviousGameWinner']>>, ParentType, ContextType>;
  wscCatchup?: Resolver<Array<Maybe<ResolversTypes['WscContent']>>, ParentType, ContextType>;
  year?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ChampionshipGameFinderResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ChampionshipGameFinder'] = ResolversParentTypes['ChampionshipGameFinder'],
> = {
  game?: Resolver<Maybe<ResolversTypes['ChampionshipGame']>, ParentType, ContextType>;
  message?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ChampionshipRoundsFinderResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['ChampionshipRoundsFinder'] = ResolversParentTypes['ChampionshipRoundsFinder'],
> = {
  message?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  round?: Resolver<Maybe<Array<Maybe<ResolversTypes['Round']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ChampionshipTeamResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ChampionshipTeam'] = ResolversParentTypes['ChampionshipTeam'],
> = {
  apRank?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  bracketId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  color?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  conferenceId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  conferenceName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  conferenceNames?: Resolver<Maybe<ResolversTypes['Conference']>, ParentType, ContextType>;
  conferenceRecord?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  conferenceSeo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  contestId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  division?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  divisionName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  eliminated?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  games?: Resolver<Maybe<Array<Maybe<ResolversTypes['MatchupAnalysis']>>>, ParentType, ContextType>;
  hashtag?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  highlight?: Resolver<Maybe<Array<Maybe<ResolversTypes['MMLRadioHighlight']>>>, ParentType, ContextType>;
  isHome?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  isMML?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  isTop?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  isVisible?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  isWinner?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  logoUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  losses?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  momentum?: Resolver<Maybe<Array<Maybe<ResolversTypes['Momentum']>>>, ParentType, ContextType>;
  name6Char?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  name8Char?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  name10Char?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  nameFull?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  nameShort?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ncaaOrgId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  nickname?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  providerTeamCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  rank?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  record?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  roster?: Resolver<Array<Maybe<ResolversTypes['Player']>>, ParentType, ContextType>;
  score?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  seasonAverage?: Resolver<Maybe<ResolversTypes['TeamStats']>, ParentType, ContextType>;
  sectionRecord?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  seed?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  seoname?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  sportCode?: Resolver<Maybe<ResolversTypes['SportCode']>, ParentType, ContextType>;
  standings?: Resolver<Maybe<ResolversTypes['ConferenceTeamStanding']>, ParentType, ContextType>;
  teamAnalysis?: Resolver<Maybe<ResolversTypes['TeamAnalysis']>, ParentType, ContextType>;
  teamId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  textOverride?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ties?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  vsTop25?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  wins?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ConferenceResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Conference'] = ResolversParentTypes['Conference'],
> = {
  conferenceDisplay?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  conferenceDivision?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  conferenceId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  conferenceName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  conferenceNcaaOrgId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  conferenceSeo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  conferenceTournament?: Resolver<Array<Maybe<ResolversTypes['Contest']>>, ParentType, ContextType>;
  division?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  sportCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  teams?: Resolver<Array<Maybe<ResolversTypes['ContestTeam']>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ConferenceSubDivisionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ConferenceSubDivision'] = ResolversParentTypes['ConferenceSubDivision'],
> = {
  conferenceTeams?: Resolver<Array<Maybe<ResolversTypes['ConferenceTeamStanding']>>, ParentType, ContextType>;
  subDivisionName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ConferenceTeamStandingResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ConferenceTeamStanding'] = ResolversParentTypes['ConferenceTeamStanding'],
> = {
  conferenceLosses?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  conferencePercentage?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  conferenceRank?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  conferenceWins?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  confernceTies?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  homeLosses?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  homeTies?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  homeWins?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  losses?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  nameFull?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  nameShort?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  overallPercentage?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  pointsAgainst?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  pointsAgainstConference?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  pointsFor?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  pointsForConference?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  seoname?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  streak?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  teamId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  ties?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  top25Losses?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  top25Ties?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  top25Wins?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  visitLosses?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  visitTies?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  visitWins?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  wins?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ConfigurationResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Configuration'] = ResolversParentTypes['Configuration'],
> = {
  configId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  lastUpdated?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  parameterName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  parameterValue?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  stageId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ContestResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Contest'] = ResolversParentTypes['Contest'],
> = {
  boxscore?: Resolver<Maybe<ResolversTypes['Boxscore']>, ParentType, ContextType>;
  bracketId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  broadcasterId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  broadcasterName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  championship?: Resolver<Maybe<ResolversTypes['Championship']>, ParentType, ContextType>;
  championshipGame?: Resolver<Maybe<ResolversTypes['ChampionshipGame']>, ParentType, ContextType>;
  championshipId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  clock?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  confDisplay?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  conferenceName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  contestClock?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  contestDate?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  contestId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  contestTeams?: Resolver<Maybe<Array<Maybe<ResolversTypes['ContestTeam']>>>, ParentType, ContextType>;
  coverage?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  currentPeriod?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  division?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  divisionName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  exhibition?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  externalWatchLink?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  finalMessage?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  gameId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  gameState?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  gameStateCode?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  gamestateDisplay?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  geniusGameId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  hasBoxscore?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  hasPbp?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  hasPreview?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  hasRecap?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  hasScoringSummary?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  hasStartTime?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  hasTeamStats?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  isChampionship?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  isConferenceTournament?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  linescores?: Resolver<Array<Maybe<ResolversTypes['Linescore']>>, ParentType, ContextType>;
  links?: Resolver<Array<Maybe<ResolversTypes['Link']>>, ParentType, ContextType>;
  liveVideos?: Resolver<Array<Maybe<ResolversTypes['Stream']>>, ParentType, ContextType>;
  location?: Resolver<Maybe<ResolversTypes['Location']>, ParentType, ContextType>;
  locationFull?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  locationId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  locationNeutral?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  ncaaGameId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  network?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  period?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  periodDisplay?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  playbyplay?: Resolver<Maybe<ResolversTypes['PlayByPlay']>, ParentType, ContextType>;
  providerCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  roundDescription?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  roundNumber?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  seasonYear?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  seriesNumber?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  source?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  sportCode?: Resolver<Maybe<ResolversTypes['SportCode']>, ParentType, ContextType>;
  sportUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  stage?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  startDate?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  startTime?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  startTimeEpoch?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  stats?: Resolver<Maybe<ResolversTypes['LinescoreStats']>, ParentType, ContextType>;
  statusCode?: Resolver<Maybe<ResolversTypes['StatusCode']>, ParentType, ContextType>;
  statusCodeDisplay?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  tba?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  teams?: Resolver<Array<Maybe<ResolversTypes['ContestTeam']>>, ParentType, ContextType>;
  url?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  week?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  winner?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ContestTeamResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ContestTeam'] = ResolversParentTypes['ContestTeam'],
> = {
  collegeName6char?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  collegeName8char?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  collegeName10char?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  collegeNameFull?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  collegeNameShort?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  color?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  confLosses?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  confTies?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  confWins?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  conferenceId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  conferenceName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  conferenceRecord?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  conferenceSeo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  division?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  divisionName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  gameRank?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  geniusTeamId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  isHome?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  isWinner?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  lineScore?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  losses?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  name6Char?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  name6char?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  name8Char?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  name10Char?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  nameFull?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  nameShort?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ncaaOrgId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  nickname?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  record?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  roster?: Resolver<Array<Maybe<ResolversTypes['Player']>>, ParentType, ContextType>;
  score?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  seasonYear?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  seed?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  seoname?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  sportCode?: Resolver<Maybe<ResolversTypes['SportCode']>, ParentType, ContextType>;
  standings?: Resolver<Maybe<ResolversTypes['ConferenceTeamStanding']>, ParentType, ContextType>;
  teamId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  teamName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  teamRank?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  ties?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  wins?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CountdownDetailResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CountdownDetail'] = ResolversParentTypes['CountdownDetail'],
> = {
  epoch?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  title?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CurrentShowResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CurrentShow'] = ResolversParentTypes['CurrentShow'],
> = {
  feedId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  isCurrentShow?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  pushTag?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  showDuration?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  showTimestamp?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  summary?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  thumbnail_1x1?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  thumbnail_2x3?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  thumbnail_4x3?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  title?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  titleId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DrupalVideoResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['DrupalVideo'] = ResolversParentTypes['DrupalVideo'],
> = {
  bracketId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  division?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  duration?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  files?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  image?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  sport?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  teams?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  timestamp?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  title?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  year?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EmbedVideoResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['EmbedVideo'] = ResolversParentTypes['EmbedVideo'],
> = {
  awayId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  awayLink?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  awayNickname?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  awaySchoolName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  awayScore?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  bracketId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  duration?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  gameStateCode?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  homeId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  homeLink?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  homeNickname?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  homeSchoolName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  homeScore?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  period?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  thumbnail?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  title?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  videoUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  videoVttUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EventResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Event'] = ResolversParentTypes['Event'],
> = {
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  division?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  divisionName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  eventId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  eventParent?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  hasStartTime?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  link?: Resolver<Array<Maybe<ResolversTypes['Link']>>, ParentType, ContextType>;
  location?: Resolver<Array<Maybe<ResolversTypes['Location']>>, ParentType, ContextType>;
  locationId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  relatedEvents?: Resolver<Array<Maybe<ResolversTypes['EventRelated']>>, ParentType, ContextType>;
  sport?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  sportCode?: Resolver<Maybe<ResolversTypes['SportCode']>, ParentType, ContextType>;
  startDate?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  startTime?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  startTimeEpoch?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  stream?: Resolver<Array<Maybe<ResolversTypes['Stream']>>, ParentType, ContextType>;
  streamId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  thumbnail?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  title?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  url?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  videoTime?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  videoTimeEpoch?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EventRelatedResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['EventRelated'] = ResolversParentTypes['EventRelated'],
> = {
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  url?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type FeaturedPlayWidgetResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['FeaturedPlayWidget'] = ResolversParentTypes['FeaturedPlayWidget'],
> = {
  content?: Resolver<Array<Maybe<ResolversTypes['PlayWidgetContent']>>, ParentType, ContextType>;
  contentType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  sortObject?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  title?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  view?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type FeaturedShopPromoResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['FeaturedShopPromo'] = ResolversParentTypes['FeaturedShopPromo'],
> = {
  content?: Resolver<Array<Maybe<ResolversTypes['ShopPromoContent']>>, ParentType, ContextType>;
  contentType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  sortObject?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  title?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  view?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type FeaturedSpotlightResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['FeaturedSpotlight'] = ResolversParentTypes['FeaturedSpotlight'],
> = {
  content?: Resolver<Array<Maybe<ResolversTypes['SpotlightContent']>>, ParentType, ContextType>;
  contentType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  sortObject?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  title?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  view?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type FeaturedStoryResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['FeaturedStory'] = ResolversParentTypes['FeaturedStory'],
> = {
  content?: Resolver<Array<Maybe<ResolversTypes['StoryContent']>>, ParentType, ContextType>;
  contentType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  sortObject?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  title?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  view?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type FeaturedTrendingResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['FeaturedTrending'] = ResolversParentTypes['FeaturedTrending'],
> = {
  content?: Resolver<Array<Maybe<ResolversTypes['TrendingContent']>>, ParentType, ContextType>;
  contentType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  sortObject?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  title?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  view?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type FeaturedcatchupLiveFinalGamesResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['FeaturedcatchupLiveFinalGames'] = ResolversParentTypes['FeaturedcatchupLiveFinalGames'],
> = {
  content?: Resolver<Array<Maybe<ResolversTypes['CatchupLiveFinalGamesContent']>>, ParentType, ContextType>;
  contentType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  sortObject?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  title?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  view?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type FieldStatResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['FieldStat'] = ResolversParentTypes['FieldStat'],
> = {
  assists?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  catcherInterference?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  caughtStealingBy?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  errors?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  involvedInDoublePlays?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  involvedInTriplePlays?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  passedBalls?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  putouts?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  stolenBasesAgainst?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type FieldingStatResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['FieldingStat'] = ResolversParentTypes['FieldingStat'],
> = {
  errors?: Resolver<Maybe<Array<Maybe<ResolversTypes['StatBreakout']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type FootballPlayResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['FootballPlay'] = ResolversParentTypes['FootballPlay'],
> = {
  clock?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  driveText?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  homeScore?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  playText?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  visitorScore?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GameResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Game'] = ResolversParentTypes['Game'],
> = {
  arena?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  home?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  start?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  visitor?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GametoolAuditResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['GametoolAudit'] = ResolversParentTypes['GametoolAudit'],
> = {
  data?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  logTime?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  mutationName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  status?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  user?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GametoolResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['GametoolResponse'] = ResolversParentTypes['GametoolResponse'],
> = {
  appFeatured?: Resolver<Maybe<ResolversTypes['AppFeatured']>, ParentType, ContextType>;
  broadcaster?: Resolver<Maybe<ResolversTypes['Broadcaster']>, ParentType, ContextType>;
  championship?: Resolver<Maybe<ResolversTypes['Championship']>, ParentType, ContextType>;
  epoch?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  event?: Resolver<Maybe<ResolversTypes['Event']>, ParentType, ContextType>;
  game?: Resolver<Maybe<ResolversTypes['ChampionshipGame']>, ParentType, ContextType>;
  location?: Resolver<Maybe<ResolversTypes['Location']>, ParentType, ContextType>;
  message?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  mmlEvent?: Resolver<Maybe<ResolversTypes['MMLEvent']>, ParentType, ContextType>;
  mmlStream?: Resolver<Maybe<ResolversTypes['MMLStream']>, ParentType, ContextType>;
  sport?: Resolver<Maybe<ResolversTypes['SportInfo']>, ParentType, ContextType>;
  stream?: Resolver<Maybe<ResolversTypes['Stream']>, ParentType, ContextType>;
  success?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  teams?: Resolver<Maybe<Array<Maybe<ResolversTypes['ChampionshipTeam']>>>, ParentType, ContextType>;
  timestamp?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GenericPlayResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['GenericPlay'] = ResolversParentTypes['GenericPlay'],
> = {
  clock?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  homeScore?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  playText?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  visitorScore?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GenericSummaryInfoResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['GenericSummaryInfo'] = ResolversParentTypes['GenericSummaryInfo'],
> = {
  homeScore?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  scoreText?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  scoreType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  teamId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  time?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  visitScore?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GeniusContestStatusResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['GeniusContestStatus'] = ResolversParentTypes['GeniusContestStatus'],
> = {
  bracketId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  clock?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  geniusGameId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  homeScore?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  period?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  pqClock?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  status?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  visitScore?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GeniusGameDetailResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['GeniusGameDetail'] = ResolversParentTypes['GeniusGameDetail'],
> = {
  homeName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  homeTeamIdGS?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  homeTeamOrgId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  liveStream?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  matchId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  matchStatus?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  matchTime?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  matchTimeUTC?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  roundNumber?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  timezone?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  venueName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  visitName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  visitTeamIdGS?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  visitTeamOrgId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type HeartbeatResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Heartbeat'] = ResolversParentTypes['Heartbeat'],
> = {
  dbDate?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type HittingSeasonResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['HittingSeason'] = ResolversParentTypes['HittingSeason'],
> = {
  atBats?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  battingAverage?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  doubles?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  hits?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  homeRuns?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  onBasePercentage?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  runsBattedIn?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  runsScored?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  strikeouts?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  triples?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  walks?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export interface JsonScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['JSON'], any> {
  name: 'JSON';
}

export type LacrosseGoalieResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['LacrosseGoalie'] = ResolversParentTypes['LacrosseGoalie'],
> = {
  combinedShutouts?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  emptyNetGoalsAllowed?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  gamesPlayed?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  gamesStarted?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  goalsAllowed?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  losses?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  minutesPlayed?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  powerplayGoalsAllowed?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  saves?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  shootoutGoalsAllowed?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  shortHandedGoalsAllowed?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  shutouts?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type LacrosseGoalsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['LacrosseGoals'] = ResolversParentTypes['LacrosseGoals'],
> = {
  emptyNetGoals?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  firstGoals?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  freePositionGoals?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  gameTyingGoals?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  gameWinningGoals?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  overtimeGoals?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  powerplayGoals?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  shootoutGoals?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  shortHandedGoals?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  unassistedGoals?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type LacrossePenaltyResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['LacrossePenalty'] = ResolversParentTypes['LacrossePenalty'],
> = {
  count?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  major?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  match?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  minor?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  minutes?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  seconds?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type LeadersResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Leaders'] = ResolversParentTypes['Leaders'],
> = {
  color?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  name6Char?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  nameShort?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ncaaOrgId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  orgLegacyId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  seoname?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  statDisplay?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  statName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  statValue?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type LinescoreResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Linescore'] = ResolversParentTypes['Linescore'],
> = {
  home?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  period?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  visit?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type LinescoreStatsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['LinescoreStats'] = ResolversParentTypes['LinescoreStats'],
> = {
  __resolveType: TypeResolveFn<'LinescoreStatsBaseball' | 'LinescoreStatsFootball', ParentType, ContextType>;
};

export type LinescoreStatsBaseballResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['LinescoreStatsBaseball'] = ResolversParentTypes['LinescoreStatsBaseball'],
> = {
  balls?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  outs?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  playerOnFirst?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  playerOnSecond?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  playerOnThird?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  strikes?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type LinescoreStatsFootballResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['LinescoreStatsFootball'] = ResolversParentTypes['LinescoreStatsFootball'],
> = {
  distance?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  down?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  possession?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  yardline?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  yardsFromGoal?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type LinkResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Link'] = ResolversParentTypes['Link'],
> = {
  championshipId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  contestId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  eventId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  linkKey?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  linkLevel?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  value?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type LiveVideoResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['LiveVideo'] = ResolversParentTypes['LiveVideo'],
> = {
  events?: Resolver<Array<Maybe<ResolversTypes['Event']>>, ParentType, ContextType>;
  games?: Resolver<Array<Maybe<ResolversTypes['ChampionshipGame']>>, ParentType, ContextType>;
  type?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type LocationResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Location'] = ResolversParentTypes['Location'],
> = {
  city?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  stateAp?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  stateId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  stateName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  stateUsps?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  venue?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type LogMessageModelResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['LogMessageModel'] = ResolversParentTypes['LogMessageModel'],
> = {
  level?: Resolver<ResolversTypes['LoggerLevel'], ParentType, ContextType>;
  message?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MmlEventResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['MMLEvent'] = ResolversParentTypes['MMLEvent'],
> = {
  awayName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  bracketId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  broadcaster?: Resolver<Maybe<ResolversTypes['Broadcaster']>, ParentType, ContextType>;
  broadcasterId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  championshipId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  encoderStatus?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  eventId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  eventType?: Resolver<Maybe<ResolversTypes['MMLEventType']>, ParentType, ContextType>;
  homeName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  isPlanned?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  latestAvailableGameIds?: Resolver<Maybe<Array<Maybe<ResolversTypes['Int']>>>, ParentType, ContextType>;
  location?: Resolver<Maybe<ResolversTypes['Location']>, ParentType, ContextType>;
  locationId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  mediaId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  mediaPhone?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  mediaTablet?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  mediaTv?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  mediaWeb?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  mmlVideo?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  parentBracketId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  sportCode?: Resolver<Maybe<ResolversTypes['SportCode']>, ParentType, ContextType>;
  startDate?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  startTime?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  startTimeEpoch?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  streamUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  thumbnailUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  title?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  videoStatus?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  videoTime?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  videoTimeEpoch?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  videoType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  vodPlaybackUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MmlOnboardingResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['MMLOnboarding'] = ResolversParentTypes['MMLOnboarding'],
> = {
  content?: Resolver<Array<Maybe<ResolversTypes['MMLOnboardingContent']>>, ParentType, ContextType>;
  contentType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  deviceType?: Resolver<Maybe<Array<Maybe<ResolversTypes['Device']>>>, ParentType, ContextType>;
  sort?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  type?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  version?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MmlOnboardingContentResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['MMLOnboardingContent'] = ResolversParentTypes['MMLOnboardingContent'],
> = {
  button?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  video?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MmlRadioHighlightResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['MMLRadioHighlight'] = ResolversParentTypes['MMLRadioHighlight'],
> = {
  bracketId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  title?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  url?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MmlRadioStreamResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['MMLRadioStream'] = ResolversParentTypes['MMLRadioStream'],
> = {
  hlsUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  mp3Url?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  spanishUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MmlStreamResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['MMLStream'] = ResolversParentTypes['MMLStream'],
> = {
  action?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  alexaRadioUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  archiveUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  backupUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  bracketId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  broadcaster?: Resolver<Maybe<ResolversTypes['Broadcaster']>, ParentType, ContextType>;
  broadcasterId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  broadcasterName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  championshipId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  contestId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  continuousPlayOverride?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  createdAt?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  disasterRecoveryUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  drupalEncoderStatus?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  drupalRadioStatus?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  drupalVideoStatus?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  eventId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  gameDate?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  gameOrder?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  googleRadioUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  homeTeamId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  host?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  location?: Resolver<Maybe<ResolversTypes['Location']>, ParentType, ContextType>;
  locationId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  mediaId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  playbackUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  primaryRadioUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  season?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  spanishRadioUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  status?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  streamId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  streamType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  tipTime?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  visitTeamId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  vodPlaybackUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MmlVideoResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['MMLVideo'] = ResolversParentTypes['MMLVideo'],
> = {
  connected1?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  connected2?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  desktop?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  feedType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  lastUpdate?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  media?: Resolver<Maybe<ResolversTypes['MediaDevice']>, ParentType, ContextType>;
  mediaId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  metadata?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  mobile?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  network?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  subType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  type?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updatedTimestamp?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  validation?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MatchupAnalysisResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['MatchupAnalysis'] = ResolversParentTypes['MatchupAnalysis'],
> = {
  date?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  isConferenceTournament?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  isMML?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  nameShort?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ncaaOrgId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  opponentTeamScore?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  opponentTeamWon?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  teamScore?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  teamWon?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MediaDeviceResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['MediaDevice'] = ResolversParentTypes['MediaDevice'],
> = {
  desktop?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  phone?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  tablet?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  tv?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MemoryUsageResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['MemoryUsage'] = ResolversParentTypes['MemoryUsage'],
> = {
  arrayBuffers?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  external?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  heapTotal?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  heapUsed?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  rss?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MomentumResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Momentum'] = ResolversParentTypes['Momentum'],
> = {
  color?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  conferenceGame?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  dateOfGame?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  gameCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  home?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  name6Char?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ncaaOrgId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  pointsDiff?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  scoreRecord?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  seoname?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MutationResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Mutation'] = ResolversParentTypes['Mutation'],
> = {
  addBroadcaster?: Resolver<
    ResolversTypes['GametoolResponse'],
    ParentType,
    ContextType,
    RequireFields<MutationAddBroadcasterArgs, 'broadcaster'>
  >;
  addChampionship?: Resolver<
    ResolversTypes['GametoolResponse'],
    ParentType,
    ContextType,
    RequireFields<MutationAddChampionshipArgs, 'championship'>
  >;
  addEvent?: Resolver<
    ResolversTypes['GametoolResponse'],
    ParentType,
    ContextType,
    RequireFields<MutationAddEventArgs, 'event'>
  >;
  addLocation?: Resolver<
    ResolversTypes['GametoolResponse'],
    ParentType,
    ContextType,
    RequireFields<MutationAddLocationArgs, 'location'>
  >;
  addMMLEvent?: Resolver<
    ResolversTypes['GametoolResponse'],
    ParentType,
    ContextType,
    RequireFields<MutationAddMmlEventArgs, 'mmlEvent'>
  >;
  addUpdateBroadcasterWithKnownId?: Resolver<
    ResolversTypes['GametoolResponse'],
    ParentType,
    ContextType,
    RequireFields<MutationAddUpdateBroadcasterWithKnownIdArgs, 'broadcaster'>
  >;
  addUpdateLocationWithKnownId?: Resolver<
    ResolversTypes['GametoolResponse'],
    ParentType,
    ContextType,
    RequireFields<MutationAddUpdateLocationWithKnownIdArgs, 'location'>
  >;
  copyConferenceTournament?: Resolver<
    ResolversTypes['GametoolResponse'],
    ParentType,
    ContextType,
    RequireFields<MutationCopyConferenceTournamentArgs, 'division' | 'fromSeason' | 'sportCode' | 'toSeason' | 'user'>
  >;
  deleteAppConfig?: Resolver<
    ResolversTypes['GametoolResponse'],
    ParentType,
    ContextType,
    RequireFields<MutationDeleteAppConfigArgs, 'appConfig' | 'user'>
  >;
  deleteBroadcaster?: Resolver<
    ResolversTypes['GametoolResponse'],
    ParentType,
    ContextType,
    RequireFields<MutationDeleteBroadcasterArgs, 'broadcaster'>
  >;
  deleteChampionship?: Resolver<
    ResolversTypes['GametoolResponse'],
    ParentType,
    ContextType,
    RequireFields<MutationDeleteChampionshipArgs, 'championshipId' | 'division' | 'sportCode' | 'user'>
  >;
  deleteEvent?: Resolver<
    ResolversTypes['GametoolResponse'],
    ParentType,
    ContextType,
    RequireFields<MutationDeleteEventArgs, 'id' | 'user'>
  >;
  deleteLocation?: Resolver<
    ResolversTypes['GametoolResponse'],
    ParentType,
    ContextType,
    RequireFields<MutationDeleteLocationArgs, 'location'>
  >;
  deleteMMLEvent?: Resolver<
    ResolversTypes['GametoolResponse'],
    ParentType,
    ContextType,
    RequireFields<MutationDeleteMmlEventArgs, 'mmlEvent'>
  >;
  deleteMMLStream?: Resolver<
    ResolversTypes['GametoolResponse'],
    ParentType,
    ContextType,
    RequireFields<MutationDeleteMmlStreamArgs, 'mmlStream'>
  >;
  deleteVideoStream?: Resolver<
    ResolversTypes['GametoolResponse'],
    ParentType,
    ContextType,
    Partial<MutationDeleteVideoStreamArgs>
  >;
  setChampionshipStaging?: Resolver<
    ResolversTypes['GametoolResponse'],
    ParentType,
    ContextType,
    RequireFields<MutationSetChampionshipStagingArgs, 'stageGames'>
  >;
  updateAppConfig?: Resolver<
    ResolversTypes['GametoolResponse'],
    ParentType,
    ContextType,
    RequireFields<MutationUpdateAppConfigArgs, 'appConfig' | 'user'>
  >;
  updateBriefing?: Resolver<
    ResolversTypes['GametoolResponse'],
    ParentType,
    ContextType,
    RequireFields<MutationUpdateBriefingArgs, 'briefDetail' | 'seasonYear' | 'user'>
  >;
  updateBroadcaster?: Resolver<
    ResolversTypes['GametoolResponse'],
    ParentType,
    ContextType,
    RequireFields<MutationUpdateBroadcasterArgs, 'broadcaster'>
  >;
  updateChampionship?: Resolver<
    ResolversTypes['GametoolResponse'],
    ParentType,
    ContextType,
    RequireFields<MutationUpdateChampionshipArgs, 'championship'>
  >;
  updateChampionshipGame?: Resolver<
    ResolversTypes['GametoolResponse'],
    ParentType,
    ContextType,
    RequireFields<MutationUpdateChampionshipGameArgs, 'championshipGame'>
  >;
  updateCondensedVideo?: Resolver<
    ResolversTypes['GametoolResponse'],
    ParentType,
    ContextType,
    RequireFields<MutationUpdateCondensedVideoArgs, 'condensedVideo' | 'seasonYear' | 'user'>
  >;
  updateConferenceTournament?: Resolver<
    ResolversTypes['GametoolResponse'],
    ParentType,
    ContextType,
    RequireFields<MutationUpdateConferenceTournamentArgs, 'conferenceTournament' | 'user'>
  >;
  updateContestProgression?: Resolver<
    ResolversTypes['AutoProgression'],
    ParentType,
    ContextType,
    RequireFields<MutationUpdateContestProgressionArgs, 'progressionGame'>
  >;
  updateEvent?: Resolver<
    ResolversTypes['GametoolResponse'],
    ParentType,
    ContextType,
    RequireFields<MutationUpdateEventArgs, 'event'>
  >;
  updateFeatured?: Resolver<
    ResolversTypes['GametoolResponse'],
    ParentType,
    ContextType,
    RequireFields<MutationUpdateFeaturedArgs, 'featuredDetail' | 'seasonYear' | 'user'>
  >;
  updateLocation?: Resolver<
    ResolversTypes['GametoolResponse'],
    ParentType,
    ContextType,
    RequireFields<MutationUpdateLocationArgs, 'location'>
  >;
  updateMMLEvent?: Resolver<
    ResolversTypes['GametoolResponse'],
    ParentType,
    ContextType,
    RequireFields<MutationUpdateMmlEventArgs, 'mmlEvent'>
  >;
  updateMMLOnboarding?: Resolver<
    ResolversTypes['GametoolResponse'],
    ParentType,
    ContextType,
    RequireFields<MutationUpdateMmlOnboardingArgs, 'mmlOnboarding' | 'seasonYear' | 'user'>
  >;
  updateMMLStream?: Resolver<
    ResolversTypes['GametoolResponse'],
    ParentType,
    ContextType,
    RequireFields<MutationUpdateMmlStreamArgs, 'mmlStream'>
  >;
  updateSport?: Resolver<
    ResolversTypes['GametoolResponse'],
    ParentType,
    ContextType,
    RequireFields<MutationUpdateSportArgs, 'sportInfo'>
  >;
  updateVideoStream?: Resolver<
    ResolversTypes['GametoolResponse'],
    ParentType,
    ContextType,
    Partial<MutationUpdateVideoStreamArgs>
  >;
  updateWPlayWidget?: Resolver<
    ResolversTypes['GametoolResponse'],
    ParentType,
    ContextType,
    RequireFields<MutationUpdateWPlayWidgetArgs, 'featuredDetail' | 'seasonYear' | 'user'>
  >;
};

export type PitcherStatResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PitcherStat'] = ResolversParentTypes['PitcherStat'],
> = {
  battersFaced?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  earnedRunAverage?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  earnedRunsAllowed?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  hitsAllowed?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  inningsPitched?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  loss?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  runsAllowed?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  save?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  strikeouts?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  strikes?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  walksAllowed?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  win?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PitcherTotalResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PitcherTotal'] = ResolversParentTypes['PitcherTotal'],
> = {
  battersFaced?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  earnedRunsAllowed?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  hitsAllowed?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  inningsPitched?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  runsAllowed?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  strikeouts?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  walksAllowed?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PitchingStatResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PitchingStat'] = ResolversParentTypes['PitchingStat'],
> = {
  loss?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  numberOfPitches?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  pitchers?: Resolver<Maybe<Array<Maybe<ResolversTypes['StatBreakout']>>>, ParentType, ContextType>;
  save?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  win?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PlayByPlayResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PlayByPlay'] = ResolversParentTypes['PlayByPlay'],
> = {
  contestId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  division?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  divisionName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  homeFouls?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  homeTimeoutsDisplay?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  homeTotalTimeoutsP1?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  homeTotalTimouts?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  minutes?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  period?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  periods?: Resolver<Maybe<Array<Maybe<ResolversTypes['PlayByPlayDetails']>>>, ParentType, ContextType>;
  seconds?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  sportCode?: Resolver<Maybe<ResolversTypes['SportCode']>, ParentType, ContextType>;
  status?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  teams?: Resolver<Maybe<Array<Maybe<ResolversTypes['ContestTeam']>>>, ParentType, ContextType>;
  title?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  visitorFouls?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  visitorTimeoutsDisplay?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  visitorTotalTimeouts?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  visitorTotalTimeoutsP1?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PlayByPlayBasketballResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PlayByPlayBasketball'] = ResolversParentTypes['PlayByPlayBasketball'],
> = {
  clock?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  distance?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  eventDescription?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  eventId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  firstName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  homeScore?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  homeText?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  isHome?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  lastName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  score?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  teamBasket?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  teamId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  visitorScore?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  visitorText?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  xCoord?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  yCoord?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PlayByPlayDetailsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PlayByPlayDetails'] = ResolversParentTypes['PlayByPlayDetails'],
> = {
  periodDisplay?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  periodNumber?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  playbyplayStats?: Resolver<Maybe<Array<Maybe<ResolversTypes['PlayByPlayStats']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PlayByPlayFootballResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PlayByPlayFootball'] = ResolversParentTypes['PlayByPlayFootball'],
> = {
  clock?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  plays?: Resolver<Maybe<Array<Maybe<ResolversTypes['FootballPlay']>>>, ParentType, ContextType>;
  teamId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PlayByPlayGenericSportResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PlayByPlayGenericSport'] = ResolversParentTypes['PlayByPlayGenericSport'],
> = {
  clock?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  plays?: Resolver<Maybe<Array<Maybe<ResolversTypes['GenericPlay']>>>, ParentType, ContextType>;
  teamId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PlayByPlayStatsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PlayByPlayStats'] = ResolversParentTypes['PlayByPlayStats'],
> = {
  __resolveType: TypeResolveFn<
    'PlayByPlayBasketball' | 'PlayByPlayFootball' | 'PlayByPlayGenericSport',
    ParentType,
    ContextType
  >;
};

export type PlayWidgetContentResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PlayWidgetContent'] = ResolversParentTypes['PlayWidgetContent'],
> = {
  awayAdvances?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  bracketId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  finalStateDesc?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  homeAdvances?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  link?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  perfectBracketsRemaining?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  removedPlayWidgetFromFeaturedOnly?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  sponsorCopy?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  title?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updatedTimestamp?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PlayerResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Player'] = ResolversParentTypes['Player'],
> = {
  collegeNameFull?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  collegeNameShort?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  conference?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  division?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  divisionName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  eligibilityYear?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  firstName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  height?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  hometown?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  jerseyNumber?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  lastName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ncaaOrgId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  position?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  schoolYear?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  seasonAverage?: Resolver<Maybe<ResolversTypes['PlayerStats']>, ParentType, ContextType>;
  sportsDataPlayerCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  teamName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  teamRank?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  weight?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PlayerStatsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PlayerStats'] = ResolversParentTypes['PlayerStats'],
> = {
  __resolveType: TypeResolveFn<
    | 'PlayerStatsBaseball'
    | 'PlayerStatsBasketball'
    | 'PlayerStatsFieldhockey'
    | 'PlayerStatsFootball'
    | 'PlayerStatsIcehockey'
    | 'PlayerStatsLacrosse'
    | 'PlayerStatsSoccer'
    | 'PlayerStatsSoftball'
    | 'PlayerStatsVolleyball',
    ParentType,
    ContextType
  >;
};

export type PlayerStatsBaseballResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PlayerStatsBaseball'] = ResolversParentTypes['PlayerStatsBaseball'],
> = {
  batterStats?: Resolver<Maybe<ResolversTypes['BatterStat']>, ParentType, ContextType>;
  fieldStats?: Resolver<Maybe<ResolversTypes['FieldStat']>, ParentType, ContextType>;
  firstName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  hittingSeason?: Resolver<Maybe<ResolversTypes['HittingSeason']>, ParentType, ContextType>;
  lastName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  number?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  participated?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  pitcherStats?: Resolver<Maybe<ResolversTypes['PitcherStat']>, ParentType, ContextType>;
  position?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  starter?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  substitute?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PlayerStatsBasketballResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PlayerStatsBasketball'] = ResolversParentTypes['PlayerStatsBasketball'],
> = {
  ass?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  assists?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  blk?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  blockedShots?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  defensiveRebounds?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  elig?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  fga?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  fgm?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  fgp?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  fieldGoalPercentage?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  fieldGoalsAttempted?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  fieldGoalsMade?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  firstName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  fls?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  fname?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  freeThrowsAttempted?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  freeThrowsMade?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  fta?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ftm?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  lastName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  lname?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  minutesPlayed?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  mpy?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  num?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  number?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  offensiveRebounds?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  personalFouls?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  points?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  pos?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  position?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  pt3a?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  pt3m?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  pt3p?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  pts?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  rbd?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  rbo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  reb?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  starter?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  steals?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  stl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  threePointPercentage?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  threePointsAttempted?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  threePointsMade?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  tno?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  totalRebounds?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  turnovers?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  year?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PlayerStatsFieldhockeyResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PlayerStatsFieldhockey'] = ResolversParentTypes['PlayerStatsFieldhockey'],
> = {
  assists?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  count?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  dsave?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  emptyNetGoals?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  firstGoals?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  firstName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  fouls?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  gameTyingGoals?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  gameWinningGoals?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  gamesPlayedAtGoalie?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  gamesStartedAtGoalie?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  goalieMinutes?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  goalieShots?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  goals?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  goalsAllowed?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  green?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  hatTricks?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  lastName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  loss?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  minutes?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  number?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  overtimeGoals?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  participated?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  penaltyShotGoals?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  penaltyShotsAttempted?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  position?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  red?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  saveByPeriod?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  saves?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  shootoutGoals?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  shots?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  shotsOnGoal?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  shutouts?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  starter?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  tie?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  unassistedGoals?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  win?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  yellow?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PlayerStatsFootballResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PlayerStatsFootball'] = ResolversParentTypes['PlayerStatsFootball'],
> = {
  defenseInterceptions?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  fieldGoalsAttempted?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  fieldGoalsLong?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  fieldGoalsMade?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  firstName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  fumblesForced?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  fumblesRecovered?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  kickReturns?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  kickReturnsAverage?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  kickReturnsLong?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  kickReturnsYards?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  kickingPts?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  lastName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  lossTackles?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  number?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  passingAttempts?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  passingCompletions?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  passingInterceptions?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  passingLong?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  passingTDs?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  passingYards?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  patMade?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  puntReturns?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  puntReturnsAverage?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  puntReturnsLong?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  puntReturnsYards?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  puntingAverage?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  puntingLong?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  puntingPunts?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  puntingYards?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  receivingLong?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  receivingReceptions?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  receivingTDs?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  receivingYards?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  rushingAttempts?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  rushingLong?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  rushingTDs?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  rushingYards?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  sacks?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  soloTackles?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  totalTackles?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PlayerStatsIcehockeyResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PlayerStatsIcehockey'] = ResolversParentTypes['PlayerStatsIcehockey'],
> = {
  assists?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  blk?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  count?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  emptyNetGoals?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  emptyNetGoalsAllowed?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  facelost?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  facewon?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  firstGoals?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  firstName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  gameTyingGoals?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  gameWinningGoals?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  goalieMinutes?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  goaliePowerPlayGoalsAllowed?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  goals?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  goalsAllowed?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  hattricks?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  lastName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  major?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  match?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  minor?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  minus?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  minutes?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  misc10?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  miscgame?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  miscgross?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  number?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  overtimeGoals?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  participated?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  penaltyShotGoals?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  penaltyShotGoalsAlowed?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  penaltyShotsAttempted?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  plus?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  plusminus?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  position?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  powerPlayGoals?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  powerPlayGoalsAllowed?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  powerPlayOpportunities?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  saveByPeriod?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  saves?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  shootoutGoals?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  shootoutGoalsAllowed?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  shortHandedGoals?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  shortHandedGoalsAllowed?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  shots?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  shutouts?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  starter?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  unassistedGoals?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PlayerStatsLacrosseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PlayerStatsLacrosse'] = ResolversParentTypes['PlayerStatsLacrosse'],
> = {
  assists?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  causedTurnovers?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  drawControls?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  firstName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  freePositionShots?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  goalTypes?: Resolver<Maybe<ResolversTypes['LacrosseGoals']>, ParentType, ContextType>;
  goalie?: Resolver<Maybe<ResolversTypes['LacrosseGoalie']>, ParentType, ContextType>;
  goals?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  groundBalls?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  lastName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  minutesPlayed?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  number?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  participated?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  penalties?: Resolver<Maybe<ResolversTypes['LacrossePenalty']>, ParentType, ContextType>;
  position?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  secondsPlayed?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  shootoutShots?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  shots?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  shotsOnGoal?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  starter?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  turnovers?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PlayerStatsSoccerResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PlayerStatsSoccer'] = ResolversParentTypes['PlayerStatsSoccer'],
> = {
  assists?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  firstName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  goalTypes?: Resolver<Maybe<ResolversTypes['SoccerGoals']>, ParentType, ContextType>;
  goalie?: Resolver<Maybe<ResolversTypes['SoccerGoalie']>, ParentType, ContextType>;
  goals?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  goalsAllowed?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  lastName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  minutesPlayed?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  number?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  participated?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  penalties?: Resolver<Maybe<ResolversTypes['SoccerPenalty']>, ParentType, ContextType>;
  penaltyShotAttempts?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  penaltyShotGoals?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  points?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  position?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  saves?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  shots?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  shotsOnGoal?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  starter?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PlayerStatsSoftballResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PlayerStatsSoftball'] = ResolversParentTypes['PlayerStatsSoftball'],
> = {
  batterStats?: Resolver<Maybe<ResolversTypes['BatterStat']>, ParentType, ContextType>;
  fieldStats?: Resolver<Maybe<ResolversTypes['FieldStat']>, ParentType, ContextType>;
  firstName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  hittingSeason?: Resolver<Maybe<ResolversTypes['HittingSeason']>, ParentType, ContextType>;
  lastName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  number?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  participated?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  pitcherStats?: Resolver<Maybe<ResolversTypes['PitcherStat']>, ParentType, ContextType>;
  position?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  starter?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  substitute?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PlayerStatsVolleyballResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PlayerStatsVolleyball'] = ResolversParentTypes['PlayerStatsVolleyball'],
> = {
  assists?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  attackAttempts?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  attackErrors?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ballHandlingErrors?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  blockAssists?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  blockSolos?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  blockingErrors?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  digs?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  firstName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  gamesPlayed?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  hittingPercentage?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  kills?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  lastName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  number?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  participated?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  points?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  position?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  receptionAttempts?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  receptionErrors?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  serveAttempts?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  serviceAces?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  serviceErrors?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  setAttempts?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  setErrors?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  starter?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  totalBlocks?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PowerPlayResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PowerPlay'] = ResolversParentTypes['PowerPlay'],
> = {
  goals?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  opportunities?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PreviewArticleResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PreviewArticle'] = ResolversParentTypes['PreviewArticle'],
> = {
  content?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  lastUpdated?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  title?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  type?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PreviousGameWinnerResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PreviousGameWinner'] = ResolversParentTypes['PreviousGameWinner'],
> = {
  bracketId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  homeSeed?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  homeTeam?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  isHome?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  isTop?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  visitSeed?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  visitTeam?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ProviderResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Provider'] = ResolversParentTypes['Provider'],
> = {
  code?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  source?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type QueryResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Query'] = ResolversParentTypes['Query'],
> = {
  alerts?: Resolver<
    Array<Maybe<ResolversTypes['Alert']>>,
    ParentType,
    ContextType,
    RequireFields<QueryAlertsArgs, 'last'>
  >;
  appConfig?: Resolver<
    Array<Maybe<ResolversTypes['AppConfig']>>,
    ParentType,
    ContextType,
    RequireFields<QueryAppConfigArgs, 'seasonYear'>
  >;
  appFeatured?: Resolver<
    Maybe<ResolversTypes['AppFeatured']>,
    ParentType,
    ContextType,
    RequireFields<QueryAppFeaturedArgs, 'seasonYear'>
  >;
  boxscore?: Resolver<
    Maybe<ResolversTypes['Boxscore']>,
    ParentType,
    ContextType,
    RequireFields<QueryBoxscoreArgs, 'contestId'>
  >;
  broadcasters?: Resolver<
    Array<Maybe<ResolversTypes['Broadcaster']>>,
    ParentType,
    ContextType,
    Partial<QueryBroadcastersArgs>
  >;
  championship?: Resolver<Maybe<ResolversTypes['Championship']>, ParentType, ContextType>;
  championshipGame?: Resolver<
    ResolversTypes['ChampionshipGame'],
    ParentType,
    ContextType,
    Partial<QueryChampionshipGameArgs>
  >;
  championshipGameFinder?: Resolver<
    Maybe<ResolversTypes['ChampionshipGameFinder']>,
    ParentType,
    ContextType,
    RequireFields<QueryChampionshipGameFinderArgs, 'bracketId' | 'championshipId'>
  >;
  championshipGames?: Resolver<
    Array<Maybe<ResolversTypes['ChampionshipGame']>>,
    ParentType,
    ContextType,
    Partial<QueryChampionshipGamesArgs>
  >;
  championshipRoundsFinder?: Resolver<
    Maybe<ResolversTypes['ChampionshipRoundsFinder']>,
    ParentType,
    ContextType,
    Partial<QueryChampionshipRoundsFinderArgs>
  >;
  championshipTeams?: Resolver<
    Array<Maybe<ResolversTypes['ChampionshipTeam']>>,
    ParentType,
    ContextType,
    Partial<QueryChampionshipTeamsArgs>
  >;
  championships?: Resolver<
    Array<Maybe<ResolversTypes['Championship']>>,
    ParentType,
    ContextType,
    Partial<QueryChampionshipsArgs>
  >;
  conferenceRecords?: Resolver<
    Array<Maybe<ResolversTypes['conferenceRecord']>>,
    ParentType,
    ContextType,
    RequireFields<QueryConferenceRecordsArgs, 'division' | 'seasonYear'>
  >;
  conferences?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['Conference']>>>,
    ParentType,
    ContextType,
    RequireFields<QueryConferencesArgs, 'division' | 'seasonYear' | 'sportCode'>
  >;
  contestTeams?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['ContestTeam']>>>,
    ParentType,
    ContextType,
    RequireFields<QueryContestTeamsArgs, 'contestId'>
  >;
  contests?: Resolver<
    Array<Maybe<ResolversTypes['Contest']>>,
    ParentType,
    ContextType,
    RequireFields<QueryContestsArgs, 'limit'>
  >;
  embedMMLVideos?: Resolver<
    Array<Maybe<ResolversTypes['EmbedVideo']>>,
    ParentType,
    ContextType,
    RequireFields<QueryEmbedMmlVideosArgs, 'seasonYear'>
  >;
  events?: Resolver<Array<Maybe<ResolversTypes['Event']>>, ParentType, ContextType, Partial<QueryEventsArgs>>;
  gametoolAudit?: Resolver<
    Array<Maybe<ResolversTypes['GametoolAudit']>>,
    ParentType,
    ContextType,
    RequireFields<QueryGametoolAuditArgs, 'user'>
  >;
  geniusGameDetails?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['GeniusGameDetail']>>>,
    ParentType,
    ContextType,
    Partial<QueryGeniusGameDetailsArgs>
  >;
  geniusLiveGameDetailedStatus?: Resolver<
    Maybe<ResolversTypes['GeniusContestStatus']>,
    ParentType,
    ContextType,
    RequireFields<QueryGeniusLiveGameDetailedStatusArgs, 'environment' | 'gameId' | 'sportCode'>
  >;
  getLogs?: Resolver<Maybe<Array<Maybe<ResolversTypes['LogMessageModel']>>>, ParentType, ContextType>;
  heartbeat?: Resolver<Array<Maybe<ResolversTypes['Heartbeat']>>, ParentType, ContextType>;
  linescores?: Resolver<
    Array<Maybe<ResolversTypes['Linescore']>>,
    ParentType,
    ContextType,
    RequireFields<QueryLinescoresArgs, 'contestId'>
  >;
  link?: Resolver<Array<Maybe<ResolversTypes['Link']>>, ParentType, ContextType, RequireFields<QueryLinkArgs, 'id'>>;
  liveVideos?: Resolver<Maybe<ResolversTypes['LiveVideo']>, ParentType, ContextType, Partial<QueryLiveVideosArgs>>;
  locations?: Resolver<Array<Maybe<ResolversTypes['Location']>>, ParentType, ContextType, Partial<QueryLocationsArgs>>;
  mmlConferences?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['Conference']>>>,
    ParentType,
    ContextType,
    RequireFields<QueryMmlConferencesArgs, 'seasonYear'>
  >;
  mmlContests?: Resolver<
    Array<Maybe<ResolversTypes['ChampionshipGame']>>,
    ParentType,
    ContextType,
    Partial<QueryMmlContestsArgs>
  >;
  mmlContinuousPlay?: Resolver<
    Array<Maybe<ResolversTypes['BracketIds']>>,
    ParentType,
    ContextType,
    RequireFields<QueryMmlContinuousPlayArgs, 'bracketId' | 'platformType' | 'seasonYear'>
  >;
  mmlDailyBriefing?: Resolver<
    Array<Maybe<ResolversTypes['Briefing']>>,
    ParentType,
    ContextType,
    RequireFields<QueryMmlDailyBriefingArgs, 'seasonYear'>
  >;
  mmlEvents?: Resolver<Array<Maybe<ResolversTypes['MMLEvent']>>, ParentType, ContextType, Partial<QueryMmlEventsArgs>>;
  mmlLeaders?: Resolver<
    Array<Maybe<ResolversTypes['Leaders']>>,
    ParentType,
    ContextType,
    RequireFields<QueryMmlLeadersArgs, 'seasonType' | 'seasonYear'>
  >;
  mmlNetRankings?: Resolver<
    Array<Maybe<ResolversTypes['Rankings']>>,
    ParentType,
    ContextType,
    RequireFields<QueryMmlNetRankingsArgs, 'seasonYear'>
  >;
  mmlOnboarding?: Resolver<
    Array<Maybe<ResolversTypes['MMLOnboarding']>>,
    ParentType,
    ContextType,
    RequireFields<QueryMmlOnboardingArgs, 'seasonYear'>
  >;
  mmlStreams?: Resolver<
    Array<Maybe<ResolversTypes['MMLStream']>>,
    ParentType,
    ContextType,
    Partial<QueryMmlStreamsArgs>
  >;
  mmlTeams?: Resolver<
    Array<Maybe<ResolversTypes['ChampionshipTeam']>>,
    ParentType,
    ContextType,
    RequireFields<QueryMmlTeamsArgs, 'seasonYear'>
  >;
  mmlTournament?: Resolver<
    Array<Maybe<ResolversTypes['Championship']>>,
    ParentType,
    ContextType,
    Partial<QueryMmlTournamentArgs>
  >;
  mmlVideos?: Resolver<
    Maybe<ResolversTypes['MMLVideo']>,
    ParentType,
    ContextType,
    RequireFields<QueryMmlVideosArgs, 'seasonYear' | 'videoType'>
  >;
  mmlWContests?: Resolver<
    Array<Maybe<ResolversTypes['ChampionshipGame']>>,
    ParentType,
    ContextType,
    Partial<QueryMmlWContestsArgs>
  >;
  mmlWNetRankings?: Resolver<
    Array<Maybe<ResolversTypes['Rankings']>>,
    ParentType,
    ContextType,
    RequireFields<QueryMmlWNetRankingsArgs, 'seasonYear'>
  >;
  mmlWTeams?: Resolver<
    Array<Maybe<ResolversTypes['ChampionshipTeam']>>,
    ParentType,
    ContextType,
    RequireFields<QueryMmlWTeamsArgs, 'seasonYear'>
  >;
  mmlWTournament?: Resolver<
    Array<Maybe<ResolversTypes['Championship']>>,
    ParentType,
    ContextType,
    Partial<QueryMmlWTournamentArgs>
  >;
  ncaaTimemachine?: Resolver<
    Array<Maybe<ResolversTypes['CurrentShow']>>,
    ParentType,
    ContextType,
    RequireFields<QueryNcaaTimemachineArgs, 'montageMediaId'>
  >;
  playWidget?: Resolver<
    Maybe<ResolversTypes['FeaturedPlayWidget']>,
    ParentType,
    ContextType,
    RequireFields<QueryPlayWidgetArgs, 'seasonYear' | 'sportCode'>
  >;
  playbyplay?: Resolver<
    Maybe<ResolversTypes['PlayByPlay']>,
    ParentType,
    ContextType,
    RequireFields<QueryPlaybyplayArgs, 'contestId'>
  >;
  playerReady?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  players?: Resolver<Array<Maybe<ResolversTypes['Player']>>, ParentType, ContextType, Partial<QueryPlayersArgs>>;
  previewArticle?: Resolver<
    Maybe<ResolversTypes['PreviewArticle']>,
    ParentType,
    ContextType,
    RequireFields<QueryPreviewArticleArgs, 'contestId'>
  >;
  providers?: Resolver<Array<Maybe<ResolversTypes['Provider']>>, ParentType, ContextType, Partial<QueryProvidersArgs>>;
  rankings?: Resolver<
    Array<Maybe<ResolversTypes['Rankings']>>,
    ParentType,
    ContextType,
    RequireFields<QueryRankingsArgs, 'division' | 'sportCode'>
  >;
  recapArticle?: Resolver<
    Maybe<ResolversTypes['RecapArticle']>,
    ParentType,
    ContextType,
    RequireFields<QueryRecapArticleArgs, 'contestId'>
  >;
  round?: Resolver<
    Maybe<ResolversTypes['Round']>,
    ParentType,
    ContextType,
    RequireFields<QueryRoundArgs, 'roundNumber'>
  >;
  rounds?: Resolver<Maybe<Array<Maybe<ResolversTypes['Round']>>>, ParentType, ContextType>;
  schedules?: Resolver<
    Maybe<ResolversTypes['Schedule']>,
    ParentType,
    ContextType,
    RequireFields<QuerySchedulesArgs, 'division' | 'seasonYear' | 'sportCode'>
  >;
  schools?: Resolver<Maybe<ResolversTypes['School']>, ParentType, ContextType, Partial<QuerySchoolsArgs>>;
  scoringSummary?: Resolver<
    Maybe<ResolversTypes['ScoringSummary']>,
    ParentType,
    ContextType,
    RequireFields<QueryScoringSummaryArgs, 'contestId'>
  >;
  service?: Resolver<Maybe<ResolversTypes['Service']>, ParentType, ContextType, Partial<QueryServiceArgs>>;
  snapshots?: Resolver<Array<Maybe<ResolversTypes['Snapshot']>>, ParentType, ContextType>;
  sports?: Resolver<Array<Maybe<ResolversTypes['SportInfo']>>, ParentType, ContextType, Partial<QuerySportsArgs>>;
  standings?: Resolver<Array<Maybe<ResolversTypes['Standing']>>, ParentType, ContextType, Partial<QueryStandingsArgs>>;
  states?: Resolver<Array<Maybe<ResolversTypes['State']>>, ParentType, ContextType, Partial<QueryStatesArgs>>;
  statistics?: Resolver<
    Array<Maybe<ResolversTypes['Statistics']>>,
    ParentType,
    ContextType,
    RequireFields<QueryStatisticsArgs, 'division' | 'seasonYear' | 'sportCode'>
  >;
  status?: Resolver<Array<Maybe<ResolversTypes['Status']>>, ParentType, ContextType, Partial<QueryStatusArgs>>;
  stream?: Resolver<
    Array<Maybe<ResolversTypes['Stream']>>,
    ParentType,
    ContextType,
    RequireFields<QueryStreamArgs, 'id'>
  >;
  teams?: Resolver<Array<Maybe<ResolversTypes['Team']>>, ParentType, ContextType, Partial<QueryTeamsArgs>>;
  timemachine?: Resolver<
    Array<Maybe<ResolversTypes['CurrentShow']>>,
    ParentType,
    ContextType,
    RequireFields<QueryTimemachineArgs, 'bracketId' | 'seasonYear'>
  >;
  tournaments?: Resolver<
    Array<Maybe<ResolversTypes['Tournament']>>,
    ParentType,
    ContextType,
    RequireFields<QueryTournamentsArgs, 'division' | 'seasonYear' | 'sportCode' | 'type'>
  >;
  version?: Resolver<Maybe<ResolversTypes['Version']>, ParentType, ContextType, Partial<QueryVersionArgs>>;
  wscCatchup?: Resolver<
    Array<Maybe<ResolversTypes['WscContent']>>,
    ParentType,
    ContextType,
    RequireFields<QueryWscCatchupArgs, 'bracketId' | 'seasonYear'>
  >;
  wscTopPlays?: Resolver<
    Array<Maybe<ResolversTypes['WscContent']>>,
    ParentType,
    ContextType,
    RequireFields<QueryWscTopPlaysArgs, 'bracketId' | 'seasonYear'>
  >;
  wscVideo?: Resolver<
    Array<Maybe<ResolversTypes['DrupalVideo']>>,
    ParentType,
    ContextType,
    RequireFields<QueryWscVideoArgs, 'seasonYear'>
  >;
  wscWVideo?: Resolver<
    Array<Maybe<ResolversTypes['DrupalVideo']>>,
    ParentType,
    ContextType,
    RequireFields<QueryWscWVideoArgs, 'seasonYear'>
  >;
};

export type RankedBasketballResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['RankedBasketball'] = ResolversParentTypes['RankedBasketball'],
> = {
  assists?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  assistsPerGame?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  average?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  blockedShots?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  blockedShotsPerGame?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  class?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  defensiveRebounds?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  disqualifications?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  doubleDouble?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  fieldGoalPercentage?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  fieldGoals?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  fieldGoalsAttempted?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  finalPoints?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  fouls?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  freeThrowPercentage?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  freeThrows?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  freeThrowsAttempted?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  games?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  height?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  lost?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  margin?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  minutesPlayed?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  minutesPlayerPerGame?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  offensiveRebounds?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  opponentFieldGoalPercentage?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  opponentFieldGoals?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  opponentFieldGoalsAttempted?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  opponentPoints?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  opponentPointsPerGame?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  opponentRebounds?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  opponentReboundsPerGame?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  opponentThreePointFieldGoalPercentage?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  opponentThreePointFieldGoals?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  opponentThreePointFieldGoalsAttempted?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  opponentTurnovers?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  percentage?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  personalFoulsPerGame?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  points?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  pointsPerGame?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  position?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  rank?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  ratio?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  reboundMargin?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  rebounds?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  reboundsPerGame?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  scoringMargin?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  sportCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  steals?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  stealsPerGame?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  team?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  teamGames?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  threePointFieldGoal?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  threePointFieldGoalAttempted?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  threePointFieldGoalPercentage?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  threePointFieldGoalsPerGame?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  tripleDoubles?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  turnovers?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  turnoversPerGame?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  won?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RankedFieldHockeyResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['RankedFieldHockey'] = ResolversParentTypes['RankedFieldHockey'],
> = {
  assists?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  average?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  class?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  defensiveSaves?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  games?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  goalKeeperMinutes?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  goals?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  goalsAgainstAverage?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  goalsAllowed?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  height?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  loss?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  penaltyCorners?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  perGame?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  percentage?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  points?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  position?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  rank?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  saves?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  shutouts?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  sportCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  team?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  teamMinutes?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  won?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RankedFootballResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['RankedFootball'] = ResolversParentTypes['RankedFootball'],
> = {
  assisstedPassSack?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  assisstedTackles?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  assistedTackleForLoss?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  averageTimeOfPossession?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  blockedKicks?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  class?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  defensiveExtraPoints?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  extraPointsAttempted?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  fieldGoalAttempts?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  fieldGoals?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  fieldGoalsPerGame?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  firstDowns?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  forcedFumbles?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  forcedFumblesPerGame?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  fourthDownConversion?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  fourthDownConversionAttempt?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  fumbleReturnTds?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  fumblesGained?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  fumblesLost?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  games?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  height?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  interceptionPerGame?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  interceptionReturnTds?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  interceptionYards?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  kickReturnTds?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  kickoffReturnYards?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  kickoffReturns?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  kickoffTouchbacks?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  losses?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  margin?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  miscYards?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  netYards?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  offensiveTds?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  oppDXP?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  oppFGM?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  oppSaf?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  oppXP?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  opponentAssistedTacklesForLoss?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  opponentBlockedKicks?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  opponentFirstDowns?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  opponentFourthDownConversion?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  opponentFourthDownConversionAttempt?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  opponentInterceptions?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  opponentKickoffReturn?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  opponentKickoffReturnTds?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  opponentKickoffReturnYards?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  opponentPassTds?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  opponentPassYards?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  opponentPasses?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  opponentPassesComplete?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  opponentPassingFirstDowns?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  opponentPenaltyFirstDowns?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  opponentPuntReturn?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  opponentPuntReturnTds?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  opponentPuntReturnYards?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  opponentPunts?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  opponentRedZoneAttempts?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  opponentRedZoneFGMade?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  opponentRedZoneScores?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  opponentRedZoneTdPasses?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  opponentRedZoneTdRushes?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  opponentRushTds?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  opponentRushYards?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  opponentRushes?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  opponentRushingFirstDowns?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  opponentSackYards?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  opponentSacks?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  opponentSoloTacklesForLoss?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  opponentTackleYards?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  opponentTacklesForLoss?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  opponentThirdDownConversion?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  opponentThirdDownConversionAttempt?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  opponentTotalTds?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  passAttempts?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  passCompletions?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  passSackYards?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  passSacksPerGame?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  passesBrokenUp?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  passesDefendedPerGame?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  passingEfficiency?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  passingFirstDowns?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  passingTds?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  passingTwoPtConversions?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  passingYards?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  pat?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  penalties?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  penaltiesPerGame?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  penaltyFirstDowns?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  penaltyYards?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  percentage?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  plays?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  pointsPerGame?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  position?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  puntBlocksAllowed?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  puntReturnTds?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  puntReturnYards?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  puntReturns?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  punts?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  puntsBlocked?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  puntsYards?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  rank?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  receivingTwoPtConversions?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  receivingYards?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  receivingYardsPerGame?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  receptions?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  receptionsPerGame?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  redZoneAttempts?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  redZoneFGMade?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  redZoneScores?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  redZoneTdPasses?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  redZoneTdRushes?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  rushTwoPtConversions?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  rushes?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  rushingFirstDowns?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  rushingTds?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  rushingYards?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  sackYards?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  sacks?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  safties?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  soloPassSack?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  soloTackleForLoss?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  soloTackles?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  soloTacklesPerGame?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  sportCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  tackleYards?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  tacklesForLossPerGame?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  tacklesPerGame?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  team?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  teamGames?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  thirdDownConversion?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  thirdDownConversionAttempt?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ties?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  timeOfPossesion?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  totalOffensiveYards?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  totalPassSacks?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  totalPassesDefended?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  totalTackles?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  totalTacklesForLoss?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  touchbacks?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  touchdown?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  turnoverLost?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  turnoversGained?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  twoPtConversions?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  wins?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  yards?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  yardsPerAttempt?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  yardsPerCarry?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  yardsPerCompletion?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  yardsPerGame?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  yardsPerPlay?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RankedIceHockeyResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['RankedIceHockey'] = ResolversParentTypes['RankedIceHockey'],
> = {
  assists?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  average?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  class?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  faceoffsLost?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  faceoffsWon?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  gameWinningGoals?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  games?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  gamesPlayed?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  goalieLoss?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  goalieMinutes?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  goalieTied?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  goalieWon?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  goals?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  goalsAgainstAverage?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  goalsAllowed?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  hatTricks?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  height?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  loss?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  margin?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  minutes?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  opponentGoals?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  opponentPowerPlayGoals?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  opponentPowerPlaysAttempted?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  penalties?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  penaltyMinutes?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  percentage?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  points?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  position?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  powerPlayGoals?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  powerPlayGoalsAllowed?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  powerPlaysAttempted?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  rank?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  saves?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  shortHandedGoals?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  shutouts?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  sportCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  team?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  tie?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  wins?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RankedLacrosseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['RankedLacrosse'] = ResolversParentTypes['RankedLacrosse'],
> = {
  assists?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  causedTurnovers?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  class?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  clearAttempts?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  clears?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  emoAttempts?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  emoGoals?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  emoGoalsAllowed?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  faceoffsLost?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  faceoffsTaken?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  faceoffsWon?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  games?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  goals?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  goalsAgainstAverage?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  goalsAllowed?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  groundBalls?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  height?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  lost?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  margin?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  minutesPlayed?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  opponentClearAttempts?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  opponentClears?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  opponentEmoAttempts?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  perGame?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  percentage?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  points?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  position?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  rank?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  saves?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  shots?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  sportCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  team?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  teamFaceoffsTaken?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  teamMinutes?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  turnovers?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  won?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RankedSeriesResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['RankedSeries'] = ResolversParentTypes['RankedSeries'],
> = {
  atBats?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  basesOnBallsAllowed?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  battingAverage?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  caughtStealing?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  class?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  completeGames?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  doubles?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  earnedRunAverage?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  earnedRuns?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  games?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  gamesStarted?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  height?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  hitBatters?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  hitByPitch?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  hits?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  hitsAllowed?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  hittingStrikeouts?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  hittingStrikoutAverage?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  homeRuns?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  inningsPitched?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  lost?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  perGame?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  percentage?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  pitchingApperances?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  position?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  rank?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  runs?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  runsBattedIn?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  sacrificeBunts?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  sacrificeFlies?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  sacrificeHits?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  saves?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  shutouts?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  sluggingPercentage?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  sportCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  stolenBaseAttempts?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  stolenBases?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  strikeoutToWalkRatio?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  strikeouts?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  strikeoutsPerNineInnings?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  strikeoutsPerSevenInnings?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  team?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  totalBases?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  triples?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  walks?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  whip?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  won?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RankedSoccerResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['RankedSoccer'] = ResolversParentTypes['RankedSoccer'],
> = {
  assists?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  class?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  cornerKicks?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  fouls?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  gameWinningGoals?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  games?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  goalieGamesPlayed?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  goalieMinutesPlayed?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  goals?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  goalsAgainstAverage?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  goalsAllowed?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  height?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  loss?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  lost?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  penaltyStrokeAttempts?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  penaltyStrokes?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  perGame?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  percentage?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  points?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  position?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  rank?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  redCards?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  saves?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  shotAttempts?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  shotsOnGoals?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  shutouts?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  sportCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  team?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  teamMinutes?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  tied?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  won?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  yellowCards?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RankedStatsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['RankedStats'] = ResolversParentTypes['RankedStats'],
> = {
  __resolveType: TypeResolveFn<
    | 'RankedBasketball'
    | 'RankedFieldHockey'
    | 'RankedFootball'
    | 'RankedIceHockey'
    | 'RankedLacrosse'
    | 'RankedSeries'
    | 'RankedSoccer'
    | 'RankedVolleyball',
    ParentType,
    ContextType
  >;
};

export type RankedTypesResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['RankedTypes'] = ResolversParentTypes['RankedTypes'],
> = {
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  headers?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  statId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  title?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  type?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RankedVolleyballResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['RankedVolleyball'] = ResolversParentTypes['RankedVolleyball'],
> = {
  aces?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  assists?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  blockAssists?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  blockSolos?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  class?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  digs?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  errors?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  height?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  kills?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  lost?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  matchesPlayed?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  opponentAttacks?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  opponentErrors?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  opponentKills?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  opponentPercentage?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  perSet?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  percentage?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  points?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  position?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  rank?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  sets?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  site?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  sportCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  team?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  total?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  totalAttacks?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  totalBlocks?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  tripleDoubles?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  won?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RankingResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Ranking'] = ResolversParentTypes['Ranking'],
> = {
  conference?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  home_wl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  nameShort?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  neutral_wl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  non_div_wl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  previous?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  quad_1?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  quad_2?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  quad_3?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  quad_4?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  rank?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  road_wl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  wl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RankingsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Rankings'] = ResolversParentTypes['Rankings'],
> = {
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  division?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  divisionName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  gender?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ranking?: Resolver<Array<Maybe<ResolversTypes['Ranking']>>, ParentType, ContextType>;
  season?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  sportCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  sportUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  title?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updated?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  year?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RecapArticleResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['RecapArticle'] = ResolversParentTypes['RecapArticle'],
> = {
  content?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  lastUpdated?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  title?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  type?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RegionSectionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['RegionSection'] = ResolversParentTypes['RegionSection'],
> = {
  abbreviation?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  championshipId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  position?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  regionCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  sectionId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  subtitle?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  title?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RoundResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Round'] = ResolversParentTypes['Round'],
> = {
  championshipId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  endDate?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  label?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  roundNumber?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  staged?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  startDate?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  subtitle?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  title?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ScheduleResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Schedule'] = ResolversParentTypes['Schedule'],
> = {
  conference?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  division?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  divisionName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  games?: Resolver<Array<Maybe<ResolversTypes['ScheduleDetail']>>, ParentType, ContextType>;
  month?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  monthName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  season?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  sportCode?: Resolver<Maybe<ResolversTypes['SportCode']>, ParentType, ContextType>;
  sportName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  sportUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  today?: Resolver<Maybe<ResolversTypes['Today']>, ParentType, ContextType>;
  type?: Resolver<Maybe<ResolversTypes['TournamentType']>, ParentType, ContextType>;
  updatedTimestamp?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  year?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ScheduleDetailResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ScheduleDetail'] = ResolversParentTypes['ScheduleDetail'],
> = {
  contestDate?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  count?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  day?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  gameDetails?: Resolver<Maybe<Array<Maybe<ResolversTypes['ChampionshipGame']>>>, ParentType, ContextType>;
  isActiveDate?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  postSeason?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  relativeDate?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  relativeDateShort?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  week?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  weekEndDate?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  weekStartDate?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  weekday?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SchoolResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['School'] = ResolversParentTypes['School'],
> = {
  address1?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  address2?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  athleticDeptUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  backgroundColor?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  city?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  country?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  foregroundColor?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  gender?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  mascotName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  mmlName6char?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  mmlNameShort?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  name4char?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  name6char?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  name8char?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  name10char?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  nameAlt?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  nameFull?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  nameShort?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ncaaOrgId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  schoolColors?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  schoolUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  seoname?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  socialHashtag?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  sports?: Resolver<Array<Maybe<ResolversTypes['Team']>>, ParentType, ContextType>;
  state?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  zipCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ScoringSummaryResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ScoringSummary'] = ResolversParentTypes['ScoringSummary'],
> = {
  periods?: Resolver<Maybe<Array<Maybe<ResolversTypes['ScoringSummaryDetails']>>>, ParentType, ContextType>;
  teams?: Resolver<Maybe<Array<Maybe<ResolversTypes['ContestTeam']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ScoringSummaryDetailsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ScoringSummaryDetails'] = ResolversParentTypes['ScoringSummaryDetails'],
> = {
  summary?: Resolver<Maybe<Array<Maybe<ResolversTypes['GenericSummaryInfo']>>>, ParentType, ContextType>;
  title?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ServiceResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Service'] = ResolversParentTypes['Service'],
> = {
  OSVersion?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  cpuUsage?: Resolver<Maybe<ResolversTypes['CPUUsage']>, ParentType, ContextType>;
  database?: Resolver<Maybe<ResolversTypes['Heartbeat']>, ParentType, ContextType>;
  memoryUsage?: Resolver<Maybe<ResolversTypes['MemoryUsage']>, ParentType, ContextType>;
  nodeJSVersion?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  sdataGraphQLReadWrite?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  sdataGraphQLRelease?: Resolver<Maybe<ResolversTypes['Version']>, ParentType, ContextType>;
  sqlCacheHits?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  sqlCacheMisses?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  sqlCacheQueries?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  sqlCacheWrites?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  uptime?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ShopPromoContentResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ShopPromoContent'] = ResolversParentTypes['ShopPromoContent'],
> = {
  contentType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  sort?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  thumbnail?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  title?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  url?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SnapshotResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Snapshot'] = ResolversParentTypes['Snapshot'],
> = {
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SoccerGoalInfoResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['SoccerGoalInfo'] = ResolversParentTypes['SoccerGoalInfo'],
> = {
  firstName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  lastName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  time?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SoccerGoalieResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['SoccerGoalie'] = ResolversParentTypes['SoccerGoalie'],
> = {
  gamesPlayed?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  gamesStarted?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  goalsAllowed?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  losses?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  minutesPlayed?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  saves?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  savesByPeriod?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  shutouts?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ties?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  wins?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SoccerGoalsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['SoccerGoals'] = ResolversParentTypes['SoccerGoals'],
> = {
  emptyNetGoals?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  firstGoals?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  gameTyingGoals?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  gameWinningGoals?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  hattricks?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  overtimeGoals?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  shootoutGoals?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  unassistedGoals?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SoccerPenaltyResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['SoccerPenalty'] = ResolversParentTypes['SoccerPenalty'],
> = {
  count?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  fouls?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  greenCards?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  redCards?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  yellowCards?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SportInfoResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['SportInfo'] = ResolversParentTypes['SportInfo'],
> = {
  academicYear?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  champYear?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  currentSeason?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  division?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  divisionName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  season?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  seasonCode?: Resolver<Maybe<ResolversTypes['SeasonCode']>, ParentType, ContextType>;
  seasonEnd?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  seasonStart?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  seasonYear?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  sportCode?: Resolver<Maybe<ResolversTypes['SportCode']>, ParentType, ContextType>;
  urlName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  year?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SpotlightContentResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['SpotlightContent'] = ResolversParentTypes['SpotlightContent'],
> = {
  bracketId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  breakingNews?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  broadcasterId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  byLine?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  closeGame?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  contentType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  countdownClock?: Resolver<Maybe<ResolversTypes['CountdownDetail']>, ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  duration?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  highlight?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  highlightMobile?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  logo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  mediaId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  mmlVideo?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  playlist?: Resolver<Array<Maybe<ResolversTypes['VodDetail']>>, ParentType, ContextType>;
  playlistTitle?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  sort?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  sponsorCopy?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  sponsorImage?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  thumbnail?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  thumbnailMobile?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  title?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updatedTimestamp?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  upsetGame?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  url?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  webUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type StandingResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Standing'] = ResolversParentTypes['Standing'],
> = {
  conferenceDisplay?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  conferenceId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  conferenceName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  conferenceSubDivision?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['ConferenceSubDivision']>>>,
    ParentType,
    ContextType
  >;
  division?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  divisionDisplay?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  lastUpdated?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  seasonYear?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type StatBreakoutResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['StatBreakout'] = ResolversParentTypes['StatBreakout'],
> = {
  lastName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  numberOfPitches?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  seasonTotal?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  strikes?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  total?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type StateResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['State'] = ResolversParentTypes['State'],
> = {
  ap?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  usps?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type StatisticsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Statistics'] = ResolversParentTypes['Statistics'],
> = {
  division?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  divisionDisplay?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  error?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  games?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  playerStats?: Resolver<Maybe<ResolversTypes['PlayerStats']>, ParentType, ContextType>;
  rankedStats?: Resolver<Maybe<Array<Maybe<ResolversTypes['RankedStats']>>>, ParentType, ContextType>;
  rankedTypes?: Resolver<Maybe<Array<Maybe<ResolversTypes['RankedTypes']>>>, ParentType, ContextType>;
  seasonYear?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  sportCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  teamStats?: Resolver<Maybe<ResolversTypes['TeamStats']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type StatusResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Status'] = ResolversParentTypes['Status'],
> = {
  code?: Resolver<Maybe<ResolversTypes['StatusCode']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type StoryContentResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['StoryContent'] = ResolversParentTypes['StoryContent'],
> = {
  contentType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  label?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type StreamResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Stream'] = ResolversParentTypes['Stream'],
> = {
  athenaId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  connectedUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  createdTimestamp?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  doNotSyndicate?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  duration?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  enabled?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  key?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  mediaId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  phoneUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  profileId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  showOnLiveSchedule?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  sourceType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  state?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  stateCode?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  streamId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  streamType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  tabletUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  thumbnail?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  thumbnailLarge?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  thumbnailMedium?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  thumbnailRaw?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  thumbnailSmall?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  thumbnailXL?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  title?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updatedTimestamp?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  url?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  videoId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  vodMediaId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TeamResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Team'] = ResolversParentTypes['Team'],
> = {
  conferenceId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  conferenceName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  conferenceSeo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  division?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  divisionName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  logoUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ncaaOrgId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  seoname?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  shortName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  sportCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  teamId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  urlName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TeamAnalysisResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TeamAnalysis'] = ResolversParentTypes['TeamAnalysis'],
> = {
  adjustedPerformance?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  assistToTurnoverRatio?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  benchPointsPercentage?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  blockedShotsPerGame?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  defensiveEfficiency?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  effectiveFieldGoalPercentage?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  fastBreakPointsPerGame?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  fieldGoalPercentage?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  freeThrowPercentage?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  last10GamesRecord?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  last10GamesWinPercentage?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  netRanking?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  offensiveEfficiency?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  offensiveReboundsPerGame?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  opponentPointsPerGame?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  personalFoulsPerGame?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  pointsFromTurnovers?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  pointsInThePaintPerGame?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  pointsPerGame?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  reboundsPerGame?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  secondChancePoints?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  seed?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  stealsPerGame?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  strengthOfSchedule?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  threePointPercentage?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  trueShootingPercentage?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  turnoversPerGame?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  winPercentage?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TeamLogoConfigResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TeamLogoConfig'] = ResolversParentTypes['TeamLogoConfig'],
> = {
  color?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  logoUri?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TeamStatsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TeamStats'] = ResolversParentTypes['TeamStats'],
> = {
  __resolveType: TypeResolveFn<
    | 'TeamStatsBaseball'
    | 'TeamStatsBasketball'
    | 'TeamStatsFieldhockey'
    | 'TeamStatsFootball'
    | 'TeamStatsIcehockey'
    | 'TeamStatsLacrosse'
    | 'TeamStatsSoccer'
    | 'TeamStatsSoftball'
    | 'TeamStatsVolleyball',
    ParentType,
    ContextType
  >;
};

export type TeamStatsBaseballResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TeamStatsBaseball'] = ResolversParentTypes['TeamStatsBaseball'],
> = {
  batterTotals?: Resolver<Maybe<ResolversTypes['BatterTotal']>, ParentType, ContextType>;
  battingStats?: Resolver<Maybe<ResolversTypes['BattingStat']>, ParentType, ContextType>;
  fieldingStats?: Resolver<Maybe<ResolversTypes['FieldingStat']>, ParentType, ContextType>;
  pitcherTotals?: Resolver<Maybe<ResolversTypes['PitcherTotal']>, ParentType, ContextType>;
  pitchingStats?: Resolver<Maybe<ResolversTypes['PitchingStat']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TeamStatsBasketballResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TeamStatsBasketball'] = ResolversParentTypes['TeamStatsBasketball'],
> = {
  ass?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  assists?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  blk?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  blockedShots?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  bonus?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  defensiveRebounds?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  fga?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  fgm?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  fgp?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  fieldGoalPercentage?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  fieldGoalsAttempted?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  fieldGoalsMade?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  fls?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  freeThrowPercentage?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  freeThrowsAttempted?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  freeThrowsMade?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  fta?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ftm?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ftp?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  offensiveRebounds?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  personalFouls?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  points?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  pos?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  possession?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  pt3a?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  pt3m?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  pt3p?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  pts?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  rbd?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  rbo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  reb?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  steals?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  stl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  threePointPercentage?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  threePointsAttempted?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  threePointsMade?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  timeout?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  tmo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  tno?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  totalRebounds?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  turnovers?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TeamStatsFieldhockeyResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TeamStatsFieldhockey'] = ResolversParentTypes['TeamStatsFieldhockey'],
> = {
  assists?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  corners?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  count?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  dsave?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  emptyNetGoals?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  firstGoals?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  fouls?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  gameTyingGoals?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  gameWinningGoals?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  gamesPlayedAtGoalie?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  gamesStartedAtGoalie?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  goalieMinutes?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  goalieShots?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  goals?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  goalsAllowed?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  green?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  hattricks?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  loss?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  minutes?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  offsides?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  overtimeGoals?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  penaltyShotGoals?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  penaltyShotsAttempted?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  red?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  saveByPeriod?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  saves?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  shootoutGoals?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  shotTotals?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  shots?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  shotsOnGoal?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  shutouts?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  tie?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  unassistedGoals?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  win?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  yellow?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TeamStatsFootballResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TeamStatsFootball'] = ResolversParentTypes['TeamStatsFootball'],
> = {
  firstDowns?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  firstDownsPassing?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  firstDownsPenalty?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  firstDownsRushing?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  fourthDownAttempts?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  fourthDowns?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  fumbles?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  fumblesLost?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  interceptionReturnYards?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  interceptionReturns?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  kickReturnYards?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  kickReturns?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  passingAttempts?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  passingAverage?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  passingCompletions?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  passingInterceptions?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  passingYards?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  penalty?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  penaltyYards?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  puntReturnYards?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  puntReturns?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  puntingAverage?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  puntingPunts?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  puntingYards?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  rushingAttempts?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  rushingAverage?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  rushingYards?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  teamAverage?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  teamPlays?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  teamYards?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  thirdDownAttempts?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  thirdDowns?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TeamStatsIcehockeyResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TeamStatsIcehockey'] = ResolversParentTypes['TeamStatsIcehockey'],
> = {
  assists?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  blk?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  count?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  emptyNetGoals?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  emptyNetGoalsAllowed?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  facelost?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  facewon?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  firstGoals?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  gameTyingGoals?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  gameWinningGoals?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  goalieMinutes?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  goaliePowerPlayGoalsAllowed?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  goals?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  goalsAllowed?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  hattricks?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  infractions?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  major?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  match?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  minor?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  minus?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  minutes?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  misc10?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  miscgame?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  miscgross?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  overtimeGoals?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  penaltyShotGoals?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  penaltyShotGoalsAlowed?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  penaltyShotsAttempted?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  plus?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  plusminus?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  powerPlayGoals?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  powerPlayGoalsAllowed?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  powerPlayOpportunities?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  powerPlayPercentage?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  saveByPeriod?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  saves?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  shootoutGoals?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  shootoutGoalsAllowed?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  shortHandedGoals?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  shortHandedGoalsAllowed?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  shots?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  shutouts?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  unassistedGoals?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TeamStatsLacrosseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TeamStatsLacrosse'] = ResolversParentTypes['TeamStatsLacrosse'],
> = {
  assists?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  causedTurnovers?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  drawControls?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  freePositionShots?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  goalTypes?: Resolver<Maybe<ResolversTypes['LacrosseGoals']>, ParentType, ContextType>;
  goalie?: Resolver<Maybe<ResolversTypes['LacrosseGoalie']>, ParentType, ContextType>;
  goals?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  groundBalls?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  minutesPlayed?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  penalties?: Resolver<Maybe<ResolversTypes['LacrossePenalty']>, ParentType, ContextType>;
  powerPlay?: Resolver<Maybe<ResolversTypes['PowerPlay']>, ParentType, ContextType>;
  secondsPlayed?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  shootoutShots?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  shots?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  shotsOnGoal?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  turnovers?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TeamStatsSoccerResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TeamStatsSoccer'] = ResolversParentTypes['TeamStatsSoccer'],
> = {
  assists?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  corners?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  fouls?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  goalTypes?: Resolver<Maybe<ResolversTypes['SoccerGoals']>, ParentType, ContextType>;
  goalie?: Resolver<Maybe<ResolversTypes['SoccerGoalie']>, ParentType, ContextType>;
  goals?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  minutesPlayed?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  offsides?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  penalties?: Resolver<Maybe<ResolversTypes['SoccerPenalty']>, ParentType, ContextType>;
  penaltyShotAttempts?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  penaltyShotGoals?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  saves?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  scores?: Resolver<Maybe<Array<Maybe<ResolversTypes['SoccerGoalInfo']>>>, ParentType, ContextType>;
  shots?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  shotsOnGoal?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TeamStatsSoftballResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TeamStatsSoftball'] = ResolversParentTypes['TeamStatsSoftball'],
> = {
  batterTotals?: Resolver<Maybe<ResolversTypes['BatterTotal']>, ParentType, ContextType>;
  battingStats?: Resolver<Maybe<ResolversTypes['BattingStat']>, ParentType, ContextType>;
  fieldingStats?: Resolver<Maybe<ResolversTypes['FieldingStat']>, ParentType, ContextType>;
  pitcherTotals?: Resolver<Maybe<ResolversTypes['PitcherTotal']>, ParentType, ContextType>;
  pitchingStats?: Resolver<Maybe<ResolversTypes['PitchingStat']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TeamStatsVolleyballResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TeamStatsVolleyball'] = ResolversParentTypes['TeamStatsVolleyball'],
> = {
  assists?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  attackAttempts?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  attackErrors?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ballHandlingErrors?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  blockAssists?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  blockSolos?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  blockingErrors?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  digs?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  gamesPlayed?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  hittingPercentage?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  kills?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  points?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  receptionAttempts?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  receptionErrors?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  serveAttempts?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  serviceAces?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  serviceErrors?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  setAttempts?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  setErrors?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  sets?: Resolver<Maybe<Array<ResolversTypes['AttackGame']>>, ParentType, ContextType>;
  totalBlocks?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TodayResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Today'] = ResolversParentTypes['Today'],
> = {
  date?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  day?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  division?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  divisionName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  monthName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  postSeason?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  season?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  sportCode?: Resolver<Maybe<ResolversTypes['SportCode']>, ParentType, ContextType>;
  sportName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  sportUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  week?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  weekday?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  weekdayLong?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  year?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TournamentResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Tournament'] = ResolversParentTypes['Tournament'],
> = {
  conference?: Resolver<Maybe<ResolversTypes['Conference']>, ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  division?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  endDate?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  orgConferenceId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  orgTournamentId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  seasonYear?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  sportCode?: Resolver<Maybe<ResolversTypes['SportCode']>, ParentType, ContextType>;
  startDate?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  type?: Resolver<Maybe<ResolversTypes['TournamentType']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TrendingContentResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TrendingContent'] = ResolversParentTypes['TrendingContent'],
> = {
  contentType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  duration?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  mediaId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  sort?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  thumbnail?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  title?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updatedTimestamp?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  url?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type VersionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Version'] = ResolversParentTypes['Version'],
> = {
  commitTag?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  revId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type VodDetailResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['VodDetail'] = ResolversParentTypes['VodDetail'],
> = {
  contentType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  duration?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  mediaId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  sort?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  thumbnail?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  title?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updatedTimestamp?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  url?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type VodListResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['VodList'] = ResolversParentTypes['VodList'],
> = {
  content?: Resolver<Array<Maybe<ResolversTypes['VodDetail']>>, ParentType, ContextType>;
  contentType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  sortObject?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  title?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  view?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type WscContentResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['WscContent'] = ResolversParentTypes['WscContent'],
> = {
  awayScore?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  bracketId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  clock?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  duration?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  homeScore?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  period?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  playerId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  teamId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  thumbnailUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  title?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  videoUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ConferenceRecordResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['conferenceRecord'] = ResolversParentTypes['conferenceRecord'],
> = {
  conferenceWins?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  confereneLosses?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  confereneTies?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  geniusId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ncaaOrgId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  rank?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  recordFound?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  year?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Resolvers<ContextType = any> = {
  Alert?: AlertResolvers<ContextType>;
  AppConfig?: AppConfigResolvers<ContextType>;
  AppFeatured?: AppFeaturedResolvers<ContextType>;
  Article?: ArticleResolvers<ContextType>;
  ArticleContent?: ArticleContentResolvers<ContextType>;
  AttackGame?: AttackGameResolvers<ContextType>;
  AutoProgression?: AutoProgressionResolvers<ContextType>;
  BatterStat?: BatterStatResolvers<ContextType>;
  BatterTotal?: BatterTotalResolvers<ContextType>;
  BattingStat?: BattingStatResolvers<ContextType>;
  Boxscore?: BoxscoreResolvers<ContextType>;
  BoxscoreDetails?: BoxscoreDetailsResolvers<ContextType>;
  BracketIds?: BracketIdsResolvers<ContextType>;
  Briefing?: BriefingResolvers<ContextType>;
  Broadcaster?: BroadcasterResolvers<ContextType>;
  CPUUsage?: CpuUsageResolvers<ContextType>;
  CatchupLiveFinalGamesContent?: CatchupLiveFinalGamesContentResolvers<ContextType>;
  Championship?: ChampionshipResolvers<ContextType>;
  ChampionshipGame?: ChampionshipGameResolvers<ContextType>;
  ChampionshipGameFinder?: ChampionshipGameFinderResolvers<ContextType>;
  ChampionshipRoundsFinder?: ChampionshipRoundsFinderResolvers<ContextType>;
  ChampionshipTeam?: ChampionshipTeamResolvers<ContextType>;
  Conference?: ConferenceResolvers<ContextType>;
  ConferenceSubDivision?: ConferenceSubDivisionResolvers<ContextType>;
  ConferenceTeamStanding?: ConferenceTeamStandingResolvers<ContextType>;
  Configuration?: ConfigurationResolvers<ContextType>;
  Contest?: ContestResolvers<ContextType>;
  ContestTeam?: ContestTeamResolvers<ContextType>;
  CountdownDetail?: CountdownDetailResolvers<ContextType>;
  CurrentShow?: CurrentShowResolvers<ContextType>;
  DrupalVideo?: DrupalVideoResolvers<ContextType>;
  EmbedVideo?: EmbedVideoResolvers<ContextType>;
  Event?: EventResolvers<ContextType>;
  EventRelated?: EventRelatedResolvers<ContextType>;
  FeaturedPlayWidget?: FeaturedPlayWidgetResolvers<ContextType>;
  FeaturedShopPromo?: FeaturedShopPromoResolvers<ContextType>;
  FeaturedSpotlight?: FeaturedSpotlightResolvers<ContextType>;
  FeaturedStory?: FeaturedStoryResolvers<ContextType>;
  FeaturedTrending?: FeaturedTrendingResolvers<ContextType>;
  FeaturedcatchupLiveFinalGames?: FeaturedcatchupLiveFinalGamesResolvers<ContextType>;
  FieldStat?: FieldStatResolvers<ContextType>;
  FieldingStat?: FieldingStatResolvers<ContextType>;
  FootballPlay?: FootballPlayResolvers<ContextType>;
  Game?: GameResolvers<ContextType>;
  GametoolAudit?: GametoolAuditResolvers<ContextType>;
  GametoolResponse?: GametoolResponseResolvers<ContextType>;
  GenericPlay?: GenericPlayResolvers<ContextType>;
  GenericSummaryInfo?: GenericSummaryInfoResolvers<ContextType>;
  GeniusContestStatus?: GeniusContestStatusResolvers<ContextType>;
  GeniusGameDetail?: GeniusGameDetailResolvers<ContextType>;
  Heartbeat?: HeartbeatResolvers<ContextType>;
  HittingSeason?: HittingSeasonResolvers<ContextType>;
  JSON?: GraphQLScalarType;
  LacrosseGoalie?: LacrosseGoalieResolvers<ContextType>;
  LacrosseGoals?: LacrosseGoalsResolvers<ContextType>;
  LacrossePenalty?: LacrossePenaltyResolvers<ContextType>;
  Leaders?: LeadersResolvers<ContextType>;
  Linescore?: LinescoreResolvers<ContextType>;
  LinescoreStats?: LinescoreStatsResolvers<ContextType>;
  LinescoreStatsBaseball?: LinescoreStatsBaseballResolvers<ContextType>;
  LinescoreStatsFootball?: LinescoreStatsFootballResolvers<ContextType>;
  Link?: LinkResolvers<ContextType>;
  LiveVideo?: LiveVideoResolvers<ContextType>;
  Location?: LocationResolvers<ContextType>;
  LogMessageModel?: LogMessageModelResolvers<ContextType>;
  MMLEvent?: MmlEventResolvers<ContextType>;
  MMLOnboarding?: MmlOnboardingResolvers<ContextType>;
  MMLOnboardingContent?: MmlOnboardingContentResolvers<ContextType>;
  MMLRadioHighlight?: MmlRadioHighlightResolvers<ContextType>;
  MMLRadioStream?: MmlRadioStreamResolvers<ContextType>;
  MMLStream?: MmlStreamResolvers<ContextType>;
  MMLVideo?: MmlVideoResolvers<ContextType>;
  MatchupAnalysis?: MatchupAnalysisResolvers<ContextType>;
  MediaDevice?: MediaDeviceResolvers<ContextType>;
  MemoryUsage?: MemoryUsageResolvers<ContextType>;
  Momentum?: MomentumResolvers<ContextType>;
  Mutation?: MutationResolvers<ContextType>;
  PitcherStat?: PitcherStatResolvers<ContextType>;
  PitcherTotal?: PitcherTotalResolvers<ContextType>;
  PitchingStat?: PitchingStatResolvers<ContextType>;
  PlayByPlay?: PlayByPlayResolvers<ContextType>;
  PlayByPlayBasketball?: PlayByPlayBasketballResolvers<ContextType>;
  PlayByPlayDetails?: PlayByPlayDetailsResolvers<ContextType>;
  PlayByPlayFootball?: PlayByPlayFootballResolvers<ContextType>;
  PlayByPlayGenericSport?: PlayByPlayGenericSportResolvers<ContextType>;
  PlayByPlayStats?: PlayByPlayStatsResolvers<ContextType>;
  PlayWidgetContent?: PlayWidgetContentResolvers<ContextType>;
  Player?: PlayerResolvers<ContextType>;
  PlayerStats?: PlayerStatsResolvers<ContextType>;
  PlayerStatsBaseball?: PlayerStatsBaseballResolvers<ContextType>;
  PlayerStatsBasketball?: PlayerStatsBasketballResolvers<ContextType>;
  PlayerStatsFieldhockey?: PlayerStatsFieldhockeyResolvers<ContextType>;
  PlayerStatsFootball?: PlayerStatsFootballResolvers<ContextType>;
  PlayerStatsIcehockey?: PlayerStatsIcehockeyResolvers<ContextType>;
  PlayerStatsLacrosse?: PlayerStatsLacrosseResolvers<ContextType>;
  PlayerStatsSoccer?: PlayerStatsSoccerResolvers<ContextType>;
  PlayerStatsSoftball?: PlayerStatsSoftballResolvers<ContextType>;
  PlayerStatsVolleyball?: PlayerStatsVolleyballResolvers<ContextType>;
  PowerPlay?: PowerPlayResolvers<ContextType>;
  PreviewArticle?: PreviewArticleResolvers<ContextType>;
  PreviousGameWinner?: PreviousGameWinnerResolvers<ContextType>;
  Provider?: ProviderResolvers<ContextType>;
  Query?: QueryResolvers<ContextType>;
  RankedBasketball?: RankedBasketballResolvers<ContextType>;
  RankedFieldHockey?: RankedFieldHockeyResolvers<ContextType>;
  RankedFootball?: RankedFootballResolvers<ContextType>;
  RankedIceHockey?: RankedIceHockeyResolvers<ContextType>;
  RankedLacrosse?: RankedLacrosseResolvers<ContextType>;
  RankedSeries?: RankedSeriesResolvers<ContextType>;
  RankedSoccer?: RankedSoccerResolvers<ContextType>;
  RankedStats?: RankedStatsResolvers<ContextType>;
  RankedTypes?: RankedTypesResolvers<ContextType>;
  RankedVolleyball?: RankedVolleyballResolvers<ContextType>;
  Ranking?: RankingResolvers<ContextType>;
  Rankings?: RankingsResolvers<ContextType>;
  RecapArticle?: RecapArticleResolvers<ContextType>;
  RegionSection?: RegionSectionResolvers<ContextType>;
  Round?: RoundResolvers<ContextType>;
  Schedule?: ScheduleResolvers<ContextType>;
  ScheduleDetail?: ScheduleDetailResolvers<ContextType>;
  School?: SchoolResolvers<ContextType>;
  ScoringSummary?: ScoringSummaryResolvers<ContextType>;
  ScoringSummaryDetails?: ScoringSummaryDetailsResolvers<ContextType>;
  Service?: ServiceResolvers<ContextType>;
  ShopPromoContent?: ShopPromoContentResolvers<ContextType>;
  Snapshot?: SnapshotResolvers<ContextType>;
  SoccerGoalInfo?: SoccerGoalInfoResolvers<ContextType>;
  SoccerGoalie?: SoccerGoalieResolvers<ContextType>;
  SoccerGoals?: SoccerGoalsResolvers<ContextType>;
  SoccerPenalty?: SoccerPenaltyResolvers<ContextType>;
  SportInfo?: SportInfoResolvers<ContextType>;
  SpotlightContent?: SpotlightContentResolvers<ContextType>;
  Standing?: StandingResolvers<ContextType>;
  StatBreakout?: StatBreakoutResolvers<ContextType>;
  State?: StateResolvers<ContextType>;
  Statistics?: StatisticsResolvers<ContextType>;
  Status?: StatusResolvers<ContextType>;
  StoryContent?: StoryContentResolvers<ContextType>;
  Stream?: StreamResolvers<ContextType>;
  Team?: TeamResolvers<ContextType>;
  TeamAnalysis?: TeamAnalysisResolvers<ContextType>;
  TeamLogoConfig?: TeamLogoConfigResolvers<ContextType>;
  TeamStats?: TeamStatsResolvers<ContextType>;
  TeamStatsBaseball?: TeamStatsBaseballResolvers<ContextType>;
  TeamStatsBasketball?: TeamStatsBasketballResolvers<ContextType>;
  TeamStatsFieldhockey?: TeamStatsFieldhockeyResolvers<ContextType>;
  TeamStatsFootball?: TeamStatsFootballResolvers<ContextType>;
  TeamStatsIcehockey?: TeamStatsIcehockeyResolvers<ContextType>;
  TeamStatsLacrosse?: TeamStatsLacrosseResolvers<ContextType>;
  TeamStatsSoccer?: TeamStatsSoccerResolvers<ContextType>;
  TeamStatsSoftball?: TeamStatsSoftballResolvers<ContextType>;
  TeamStatsVolleyball?: TeamStatsVolleyballResolvers<ContextType>;
  Today?: TodayResolvers<ContextType>;
  Tournament?: TournamentResolvers<ContextType>;
  TrendingContent?: TrendingContentResolvers<ContextType>;
  Version?: VersionResolvers<ContextType>;
  VodDetail?: VodDetailResolvers<ContextType>;
  VodList?: VodListResolvers<ContextType>;
  WscContent?: WscContentResolvers<ContextType>;
  conferenceRecord?: ConferenceRecordResolvers<ContextType>;
};

export type DirectiveResolvers<ContextType = any> = {
  oneOf?: OneOfDirectiveResolver<any, any, ContextType>;
};

export type GetAppFeaturedFromCacheQueryVariables = Exact<{
  seasonYear: Scalars['Int']['input'];
  staticTestEnv?: InputMaybe<StaticTestEnv>;
  view?: InputMaybe<AppFeaturedView>;
}>;

export type GetAppFeaturedFromCacheQuery = {
  __typename?: 'Query';
  appFeatured?: {
    __typename?: 'AppFeatured';
    spotlight?: {
      __typename?: 'FeaturedSpotlight';
      id?: string | null;
      title?: string | null;
      view?: string | null;
      sortObject?: number | null;
      description?: string | null;
      contentType?: string | null;
      content: Array<{
        __typename?: 'SpotlightContent';
        title?: string | null;
        sort?: number | null;
        description?: string | null;
        contentType?: string | null;
        thumbnail?: string | null;
        mediaId?: string | null;
        duration?: string | null;
        breakingNews?: boolean | null;
        upsetGame?: boolean | null;
        closeGame?: boolean | null;
        updatedTimestamp?: string | null;
        bracketId?: number | null;
        highlight?: string | null;
        logo?: string | null;
        sponsorCopy?: string | null;
        sponsorImage?: string | null;
        countdownClock?: { __typename?: 'CountdownDetail'; title?: string | null; epoch?: number | null } | null;
        playlist: Array<{
          __typename?: 'VodDetail';
          contentType?: string | null;
          sort?: number | null;
          title?: string | null;
          description?: string | null;
          thumbnail?: string | null;
          duration?: string | null;
          mediaId?: string | null;
        } | null>;
      } | null>;
    } | null;
    trending?: {
      __typename?: 'FeaturedTrending';
      id?: string | null;
      title?: string | null;
      view?: string | null;
      sortObject?: number | null;
      description?: string | null;
      contentType?: string | null;
      content: Array<{
        __typename?: 'TrendingContent';
        title?: string | null;
        sort?: number | null;
        url?: string | null;
        description?: string | null;
        contentType?: string | null;
        duration?: string | null;
        mediaId?: string | null;
        thumbnail?: string | null;
        updatedTimestamp?: string | null;
      } | null>;
    } | null;
    vodPlaylist?: Array<{
      __typename?: 'VodList';
      id?: string | null;
      title?: string | null;
      view?: string | null;
      sortObject?: number | null;
      description?: string | null;
      contentType?: string | null;
      content: Array<{
        __typename?: 'VodDetail';
        title?: string | null;
        sort?: number | null;
        url?: string | null;
        description?: string | null;
        contentType?: string | null;
        duration?: string | null;
        mediaId?: string | null;
        thumbnail?: string | null;
        updatedTimestamp?: string | null;
      } | null>;
    } | null> | null;
  } | null;
};

export type GetBoxscoreFromCacheQueryVariables = Exact<{
  contestId: Scalars['ID']['input'];
}>;

export type GetBoxscoreFromCacheQuery = {
  __typename?: 'Query';
  boxscore?: {
    __typename: 'Boxscore';
    contestId?: number | null;
    homeBoxscore?: {
      __typename?: 'BoxscoreDetails';
      isHome?: boolean | null;
      teamStats?:
        | { __typename: 'TeamStatsBaseball' }
        | {
            __typename: 'TeamStatsBasketball';
            tmo?: string | null;
            bonus?: string | null;
            pos?: boolean | null;
            fgm?: string | null;
            fga?: string | null;
            fgp?: string | null;
            pt3m?: string | null;
            pt3a?: string | null;
            pt3p?: string | null;
            ftm?: string | null;
            fta?: string | null;
            ftp?: string | null;
            rbo?: string | null;
            rbd?: string | null;
            reb?: string | null;
            ass?: string | null;
            fls?: string | null;
            tno?: string | null;
            blk?: string | null;
            stl?: string | null;
            pts?: string | null;
          }
        | { __typename: 'TeamStatsFieldhockey' }
        | { __typename: 'TeamStatsFootball' }
        | { __typename: 'TeamStatsIcehockey' }
        | { __typename: 'TeamStatsLacrosse' }
        | { __typename: 'TeamStatsSoccer' }
        | { __typename: 'TeamStatsSoftball' }
        | { __typename: 'TeamStatsVolleyball' }
        | null;
    } | null;
    visitorBoxscore?: {
      __typename?: 'BoxscoreDetails';
      isHome?: boolean | null;
      teamStats?:
        | { __typename: 'TeamStatsBaseball' }
        | {
            __typename: 'TeamStatsBasketball';
            tmo?: string | null;
            bonus?: string | null;
            pos?: boolean | null;
            fgm?: string | null;
            fga?: string | null;
            fgp?: string | null;
            pt3m?: string | null;
            pt3a?: string | null;
            pt3p?: string | null;
            ftm?: string | null;
            fta?: string | null;
            ftp?: string | null;
            rbo?: string | null;
            rbd?: string | null;
            reb?: string | null;
            ass?: string | null;
            fls?: string | null;
            tno?: string | null;
            blk?: string | null;
            stl?: string | null;
            pts?: string | null;
          }
        | { __typename: 'TeamStatsFieldhockey' }
        | { __typename: 'TeamStatsFootball' }
        | { __typename: 'TeamStatsIcehockey' }
        | { __typename: 'TeamStatsLacrosse' }
        | { __typename: 'TeamStatsSoccer' }
        | { __typename: 'TeamStatsSoftball' }
        | { __typename: 'TeamStatsVolleyball' }
        | null;
    } | null;
    teamBoxscore?: Array<{
      __typename?: 'BoxscoreDetails';
      isHome?: boolean | null;
      teamStats?:
        | { __typename: 'TeamStatsBaseball' }
        | {
            __typename: 'TeamStatsBasketball';
            tmo?: string | null;
            bonus?: string | null;
            pos?: boolean | null;
            fgm?: string | null;
            fga?: string | null;
            fgp?: string | null;
            pt3m?: string | null;
            pt3a?: string | null;
            pt3p?: string | null;
            ftm?: string | null;
            fta?: string | null;
            ftp?: string | null;
            rbo?: string | null;
            rbd?: string | null;
            reb?: string | null;
            ass?: string | null;
            fls?: string | null;
            tno?: string | null;
            blk?: string | null;
            stl?: string | null;
            pts?: string | null;
          }
        | { __typename: 'TeamStatsFieldhockey' }
        | { __typename: 'TeamStatsFootball' }
        | { __typename: 'TeamStatsIcehockey' }
        | { __typename: 'TeamStatsLacrosse' }
        | { __typename: 'TeamStatsSoccer' }
        | { __typename: 'TeamStatsSoftball' }
        | { __typename: 'TeamStatsVolleyball' }
        | null;
    } | null> | null;
  } | null;
};

export type GetBracketDataFromCacheQueryVariables = Exact<{ [key: string]: never }>;

export type GetBracketDataFromCacheQuery = {
  __typename?: 'Query';
  mmlContests: Array<{
    __typename: 'ChampionshipGame';
    contestId?: number | null;
    bracketId?: number | null;
    startDate?: string | null;
    startTimeEpoch?: number | null;
    gameState?: string | null;
    gameStateCode?: number | null;
    gamestateDisplay?: string | null;
    currentPeriod?: string | null;
    contestClock?: string | null;
    hasPreviewVideo?: boolean | null;
    hasRecapVideo?: boolean | null;
    hasReplayVideo?: boolean | null;
    hasCondensedVideo?: boolean | null;
    mmlVideo?: boolean | null;
    mmlRadio?: boolean | null;
    startTime?: string | null;
    hasStartTime?: boolean | null;
    victorGamePosition?: string | null;
    victorBracketPositionId?: number | null;
    formattedClock?: string | null;
    formattedVenue?: string | null;
    formattedRound?: string | null;
    teamsOnDeck: TeamsOnDeck;
    round?: {
      __typename: 'Round';
      roundNumber?: number | null;
      title?: string | null;
      subtitle?: string | null;
      label?: string | null;
    } | null;
    broadcaster?: { __typename: 'Broadcaster'; id?: string | null; name?: string | null } | null;
    region?: {
      __typename: 'RegionSection';
      title?: string | null;
      sectionId?: number | null;
      position?: string | null;
      abbreviation?: string | null;
    } | null;
    mmlStreams: Array<{ __typename?: 'MMLStream'; mediaId?: string | null } | null>;
    previewVideo: Array<{
      __typename?: 'Stream';
      title?: string | null;
      description?: string | null;
      mediaId?: string | null;
      duration?: string | null;
      thumbnailRaw?: string | null;
    } | null>;
    recapVideo: Array<{
      __typename?: 'Stream';
      title?: string | null;
      description?: string | null;
      mediaId?: string | null;
      thumbnailRaw?: string | null;
      duration?: string | null;
    } | null>;
    condensedVideo: Array<{
      __typename?: 'WscContent';
      title?: string | null;
      duration?: string | null;
      thumbnailUrl?: string | null;
      videoUrl?: string | null;
    } | null>;
    location?: {
      __typename: 'Location';
      id?: string | null;
      venue?: string | null;
      city?: string | null;
      stateId?: number | null;
      stateName?: string | null;
      stateUsps?: string | null;
      stateAp?: string | null;
    } | null;
    winnerOf: Array<{
      __typename: 'PreviousGameWinner';
      bracketId?: number | null;
      homeTeam?: string | null;
      homeSeed?: number | null;
      visitTeam?: string | null;
      visitSeed?: number | null;
      isTop?: boolean | null;
    } | null>;
    homeSeasonAverage?: {
      __typename?: 'TeamStatsBasketball';
      tmo?: string | null;
      bonus?: string | null;
      pos?: boolean | null;
      fgm?: string | null;
      fga?: string | null;
      fgp?: string | null;
      pt3m?: string | null;
      pt3a?: string | null;
      pt3p?: string | null;
      ftm?: string | null;
      fta?: string | null;
      ftp?: string | null;
      rbo?: string | null;
      rbd?: string | null;
      reb?: string | null;
      ass?: string | null;
      fls?: string | null;
      tno?: string | null;
      blk?: string | null;
      stl?: string | null;
    } | null;
    visitorSeasonAverage?: {
      __typename?: 'TeamStatsBasketball';
      tmo?: string | null;
      bonus?: string | null;
      pos?: boolean | null;
      fgm?: string | null;
      fga?: string | null;
      fgp?: string | null;
      pt3m?: string | null;
      pt3a?: string | null;
      pt3p?: string | null;
      ftm?: string | null;
      fta?: string | null;
      ftp?: string | null;
      rbo?: string | null;
      rbd?: string | null;
      reb?: string | null;
      ass?: string | null;
      fls?: string | null;
      tno?: string | null;
      blk?: string | null;
      stl?: string | null;
    } | null;
    teams: Array<{
      __typename: 'ChampionshipTeam';
      ncaaOrgId?: number | null;
      isHome?: boolean | null;
      isTop?: boolean | null;
      score?: number | null;
      color?: string | null;
      seed?: number | null;
      name6Char?: string | null;
      seoname?: string | null;
      nickname?: string | null;
      nameShort?: string | null;
      isWinner?: boolean | null;
      textOverride?: string | null;
      wins?: number | null;
      losses?: number | null;
      seasonAverage?:
        | { __typename: 'TeamStatsBaseball' }
        | {
            __typename: 'TeamStatsBasketball';
            tmo?: string | null;
            bonus?: string | null;
            pos?: boolean | null;
            fgm?: string | null;
            fga?: string | null;
            fgp?: string | null;
            pt3m?: string | null;
            pt3a?: string | null;
            pt3p?: string | null;
            ftm?: string | null;
            fta?: string | null;
            ftp?: string | null;
            rbo?: string | null;
            rbd?: string | null;
            reb?: string | null;
            ass?: string | null;
            fls?: string | null;
            tno?: string | null;
            blk?: string | null;
            stl?: string | null;
          }
        | { __typename: 'TeamStatsFieldhockey' }
        | { __typename: 'TeamStatsFootball' }
        | { __typename: 'TeamStatsIcehockey' }
        | { __typename: 'TeamStatsLacrosse' }
        | { __typename: 'TeamStatsSoccer' }
        | { __typename: 'TeamStatsSoftball' }
        | { __typename: 'TeamStatsVolleyball' }
        | null;
    } | null>;
    homeTeamLogo?: { __typename?: 'TeamLogoConfig'; color: string; logoUri: string; name: string } | null;
    visitorTeamLogo?: { __typename?: 'TeamLogoConfig'; color: string; logoUri: string; name: string } | null;
    topChampionshipTeam?: {
      __typename?: 'ChampionshipTeam';
      isTop?: boolean | null;
      isHome?: boolean | null;
      score?: number | null;
      color?: string | null;
      ncaaOrgId?: number | null;
      seed?: number | null;
      name6Char?: string | null;
      seoname?: string | null;
      nickname?: string | null;
      nameShort?: string | null;
      isWinner?: boolean | null;
      textOverride?: string | null;
      wins?: number | null;
      losses?: number | null;
    } | null;
    bottomChampionshipTeam?: {
      __typename?: 'ChampionshipTeam';
      isTop?: boolean | null;
      isHome?: boolean | null;
      score?: number | null;
      color?: string | null;
      ncaaOrgId?: number | null;
      seed?: number | null;
      name6Char?: string | null;
      seoname?: string | null;
      nickname?: string | null;
      nameShort?: string | null;
      isWinner?: boolean | null;
      textOverride?: string | null;
      wins?: number | null;
      losses?: number | null;
    } | null;
    homeChampionshipTeam?: {
      __typename?: 'ChampionshipTeam';
      isTop?: boolean | null;
      isHome?: boolean | null;
      score?: number | null;
      color?: string | null;
      ncaaOrgId?: number | null;
      seed?: number | null;
      name6Char?: string | null;
      seoname?: string | null;
      nickname?: string | null;
      nameShort?: string | null;
      isWinner?: boolean | null;
      textOverride?: string | null;
      wins?: number | null;
      losses?: number | null;
    } | null;
    visitorChampionshipTeam?: {
      __typename?: 'ChampionshipTeam';
      isTop?: boolean | null;
      isHome?: boolean | null;
      score?: number | null;
      color?: string | null;
      ncaaOrgId?: number | null;
      seed?: number | null;
      name6Char?: string | null;
      seoname?: string | null;
      nickname?: string | null;
      nameShort?: string | null;
      isWinner?: boolean | null;
      textOverride?: string | null;
      wins?: number | null;
      losses?: number | null;
    } | null;
  } | null>;
};

export type GetCurrentScoresFromCacheQueryVariables = Exact<{ [key: string]: never }>;

export type GetCurrentScoresFromCacheQuery = {
  __typename?: 'Query';
  mmlContests: Array<{
    __typename?: 'ChampionshipGame';
    contestId?: number | null;
    bracketId?: number | null;
    startDate?: string | null;
    startTimeEpoch?: number | null;
    gameState?: string | null;
    gameStateCode?: number | null;
    gamestateDisplay?: string | null;
    currentPeriod?: string | null;
    period?: number | null;
    formattedClock?: string | null;
    formattedVenue?: string | null;
    formattedRound?: string | null;
    contestClock?: string | null;
    hasPreviewVideo?: boolean | null;
    hasRecapVideo?: boolean | null;
    hasReplayVideo?: boolean | null;
    hasCondensedVideo?: boolean | null;
    mmlVideo?: boolean | null;
    mmlRadio?: boolean | null;
    hasExcitementAlert?: boolean | null;
    round?: {
      __typename?: 'Round';
      roundNumber?: number | null;
      title?: string | null;
      subtitle?: string | null;
      label?: string | null;
    } | null;
    location?: {
      __typename?: 'Location';
      id?: string | null;
      venue?: string | null;
      city?: string | null;
      stateId?: number | null;
      stateName?: string | null;
      stateUsps?: string | null;
      stateAp?: string | null;
    } | null;
    broadcaster?: { __typename?: 'Broadcaster'; id?: string | null; name?: string | null } | null;
    mmlStreams: Array<{ __typename?: 'MMLStream'; mediaId?: string | null } | null>;
    teams: Array<{
      __typename?: 'ChampionshipTeam';
      isHome?: boolean | null;
      score?: number | null;
      ncaaOrgId?: number | null;
      color?: string | null;
      seed?: number | null;
      isWinner?: boolean | null;
      nameShort?: string | null;
      seoname?: string | null;
    } | null>;
    excitementAlerts: Array<{ __typename?: 'Alert'; alertTypeName?: string | null } | null>;
  } | null>;
  mmlEvents: Array<{
    __typename?: 'MMLEvent';
    bracketId?: number | null;
    mediaId?: string | null;
    eventType?: MmlEventType | null;
    parentBracketId?: number | null;
    title?: string | null;
    description?: string | null;
    videoStatus?: string | null;
    startDate?: string | null;
    startTimeEpoch?: number | null;
    thumbnailUrl?: string | null;
    mmlVideo?: boolean | null;
    broadcaster?: { __typename?: 'Broadcaster'; id?: string | null; name?: string | null } | null;
  } | null>;
};

export type GetGameFromCacheQueryVariables = Exact<{
  seasonYear: Scalars['Int']['input'];
  staticTestEnv?: InputMaybe<StaticTestEnv>;
  bracketId?: InputMaybe<Scalars['Int']['input']>;
}>;

export type GetGameFromCacheQuery = {
  __typename?: 'Query';
  championshipGame: {
    __typename: 'ChampionshipGame';
    contestId?: number | null;
    bracketId?: number | null;
    startDate?: string | null;
    startTimeEpoch?: number | null;
    startTime?: string | null;
    hasStartTime?: boolean | null;
    gameState?: string | null;
    gameStateCode?: number | null;
    gamestateDisplay?: string | null;
    victorGamePosition?: string | null;
    victorBracketPositionId?: number | null;
    currentPeriod?: string | null;
    contestClock?: string | null;
    formattedClock?: string | null;
    formattedVenue?: string | null;
    formattedRound?: string | null;
    teamsOnDeck: TeamsOnDeck;
    hasExcitementAlert?: boolean | null;
    hasRecapVideo?: boolean | null;
    hasPreviewVideo?: boolean | null;
    hasReplayVideo?: boolean | null;
    hasCondensedVideo?: boolean | null;
    mmlVideo?: boolean | null;
    mmlRadio?: boolean | null;
    mmlStreams: Array<{ __typename?: 'MMLStream'; mediaId?: string | null } | null>;
    round?: {
      __typename: 'Round';
      roundNumber?: number | null;
      title?: string | null;
      subtitle?: string | null;
    } | null;
    location?: {
      __typename: 'Location';
      id?: string | null;
      venue?: string | null;
      city?: string | null;
      stateId?: number | null;
      stateName?: string | null;
      stateUsps?: string | null;
      stateAp?: string | null;
    } | null;
    region?: {
      __typename: 'RegionSection';
      sectionId?: number | null;
      position?: string | null;
      title?: string | null;
      abbreviation?: string | null;
    } | null;
    broadcaster?: { __typename: 'Broadcaster'; id?: string | null; name?: string | null } | null;
    winnerOf: Array<{
      __typename: 'PreviousGameWinner';
      bracketId?: number | null;
      homeTeam?: string | null;
      homeSeed?: number | null;
      visitTeam?: string | null;
      visitSeed?: number | null;
      isTop?: boolean | null;
    } | null>;
    topChampionshipTeam?: {
      __typename?: 'ChampionshipTeam';
      isTop?: boolean | null;
      isHome?: boolean | null;
      score?: number | null;
      color?: string | null;
      ncaaOrgId?: number | null;
      seed?: number | null;
      name6Char?: string | null;
      seoname?: string | null;
      nickname?: string | null;
      nameShort?: string | null;
      isWinner?: boolean | null;
      textOverride?: string | null;
      wins?: number | null;
      losses?: number | null;
    } | null;
    homeTeamLogo?: { __typename?: 'TeamLogoConfig'; color: string; logoUri: string; name: string } | null;
    visitorTeamLogo?: { __typename?: 'TeamLogoConfig'; color: string; logoUri: string; name: string } | null;
    bottomChampionshipTeam?: {
      __typename?: 'ChampionshipTeam';
      isTop?: boolean | null;
      isHome?: boolean | null;
      score?: number | null;
      color?: string | null;
      ncaaOrgId?: number | null;
      seed?: number | null;
      name6Char?: string | null;
      seoname?: string | null;
      nickname?: string | null;
      nameShort?: string | null;
      isWinner?: boolean | null;
      textOverride?: string | null;
      wins?: number | null;
      losses?: number | null;
    } | null;
    homeChampionshipTeam?: {
      __typename?: 'ChampionshipTeam';
      isTop?: boolean | null;
      isHome?: boolean | null;
      score?: number | null;
      color?: string | null;
      ncaaOrgId?: number | null;
      seed?: number | null;
      name6Char?: string | null;
      seoname?: string | null;
      nickname?: string | null;
      nameShort?: string | null;
      isWinner?: boolean | null;
      textOverride?: string | null;
      wins?: number | null;
      losses?: number | null;
    } | null;
    visitorChampionshipTeam?: {
      __typename?: 'ChampionshipTeam';
      isTop?: boolean | null;
      isHome?: boolean | null;
      score?: number | null;
      color?: string | null;
      ncaaOrgId?: number | null;
      seed?: number | null;
      name6Char?: string | null;
      seoname?: string | null;
      nickname?: string | null;
      nameShort?: string | null;
      isWinner?: boolean | null;
      textOverride?: string | null;
      wins?: number | null;
      losses?: number | null;
    } | null;
    homeSeasonAverage?: {
      __typename?: 'TeamStatsBasketball';
      tmo?: string | null;
      bonus?: string | null;
      pos?: boolean | null;
      fgm?: string | null;
      fga?: string | null;
      fgp?: string | null;
      pt3m?: string | null;
      pt3a?: string | null;
      pt3p?: string | null;
      ftm?: string | null;
      fta?: string | null;
      ftp?: string | null;
      rbo?: string | null;
      rbd?: string | null;
      reb?: string | null;
      ass?: string | null;
      fls?: string | null;
      tno?: string | null;
      blk?: string | null;
      stl?: string | null;
    } | null;
    visitorSeasonAverage?: {
      __typename?: 'TeamStatsBasketball';
      tmo?: string | null;
      bonus?: string | null;
      pos?: boolean | null;
      fgm?: string | null;
      fga?: string | null;
      fgp?: string | null;
      pt3m?: string | null;
      pt3a?: string | null;
      pt3p?: string | null;
      ftm?: string | null;
      fta?: string | null;
      ftp?: string | null;
      rbo?: string | null;
      rbd?: string | null;
      reb?: string | null;
      ass?: string | null;
      fls?: string | null;
      tno?: string | null;
      blk?: string | null;
      stl?: string | null;
    } | null;
    teams: Array<{
      __typename: 'ChampionshipTeam';
      isTop?: boolean | null;
      isHome?: boolean | null;
      score?: number | null;
      color?: string | null;
      nickname?: string | null;
      wins?: number | null;
      losses?: number | null;
      ncaaOrgId?: number | null;
      seed?: number | null;
      name6Char?: string | null;
      seoname?: string | null;
      nameShort?: string | null;
      isWinner?: boolean | null;
      seasonAverage?:
        | { __typename: 'TeamStatsBaseball' }
        | {
            __typename: 'TeamStatsBasketball';
            tmo?: string | null;
            bonus?: string | null;
            pos?: boolean | null;
            fgm?: string | null;
            fga?: string | null;
            fgp?: string | null;
            pt3m?: string | null;
            pt3a?: string | null;
            pt3p?: string | null;
            ftm?: string | null;
            fta?: string | null;
            ftp?: string | null;
            rbo?: string | null;
            rbd?: string | null;
            reb?: string | null;
            ass?: string | null;
            fls?: string | null;
            tno?: string | null;
            blk?: string | null;
            stl?: string | null;
          }
        | { __typename: 'TeamStatsFieldhockey' }
        | { __typename: 'TeamStatsFootball' }
        | { __typename: 'TeamStatsIcehockey' }
        | { __typename: 'TeamStatsLacrosse' }
        | { __typename: 'TeamStatsSoccer' }
        | { __typename: 'TeamStatsSoftball' }
        | { __typename: 'TeamStatsVolleyball' }
        | null;
    } | null>;
    excitementAlerts: Array<{ __typename?: 'Alert'; alertTypeName?: string | null } | null>;
    previewVideo: Array<{
      __typename?: 'Stream';
      title?: string | null;
      description?: string | null;
      mediaId?: string | null;
      duration?: string | null;
      thumbnailRaw?: string | null;
    } | null>;
    recapVideo: Array<{
      __typename?: 'Stream';
      title?: string | null;
      description?: string | null;
      mediaId?: string | null;
      thumbnailRaw?: string | null;
      duration?: string | null;
    } | null>;
    condensedVideo: Array<{
      __typename?: 'WscContent';
      title?: string | null;
      duration?: string | null;
      thumbnailUrl?: string | null;
      videoUrl?: string | null;
    } | null>;
  };
};

export type GetRoundInfoFromCacheQueryVariables = Exact<{
  roundNumber: Scalars['Int']['input'];
}>;

export type GetRoundInfoFromCacheQuery = {
  __typename?: 'Query';
  round?: { __typename?: 'Round'; roundNumber?: number | null; title?: string | null } | null;
};

export type GetRoundsFromCacheQueryVariables = Exact<{ [key: string]: never }>;

export type GetRoundsFromCacheQuery = {
  __typename?: 'Query';
  championship?: {
    __typename?: 'Championship';
    currentRound?: string | null;
    rounds: Array<{ __typename?: 'Round'; roundNumber?: number | null; title?: string | null } | null>;
  } | null;
};

export type GetScoreboardFromCacheQueryVariables = Exact<{ [key: string]: never }>;

export type GetScoreboardFromCacheQuery = {
  __typename?: 'Query';
  mmlContests: Array<{
    __typename: 'ChampionshipGame';
    contestId?: number | null;
    bracketId?: number | null;
    startDate?: string | null;
    startTimeEpoch?: number | null;
    gameState?: string | null;
    gameStateCode?: number | null;
    gamestateDisplay?: string | null;
    currentPeriod?: string | null;
    formattedClock?: string | null;
    formattedVenue?: string | null;
    formattedRound?: string | null;
    contestClock?: string | null;
    hasPreviewVideo?: boolean | null;
    hasRecapVideo?: boolean | null;
    hasReplayVideo?: boolean | null;
    hasCondensedVideo?: boolean | null;
    mmlVideo?: boolean | null;
    mmlRadio?: boolean | null;
    startTime?: string | null;
    hasStartTime?: boolean | null;
    victorGamePosition?: string | null;
    victorBracketPositionId?: number | null;
    teamsOnDeck: TeamsOnDeck;
    hasExcitementAlert?: boolean | null;
    round?: {
      __typename?: 'Round';
      roundNumber?: number | null;
      title?: string | null;
      subtitle?: string | null;
      label?: string | null;
    } | null;
    location?: {
      __typename?: 'Location';
      id?: string | null;
      venue?: string | null;
      city?: string | null;
      stateId?: number | null;
      stateName?: string | null;
      stateUsps?: string | null;
      stateAp?: string | null;
    } | null;
    broadcaster?: { __typename: 'Broadcaster'; id?: string | null; name?: string | null } | null;
    mmlStreams: Array<{ __typename?: 'MMLStream'; mediaId?: string | null } | null>;
    previewVideo: Array<{
      __typename?: 'Stream';
      title?: string | null;
      description?: string | null;
      mediaId?: string | null;
      duration?: string | null;
      thumbnailRaw?: string | null;
    } | null>;
    recapVideo: Array<{
      __typename?: 'Stream';
      title?: string | null;
      description?: string | null;
      mediaId?: string | null;
      thumbnailRaw?: string | null;
      duration?: string | null;
    } | null>;
    condensedVideo: Array<{
      __typename?: 'WscContent';
      title?: string | null;
      duration?: string | null;
      thumbnailUrl?: string | null;
      videoUrl?: string | null;
    } | null>;
    region?: {
      __typename: 'RegionSection';
      sectionId?: number | null;
      position?: string | null;
      title?: string | null;
      abbreviation?: string | null;
    } | null;
    winnerOf: Array<{
      __typename: 'PreviousGameWinner';
      bracketId?: number | null;
      homeTeam?: string | null;
      homeSeed?: number | null;
      visitTeam?: string | null;
      visitSeed?: number | null;
      isTop?: boolean | null;
    } | null>;
    homeSeasonAverage?: {
      __typename?: 'TeamStatsBasketball';
      tmo?: string | null;
      bonus?: string | null;
      pos?: boolean | null;
      fgm?: string | null;
      fga?: string | null;
      fgp?: string | null;
      pt3m?: string | null;
      pt3a?: string | null;
      pt3p?: string | null;
      ftm?: string | null;
      fta?: string | null;
      ftp?: string | null;
      rbo?: string | null;
      rbd?: string | null;
      reb?: string | null;
      ass?: string | null;
      fls?: string | null;
      tno?: string | null;
      blk?: string | null;
      stl?: string | null;
    } | null;
    visitorSeasonAverage?: {
      __typename?: 'TeamStatsBasketball';
      tmo?: string | null;
      bonus?: string | null;
      pos?: boolean | null;
      fgm?: string | null;
      fga?: string | null;
      fgp?: string | null;
      pt3m?: string | null;
      pt3a?: string | null;
      pt3p?: string | null;
      ftm?: string | null;
      fta?: string | null;
      ftp?: string | null;
      rbo?: string | null;
      rbd?: string | null;
      reb?: string | null;
      ass?: string | null;
      fls?: string | null;
      tno?: string | null;
      blk?: string | null;
      stl?: string | null;
    } | null;
    teams: Array<{
      __typename: 'ChampionshipTeam';
      ncaaOrgId?: number | null;
      isHome?: boolean | null;
      isTop?: boolean | null;
      score?: number | null;
      color?: string | null;
      seed?: number | null;
      name6Char?: string | null;
      seoname?: string | null;
      nickname?: string | null;
      nameShort?: string | null;
      isWinner?: boolean | null;
      textOverride?: string | null;
      wins?: number | null;
      losses?: number | null;
      seasonAverage?:
        | { __typename: 'TeamStatsBaseball' }
        | {
            __typename: 'TeamStatsBasketball';
            tmo?: string | null;
            bonus?: string | null;
            pos?: boolean | null;
            fgm?: string | null;
            fga?: string | null;
            fgp?: string | null;
            pt3m?: string | null;
            pt3a?: string | null;
            pt3p?: string | null;
            ftm?: string | null;
            fta?: string | null;
            ftp?: string | null;
            rbo?: string | null;
            rbd?: string | null;
            reb?: string | null;
            ass?: string | null;
            fls?: string | null;
            tno?: string | null;
            blk?: string | null;
            stl?: string | null;
          }
        | { __typename: 'TeamStatsFieldhockey' }
        | { __typename: 'TeamStatsFootball' }
        | { __typename: 'TeamStatsIcehockey' }
        | { __typename: 'TeamStatsLacrosse' }
        | { __typename: 'TeamStatsSoccer' }
        | { __typename: 'TeamStatsSoftball' }
        | { __typename: 'TeamStatsVolleyball' }
        | null;
    } | null>;
    homeTeamLogo?: { __typename?: 'TeamLogoConfig'; color: string; logoUri: string; name: string } | null;
    visitorTeamLogo?: { __typename?: 'TeamLogoConfig'; color: string; logoUri: string; name: string } | null;
    topChampionshipTeam?: {
      __typename?: 'ChampionshipTeam';
      isTop?: boolean | null;
      isHome?: boolean | null;
      score?: number | null;
      color?: string | null;
      ncaaOrgId?: number | null;
      seed?: number | null;
      name6Char?: string | null;
      seoname?: string | null;
      nickname?: string | null;
      nameShort?: string | null;
      isWinner?: boolean | null;
      textOverride?: string | null;
      wins?: number | null;
      losses?: number | null;
    } | null;
    bottomChampionshipTeam?: {
      __typename?: 'ChampionshipTeam';
      isTop?: boolean | null;
      isHome?: boolean | null;
      score?: number | null;
      color?: string | null;
      ncaaOrgId?: number | null;
      seed?: number | null;
      name6Char?: string | null;
      seoname?: string | null;
      nickname?: string | null;
      nameShort?: string | null;
      isWinner?: boolean | null;
      textOverride?: string | null;
      wins?: number | null;
      losses?: number | null;
    } | null;
    homeChampionshipTeam?: {
      __typename?: 'ChampionshipTeam';
      isTop?: boolean | null;
      isHome?: boolean | null;
      score?: number | null;
      color?: string | null;
      ncaaOrgId?: number | null;
      seed?: number | null;
      name6Char?: string | null;
      seoname?: string | null;
      nickname?: string | null;
      nameShort?: string | null;
      isWinner?: boolean | null;
      textOverride?: string | null;
      wins?: number | null;
      losses?: number | null;
    } | null;
    visitorChampionshipTeam?: {
      __typename?: 'ChampionshipTeam';
      isTop?: boolean | null;
      isHome?: boolean | null;
      score?: number | null;
      color?: string | null;
      ncaaOrgId?: number | null;
      seed?: number | null;
      name6Char?: string | null;
      seoname?: string | null;
      nickname?: string | null;
      nameShort?: string | null;
      isWinner?: boolean | null;
      textOverride?: string | null;
      wins?: number | null;
      losses?: number | null;
    } | null;
    excitementAlerts: Array<{ __typename?: 'Alert'; alertTypeName?: string | null } | null>;
  } | null>;
};

export type MmlContestFromCacheQueryVariables = Exact<{
  seasonYear: Scalars['Int']['input'];
  staticTestEnv?: InputMaybe<StaticTestEnv>;
}>;

export type MmlContestFromCacheQuery = {
  __typename?: 'Query';
  mmlContests: Array<{
    __typename?: 'ChampionshipGame';
    bracketId?: number | null;
    startDate?: string | null;
    startTimeEpoch?: number | null;
    mmlStreams: Array<{ __typename?: 'MMLStream'; mediaId?: string | null } | null>;
    round?: { __typename?: 'Round'; roundNumber?: number | null; title?: string | null } | null;
  } | null>;
};

export type MmlEventFromCacheQueryVariables = Exact<{
  bracketId?: InputMaybe<Scalars['Int']['input']>;
}>;

export type MmlEventFromCacheQuery = {
  __typename?: 'Query';
  mmlEvents: Array<{
    __typename: 'MMLEvent';
    bracketId?: number | null;
    mediaId?: string | null;
    eventType?: MmlEventType | null;
    parentBracketId?: number | null;
    title?: string | null;
    description?: string | null;
    videoStatus?: string | null;
    startDate?: string | null;
    startTimeEpoch?: number | null;
    thumbnailUrl?: string | null;
    mmlVideo?: boolean | null;
    broadcaster?: { __typename: 'Broadcaster'; id?: string | null; name?: string | null } | null;
  } | null>;
};

export type ChampionshipFragment = {
  __typename: 'Championship';
  currentRound?: string | null;
  year?: number | null;
  regions: Array<{
    __typename: 'RegionSection';
    sectionId?: number | null;
    position?: string | null;
    title?: string | null;
    abbreviation?: string | null;
  } | null>;
  rounds: Array<{
    __typename: 'Round';
    roundNumber?: number | null;
    subtitle?: string | null;
    title?: string | null;
  } | null>;
};

export type GameFragment = {
  __typename: 'ChampionshipGame';
  contestId?: number | null;
  bracketId?: number | null;
  startDate?: string | null;
  startTimeEpoch?: number | null;
  gameState?: string | null;
  gameStateCode?: number | null;
  gamestateDisplay?: string | null;
  currentPeriod?: string | null;
  formattedClock?: string | null;
  formattedVenue?: string | null;
  formattedRound?: string | null;
  contestClock?: string | null;
  hasPreviewVideo?: boolean | null;
  hasRecapVideo?: boolean | null;
  hasReplayVideo?: boolean | null;
  hasCondensedVideo?: boolean | null;
  mmlVideo?: boolean | null;
  mmlRadio?: boolean | null;
  startTime?: string | null;
  hasStartTime?: boolean | null;
  victorGamePosition?: string | null;
  victorBracketPositionId?: number | null;
  teamsOnDeck: TeamsOnDeck;
  hasExcitementAlert?: boolean | null;
  round?: {
    __typename: 'Round';
    roundNumber?: number | null;
    title?: string | null;
    subtitle?: string | null;
    label?: string | null;
  } | null;
  location?: {
    __typename?: 'Location';
    id?: string | null;
    venue?: string | null;
    city?: string | null;
    stateId?: number | null;
    stateName?: string | null;
    stateUsps?: string | null;
    stateAp?: string | null;
  } | null;
  broadcaster?: { __typename: 'Broadcaster'; id?: string | null; name?: string | null } | null;
  region?: {
    __typename: 'RegionSection';
    title?: string | null;
    sectionId?: number | null;
    position?: string | null;
    abbreviation?: string | null;
  } | null;
  mmlStreams: Array<{ __typename?: 'MMLStream'; mediaId?: string | null } | null>;
  previewVideo: Array<{
    __typename?: 'Stream';
    title?: string | null;
    description?: string | null;
    mediaId?: string | null;
    duration?: string | null;
    thumbnailRaw?: string | null;
  } | null>;
  recapVideo: Array<{
    __typename?: 'Stream';
    title?: string | null;
    description?: string | null;
    mediaId?: string | null;
    thumbnailRaw?: string | null;
    duration?: string | null;
  } | null>;
  condensedVideo: Array<{
    __typename?: 'WscContent';
    title?: string | null;
    duration?: string | null;
    thumbnailUrl?: string | null;
    videoUrl?: string | null;
  } | null>;
  winnerOf: Array<{
    __typename: 'PreviousGameWinner';
    bracketId?: number | null;
    homeTeam?: string | null;
    homeSeed?: number | null;
    visitTeam?: string | null;
    visitSeed?: number | null;
    isTop?: boolean | null;
  } | null>;
  homeSeasonAverage?: {
    __typename?: 'TeamStatsBasketball';
    tmo?: string | null;
    bonus?: string | null;
    pos?: boolean | null;
    fgm?: string | null;
    fga?: string | null;
    fgp?: string | null;
    pt3m?: string | null;
    pt3a?: string | null;
    pt3p?: string | null;
    ftm?: string | null;
    fta?: string | null;
    ftp?: string | null;
    rbo?: string | null;
    rbd?: string | null;
    reb?: string | null;
    ass?: string | null;
    fls?: string | null;
    tno?: string | null;
    blk?: string | null;
    stl?: string | null;
  } | null;
  visitorSeasonAverage?: {
    __typename?: 'TeamStatsBasketball';
    tmo?: string | null;
    bonus?: string | null;
    pos?: boolean | null;
    fgm?: string | null;
    fga?: string | null;
    fgp?: string | null;
    pt3m?: string | null;
    pt3a?: string | null;
    pt3p?: string | null;
    ftm?: string | null;
    fta?: string | null;
    ftp?: string | null;
    rbo?: string | null;
    rbd?: string | null;
    reb?: string | null;
    ass?: string | null;
    fls?: string | null;
    tno?: string | null;
    blk?: string | null;
    stl?: string | null;
  } | null;
  teams: Array<{
    __typename: 'ChampionshipTeam';
    ncaaOrgId?: number | null;
    isHome?: boolean | null;
    isTop?: boolean | null;
    score?: number | null;
    color?: string | null;
    seed?: number | null;
    name6Char?: string | null;
    seoname?: string | null;
    nickname?: string | null;
    nameShort?: string | null;
    isWinner?: boolean | null;
    textOverride?: string | null;
    wins?: number | null;
    losses?: number | null;
    seasonAverage?:
      | { __typename: 'TeamStatsBaseball' }
      | {
          __typename: 'TeamStatsBasketball';
          tmo?: string | null;
          bonus?: string | null;
          pos?: boolean | null;
          fgm?: string | null;
          fga?: string | null;
          fgp?: string | null;
          pt3m?: string | null;
          pt3a?: string | null;
          pt3p?: string | null;
          ftm?: string | null;
          fta?: string | null;
          ftp?: string | null;
          rbo?: string | null;
          rbd?: string | null;
          reb?: string | null;
          ass?: string | null;
          fls?: string | null;
          tno?: string | null;
          blk?: string | null;
          stl?: string | null;
        }
      | { __typename: 'TeamStatsFieldhockey' }
      | { __typename: 'TeamStatsFootball' }
      | { __typename: 'TeamStatsIcehockey' }
      | { __typename: 'TeamStatsLacrosse' }
      | { __typename: 'TeamStatsSoccer' }
      | { __typename: 'TeamStatsSoftball' }
      | { __typename: 'TeamStatsVolleyball' }
      | null;
  } | null>;
  homeTeamLogo?: { __typename?: 'TeamLogoConfig'; color: string; logoUri: string; name: string } | null;
  visitorTeamLogo?: { __typename?: 'TeamLogoConfig'; color: string; logoUri: string; name: string } | null;
  topChampionshipTeam?: {
    __typename?: 'ChampionshipTeam';
    isTop?: boolean | null;
    isHome?: boolean | null;
    score?: number | null;
    color?: string | null;
    ncaaOrgId?: number | null;
    seed?: number | null;
    name6Char?: string | null;
    seoname?: string | null;
    nickname?: string | null;
    nameShort?: string | null;
    isWinner?: boolean | null;
    textOverride?: string | null;
    wins?: number | null;
    losses?: number | null;
  } | null;
  bottomChampionshipTeam?: {
    __typename?: 'ChampionshipTeam';
    isTop?: boolean | null;
    isHome?: boolean | null;
    score?: number | null;
    color?: string | null;
    ncaaOrgId?: number | null;
    seed?: number | null;
    name6Char?: string | null;
    seoname?: string | null;
    nickname?: string | null;
    nameShort?: string | null;
    isWinner?: boolean | null;
    textOverride?: string | null;
    wins?: number | null;
    losses?: number | null;
  } | null;
  homeChampionshipTeam?: {
    __typename?: 'ChampionshipTeam';
    isTop?: boolean | null;
    isHome?: boolean | null;
    score?: number | null;
    color?: string | null;
    ncaaOrgId?: number | null;
    seed?: number | null;
    name6Char?: string | null;
    seoname?: string | null;
    nickname?: string | null;
    nameShort?: string | null;
    isWinner?: boolean | null;
    textOverride?: string | null;
    wins?: number | null;
    losses?: number | null;
  } | null;
  visitorChampionshipTeam?: {
    __typename?: 'ChampionshipTeam';
    isTop?: boolean | null;
    isHome?: boolean | null;
    score?: number | null;
    color?: string | null;
    ncaaOrgId?: number | null;
    seed?: number | null;
    name6Char?: string | null;
    seoname?: string | null;
    nickname?: string | null;
    nameShort?: string | null;
    isWinner?: boolean | null;
    textOverride?: string | null;
    wins?: number | null;
    losses?: number | null;
  } | null;
  excitementAlerts: Array<{ __typename?: 'Alert'; alertTypeName?: string | null } | null>;
};

export type AppFeaturedQueryVariables = Exact<{
  seasonYear: Scalars['Int']['input'];
  staticTestEnv?: InputMaybe<StaticTestEnv>;
  view?: InputMaybe<AppFeaturedView>;
}>;

export type AppFeaturedQuery = {
  __typename?: 'Query';
  appFeatured?: {
    __typename?: 'AppFeatured';
    spotlight?: {
      __typename?: 'FeaturedSpotlight';
      id?: string | null;
      title?: string | null;
      view?: string | null;
      sortObject?: number | null;
      description?: string | null;
      contentType?: string | null;
      content: Array<{
        __typename?: 'SpotlightContent';
        title?: string | null;
        sort?: number | null;
        description?: string | null;
        contentType?: string | null;
        thumbnail?: string | null;
        mediaId?: string | null;
        duration?: string | null;
        breakingNews?: boolean | null;
        upsetGame?: boolean | null;
        closeGame?: boolean | null;
        updatedTimestamp?: string | null;
        bracketId?: number | null;
        highlight?: string | null;
        url?: string | null;
        logo?: string | null;
        sponsorCopy?: string | null;
        sponsorImage?: string | null;
        countdownClock?: { __typename?: 'CountdownDetail'; title?: string | null; epoch?: number | null } | null;
        playlist: Array<{
          __typename?: 'VodDetail';
          contentType?: string | null;
          sort?: number | null;
          title?: string | null;
          description?: string | null;
          thumbnail?: string | null;
          duration?: string | null;
          mediaId?: string | null;
        } | null>;
      } | null>;
    } | null;
    trending?: {
      __typename?: 'FeaturedTrending';
      id?: string | null;
      title?: string | null;
      view?: string | null;
      sortObject?: number | null;
      description?: string | null;
      contentType?: string | null;
      content: Array<{
        __typename?: 'TrendingContent';
        title?: string | null;
        sort?: number | null;
        url?: string | null;
        description?: string | null;
        contentType?: string | null;
        duration?: string | null;
        mediaId?: string | null;
        thumbnail?: string | null;
        updatedTimestamp?: string | null;
      } | null>;
    } | null;
    vodPlaylist?: Array<{
      __typename?: 'VodList';
      id?: string | null;
      title?: string | null;
      view?: string | null;
      sortObject?: number | null;
      description?: string | null;
      contentType?: string | null;
      content: Array<{
        __typename?: 'VodDetail';
        title?: string | null;
        sort?: number | null;
        url?: string | null;
        description?: string | null;
        contentType?: string | null;
        duration?: string | null;
        mediaId?: string | null;
        thumbnail?: string | null;
        updatedTimestamp?: string | null;
      } | null>;
    } | null> | null;
  } | null;
};

export type BracketDataQueryVariables = Exact<{
  seasonYear: Scalars['Int']['input'];
  staticTestEnv?: InputMaybe<StaticTestEnv>;
  bracketId?: InputMaybe<Scalars['Int']['input']>;
}>;

export type BracketDataQuery = {
  __typename?: 'Query';
  mmlContests: Array<{
    __typename: 'ChampionshipGame';
    contestId?: number | null;
    bracketId?: number | null;
    startDate?: string | null;
    startTimeEpoch?: number | null;
    gameState?: string | null;
    gameStateCode?: number | null;
    gamestateDisplay?: string | null;
    currentPeriod?: string | null;
    formattedClock?: string | null;
    formattedVenue?: string | null;
    formattedRound?: string | null;
    contestClock?: string | null;
    hasPreviewVideo?: boolean | null;
    hasRecapVideo?: boolean | null;
    hasReplayVideo?: boolean | null;
    hasCondensedVideo?: boolean | null;
    mmlVideo?: boolean | null;
    mmlRadio?: boolean | null;
    startTime?: string | null;
    hasStartTime?: boolean | null;
    victorGamePosition?: string | null;
    victorBracketPositionId?: number | null;
    teamsOnDeck: TeamsOnDeck;
    hasExcitementAlert?: boolean | null;
    round?: {
      __typename: 'Round';
      roundNumber?: number | null;
      title?: string | null;
      subtitle?: string | null;
      label?: string | null;
    } | null;
    location?: {
      __typename?: 'Location';
      id?: string | null;
      venue?: string | null;
      city?: string | null;
      stateId?: number | null;
      stateName?: string | null;
      stateUsps?: string | null;
      stateAp?: string | null;
    } | null;
    broadcaster?: { __typename: 'Broadcaster'; id?: string | null; name?: string | null } | null;
    region?: {
      __typename: 'RegionSection';
      title?: string | null;
      sectionId?: number | null;
      position?: string | null;
      abbreviation?: string | null;
    } | null;
    mmlStreams: Array<{ __typename?: 'MMLStream'; mediaId?: string | null } | null>;
    previewVideo: Array<{
      __typename?: 'Stream';
      title?: string | null;
      description?: string | null;
      mediaId?: string | null;
      duration?: string | null;
      thumbnailRaw?: string | null;
    } | null>;
    recapVideo: Array<{
      __typename?: 'Stream';
      title?: string | null;
      description?: string | null;
      mediaId?: string | null;
      thumbnailRaw?: string | null;
      duration?: string | null;
    } | null>;
    condensedVideo: Array<{
      __typename?: 'WscContent';
      title?: string | null;
      duration?: string | null;
      thumbnailUrl?: string | null;
      videoUrl?: string | null;
    } | null>;
    winnerOf: Array<{
      __typename: 'PreviousGameWinner';
      bracketId?: number | null;
      homeTeam?: string | null;
      homeSeed?: number | null;
      visitTeam?: string | null;
      visitSeed?: number | null;
      isTop?: boolean | null;
    } | null>;
    homeSeasonAverage?: {
      __typename?: 'TeamStatsBasketball';
      tmo?: string | null;
      bonus?: string | null;
      pos?: boolean | null;
      fgm?: string | null;
      fga?: string | null;
      fgp?: string | null;
      pt3m?: string | null;
      pt3a?: string | null;
      pt3p?: string | null;
      ftm?: string | null;
      fta?: string | null;
      ftp?: string | null;
      rbo?: string | null;
      rbd?: string | null;
      reb?: string | null;
      ass?: string | null;
      fls?: string | null;
      tno?: string | null;
      blk?: string | null;
      stl?: string | null;
    } | null;
    visitorSeasonAverage?: {
      __typename?: 'TeamStatsBasketball';
      tmo?: string | null;
      bonus?: string | null;
      pos?: boolean | null;
      fgm?: string | null;
      fga?: string | null;
      fgp?: string | null;
      pt3m?: string | null;
      pt3a?: string | null;
      pt3p?: string | null;
      ftm?: string | null;
      fta?: string | null;
      ftp?: string | null;
      rbo?: string | null;
      rbd?: string | null;
      reb?: string | null;
      ass?: string | null;
      fls?: string | null;
      tno?: string | null;
      blk?: string | null;
      stl?: string | null;
    } | null;
    teams: Array<{
      __typename: 'ChampionshipTeam';
      ncaaOrgId?: number | null;
      isHome?: boolean | null;
      isTop?: boolean | null;
      score?: number | null;
      color?: string | null;
      seed?: number | null;
      name6Char?: string | null;
      seoname?: string | null;
      nickname?: string | null;
      nameShort?: string | null;
      isWinner?: boolean | null;
      textOverride?: string | null;
      wins?: number | null;
      losses?: number | null;
      seasonAverage?:
        | { __typename: 'TeamStatsBaseball' }
        | {
            __typename: 'TeamStatsBasketball';
            tmo?: string | null;
            bonus?: string | null;
            pos?: boolean | null;
            fgm?: string | null;
            fga?: string | null;
            fgp?: string | null;
            pt3m?: string | null;
            pt3a?: string | null;
            pt3p?: string | null;
            ftm?: string | null;
            fta?: string | null;
            ftp?: string | null;
            rbo?: string | null;
            rbd?: string | null;
            reb?: string | null;
            ass?: string | null;
            fls?: string | null;
            tno?: string | null;
            blk?: string | null;
            stl?: string | null;
          }
        | { __typename: 'TeamStatsFieldhockey' }
        | { __typename: 'TeamStatsFootball' }
        | { __typename: 'TeamStatsIcehockey' }
        | { __typename: 'TeamStatsLacrosse' }
        | { __typename: 'TeamStatsSoccer' }
        | { __typename: 'TeamStatsSoftball' }
        | { __typename: 'TeamStatsVolleyball' }
        | null;
    } | null>;
    homeTeamLogo?: { __typename?: 'TeamLogoConfig'; color: string; logoUri: string; name: string } | null;
    visitorTeamLogo?: { __typename?: 'TeamLogoConfig'; color: string; logoUri: string; name: string } | null;
    topChampionshipTeam?: {
      __typename?: 'ChampionshipTeam';
      isTop?: boolean | null;
      isHome?: boolean | null;
      score?: number | null;
      color?: string | null;
      ncaaOrgId?: number | null;
      seed?: number | null;
      name6Char?: string | null;
      seoname?: string | null;
      nickname?: string | null;
      nameShort?: string | null;
      isWinner?: boolean | null;
      textOverride?: string | null;
      wins?: number | null;
      losses?: number | null;
    } | null;
    bottomChampionshipTeam?: {
      __typename?: 'ChampionshipTeam';
      isTop?: boolean | null;
      isHome?: boolean | null;
      score?: number | null;
      color?: string | null;
      ncaaOrgId?: number | null;
      seed?: number | null;
      name6Char?: string | null;
      seoname?: string | null;
      nickname?: string | null;
      nameShort?: string | null;
      isWinner?: boolean | null;
      textOverride?: string | null;
      wins?: number | null;
      losses?: number | null;
    } | null;
    homeChampionshipTeam?: {
      __typename?: 'ChampionshipTeam';
      isTop?: boolean | null;
      isHome?: boolean | null;
      score?: number | null;
      color?: string | null;
      ncaaOrgId?: number | null;
      seed?: number | null;
      name6Char?: string | null;
      seoname?: string | null;
      nickname?: string | null;
      nameShort?: string | null;
      isWinner?: boolean | null;
      textOverride?: string | null;
      wins?: number | null;
      losses?: number | null;
    } | null;
    visitorChampionshipTeam?: {
      __typename?: 'ChampionshipTeam';
      isTop?: boolean | null;
      isHome?: boolean | null;
      score?: number | null;
      color?: string | null;
      ncaaOrgId?: number | null;
      seed?: number | null;
      name6Char?: string | null;
      seoname?: string | null;
      nickname?: string | null;
      nameShort?: string | null;
      isWinner?: boolean | null;
      textOverride?: string | null;
      wins?: number | null;
      losses?: number | null;
    } | null;
    excitementAlerts: Array<{ __typename?: 'Alert'; alertTypeName?: string | null } | null>;
  } | null>;
  mmlTournament: Array<{
    __typename: 'Championship';
    currentRound?: string | null;
    year?: number | null;
    regions: Array<{
      __typename: 'RegionSection';
      sectionId?: number | null;
      position?: string | null;
      title?: string | null;
      abbreviation?: string | null;
    } | null>;
    rounds: Array<{
      __typename: 'Round';
      roundNumber?: number | null;
      subtitle?: string | null;
      title?: string | null;
    } | null>;
  } | null>;
};

export type CurrentScoresQueryVariables = Exact<{
  seasonYear: Scalars['Int']['input'];
  staticTestEnv?: InputMaybe<StaticTestEnv>;
  current?: InputMaybe<Scalars['Boolean']['input']>;
}>;

export type CurrentScoresQuery = {
  __typename?: 'Query';
  mmlContests: Array<{
    __typename: 'ChampionshipGame';
    contestId?: number | null;
    bracketId?: number | null;
    startDate?: string | null;
    startTimeEpoch?: number | null;
    gameState?: string | null;
    gameStateCode?: number | null;
    gamestateDisplay?: string | null;
    currentPeriod?: string | null;
    formattedClock?: string | null;
    formattedVenue?: string | null;
    formattedRound?: string | null;
    contestClock?: string | null;
    hasPreviewVideo?: boolean | null;
    hasRecapVideo?: boolean | null;
    hasReplayVideo?: boolean | null;
    hasCondensedVideo?: boolean | null;
    mmlVideo?: boolean | null;
    mmlRadio?: boolean | null;
    startTime?: string | null;
    hasStartTime?: boolean | null;
    victorGamePosition?: string | null;
    victorBracketPositionId?: number | null;
    teamsOnDeck: TeamsOnDeck;
    hasExcitementAlert?: boolean | null;
    round?: {
      __typename: 'Round';
      roundNumber?: number | null;
      title?: string | null;
      subtitle?: string | null;
      label?: string | null;
    } | null;
    location?: {
      __typename?: 'Location';
      id?: string | null;
      venue?: string | null;
      city?: string | null;
      stateId?: number | null;
      stateName?: string | null;
      stateUsps?: string | null;
      stateAp?: string | null;
    } | null;
    broadcaster?: { __typename: 'Broadcaster'; id?: string | null; name?: string | null } | null;
    region?: {
      __typename: 'RegionSection';
      title?: string | null;
      sectionId?: number | null;
      position?: string | null;
      abbreviation?: string | null;
    } | null;
    mmlStreams: Array<{ __typename?: 'MMLStream'; mediaId?: string | null } | null>;
    previewVideo: Array<{
      __typename?: 'Stream';
      title?: string | null;
      description?: string | null;
      mediaId?: string | null;
      duration?: string | null;
      thumbnailRaw?: string | null;
    } | null>;
    recapVideo: Array<{
      __typename?: 'Stream';
      title?: string | null;
      description?: string | null;
      mediaId?: string | null;
      thumbnailRaw?: string | null;
      duration?: string | null;
    } | null>;
    condensedVideo: Array<{
      __typename?: 'WscContent';
      title?: string | null;
      duration?: string | null;
      thumbnailUrl?: string | null;
      videoUrl?: string | null;
    } | null>;
    winnerOf: Array<{
      __typename: 'PreviousGameWinner';
      bracketId?: number | null;
      homeTeam?: string | null;
      homeSeed?: number | null;
      visitTeam?: string | null;
      visitSeed?: number | null;
      isTop?: boolean | null;
    } | null>;
    homeSeasonAverage?: {
      __typename?: 'TeamStatsBasketball';
      tmo?: string | null;
      bonus?: string | null;
      pos?: boolean | null;
      fgm?: string | null;
      fga?: string | null;
      fgp?: string | null;
      pt3m?: string | null;
      pt3a?: string | null;
      pt3p?: string | null;
      ftm?: string | null;
      fta?: string | null;
      ftp?: string | null;
      rbo?: string | null;
      rbd?: string | null;
      reb?: string | null;
      ass?: string | null;
      fls?: string | null;
      tno?: string | null;
      blk?: string | null;
      stl?: string | null;
    } | null;
    visitorSeasonAverage?: {
      __typename?: 'TeamStatsBasketball';
      tmo?: string | null;
      bonus?: string | null;
      pos?: boolean | null;
      fgm?: string | null;
      fga?: string | null;
      fgp?: string | null;
      pt3m?: string | null;
      pt3a?: string | null;
      pt3p?: string | null;
      ftm?: string | null;
      fta?: string | null;
      ftp?: string | null;
      rbo?: string | null;
      rbd?: string | null;
      reb?: string | null;
      ass?: string | null;
      fls?: string | null;
      tno?: string | null;
      blk?: string | null;
      stl?: string | null;
    } | null;
    teams: Array<{
      __typename: 'ChampionshipTeam';
      ncaaOrgId?: number | null;
      isHome?: boolean | null;
      isTop?: boolean | null;
      score?: number | null;
      color?: string | null;
      seed?: number | null;
      name6Char?: string | null;
      seoname?: string | null;
      nickname?: string | null;
      nameShort?: string | null;
      isWinner?: boolean | null;
      textOverride?: string | null;
      wins?: number | null;
      losses?: number | null;
      seasonAverage?:
        | { __typename: 'TeamStatsBaseball' }
        | {
            __typename: 'TeamStatsBasketball';
            tmo?: string | null;
            bonus?: string | null;
            pos?: boolean | null;
            fgm?: string | null;
            fga?: string | null;
            fgp?: string | null;
            pt3m?: string | null;
            pt3a?: string | null;
            pt3p?: string | null;
            ftm?: string | null;
            fta?: string | null;
            ftp?: string | null;
            rbo?: string | null;
            rbd?: string | null;
            reb?: string | null;
            ass?: string | null;
            fls?: string | null;
            tno?: string | null;
            blk?: string | null;
            stl?: string | null;
          }
        | { __typename: 'TeamStatsFieldhockey' }
        | { __typename: 'TeamStatsFootball' }
        | { __typename: 'TeamStatsIcehockey' }
        | { __typename: 'TeamStatsLacrosse' }
        | { __typename: 'TeamStatsSoccer' }
        | { __typename: 'TeamStatsSoftball' }
        | { __typename: 'TeamStatsVolleyball' }
        | null;
    } | null>;
    homeTeamLogo?: { __typename?: 'TeamLogoConfig'; color: string; logoUri: string; name: string } | null;
    visitorTeamLogo?: { __typename?: 'TeamLogoConfig'; color: string; logoUri: string; name: string } | null;
    topChampionshipTeam?: {
      __typename?: 'ChampionshipTeam';
      isTop?: boolean | null;
      isHome?: boolean | null;
      score?: number | null;
      color?: string | null;
      ncaaOrgId?: number | null;
      seed?: number | null;
      name6Char?: string | null;
      seoname?: string | null;
      nickname?: string | null;
      nameShort?: string | null;
      isWinner?: boolean | null;
      textOverride?: string | null;
      wins?: number | null;
      losses?: number | null;
    } | null;
    bottomChampionshipTeam?: {
      __typename?: 'ChampionshipTeam';
      isTop?: boolean | null;
      isHome?: boolean | null;
      score?: number | null;
      color?: string | null;
      ncaaOrgId?: number | null;
      seed?: number | null;
      name6Char?: string | null;
      seoname?: string | null;
      nickname?: string | null;
      nameShort?: string | null;
      isWinner?: boolean | null;
      textOverride?: string | null;
      wins?: number | null;
      losses?: number | null;
    } | null;
    homeChampionshipTeam?: {
      __typename?: 'ChampionshipTeam';
      isTop?: boolean | null;
      isHome?: boolean | null;
      score?: number | null;
      color?: string | null;
      ncaaOrgId?: number | null;
      seed?: number | null;
      name6Char?: string | null;
      seoname?: string | null;
      nickname?: string | null;
      nameShort?: string | null;
      isWinner?: boolean | null;
      textOverride?: string | null;
      wins?: number | null;
      losses?: number | null;
    } | null;
    visitorChampionshipTeam?: {
      __typename?: 'ChampionshipTeam';
      isTop?: boolean | null;
      isHome?: boolean | null;
      score?: number | null;
      color?: string | null;
      ncaaOrgId?: number | null;
      seed?: number | null;
      name6Char?: string | null;
      seoname?: string | null;
      nickname?: string | null;
      nameShort?: string | null;
      isWinner?: boolean | null;
      textOverride?: string | null;
      wins?: number | null;
      losses?: number | null;
    } | null;
    excitementAlerts: Array<{ __typename?: 'Alert'; alertTypeName?: string | null } | null>;
  } | null>;
  mmlEvents: Array<{
    __typename: 'MMLEvent';
    bracketId?: number | null;
    mediaId?: string | null;
    eventType?: MmlEventType | null;
    parentBracketId?: number | null;
    title?: string | null;
    description?: string | null;
    videoStatus?: string | null;
    startDate?: string | null;
    startTimeEpoch?: number | null;
    thumbnailUrl?: string | null;
    mmlVideo?: boolean | null;
    broadcaster?: { __typename: 'Broadcaster'; id?: string | null; name?: string | null } | null;
  } | null>;
};

export type GameCenterBoxscoreQueryVariables = Exact<{
  contestId: Scalars['ID']['input'];
  staticTestEnv?: InputMaybe<StaticTestEnv>;
}>;

export type GameCenterBoxscoreQuery = {
  __typename?: 'Query';
  boxscore?: {
    __typename: 'Boxscore';
    contestId?: number | null;
    homeBoxscore?: {
      __typename?: 'BoxscoreDetails';
      isHome?: boolean | null;
      teamStats?:
        | { __typename: 'TeamStatsBaseball' }
        | {
            __typename: 'TeamStatsBasketball';
            tmo?: string | null;
            bonus?: string | null;
            pos?: boolean | null;
            fgm?: string | null;
            fga?: string | null;
            fgp?: string | null;
            pt3m?: string | null;
            pt3a?: string | null;
            pt3p?: string | null;
            ftm?: string | null;
            fta?: string | null;
            ftp?: string | null;
            rbo?: string | null;
            rbd?: string | null;
            reb?: string | null;
            ass?: string | null;
            fls?: string | null;
            tno?: string | null;
            blk?: string | null;
            stl?: string | null;
            pts?: string | null;
          }
        | { __typename: 'TeamStatsFieldhockey' }
        | { __typename: 'TeamStatsFootball' }
        | { __typename: 'TeamStatsIcehockey' }
        | { __typename: 'TeamStatsLacrosse' }
        | { __typename: 'TeamStatsSoccer' }
        | { __typename: 'TeamStatsSoftball' }
        | { __typename: 'TeamStatsVolleyball' }
        | null;
    } | null;
    visitorBoxscore?: {
      __typename?: 'BoxscoreDetails';
      isHome?: boolean | null;
      teamStats?:
        | { __typename: 'TeamStatsBaseball' }
        | {
            __typename: 'TeamStatsBasketball';
            tmo?: string | null;
            bonus?: string | null;
            pos?: boolean | null;
            fgm?: string | null;
            fga?: string | null;
            fgp?: string | null;
            pt3m?: string | null;
            pt3a?: string | null;
            pt3p?: string | null;
            ftm?: string | null;
            fta?: string | null;
            ftp?: string | null;
            rbo?: string | null;
            rbd?: string | null;
            reb?: string | null;
            ass?: string | null;
            fls?: string | null;
            tno?: string | null;
            blk?: string | null;
            stl?: string | null;
            pts?: string | null;
          }
        | { __typename: 'TeamStatsFieldhockey' }
        | { __typename: 'TeamStatsFootball' }
        | { __typename: 'TeamStatsIcehockey' }
        | { __typename: 'TeamStatsLacrosse' }
        | { __typename: 'TeamStatsSoccer' }
        | { __typename: 'TeamStatsSoftball' }
        | { __typename: 'TeamStatsVolleyball' }
        | null;
    } | null;
    teamBoxscore?: Array<{
      __typename?: 'BoxscoreDetails';
      isHome?: boolean | null;
      teamStats?:
        | { __typename: 'TeamStatsBaseball' }
        | {
            __typename: 'TeamStatsBasketball';
            tmo?: string | null;
            bonus?: string | null;
            pos?: boolean | null;
            fgm?: string | null;
            fga?: string | null;
            fgp?: string | null;
            pt3m?: string | null;
            pt3a?: string | null;
            pt3p?: string | null;
            ftm?: string | null;
            fta?: string | null;
            ftp?: string | null;
            rbo?: string | null;
            rbd?: string | null;
            reb?: string | null;
            ass?: string | null;
            fls?: string | null;
            tno?: string | null;
            blk?: string | null;
            stl?: string | null;
            pts?: string | null;
          }
        | { __typename: 'TeamStatsFieldhockey' }
        | { __typename: 'TeamStatsFootball' }
        | { __typename: 'TeamStatsIcehockey' }
        | { __typename: 'TeamStatsLacrosse' }
        | { __typename: 'TeamStatsSoccer' }
        | { __typename: 'TeamStatsSoftball' }
        | { __typename: 'TeamStatsVolleyball' }
        | null;
    } | null> | null;
  } | null;
};

export type MmlContinuousPlayQueryVariables = Exact<{
  seasonYear: Scalars['Int']['input'];
  bracketId: Scalars['Int']['input'];
  staticTestEnv?: InputMaybe<StaticTestEnv>;
}>;

export type MmlContinuousPlayQuery = {
  __typename?: 'Query';
  mmlContinuousPlay: Array<{ __typename?: 'BracketIds'; bracketId?: number | null } | null>;
};

export type OfficialBracketQueryVariables = Exact<{
  seasonYear: Scalars['Int']['input'];
  current?: InputMaybe<Scalars['Boolean']['input']>;
  staticTestEnv?: InputMaybe<StaticTestEnv>;
}>;

export type OfficialBracketQuery = {
  __typename?: 'Query';
  mmlContests: Array<{
    __typename: 'ChampionshipGame';
    bracketId?: number | null;
    gameStateCode?: number | null;
    victorBracketPositionId?: number | null;
    mmlVideo?: boolean | null;
    teamsOnDeck: TeamsOnDeck;
    round?: {
      __typename: 'Round';
      roundNumber?: number | null;
      title?: string | null;
      subtitle?: string | null;
    } | null;
    region?: {
      __typename: 'RegionSection';
      sectionId?: number | null;
      position?: string | null;
      title?: string | null;
      abbreviation?: string | null;
    } | null;
    teams: Array<{
      __typename: 'ChampionshipTeam';
      isTop?: boolean | null;
      isHome?: boolean | null;
      score?: number | null;
      color?: string | null;
      ncaaOrgId?: number | null;
      seed?: number | null;
      seoname?: string | null;
      nickname?: string | null;
      nameShort?: string | null;
      isWinner?: boolean | null;
      textOverride?: string | null;
      wins?: number | null;
      losses?: number | null;
      name6Char?: string | null;
      seasonAverage?:
        | { __typename: 'TeamStatsBaseball' }
        | {
            __typename: 'TeamStatsBasketball';
            tmo?: string | null;
            bonus?: string | null;
            pos?: boolean | null;
            fgm?: string | null;
            fga?: string | null;
            fgp?: string | null;
            pt3m?: string | null;
            pt3a?: string | null;
            pt3p?: string | null;
            ftm?: string | null;
            fta?: string | null;
            ftp?: string | null;
            rbo?: string | null;
            rbd?: string | null;
            reb?: string | null;
            ass?: string | null;
            fls?: string | null;
            tno?: string | null;
            blk?: string | null;
            stl?: string | null;
          }
        | { __typename: 'TeamStatsFieldhockey' }
        | { __typename: 'TeamStatsFootball' }
        | { __typename: 'TeamStatsIcehockey' }
        | { __typename: 'TeamStatsLacrosse' }
        | { __typename: 'TeamStatsSoccer' }
        | { __typename: 'TeamStatsSoftball' }
        | { __typename: 'TeamStatsVolleyball' }
        | null;
    } | null>;
    topChampionshipTeam?: {
      __typename?: 'ChampionshipTeam';
      score?: number | null;
      color?: string | null;
      ncaaOrgId?: number | null;
      seed?: number | null;
      seoname?: string | null;
      nickname?: string | null;
      nameShort?: string | null;
      isWinner?: boolean | null;
      textOverride?: string | null;
    } | null;
    homeTeamLogo?: { __typename?: 'TeamLogoConfig'; logoUri: string } | null;
    visitorTeamLogo?: { __typename?: 'TeamLogoConfig'; logoUri: string } | null;
    bottomChampionshipTeam?: {
      __typename?: 'ChampionshipTeam';
      score?: number | null;
      color?: string | null;
      ncaaOrgId?: number | null;
      seed?: number | null;
      seoname?: string | null;
      nickname?: string | null;
      nameShort?: string | null;
      isWinner?: boolean | null;
      textOverride?: string | null;
    } | null;
  } | null>;
  mmlTournament: Array<{
    __typename?: 'Championship';
    year?: number | null;
    currentRound?: string | null;
    regions: Array<{
      __typename: 'RegionSection';
      sectionId?: number | null;
      position?: string | null;
      title?: string | null;
      abbreviation?: string | null;
    } | null>;
    rounds: Array<{
      __typename: 'Round';
      roundNumber?: number | null;
      title?: string | null;
      subtitle?: string | null;
    } | null>;
  } | null>;
};

export type ScoreboardCurrentQueryVariables = Exact<{
  seasonYear: Scalars['Int']['input'];
  current?: InputMaybe<Scalars['Boolean']['input']>;
  staticTestEnv?: InputMaybe<StaticTestEnv>;
}>;

export type ScoreboardCurrentQuery = {
  __typename?: 'Query';
  mmlContests: Array<{
    __typename: 'ChampionshipGame';
    contestId?: number | null;
    bracketId?: number | null;
    gameState?: string | null;
    gameStateCode?: number | null;
    gamestateDisplay?: string | null;
    currentPeriod?: string | null;
    contestClock?: string | null;
    hasPreviewVideo?: boolean | null;
    hasRecapVideo?: boolean | null;
    hasReplayVideo?: boolean | null;
    hasCondensedVideo?: boolean | null;
    mmlVideo?: boolean | null;
    mmlRadio?: boolean | null;
    hasExcitementAlert?: boolean | null;
    mmlStreams: Array<{ __typename?: 'MMLStream'; mediaId?: string | null } | null>;
    teams: Array<{
      __typename: 'ChampionshipTeam';
      ncaaOrgId?: number | null;
      teamId?: number | null;
      isHome?: boolean | null;
      score?: number | null;
      isWinner?: boolean | null;
    } | null>;
    excitementAlerts: Array<{ __typename: 'Alert'; alertTypeName?: string | null } | null>;
  } | null>;
};

export type TimeMachineQueryVariables = Exact<{
  seasonYear: Scalars['Int']['input'];
  bracketId: Scalars['Int']['input'];
  staticTestEnv?: InputMaybe<StaticTestEnv>;
}>;

export type TimeMachineQuery = {
  __typename?: 'Query';
  timemachine: Array<{
    __typename?: 'CurrentShow';
    title?: string | null;
    titleId?: string | null;
    summary?: string | null;
    thumbnail_1x1?: string | null;
    thumbnail_2x3?: string | null;
    thumbnail_4x3?: string | null;
    showTimestamp?: string | null;
    showDuration?: string | null;
    isCurrentShow?: boolean | null;
    pushTag?: string | null;
  } | null>;
};

export const ChampionshipFragmentDoc = gql`
  fragment championship on Championship {
    __typename
    currentRound
    year
    regions {
      __typename
      sectionId
      position
      title
      abbreviation
    }
    rounds {
      __typename
      roundNumber
      subtitle
      title
    }
  }
`;
export const GameFragmentDoc = gql`
  fragment game on ChampionshipGame {
    contestId
    bracketId
    startDate
    startTimeEpoch
    gameState
    gameStateCode
    gamestateDisplay
    currentPeriod
    round {
      roundNumber
      title
      subtitle
      label
    }
    formattedClock @client
    formattedVenue @client
    formattedRound @client
    location {
      id
      venue
      city
      stateId
      stateName
      stateUsps
      stateAp
    }
    broadcaster {
      id
      name
    }
    region {
      title
    }
    mmlStreams {
      mediaId
    }
    contestClock
    hasPreviewVideo
    hasRecapVideo
    hasReplayVideo
    hasCondensedVideo
    mmlVideo
    mmlRadio
    previewVideo {
      title
      description
      mediaId
      duration
      thumbnailRaw
    }
    recapVideo {
      title
      description
      mediaId
      thumbnailRaw
      duration
    }
    condensedVideo {
      title
      duration
      thumbnailUrl
      videoUrl
    }
    __typename
    bracketId
    gameState
    gameStateCode
    gamestateDisplay
    startDate
    startTimeEpoch
    startTime @client
    hasStartTime
    victorGamePosition
    victorBracketPositionId
    currentPeriod
    mmlVideo
    hasRecapVideo
    hasPreviewVideo
    hasReplayVideo
    hasCondensedVideo
    mmlRadio
    round {
      __typename
      title
      subtitle
      roundNumber
    }
    region {
      __typename
      sectionId
      position
      title
      abbreviation
    }
    broadcaster {
      __typename
      id
      name
    }
    winnerOf {
      __typename
      bracketId
      homeTeam
      homeSeed
      visitTeam
      visitSeed
      isTop
    }
    homeSeasonAverage @client {
      tmo
      bonus
      pos
      fgm
      fga
      fgp
      pt3m
      pt3a
      pt3p
      ftm
      fta
      ftp
      rbo
      rbd
      reb
      ass
      fls
      tno
      blk
      stl
    }
    visitorSeasonAverage @client {
      tmo
      bonus
      pos
      fgm
      fga
      fgp
      pt3m
      pt3a
      pt3p
      ftm
      fta
      ftp
      rbo
      rbd
      reb
      ass
      fls
      tno
      blk
      stl
    }
    teams {
      __typename
      ncaaOrgId
      isHome
      seasonAverage {
        __typename
        ... on TeamStatsBasketball {
          tmo
          bonus
          pos
          fgm
          fga
          fgp
          pt3m
          pt3a
          pt3p
          ftm
          fta
          ftp
          rbo
          rbd
          reb
          ass
          fls
          tno
          blk
          stl
        }
      }
    }
    teamsOnDeck @client
    homeTeamLogo @client {
      color
      logoUri
      name
    }
    visitorTeamLogo @client {
      color
      logoUri
      name
    }
    topChampionshipTeam @client {
      isTop
      isHome
      score
      color
      ncaaOrgId
      seed
      name6Char
      seoname
      nickname
      nameShort
      isWinner
      textOverride
      wins
      losses
    }
    bottomChampionshipTeam @client {
      isTop
      isHome
      score
      color
      ncaaOrgId
      seed
      name6Char
      seoname
      nickname
      nameShort
      isWinner
      textOverride
      wins
      losses
    }
    homeChampionshipTeam @client {
      isTop
      isHome
      score
      color
      ncaaOrgId
      seed
      name6Char
      seoname
      nickname
      nameShort
      isWinner
      textOverride
      wins
      losses
    }
    visitorChampionshipTeam @client {
      isTop
      isHome
      score
      color
      ncaaOrgId
      seed
      name6Char
      seoname
      nickname
      nameShort
      isWinner
      textOverride
      wins
      losses
    }
    teams {
      __typename
      isTop
      isHome
      score
      color
      ncaaOrgId
      seed
      name6Char
      seoname
      nickname
      nameShort
      isWinner
      textOverride
      wins
      losses
    }
    hasExcitementAlert
    excitementAlerts {
      alertTypeName
    }
  }
`;
export const GetAppFeaturedFromCacheDocument = gql`
  query getAppFeaturedFromCache($seasonYear: Int!, $staticTestEnv: StaticTestEnv, $view: AppFeaturedView) {
    appFeatured(seasonYear: $seasonYear, staticTestEnv: $staticTestEnv, view: $view) {
      spotlight {
        id
        title
        view
        sortObject
        description
        contentType
        content {
          title
          sort
          description
          contentType
          thumbnail
          mediaId
          duration
          breakingNews
          upsetGame
          closeGame
          updatedTimestamp
          bracketId
          highlight
          logo
          sponsorCopy
          sponsorImage
          countdownClock {
            title
            epoch
          }
          playlist {
            contentType
            sort
            title
            description
            thumbnail
            duration
            mediaId
          }
        }
      }
      trending {
        id
        title
        view
        sortObject
        description
        contentType
        content {
          title
          sort
          url
          description
          contentType
          duration
          mediaId
          thumbnail
          updatedTimestamp
        }
      }
      vodPlaylist {
        id
        title
        view
        sortObject
        description
        contentType
        content {
          title
          sort
          url
          description
          contentType
          duration
          mediaId
          thumbnail
          updatedTimestamp
        }
      }
    }
  }
`;

/**
 * __useGetAppFeaturedFromCacheQuery__
 *
 * To run a query within a React component, call `useGetAppFeaturedFromCacheQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAppFeaturedFromCacheQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAppFeaturedFromCacheQuery({
 *   variables: {
 *      seasonYear: // value for 'seasonYear'
 *      staticTestEnv: // value for 'staticTestEnv'
 *      view: // value for 'view'
 *   },
 * });
 */
export function useGetAppFeaturedFromCacheQuery(
  baseOptions: Apollo.QueryHookOptions<GetAppFeaturedFromCacheQuery, GetAppFeaturedFromCacheQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetAppFeaturedFromCacheQuery, GetAppFeaturedFromCacheQueryVariables>(
    GetAppFeaturedFromCacheDocument,
    options,
  );
}
export function useGetAppFeaturedFromCacheLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetAppFeaturedFromCacheQuery, GetAppFeaturedFromCacheQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetAppFeaturedFromCacheQuery, GetAppFeaturedFromCacheQueryVariables>(
    GetAppFeaturedFromCacheDocument,
    options,
  );
}
export function useGetAppFeaturedFromCacheSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<GetAppFeaturedFromCacheQuery, GetAppFeaturedFromCacheQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetAppFeaturedFromCacheQuery, GetAppFeaturedFromCacheQueryVariables>(
    GetAppFeaturedFromCacheDocument,
    options,
  );
}
export type GetAppFeaturedFromCacheQueryHookResult = ReturnType<typeof useGetAppFeaturedFromCacheQuery>;
export type GetAppFeaturedFromCacheLazyQueryHookResult = ReturnType<typeof useGetAppFeaturedFromCacheLazyQuery>;
export type GetAppFeaturedFromCacheSuspenseQueryHookResult = ReturnType<typeof useGetAppFeaturedFromCacheSuspenseQuery>;
export type GetAppFeaturedFromCacheQueryResult = Apollo.QueryResult<
  GetAppFeaturedFromCacheQuery,
  GetAppFeaturedFromCacheQueryVariables
>;
export const GetBoxscoreFromCacheDocument = gql`
  query GetBoxscoreFromCache($contestId: ID!) {
    boxscore(contestId: $contestId) {
      __typename
      contestId
      homeBoxscore @client {
        isHome
        teamStats {
          __typename
          ... on TeamStatsBasketball {
            tmo
            bonus
            pos
            fgm
            fga
            fgp
            pt3m
            pt3a
            pt3p
            ftm
            fta
            ftp
            rbo
            rbd
            reb
            ass
            fls
            tno
            blk
            stl
            pts
          }
        }
      }
      visitorBoxscore @client {
        isHome
        teamStats {
          __typename
          ... on TeamStatsBasketball {
            tmo
            bonus
            pos
            fgm
            fga
            fgp
            pt3m
            pt3a
            pt3p
            ftm
            fta
            ftp
            rbo
            rbd
            reb
            ass
            fls
            tno
            blk
            stl
            pts
          }
        }
      }
      teamBoxscore {
        isHome
        teamStats {
          __typename
          ... on TeamStatsBasketball {
            tmo
            bonus
            pos
            fgm
            fga
            fgp
            pt3m
            pt3a
            pt3p
            ftm
            fta
            ftp
            rbo
            rbd
            reb
            ass
            fls
            tno
            blk
            stl
            pts
          }
        }
      }
    }
  }
`;

/**
 * __useGetBoxscoreFromCacheQuery__
 *
 * To run a query within a React component, call `useGetBoxscoreFromCacheQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBoxscoreFromCacheQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBoxscoreFromCacheQuery({
 *   variables: {
 *      contestId: // value for 'contestId'
 *   },
 * });
 */
export function useGetBoxscoreFromCacheQuery(
  baseOptions: Apollo.QueryHookOptions<GetBoxscoreFromCacheQuery, GetBoxscoreFromCacheQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetBoxscoreFromCacheQuery, GetBoxscoreFromCacheQueryVariables>(
    GetBoxscoreFromCacheDocument,
    options,
  );
}
export function useGetBoxscoreFromCacheLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetBoxscoreFromCacheQuery, GetBoxscoreFromCacheQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetBoxscoreFromCacheQuery, GetBoxscoreFromCacheQueryVariables>(
    GetBoxscoreFromCacheDocument,
    options,
  );
}
export function useGetBoxscoreFromCacheSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<GetBoxscoreFromCacheQuery, GetBoxscoreFromCacheQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetBoxscoreFromCacheQuery, GetBoxscoreFromCacheQueryVariables>(
    GetBoxscoreFromCacheDocument,
    options,
  );
}
export type GetBoxscoreFromCacheQueryHookResult = ReturnType<typeof useGetBoxscoreFromCacheQuery>;
export type GetBoxscoreFromCacheLazyQueryHookResult = ReturnType<typeof useGetBoxscoreFromCacheLazyQuery>;
export type GetBoxscoreFromCacheSuspenseQueryHookResult = ReturnType<typeof useGetBoxscoreFromCacheSuspenseQuery>;
export type GetBoxscoreFromCacheQueryResult = Apollo.QueryResult<
  GetBoxscoreFromCacheQuery,
  GetBoxscoreFromCacheQueryVariables
>;
export const GetBracketDataFromCacheDocument = gql`
  query getBracketDataFromCache {
    mmlContests {
      contestId
      bracketId
      startDate
      startTimeEpoch
      gameState
      gameStateCode
      gamestateDisplay
      currentPeriod
      round {
        roundNumber
        title
        subtitle
        label
      }
      broadcaster {
        id
        name
      }
      region {
        title
      }
      mmlStreams {
        mediaId
      }
      contestClock
      hasPreviewVideo
      hasRecapVideo
      hasReplayVideo
      hasCondensedVideo
      mmlVideo
      mmlRadio
      previewVideo {
        title
        description
        mediaId
        duration
        thumbnailRaw
      }
      recapVideo {
        title
        description
        mediaId
        thumbnailRaw
        duration
      }
      condensedVideo {
        title
        duration
        thumbnailUrl
        videoUrl
      }
      __typename
      bracketId
      gameState
      gameStateCode
      startDate
      startTimeEpoch
      startTime @client
      hasStartTime
      victorGamePosition
      victorBracketPositionId
      currentPeriod
      mmlVideo
      hasRecapVideo
      hasPreviewVideo
      hasReplayVideo
      hasCondensedVideo
      mmlRadio
      round {
        __typename
        title
        subtitle
        roundNumber
      }
      formattedClock @client
      formattedVenue @client
      formattedRound @client
      location {
        __typename
        id
        venue
        city
        stateId
        stateName
        stateUsps
        stateAp
      }
      region {
        __typename
        sectionId
        position
        title
        abbreviation
      }
      broadcaster {
        __typename
        id
        name
      }
      winnerOf {
        __typename
        bracketId
        homeTeam
        homeSeed
        visitTeam
        visitSeed
        isTop
      }
      homeSeasonAverage @client {
        tmo
        bonus
        pos
        fgm
        fga
        fgp
        pt3m
        pt3a
        pt3p
        ftm
        fta
        ftp
        rbo
        rbd
        reb
        ass
        fls
        tno
        blk
        stl
      }
      visitorSeasonAverage @client {
        tmo
        bonus
        pos
        fgm
        fga
        fgp
        pt3m
        pt3a
        pt3p
        ftm
        fta
        ftp
        rbo
        rbd
        reb
        ass
        fls
        tno
        blk
        stl
      }
      teams {
        __typename
        ncaaOrgId
        isHome
        seasonAverage {
          __typename
          ... on TeamStatsBasketball {
            tmo
            bonus
            pos
            fgm
            fga
            fgp
            pt3m
            pt3a
            pt3p
            ftm
            fta
            ftp
            rbo
            rbd
            reb
            ass
            fls
            tno
            blk
            stl
          }
        }
      }
      teamsOnDeck @client
      homeTeamLogo @client {
        color
        logoUri
        name
      }
      visitorTeamLogo @client {
        color
        logoUri
        name
      }
      topChampionshipTeam @client {
        isTop
        isHome
        score
        color
        ncaaOrgId
        seed
        name6Char
        seoname
        nickname
        nameShort
        isWinner
        textOverride
        wins
        losses
      }
      bottomChampionshipTeam @client {
        isTop
        isHome
        score
        color
        ncaaOrgId
        seed
        name6Char
        seoname
        nickname
        nameShort
        isWinner
        textOverride
        wins
        losses
      }
      homeChampionshipTeam @client {
        isTop
        isHome
        score
        color
        ncaaOrgId
        seed
        name6Char
        seoname
        nickname
        nameShort
        isWinner
        textOverride
        wins
        losses
      }
      visitorChampionshipTeam @client {
        isTop
        isHome
        score
        color
        ncaaOrgId
        seed
        name6Char
        seoname
        nickname
        nameShort
        isWinner
        textOverride
        wins
        losses
      }
      teams {
        __typename
        isTop
        isHome
        score
        color
        ncaaOrgId
        seed
        name6Char
        seoname
        nickname
        nameShort
        isWinner
        textOverride
        wins
        losses
      }
    }
  }
`;

/**
 * __useGetBracketDataFromCacheQuery__
 *
 * To run a query within a React component, call `useGetBracketDataFromCacheQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBracketDataFromCacheQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBracketDataFromCacheQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetBracketDataFromCacheQuery(
  baseOptions?: Apollo.QueryHookOptions<GetBracketDataFromCacheQuery, GetBracketDataFromCacheQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetBracketDataFromCacheQuery, GetBracketDataFromCacheQueryVariables>(
    GetBracketDataFromCacheDocument,
    options,
  );
}
export function useGetBracketDataFromCacheLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetBracketDataFromCacheQuery, GetBracketDataFromCacheQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetBracketDataFromCacheQuery, GetBracketDataFromCacheQueryVariables>(
    GetBracketDataFromCacheDocument,
    options,
  );
}
export function useGetBracketDataFromCacheSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<GetBracketDataFromCacheQuery, GetBracketDataFromCacheQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetBracketDataFromCacheQuery, GetBracketDataFromCacheQueryVariables>(
    GetBracketDataFromCacheDocument,
    options,
  );
}
export type GetBracketDataFromCacheQueryHookResult = ReturnType<typeof useGetBracketDataFromCacheQuery>;
export type GetBracketDataFromCacheLazyQueryHookResult = ReturnType<typeof useGetBracketDataFromCacheLazyQuery>;
export type GetBracketDataFromCacheSuspenseQueryHookResult = ReturnType<typeof useGetBracketDataFromCacheSuspenseQuery>;
export type GetBracketDataFromCacheQueryResult = Apollo.QueryResult<
  GetBracketDataFromCacheQuery,
  GetBracketDataFromCacheQueryVariables
>;
export const GetCurrentScoresFromCacheDocument = gql`
  query getCurrentScoresFromCache {
    mmlContests {
      contestId
      bracketId
      startDate
      startTimeEpoch
      gameState
      gameStateCode
      gamestateDisplay
      currentPeriod
      period
      round {
        roundNumber
        title
        subtitle
        label
      }
      formattedClock @client
      formattedVenue @client
      formattedRound @client
      location {
        id
        venue
        city
        stateId
        stateName
        stateUsps
        stateAp
      }
      broadcaster {
        id
        name
      }
      mmlStreams {
        mediaId
      }
      contestClock
      hasPreviewVideo
      hasRecapVideo
      hasReplayVideo
      hasCondensedVideo
      mmlVideo
      mmlRadio
      teams {
        isHome
        score
        ncaaOrgId
        color
        seed
        isWinner
        nameShort
        seoname
      }
      hasExcitementAlert
      excitementAlerts {
        alertTypeName
      }
    }
    mmlEvents {
      bracketId
      mediaId
      eventType
      parentBracketId
      broadcaster {
        id
        name
      }
      title
      description
      videoStatus
      startDate
      startTimeEpoch
      thumbnailUrl
      mmlVideo
    }
  }
`;

/**
 * __useGetCurrentScoresFromCacheQuery__
 *
 * To run a query within a React component, call `useGetCurrentScoresFromCacheQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCurrentScoresFromCacheQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCurrentScoresFromCacheQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCurrentScoresFromCacheQuery(
  baseOptions?: Apollo.QueryHookOptions<GetCurrentScoresFromCacheQuery, GetCurrentScoresFromCacheQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetCurrentScoresFromCacheQuery, GetCurrentScoresFromCacheQueryVariables>(
    GetCurrentScoresFromCacheDocument,
    options,
  );
}
export function useGetCurrentScoresFromCacheLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetCurrentScoresFromCacheQuery, GetCurrentScoresFromCacheQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetCurrentScoresFromCacheQuery, GetCurrentScoresFromCacheQueryVariables>(
    GetCurrentScoresFromCacheDocument,
    options,
  );
}
export function useGetCurrentScoresFromCacheSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetCurrentScoresFromCacheQuery,
    GetCurrentScoresFromCacheQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetCurrentScoresFromCacheQuery, GetCurrentScoresFromCacheQueryVariables>(
    GetCurrentScoresFromCacheDocument,
    options,
  );
}
export type GetCurrentScoresFromCacheQueryHookResult = ReturnType<typeof useGetCurrentScoresFromCacheQuery>;
export type GetCurrentScoresFromCacheLazyQueryHookResult = ReturnType<typeof useGetCurrentScoresFromCacheLazyQuery>;
export type GetCurrentScoresFromCacheSuspenseQueryHookResult = ReturnType<
  typeof useGetCurrentScoresFromCacheSuspenseQuery
>;
export type GetCurrentScoresFromCacheQueryResult = Apollo.QueryResult<
  GetCurrentScoresFromCacheQuery,
  GetCurrentScoresFromCacheQueryVariables
>;
export const GetGameFromCacheDocument = gql`
  query GetGameFromCache($seasonYear: Int!, $staticTestEnv: StaticTestEnv, $bracketId: Int) {
    championshipGame(seasonYear: $seasonYear, staticTestEnv: $staticTestEnv, bracketId: $bracketId) {
      __typename
      contestId
      bracketId
      startDate
      startTimeEpoch
      startTime
      hasStartTime
      bracketId
      gameState
      gameStateCode
      gamestateDisplay
      victorGamePosition
      victorBracketPositionId
      currentPeriod
      mmlStreams {
        mediaId
      }
      round {
        __typename
        roundNumber
        title
        subtitle
      }
      contestClock
      formattedClock @client
      formattedVenue @client
      formattedRound @client
      location {
        __typename
        id
        venue
        city
        stateId
        stateName
        stateUsps
        stateAp
      }
      region {
        __typename
        sectionId
        position
        title
        abbreviation
      }
      broadcaster {
        __typename
        id
        name
      }
      winnerOf {
        __typename
        bracketId
        homeTeam
        homeSeed
        visitTeam
        visitSeed
        isTop
      }
      topChampionshipTeam @client {
        isTop
        isHome
        score
        color
        ncaaOrgId
        seed
        name6Char
        seoname
        nickname
        nameShort
        isWinner
        textOverride
        wins
        losses
      }
      homeTeamLogo @client {
        color
        logoUri
        name
      }
      visitorTeamLogo @client {
        color
        logoUri
        name
      }
      bottomChampionshipTeam @client {
        isTop
        isHome
        score
        color
        ncaaOrgId
        seed
        name6Char
        seoname
        nickname
        nameShort
        isWinner
        textOverride
        wins
        losses
      }
      teamsOnDeck @client
      homeChampionshipTeam @client {
        isTop
        isHome
        score
        color
        ncaaOrgId
        seed
        name6Char
        seoname
        nickname
        nameShort
        isWinner
        textOverride
        wins
        losses
      }
      visitorChampionshipTeam @client {
        isTop
        isHome
        score
        color
        ncaaOrgId
        seed
        name6Char
        seoname
        nickname
        nameShort
        isWinner
        textOverride
        wins
        losses
      }
      homeSeasonAverage @client {
        tmo
        bonus
        pos
        fgm
        fga
        fgp
        pt3m
        pt3a
        pt3p
        ftm
        fta
        ftp
        rbo
        rbd
        reb
        ass
        fls
        tno
        blk
        stl
      }
      visitorSeasonAverage @client {
        tmo
        bonus
        pos
        fgm
        fga
        fgp
        pt3m
        pt3a
        pt3p
        ftm
        fta
        ftp
        rbo
        rbd
        reb
        ass
        fls
        tno
        blk
        stl
      }
      teams {
        __typename
        isTop
        isHome
        score
        color
        nickname
        wins
        losses
        ncaaOrgId
        seed
        name6Char
        seoname
        nameShort
        isWinner
        ncaaOrgId
        seasonAverage {
          __typename
          ... on TeamStatsBasketball {
            tmo
            bonus
            pos
            fgm
            fga
            fgp
            pt3m
            pt3a
            pt3p
            ftm
            fta
            ftp
            rbo
            rbd
            reb
            ass
            fls
            tno
            blk
            stl
          }
        }
      }
      hasExcitementAlert
      excitementAlerts {
        alertTypeName
      }
      hasRecapVideo
      hasPreviewVideo
      hasReplayVideo
      hasCondensedVideo
      mmlVideo
      mmlRadio
      previewVideo {
        title
        description
        mediaId
        duration
        thumbnailRaw
      }
      recapVideo {
        title
        description
        mediaId
        thumbnailRaw
        duration
      }
      condensedVideo {
        title
        duration
        thumbnailUrl
        videoUrl
      }
    }
  }
`;

/**
 * __useGetGameFromCacheQuery__
 *
 * To run a query within a React component, call `useGetGameFromCacheQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGameFromCacheQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGameFromCacheQuery({
 *   variables: {
 *      seasonYear: // value for 'seasonYear'
 *      staticTestEnv: // value for 'staticTestEnv'
 *      bracketId: // value for 'bracketId'
 *   },
 * });
 */
export function useGetGameFromCacheQuery(
  baseOptions: Apollo.QueryHookOptions<GetGameFromCacheQuery, GetGameFromCacheQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetGameFromCacheQuery, GetGameFromCacheQueryVariables>(GetGameFromCacheDocument, options);
}
export function useGetGameFromCacheLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetGameFromCacheQuery, GetGameFromCacheQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetGameFromCacheQuery, GetGameFromCacheQueryVariables>(GetGameFromCacheDocument, options);
}
export function useGetGameFromCacheSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<GetGameFromCacheQuery, GetGameFromCacheQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetGameFromCacheQuery, GetGameFromCacheQueryVariables>(
    GetGameFromCacheDocument,
    options,
  );
}
export type GetGameFromCacheQueryHookResult = ReturnType<typeof useGetGameFromCacheQuery>;
export type GetGameFromCacheLazyQueryHookResult = ReturnType<typeof useGetGameFromCacheLazyQuery>;
export type GetGameFromCacheSuspenseQueryHookResult = ReturnType<typeof useGetGameFromCacheSuspenseQuery>;
export type GetGameFromCacheQueryResult = Apollo.QueryResult<GetGameFromCacheQuery, GetGameFromCacheQueryVariables>;
export const GetRoundInfoFromCacheDocument = gql`
  query getRoundInfoFromCache($roundNumber: Int!) {
    round(roundNumber: $roundNumber) {
      roundNumber
      title
    }
  }
`;

/**
 * __useGetRoundInfoFromCacheQuery__
 *
 * To run a query within a React component, call `useGetRoundInfoFromCacheQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRoundInfoFromCacheQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRoundInfoFromCacheQuery({
 *   variables: {
 *      roundNumber: // value for 'roundNumber'
 *   },
 * });
 */
export function useGetRoundInfoFromCacheQuery(
  baseOptions: Apollo.QueryHookOptions<GetRoundInfoFromCacheQuery, GetRoundInfoFromCacheQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetRoundInfoFromCacheQuery, GetRoundInfoFromCacheQueryVariables>(
    GetRoundInfoFromCacheDocument,
    options,
  );
}
export function useGetRoundInfoFromCacheLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetRoundInfoFromCacheQuery, GetRoundInfoFromCacheQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetRoundInfoFromCacheQuery, GetRoundInfoFromCacheQueryVariables>(
    GetRoundInfoFromCacheDocument,
    options,
  );
}
export function useGetRoundInfoFromCacheSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<GetRoundInfoFromCacheQuery, GetRoundInfoFromCacheQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetRoundInfoFromCacheQuery, GetRoundInfoFromCacheQueryVariables>(
    GetRoundInfoFromCacheDocument,
    options,
  );
}
export type GetRoundInfoFromCacheQueryHookResult = ReturnType<typeof useGetRoundInfoFromCacheQuery>;
export type GetRoundInfoFromCacheLazyQueryHookResult = ReturnType<typeof useGetRoundInfoFromCacheLazyQuery>;
export type GetRoundInfoFromCacheSuspenseQueryHookResult = ReturnType<typeof useGetRoundInfoFromCacheSuspenseQuery>;
export type GetRoundInfoFromCacheQueryResult = Apollo.QueryResult<
  GetRoundInfoFromCacheQuery,
  GetRoundInfoFromCacheQueryVariables
>;
export const GetRoundsFromCacheDocument = gql`
  query getRoundsFromCache {
    championship {
      currentRound
      rounds {
        roundNumber
        title
      }
    }
  }
`;

/**
 * __useGetRoundsFromCacheQuery__
 *
 * To run a query within a React component, call `useGetRoundsFromCacheQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRoundsFromCacheQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRoundsFromCacheQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetRoundsFromCacheQuery(
  baseOptions?: Apollo.QueryHookOptions<GetRoundsFromCacheQuery, GetRoundsFromCacheQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetRoundsFromCacheQuery, GetRoundsFromCacheQueryVariables>(
    GetRoundsFromCacheDocument,
    options,
  );
}
export function useGetRoundsFromCacheLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetRoundsFromCacheQuery, GetRoundsFromCacheQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetRoundsFromCacheQuery, GetRoundsFromCacheQueryVariables>(
    GetRoundsFromCacheDocument,
    options,
  );
}
export function useGetRoundsFromCacheSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<GetRoundsFromCacheQuery, GetRoundsFromCacheQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetRoundsFromCacheQuery, GetRoundsFromCacheQueryVariables>(
    GetRoundsFromCacheDocument,
    options,
  );
}
export type GetRoundsFromCacheQueryHookResult = ReturnType<typeof useGetRoundsFromCacheQuery>;
export type GetRoundsFromCacheLazyQueryHookResult = ReturnType<typeof useGetRoundsFromCacheLazyQuery>;
export type GetRoundsFromCacheSuspenseQueryHookResult = ReturnType<typeof useGetRoundsFromCacheSuspenseQuery>;
export type GetRoundsFromCacheQueryResult = Apollo.QueryResult<
  GetRoundsFromCacheQuery,
  GetRoundsFromCacheQueryVariables
>;
export const GetScoreboardFromCacheDocument = gql`
  query getScoreboardFromCache {
    mmlContests {
      contestId
      bracketId
      startDate
      startTimeEpoch
      gameState
      gameStateCode
      gamestateDisplay
      currentPeriod
      round {
        roundNumber
        title
        subtitle
        label
      }
      formattedClock @client
      formattedVenue @client
      formattedRound @client
      location {
        id
        venue
        city
        stateId
        stateName
        stateUsps
        stateAp
      }
      broadcaster {
        id
        name
      }
      mmlStreams {
        mediaId
      }
      contestClock
      hasPreviewVideo
      hasRecapVideo
      hasReplayVideo
      hasCondensedVideo
      mmlVideo
      mmlRadio
      previewVideo {
        title
        description
        mediaId
        duration
        thumbnailRaw
      }
      recapVideo {
        title
        description
        mediaId
        thumbnailRaw
        duration
      }
      condensedVideo {
        title
        duration
        thumbnailUrl
        videoUrl
      }
      __typename
      bracketId
      gameState
      gameStateCode
      startDate
      startTimeEpoch
      startTime @client
      hasStartTime
      victorGamePosition
      victorBracketPositionId
      currentPeriod
      mmlVideo
      hasRecapVideo
      hasPreviewVideo
      hasReplayVideo
      hasCondensedVideo
      mmlRadio
      region {
        __typename
        sectionId
        position
        title
        abbreviation
      }
      broadcaster {
        __typename
        id
        name
      }
      winnerOf {
        __typename
        bracketId
        homeTeam
        homeSeed
        visitTeam
        visitSeed
        isTop
      }
      homeSeasonAverage @client {
        tmo
        bonus
        pos
        fgm
        fga
        fgp
        pt3m
        pt3a
        pt3p
        ftm
        fta
        ftp
        rbo
        rbd
        reb
        ass
        fls
        tno
        blk
        stl
      }
      visitorSeasonAverage @client {
        tmo
        bonus
        pos
        fgm
        fga
        fgp
        pt3m
        pt3a
        pt3p
        ftm
        fta
        ftp
        rbo
        rbd
        reb
        ass
        fls
        tno
        blk
        stl
      }
      teams {
        __typename
        ncaaOrgId
        isHome
        seasonAverage {
          __typename
          ... on TeamStatsBasketball {
            tmo
            bonus
            pos
            fgm
            fga
            fgp
            pt3m
            pt3a
            pt3p
            ftm
            fta
            ftp
            rbo
            rbd
            reb
            ass
            fls
            tno
            blk
            stl
          }
        }
      }
      teamsOnDeck @client
      homeTeamLogo @client {
        color
        logoUri
        name
      }
      visitorTeamLogo @client {
        color
        logoUri
        name
      }
      topChampionshipTeam @client {
        isTop
        isHome
        score
        color
        ncaaOrgId
        seed
        name6Char
        seoname
        nickname
        nameShort
        isWinner
        textOverride
        wins
        losses
      }
      bottomChampionshipTeam @client {
        isTop
        isHome
        score
        color
        ncaaOrgId
        seed
        name6Char
        seoname
        nickname
        nameShort
        isWinner
        textOverride
        wins
        losses
      }
      homeChampionshipTeam @client {
        isTop
        isHome
        score
        color
        ncaaOrgId
        seed
        name6Char
        seoname
        nickname
        nameShort
        isWinner
        textOverride
        wins
        losses
      }
      visitorChampionshipTeam @client {
        isTop
        isHome
        score
        color
        ncaaOrgId
        seed
        name6Char
        seoname
        nickname
        nameShort
        isWinner
        textOverride
        wins
        losses
      }
      teams {
        __typename
        isTop
        isHome
        score
        color
        ncaaOrgId
        seed
        name6Char
        seoname
        nickname
        nameShort
        isWinner
        textOverride
        wins
        losses
      }
      hasExcitementAlert
      excitementAlerts {
        alertTypeName
      }
    }
  }
`;

/**
 * __useGetScoreboardFromCacheQuery__
 *
 * To run a query within a React component, call `useGetScoreboardFromCacheQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetScoreboardFromCacheQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetScoreboardFromCacheQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetScoreboardFromCacheQuery(
  baseOptions?: Apollo.QueryHookOptions<GetScoreboardFromCacheQuery, GetScoreboardFromCacheQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetScoreboardFromCacheQuery, GetScoreboardFromCacheQueryVariables>(
    GetScoreboardFromCacheDocument,
    options,
  );
}
export function useGetScoreboardFromCacheLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetScoreboardFromCacheQuery, GetScoreboardFromCacheQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetScoreboardFromCacheQuery, GetScoreboardFromCacheQueryVariables>(
    GetScoreboardFromCacheDocument,
    options,
  );
}
export function useGetScoreboardFromCacheSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<GetScoreboardFromCacheQuery, GetScoreboardFromCacheQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetScoreboardFromCacheQuery, GetScoreboardFromCacheQueryVariables>(
    GetScoreboardFromCacheDocument,
    options,
  );
}
export type GetScoreboardFromCacheQueryHookResult = ReturnType<typeof useGetScoreboardFromCacheQuery>;
export type GetScoreboardFromCacheLazyQueryHookResult = ReturnType<typeof useGetScoreboardFromCacheLazyQuery>;
export type GetScoreboardFromCacheSuspenseQueryHookResult = ReturnType<typeof useGetScoreboardFromCacheSuspenseQuery>;
export type GetScoreboardFromCacheQueryResult = Apollo.QueryResult<
  GetScoreboardFromCacheQuery,
  GetScoreboardFromCacheQueryVariables
>;
export const MmlContestFromCacheDocument = gql`
  query mmlContestFromCache($seasonYear: Int!, $staticTestEnv: StaticTestEnv) {
    mmlContests(seasonYear: $seasonYear, staticTestEnv: $staticTestEnv) {
      bracketId
      startDate
      startTimeEpoch
      mmlStreams {
        mediaId
      }
      round {
        roundNumber
        title
      }
    }
  }
`;

/**
 * __useMmlContestFromCacheQuery__
 *
 * To run a query within a React component, call `useMmlContestFromCacheQuery` and pass it any options that fit your needs.
 * When your component renders, `useMmlContestFromCacheQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMmlContestFromCacheQuery({
 *   variables: {
 *      seasonYear: // value for 'seasonYear'
 *      staticTestEnv: // value for 'staticTestEnv'
 *   },
 * });
 */
export function useMmlContestFromCacheQuery(
  baseOptions: Apollo.QueryHookOptions<MmlContestFromCacheQuery, MmlContestFromCacheQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<MmlContestFromCacheQuery, MmlContestFromCacheQueryVariables>(
    MmlContestFromCacheDocument,
    options,
  );
}
export function useMmlContestFromCacheLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<MmlContestFromCacheQuery, MmlContestFromCacheQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<MmlContestFromCacheQuery, MmlContestFromCacheQueryVariables>(
    MmlContestFromCacheDocument,
    options,
  );
}
export function useMmlContestFromCacheSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<MmlContestFromCacheQuery, MmlContestFromCacheQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<MmlContestFromCacheQuery, MmlContestFromCacheQueryVariables>(
    MmlContestFromCacheDocument,
    options,
  );
}
export type MmlContestFromCacheQueryHookResult = ReturnType<typeof useMmlContestFromCacheQuery>;
export type MmlContestFromCacheLazyQueryHookResult = ReturnType<typeof useMmlContestFromCacheLazyQuery>;
export type MmlContestFromCacheSuspenseQueryHookResult = ReturnType<typeof useMmlContestFromCacheSuspenseQuery>;
export type MmlContestFromCacheQueryResult = Apollo.QueryResult<
  MmlContestFromCacheQuery,
  MmlContestFromCacheQueryVariables
>;
export const MmlEventFromCacheDocument = gql`
  query mmlEventFromCache($bracketId: Int) {
    mmlEvents(bracketId: $bracketId) {
      __typename
      bracketId
      mediaId
      eventType
      parentBracketId
      broadcaster {
        __typename
        id
        name
      }
      title
      description
      videoStatus
      startDate
      startTimeEpoch
      thumbnailUrl
      mmlVideo
    }
  }
`;

/**
 * __useMmlEventFromCacheQuery__
 *
 * To run a query within a React component, call `useMmlEventFromCacheQuery` and pass it any options that fit your needs.
 * When your component renders, `useMmlEventFromCacheQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMmlEventFromCacheQuery({
 *   variables: {
 *      bracketId: // value for 'bracketId'
 *   },
 * });
 */
export function useMmlEventFromCacheQuery(
  baseOptions?: Apollo.QueryHookOptions<MmlEventFromCacheQuery, MmlEventFromCacheQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<MmlEventFromCacheQuery, MmlEventFromCacheQueryVariables>(MmlEventFromCacheDocument, options);
}
export function useMmlEventFromCacheLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<MmlEventFromCacheQuery, MmlEventFromCacheQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<MmlEventFromCacheQuery, MmlEventFromCacheQueryVariables>(
    MmlEventFromCacheDocument,
    options,
  );
}
export function useMmlEventFromCacheSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<MmlEventFromCacheQuery, MmlEventFromCacheQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<MmlEventFromCacheQuery, MmlEventFromCacheQueryVariables>(
    MmlEventFromCacheDocument,
    options,
  );
}
export type MmlEventFromCacheQueryHookResult = ReturnType<typeof useMmlEventFromCacheQuery>;
export type MmlEventFromCacheLazyQueryHookResult = ReturnType<typeof useMmlEventFromCacheLazyQuery>;
export type MmlEventFromCacheSuspenseQueryHookResult = ReturnType<typeof useMmlEventFromCacheSuspenseQuery>;
export type MmlEventFromCacheQueryResult = Apollo.QueryResult<MmlEventFromCacheQuery, MmlEventFromCacheQueryVariables>;
export const AppFeaturedDocument = gql`
  query AppFeatured($seasonYear: Int!, $staticTestEnv: StaticTestEnv, $view: AppFeaturedView) {
    appFeatured(seasonYear: $seasonYear, staticTestEnv: $staticTestEnv, view: $view) {
      spotlight {
        id
        title
        view
        sortObject
        description
        contentType
        content {
          title
          sort
          description
          contentType
          thumbnail
          mediaId
          duration
          breakingNews
          upsetGame
          closeGame
          updatedTimestamp
          bracketId
          highlight
          url
          logo
          sponsorCopy
          sponsorImage
          countdownClock {
            title
            epoch
          }
          playlist {
            contentType
            sort
            title
            description
            thumbnail
            duration
            mediaId
          }
        }
      }
      trending {
        id
        title
        view
        sortObject
        description
        contentType
        content {
          title
          sort
          url
          description
          contentType
          duration
          mediaId
          thumbnail
          updatedTimestamp
        }
      }
      vodPlaylist {
        id
        title
        view
        sortObject
        description
        contentType
        content {
          title
          sort
          url
          description
          contentType
          duration
          mediaId
          thumbnail
          updatedTimestamp
        }
      }
    }
  }
`;

/**
 * __useAppFeaturedQuery__
 *
 * To run a query within a React component, call `useAppFeaturedQuery` and pass it any options that fit your needs.
 * When your component renders, `useAppFeaturedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAppFeaturedQuery({
 *   variables: {
 *      seasonYear: // value for 'seasonYear'
 *      staticTestEnv: // value for 'staticTestEnv'
 *      view: // value for 'view'
 *   },
 * });
 */
export function useAppFeaturedQuery(baseOptions: Apollo.QueryHookOptions<AppFeaturedQuery, AppFeaturedQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AppFeaturedQuery, AppFeaturedQueryVariables>(AppFeaturedDocument, options);
}
export function useAppFeaturedLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<AppFeaturedQuery, AppFeaturedQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AppFeaturedQuery, AppFeaturedQueryVariables>(AppFeaturedDocument, options);
}
export function useAppFeaturedSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<AppFeaturedQuery, AppFeaturedQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<AppFeaturedQuery, AppFeaturedQueryVariables>(AppFeaturedDocument, options);
}
export type AppFeaturedQueryHookResult = ReturnType<typeof useAppFeaturedQuery>;
export type AppFeaturedLazyQueryHookResult = ReturnType<typeof useAppFeaturedLazyQuery>;
export type AppFeaturedSuspenseQueryHookResult = ReturnType<typeof useAppFeaturedSuspenseQuery>;
export type AppFeaturedQueryResult = Apollo.QueryResult<AppFeaturedQuery, AppFeaturedQueryVariables>;
export const BracketDataDocument = gql`
  query BracketData($seasonYear: Int!, $staticTestEnv: StaticTestEnv, $bracketId: Int) {
    mmlContests(seasonYear: $seasonYear, staticTestEnv: $staticTestEnv, bracketId: $bracketId) {
      ...game
    }
    mmlTournament(seasonYear: $seasonYear, staticTestEnv: $staticTestEnv) {
      ...championship
    }
  }
  ${GameFragmentDoc}
  ${ChampionshipFragmentDoc}
`;

/**
 * __useBracketDataQuery__
 *
 * To run a query within a React component, call `useBracketDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useBracketDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBracketDataQuery({
 *   variables: {
 *      seasonYear: // value for 'seasonYear'
 *      staticTestEnv: // value for 'staticTestEnv'
 *      bracketId: // value for 'bracketId'
 *   },
 * });
 */
export function useBracketDataQuery(baseOptions: Apollo.QueryHookOptions<BracketDataQuery, BracketDataQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<BracketDataQuery, BracketDataQueryVariables>(BracketDataDocument, options);
}
export function useBracketDataLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<BracketDataQuery, BracketDataQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<BracketDataQuery, BracketDataQueryVariables>(BracketDataDocument, options);
}
export function useBracketDataSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<BracketDataQuery, BracketDataQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<BracketDataQuery, BracketDataQueryVariables>(BracketDataDocument, options);
}
export type BracketDataQueryHookResult = ReturnType<typeof useBracketDataQuery>;
export type BracketDataLazyQueryHookResult = ReturnType<typeof useBracketDataLazyQuery>;
export type BracketDataSuspenseQueryHookResult = ReturnType<typeof useBracketDataSuspenseQuery>;
export type BracketDataQueryResult = Apollo.QueryResult<BracketDataQuery, BracketDataQueryVariables>;
export const CurrentScoresDocument = gql`
  query CurrentScores($seasonYear: Int!, $staticTestEnv: StaticTestEnv, $current: Boolean) {
    mmlContests(seasonYear: $seasonYear, staticTestEnv: $staticTestEnv, current: $current) {
      ...game
    }
    mmlEvents(seasonYear: $seasonYear, staticTestEnv: $staticTestEnv, current: $current) {
      __typename
      bracketId
      mediaId
      eventType
      parentBracketId
      broadcaster {
        __typename
        id
        name
      }
      title
      description
      videoStatus
      startDate
      startTimeEpoch
      thumbnailUrl
      mmlVideo
    }
  }
  ${GameFragmentDoc}
`;

/**
 * __useCurrentScoresQuery__
 *
 * To run a query within a React component, call `useCurrentScoresQuery` and pass it any options that fit your needs.
 * When your component renders, `useCurrentScoresQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCurrentScoresQuery({
 *   variables: {
 *      seasonYear: // value for 'seasonYear'
 *      staticTestEnv: // value for 'staticTestEnv'
 *      current: // value for 'current'
 *   },
 * });
 */
export function useCurrentScoresQuery(
  baseOptions: Apollo.QueryHookOptions<CurrentScoresQuery, CurrentScoresQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CurrentScoresQuery, CurrentScoresQueryVariables>(CurrentScoresDocument, options);
}
export function useCurrentScoresLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<CurrentScoresQuery, CurrentScoresQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CurrentScoresQuery, CurrentScoresQueryVariables>(CurrentScoresDocument, options);
}
export function useCurrentScoresSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<CurrentScoresQuery, CurrentScoresQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<CurrentScoresQuery, CurrentScoresQueryVariables>(CurrentScoresDocument, options);
}
export type CurrentScoresQueryHookResult = ReturnType<typeof useCurrentScoresQuery>;
export type CurrentScoresLazyQueryHookResult = ReturnType<typeof useCurrentScoresLazyQuery>;
export type CurrentScoresSuspenseQueryHookResult = ReturnType<typeof useCurrentScoresSuspenseQuery>;
export type CurrentScoresQueryResult = Apollo.QueryResult<CurrentScoresQuery, CurrentScoresQueryVariables>;
export const GameCenterBoxscoreDocument = gql`
  query GameCenterBoxscore($contestId: ID!, $staticTestEnv: StaticTestEnv) {
    boxscore(contestId: $contestId, staticTestEnv: $staticTestEnv) {
      __typename
      contestId
      homeBoxscore @client {
        isHome
        teamStats {
          __typename
          ... on TeamStatsBasketball {
            tmo
            bonus
            pos
            fgm
            fga
            fgp
            pt3m
            pt3a
            pt3p
            ftm
            fta
            ftp
            rbo
            rbd
            reb
            ass
            fls
            tno
            blk
            stl
            pts
          }
        }
      }
      visitorBoxscore @client {
        isHome
        teamStats {
          __typename
          ... on TeamStatsBasketball {
            tmo
            bonus
            pos
            fgm
            fga
            fgp
            pt3m
            pt3a
            pt3p
            ftm
            fta
            ftp
            rbo
            rbd
            reb
            ass
            fls
            tno
            blk
            stl
            pts
          }
        }
      }
      teamBoxscore {
        isHome
        teamStats {
          __typename
          ... on TeamStatsBasketball {
            tmo
            bonus
            pos
            fgm
            fga
            fgp
            pt3m
            pt3a
            pt3p
            ftm
            fta
            ftp
            rbo
            rbd
            reb
            ass
            fls
            tno
            blk
            stl
            pts
          }
        }
      }
    }
  }
`;

/**
 * __useGameCenterBoxscoreQuery__
 *
 * To run a query within a React component, call `useGameCenterBoxscoreQuery` and pass it any options that fit your needs.
 * When your component renders, `useGameCenterBoxscoreQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGameCenterBoxscoreQuery({
 *   variables: {
 *      contestId: // value for 'contestId'
 *      staticTestEnv: // value for 'staticTestEnv'
 *   },
 * });
 */
export function useGameCenterBoxscoreQuery(
  baseOptions: Apollo.QueryHookOptions<GameCenterBoxscoreQuery, GameCenterBoxscoreQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GameCenterBoxscoreQuery, GameCenterBoxscoreQueryVariables>(
    GameCenterBoxscoreDocument,
    options,
  );
}
export function useGameCenterBoxscoreLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GameCenterBoxscoreQuery, GameCenterBoxscoreQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GameCenterBoxscoreQuery, GameCenterBoxscoreQueryVariables>(
    GameCenterBoxscoreDocument,
    options,
  );
}
export function useGameCenterBoxscoreSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<GameCenterBoxscoreQuery, GameCenterBoxscoreQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GameCenterBoxscoreQuery, GameCenterBoxscoreQueryVariables>(
    GameCenterBoxscoreDocument,
    options,
  );
}
export type GameCenterBoxscoreQueryHookResult = ReturnType<typeof useGameCenterBoxscoreQuery>;
export type GameCenterBoxscoreLazyQueryHookResult = ReturnType<typeof useGameCenterBoxscoreLazyQuery>;
export type GameCenterBoxscoreSuspenseQueryHookResult = ReturnType<typeof useGameCenterBoxscoreSuspenseQuery>;
export type GameCenterBoxscoreQueryResult = Apollo.QueryResult<
  GameCenterBoxscoreQuery,
  GameCenterBoxscoreQueryVariables
>;
export const MmlContinuousPlayDocument = gql`
  query MmlContinuousPlay($seasonYear: Int!, $bracketId: Int!, $staticTestEnv: StaticTestEnv) {
    mmlContinuousPlay(
      seasonYear: $seasonYear
      bracketId: $bracketId
      platformType: connected
      staticTestEnv: $staticTestEnv
    ) {
      bracketId
    }
  }
`;

/**
 * __useMmlContinuousPlayQuery__
 *
 * To run a query within a React component, call `useMmlContinuousPlayQuery` and pass it any options that fit your needs.
 * When your component renders, `useMmlContinuousPlayQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMmlContinuousPlayQuery({
 *   variables: {
 *      seasonYear: // value for 'seasonYear'
 *      bracketId: // value for 'bracketId'
 *      staticTestEnv: // value for 'staticTestEnv'
 *   },
 * });
 */
export function useMmlContinuousPlayQuery(
  baseOptions: Apollo.QueryHookOptions<MmlContinuousPlayQuery, MmlContinuousPlayQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<MmlContinuousPlayQuery, MmlContinuousPlayQueryVariables>(MmlContinuousPlayDocument, options);
}
export function useMmlContinuousPlayLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<MmlContinuousPlayQuery, MmlContinuousPlayQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<MmlContinuousPlayQuery, MmlContinuousPlayQueryVariables>(
    MmlContinuousPlayDocument,
    options,
  );
}
export function useMmlContinuousPlaySuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<MmlContinuousPlayQuery, MmlContinuousPlayQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<MmlContinuousPlayQuery, MmlContinuousPlayQueryVariables>(
    MmlContinuousPlayDocument,
    options,
  );
}
export type MmlContinuousPlayQueryHookResult = ReturnType<typeof useMmlContinuousPlayQuery>;
export type MmlContinuousPlayLazyQueryHookResult = ReturnType<typeof useMmlContinuousPlayLazyQuery>;
export type MmlContinuousPlaySuspenseQueryHookResult = ReturnType<typeof useMmlContinuousPlaySuspenseQuery>;
export type MmlContinuousPlayQueryResult = Apollo.QueryResult<MmlContinuousPlayQuery, MmlContinuousPlayQueryVariables>;
export const OfficialBracketDocument = gql`
  query OfficialBracket($seasonYear: Int!, $current: Boolean, $staticTestEnv: StaticTestEnv) {
    mmlContests(seasonYear: $seasonYear, current: $current, staticTestEnv: $staticTestEnv) {
      __typename
      bracketId
      gameStateCode
      victorBracketPositionId
      mmlVideo
      round {
        __typename
        roundNumber
        title
        subtitle
      }
      region {
        __typename
        sectionId
        position
        title
        abbreviation
      }
      teams {
        __typename
        isTop
        isHome
        score
        color
        ncaaOrgId
        seed
        seoname
        nickname
        nameShort
        isWinner
        textOverride
        wins
        losses
        name6Char
        seasonAverage {
          __typename
          ... on TeamStatsBasketball {
            tmo
            bonus
            pos
            fgm
            fga
            fgp
            pt3m
            pt3a
            pt3p
            ftm
            fta
            ftp
            rbo
            rbd
            reb
            ass
            fls
            tno
            blk
            stl
          }
        }
      }
      teamsOnDeck @client
      topChampionshipTeam @client {
        score
        color
        ncaaOrgId
        seed
        seoname
        nickname
        nameShort
        isWinner
        textOverride
      }
      homeTeamLogo @client {
        logoUri
      }
      visitorTeamLogo @client {
        logoUri
      }
      bottomChampionshipTeam @client {
        score
        color
        ncaaOrgId
        seed
        seoname
        nickname
        nameShort
        isWinner
        textOverride
      }
    }
    mmlTournament(seasonYear: $seasonYear, staticTestEnv: $staticTestEnv) {
      year
      currentRound
      regions {
        __typename
        sectionId
        position
        title
        abbreviation
      }
      rounds {
        __typename
        roundNumber
        title
        subtitle
      }
    }
  }
`;

/**
 * __useOfficialBracketQuery__
 *
 * To run a query within a React component, call `useOfficialBracketQuery` and pass it any options that fit your needs.
 * When your component renders, `useOfficialBracketQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOfficialBracketQuery({
 *   variables: {
 *      seasonYear: // value for 'seasonYear'
 *      current: // value for 'current'
 *      staticTestEnv: // value for 'staticTestEnv'
 *   },
 * });
 */
export function useOfficialBracketQuery(
  baseOptions: Apollo.QueryHookOptions<OfficialBracketQuery, OfficialBracketQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<OfficialBracketQuery, OfficialBracketQueryVariables>(OfficialBracketDocument, options);
}
export function useOfficialBracketLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<OfficialBracketQuery, OfficialBracketQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<OfficialBracketQuery, OfficialBracketQueryVariables>(OfficialBracketDocument, options);
}
export function useOfficialBracketSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<OfficialBracketQuery, OfficialBracketQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<OfficialBracketQuery, OfficialBracketQueryVariables>(OfficialBracketDocument, options);
}
export type OfficialBracketQueryHookResult = ReturnType<typeof useOfficialBracketQuery>;
export type OfficialBracketLazyQueryHookResult = ReturnType<typeof useOfficialBracketLazyQuery>;
export type OfficialBracketSuspenseQueryHookResult = ReturnType<typeof useOfficialBracketSuspenseQuery>;
export type OfficialBracketQueryResult = Apollo.QueryResult<OfficialBracketQuery, OfficialBracketQueryVariables>;
export const ScoreboardCurrentDocument = gql`
  query ScoreboardCurrent($seasonYear: Int!, $current: Boolean, $staticTestEnv: StaticTestEnv) {
    mmlContests(seasonYear: $seasonYear, current: $current, staticTestEnv: $staticTestEnv) {
      __typename
      contestId
      bracketId
      gameState
      gameStateCode
      gamestateDisplay
      currentPeriod
      contestClock
      hasPreviewVideo
      hasRecapVideo
      hasReplayVideo
      hasCondensedVideo
      mmlVideo
      mmlRadio
      mmlStreams {
        mediaId
      }
      teams {
        __typename
        ncaaOrgId
        teamId
        isHome
        score
        ncaaOrgId
        isWinner
      }
      hasExcitementAlert
      excitementAlerts {
        __typename
        alertTypeName
      }
    }
  }
`;

/**
 * __useScoreboardCurrentQuery__
 *
 * To run a query within a React component, call `useScoreboardCurrentQuery` and pass it any options that fit your needs.
 * When your component renders, `useScoreboardCurrentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useScoreboardCurrentQuery({
 *   variables: {
 *      seasonYear: // value for 'seasonYear'
 *      current: // value for 'current'
 *      staticTestEnv: // value for 'staticTestEnv'
 *   },
 * });
 */
export function useScoreboardCurrentQuery(
  baseOptions: Apollo.QueryHookOptions<ScoreboardCurrentQuery, ScoreboardCurrentQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ScoreboardCurrentQuery, ScoreboardCurrentQueryVariables>(ScoreboardCurrentDocument, options);
}
export function useScoreboardCurrentLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ScoreboardCurrentQuery, ScoreboardCurrentQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ScoreboardCurrentQuery, ScoreboardCurrentQueryVariables>(
    ScoreboardCurrentDocument,
    options,
  );
}
export function useScoreboardCurrentSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<ScoreboardCurrentQuery, ScoreboardCurrentQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<ScoreboardCurrentQuery, ScoreboardCurrentQueryVariables>(
    ScoreboardCurrentDocument,
    options,
  );
}
export type ScoreboardCurrentQueryHookResult = ReturnType<typeof useScoreboardCurrentQuery>;
export type ScoreboardCurrentLazyQueryHookResult = ReturnType<typeof useScoreboardCurrentLazyQuery>;
export type ScoreboardCurrentSuspenseQueryHookResult = ReturnType<typeof useScoreboardCurrentSuspenseQuery>;
export type ScoreboardCurrentQueryResult = Apollo.QueryResult<ScoreboardCurrentQuery, ScoreboardCurrentQueryVariables>;
export const TimeMachineDocument = gql`
  query TimeMachine($seasonYear: Int!, $bracketId: Int!, $staticTestEnv: StaticTestEnv) {
    timemachine(seasonYear: $seasonYear, bracketId: $bracketId, staticTestEnv: $staticTestEnv) {
      title
      titleId
      summary
      thumbnail_1x1
      thumbnail_2x3
      thumbnail_4x3
      showTimestamp
      showDuration
      isCurrentShow
      pushTag
    }
  }
`;

/**
 * __useTimeMachineQuery__
 *
 * To run a query within a React component, call `useTimeMachineQuery` and pass it any options that fit your needs.
 * When your component renders, `useTimeMachineQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTimeMachineQuery({
 *   variables: {
 *      seasonYear: // value for 'seasonYear'
 *      bracketId: // value for 'bracketId'
 *      staticTestEnv: // value for 'staticTestEnv'
 *   },
 * });
 */
export function useTimeMachineQuery(baseOptions: Apollo.QueryHookOptions<TimeMachineQuery, TimeMachineQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<TimeMachineQuery, TimeMachineQueryVariables>(TimeMachineDocument, options);
}
export function useTimeMachineLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<TimeMachineQuery, TimeMachineQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<TimeMachineQuery, TimeMachineQueryVariables>(TimeMachineDocument, options);
}
export function useTimeMachineSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<TimeMachineQuery, TimeMachineQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<TimeMachineQuery, TimeMachineQueryVariables>(TimeMachineDocument, options);
}
export type TimeMachineQueryHookResult = ReturnType<typeof useTimeMachineQuery>;
export type TimeMachineLazyQueryHookResult = ReturnType<typeof useTimeMachineLazyQuery>;
export type TimeMachineSuspenseQueryHookResult = ReturnType<typeof useTimeMachineSuspenseQuery>;
export type TimeMachineQueryResult = Apollo.QueryResult<TimeMachineQuery, TimeMachineQueryVariables>;

export type PossibleTypesResultData = {
  possibleTypes: {
    LinescoreStats: ['LinescoreStatsBaseball', 'LinescoreStatsFootball'];
    PlayByPlayStats: ['PlayByPlayBasketball', 'PlayByPlayFootball', 'PlayByPlayGenericSport'];
    PlayerStats: [
      'PlayerStatsBaseball',
      'PlayerStatsBasketball',
      'PlayerStatsFieldhockey',
      'PlayerStatsFootball',
      'PlayerStatsIcehockey',
      'PlayerStatsLacrosse',
      'PlayerStatsSoccer',
      'PlayerStatsSoftball',
      'PlayerStatsVolleyball',
    ];
    RankedStats: [
      'RankedBasketball',
      'RankedFieldHockey',
      'RankedFootball',
      'RankedIceHockey',
      'RankedLacrosse',
      'RankedSeries',
      'RankedSoccer',
      'RankedVolleyball',
    ];
    TeamStats: [
      'TeamStatsBaseball',
      'TeamStatsBasketball',
      'TeamStatsFieldhockey',
      'TeamStatsFootball',
      'TeamStatsIcehockey',
      'TeamStatsLacrosse',
      'TeamStatsSoccer',
      'TeamStatsSoftball',
      'TeamStatsVolleyball',
    ];
  };
};
const result: PossibleTypesResultData = {
  possibleTypes: {
    LinescoreStats: ['LinescoreStatsBaseball', 'LinescoreStatsFootball'],
    PlayByPlayStats: ['PlayByPlayBasketball', 'PlayByPlayFootball', 'PlayByPlayGenericSport'],
    PlayerStats: [
      'PlayerStatsBaseball',
      'PlayerStatsBasketball',
      'PlayerStatsFieldhockey',
      'PlayerStatsFootball',
      'PlayerStatsIcehockey',
      'PlayerStatsLacrosse',
      'PlayerStatsSoccer',
      'PlayerStatsSoftball',
      'PlayerStatsVolleyball',
    ],
    RankedStats: [
      'RankedBasketball',
      'RankedFieldHockey',
      'RankedFootball',
      'RankedIceHockey',
      'RankedLacrosse',
      'RankedSeries',
      'RankedSoccer',
      'RankedVolleyball',
    ],
    TeamStats: [
      'TeamStatsBaseball',
      'TeamStatsBasketball',
      'TeamStatsFieldhockey',
      'TeamStatsFootball',
      'TeamStatsIcehockey',
      'TeamStatsLacrosse',
      'TeamStatsSoccer',
      'TeamStatsSoftball',
      'TeamStatsVolleyball',
    ],
  },
};
export default result;
