import { InMemoryCache, makeVar, Reference } from '@apollo/client';
import {
  DEFAULT_CHAMPIONSHIP_TEAM,
  DEFAULT_EMPTY_CHAMPIONSHIP,
  DEFAULT_EMPTY_CHAMPIONSHIP_GAME,
  DEFAULT_EMPTY_ROUND,
} from 'defaults/default-data-types';
import { logger } from 'logger';
import {
  BaseProperties,
  BasePropertyType,
  CustomProperties,
  CustomPropertyType,
  PAGE_INFO_STATE_INTIAL,
  PageInfoState,
  PersistentProperties,
  PersistentPropertyType,
  TelemetryUserProps,
  UserProperties,
} from 'state-machines/analytic-states';
import { APP_READY_STATE_INITIAL, AppReadyState } from 'state-machines/appready-states';
import { BCG_STATE_INITIAL, BCG_USERINFO_INITIAL, BCGState, BCGUserInfo } from 'state-machines/bcg-states';
import { EVENT_CENTER_STATE_INITIAL, EventCenterState } from 'state-machines/event-center-states';
import {
  FULL_SCREEN_EXCITEMENT_ALERT_STATE_INITIAL,
  FullScreenExcitementAlertState,
  GAME_CENTER_STATE_INITIAL,
  GameCenterState,
  GET_ACCESS_MODAL_STATE_INITIAL,
  GetAccessModalState,
  MULTI_GAME_MODAL_STATE_INITIAL,
  MultiGameModalState,
} from 'state-machines/game-center-states';
import {
  AUTH_RE_ROUTE_STATE_INITIAL,
  AuthReRoute,
  GAMECENTER_RE_ROUTE_STATE_INITIAL,
  GameCenterReRoute,
} from 'state-machines/re-routing-states';
import { VideoControlsState } from 'state-machines/video-controls-state';
import { INITIAL_VIDEO_SESSION_STATE, VideoSessionState } from 'state-machines/video-session-state';
import { VOD_INITIAL_STATE, VodState } from 'state-machines/vod-state';
import { PlayerSessionType } from 'types/types';
import { FreeViewStateNames } from '../components/VideoPlayer/tve/freeview-states';
import {
  VIDEO_CONTROLS_INITIAL_STATE,
  VIDEO_SCRUBBER_STATE_INITIAL,
  VideoScrubberState,
} from '../state-machines/video-controls-state';
import {
  AppFeatured,
  Boxscore,
  BoxscoreDetails,
  Championship,
  ChampionshipGame,
  ChampionshipTeam,
  FeaturedSpotlight,
  FeaturedTrending,
  LogMessageModel,
  MmlEvent,
  Round,
  StatusCode,
  TeamLogoConfig,
  TeamsOnDeck,
  TeamStatsBasketball,
  VodList,
} from '../types/generated-types';
import { getFormattedGameTime } from '../utils/game';
import {
  createformattedRoundTitle,
  createVenueTitle,
  filterByFieldValue,
  getTeamsOnDeck,
  pluckReadFieldValue,
  teamLogoFactory,
} from './field-utils';
import { makeVarPersisted } from './makeVarPersisted';
import { MediaJSONPlatform } from 'video-api';
import { Aval } from 'types/configs/controls-config';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const LOG = logger.getLogger('MmlApolloCache');

////////////////////////////
// REACTIVE VARS
////////////////////////////

export const adsPlayingStore = makeVar<boolean>(false);
export const appReady = makeVar<AppReadyState>(APP_READY_STATE_INITIAL);
export const authReRouteStore = makeVar<AuthReRoute>(AUTH_RE_ROUTE_STATE_INITIAL);
export const basePropertyStore = makeVar<BasePropertyType>(BaseProperties);
export const bcgPlayerStatusStore = makeVarPersisted<string>('false', 'bcgPlayerStatus');
export const bcgStatus = makeVar<BCGState>(BCG_STATE_INITIAL);
export const bcgUserInfo = makeVar<BCGUserInfo>(BCG_USERINFO_INITIAL);
export const customPropertyStore = makeVar<CustomPropertyType>(CustomProperties);
export const documentVisibilityStore = makeVar<boolean>(true);
export const enableChapterEndCuesStore = makeVarPersisted<boolean>(true, 'enableChapterEndCues');
export const enableProgramEndCuesStore = makeVarPersisted<boolean>(true, 'enableProgramEndCues');
export const eventCenterStateStore = makeVar<EventCenterState>(EVENT_CENTER_STATE_INITIAL);
export const excitingGamesStore = makeVar<number[]>([]);
export const externalCampaignStore = makeVar<string>('');
export const finalGameStore = makeVar<number[]>([]);
export const forceShowMarqueeStore = makeVar<boolean>(false);
export const freeViewFingerprintStore = makeVarPersisted<string | undefined>(undefined, 'freeViewFingerprint');
export const freeviewStatePersistedStore = makeVarPersisted<FreeViewStateNames>(
  FreeViewStateNames.USING_FREEVIEW,
  'freeviewStateStore',
);
export const freeviewStateStore = makeVar<FreeViewStateNames>(freeviewStatePersistedStore());
export const freeviewTimePersistedStore = makeVarPersisted<number>(0, 'freeviewTimeStore');
export const freeviewTimeStore = makeVar<number>(freeviewTimePersistedStore());
export const fullscreenExcitementAlertPromptStore = makeVar<FullScreenExcitementAlertState>(
  FULL_SCREEN_EXCITEMENT_ALERT_STATE_INITIAL,
);
export const gamecenterReRouteStore = makeVar<GameCenterReRoute>(GAMECENTER_RE_ROUTE_STATE_INITIAL);
export const gameCenterStateStore = makeVar<GameCenterState>(GAME_CENTER_STATE_INITIAL);
export const hpContents = makeVar<VideoListItems[]>([]);
export const ignoredUpdateVersion = makeVarPersisted<string>('', 'ignoredUpdateVersion');
export const isConnectingStore = makeVar<boolean>(false);
export const lastFocusedTileKeyStore = makeVar<string>('');
export const lastManualFocusKeyStore = makeVar<string>('');
export const liveEventStore = makeVar<MmlEvent[]>([]);
export const liveGameStore = makeVar<number[]>([]);
export const liveGameWithVideoStore = makeVar<number[]>([]);
export const liveTeamStore = makeVar<ChampionshipTeam[]>([]);
export const liveVideoOfGameStore = makeVar<number[]>([]);
export const logMessageStore = makeVar<LogMessageModel[]>([]);
export const lostTeamStore = makeVar<number[]>([]);
export const manualChapterEndCueStartTimeStore = makeVarPersisted<string>('', 'manualChapterEndCueStartTime');
export const manualCueTargetPlayerStore = makeVarPersisted<PlayerSessionType>(
  PlayerSessionType.Primary,
  'manualCueTargetPlayer',
);
export const manualProgramEndCueStartTimeStore = makeVarPersisted<string>('', 'manualProgramEndCueStartTime');
export const mediaLoadedStore = makeVar<boolean>(false);
export const multiGameModalStore = makeVar<MultiGameModalState>(MULTI_GAME_MODAL_STATE_INITIAL);
export const navFocusTrap = makeVar<boolean>(true);
export const pageInfoState = makeVar<PageInfoState>(PAGE_INFO_STATE_INTIAL);
export const pathnameStore = makeVar<string>('');
export const persistentPropertyStore = makeVar<PersistentPropertyType>(PersistentProperties);
export const playerReadyStore = makeVar<boolean>(false);
export const preRollStartedAtStore = makeVar<number>(0);
export const queryParamValueStore = makeVar<Record<string, string> | undefined>(undefined);
export const remoteConsoleStore = makeVarPersisted(false, 'remoteConsole');
export const seekHotStore = makeVar<VideoScrubberState>(VIDEO_SCRUBBER_STATE_INITIAL);
export const showAppUpdateDialogStore = makeVar<boolean>(false);
export const showDebugGameIds = makeVarPersisted<boolean>(true, 'showDebugGameIds');
export const showDebugVideoOverlayStore = makeVarPersisted(false, 'showDebugVideoOverlay');
export const showGetAccessModalStore = makeVar<GetAccessModalState>(GET_ACCESS_MODAL_STATE_INITIAL);
export const showFreeViewBannerStore = makeVar<boolean>(false);
export const showMultigameToastStore = makeVar<boolean>(false);
export const suppressPrerollStore = makeVar<boolean>(false);
export const useConvivaTestAccountStore = makeVar<boolean>(false);
export const useConvivaTouchstoneStore = makeVar<boolean>(false);
export const userPropertyStore = makeVar<TelemetryUserProps>(UserProperties);
export const videoControlsStore = makeVar<VideoControlsState>(VIDEO_CONTROLS_INITIAL_STATE);
export const videoSessionStore = makeVar<VideoSessionState>(INITIAL_VIDEO_SESSION_STATE);
export const vodStateStore = makeVar<VodState>(VOD_INITIAL_STATE);

// app config primitives for rendering performance
export const allowedEventsStore = makeVar<number[]>([]);
export const appConfigIndicatorStore = makeVar<string>('');
export const bracketIncompleteStore = makeVar<string | undefined>(undefined);
export const bracketLockStore = makeVar<number | undefined>(undefined);
export const connectedFullscreenStore = makeVar<number | undefined>(undefined);
export const connectedMultigameStore = makeVar<number | undefined>(undefined);
export const enableBracketSponsorStore = makeVar<boolean | undefined>(undefined);
export const enableMultiGameStore = makeVar<boolean | undefined>(undefined);
export const excitementAlertsBrandedToastStore = makeVar<boolean | undefined>(undefined);
export const gameSwitcherAllowedStore = makeVar<boolean | undefined>(undefined);
export const internalExcitementStore = makeVar<boolean | undefined>(undefined);
export const liveVideoCueIdStore = makeVar<number[] | null>(null);
export const liveVideoCueStore = makeVar<number | null>(null);
export const montageChannelGuideStore = makeVar<boolean>(false);
export const montageSponsorStore = makeVar<string | undefined>(undefined);
export const multiGameActiveStore = makeVar<boolean | undefined>(undefined);
export const qrcodeCBSStore = makeVar<boolean | undefined>(undefined);
export const scheduleSlateStore = makeVar<boolean | undefined>(undefined);
export const sponsorMultiStore = makeVar<string | undefined>(undefined);
export const statsActiveStore = makeVar<boolean>(false);
export const statsInactiveGamesStore = makeVar<number[]>([]);
export const tournamentYearStore = makeVar<number | undefined>(undefined);
export const tvAuthRegCodeStore = makeVar<string | undefined>(undefined);
export const videoAvalStore = makeVar<Aval | undefined>(undefined);
export const videoPlatformStore = makeVar<MediaJSONPlatform | undefined>(undefined);
export const reportVideoErrorMetaStore = makeVar<boolean>(false);
export const enableDoubleBoxPrerollStore = makeVar<boolean>(false);
export const enableExcitementDoubleBoxPrerollStore = makeVar<boolean>(false);
export const enableFastbreakDoubleBoxPrerollStore = makeVar<boolean>(false);

export type VideoListItems = FeaturedSpotlight | FeaturedTrending | VodList;
////////////////////////////
// CLIENT CACHE
////////////////////////////
export const MML_APOLLO_CACHE = new InMemoryCache({
  typePolicies: {
    Query: {
      fields: {
        playerReady(): boolean {
          LOG.info('playerReadyStore');
          return playerReadyStore();
        },
        getLogs(): LogMessageModel[] {
          return logMessageStore();
        },
        championshipGame: {
          read: (existing, { toReference, canRead, args }): ChampionshipGame | Reference | undefined => {
            const gameRef = toReference({ __typename: 'ChampionshipGame', bracketId: parseInt(args?.bracketId) });
            if (!canRead(gameRef)) {
              return DEFAULT_EMPTY_CHAMPIONSHIP_GAME;
            }
            return gameRef;
          },
        },
        mmlEvents: {
          read: (existing, { toReference, canRead, args }): MmlEvent | Reference | undefined => {
            const eventRef = toReference({ __typename: 'MMLEvent', bracketId: parseInt(args?.bracketId) });
            if (!canRead(eventRef)) {
              return undefined;
            }
            return eventRef;
          },
        },
        championship: {
          read: (existing, { toReference, canRead }): Championship | Reference | undefined => {
            const championshipRef = toReference({ __typename: 'Championship' });
            if (!canRead(championshipRef)) {
              return DEFAULT_EMPTY_CHAMPIONSHIP;
            }
            return championshipRef;
          },
        },
        appFeatured: {
          read: (existing, { toReference, canRead }): AppFeatured | Reference | undefined => {
            const appFeaturedRef = toReference({ __typename: 'AppFeatured' });
            if (!canRead(appFeaturedRef)) {
              return undefined;
            }
            return appFeaturedRef;
          },
        },
        round: {
          read: (existing, { toReference, canRead, args }): Round | Reference | undefined => {
            const roundRef = toReference({ __typename: 'Round', roundNumber: args?.roundNumber });
            if (!canRead(roundRef)) {
              return DEFAULT_EMPTY_ROUND;
            }
            return roundRef;
          },
        },
        boxscore: {
          read: (existing, { toReference, canRead, args }): Boxscore | Reference | undefined => {
            const boxscoreRef = toReference({ __typename: 'Boxscore', contestId: parseInt(args?.contestId) });
            if (!canRead(boxscoreRef)) {
              return {};
            }
            return boxscoreRef;
          },
        },
      },
    },
    Championship: {
      keyFields: [],
    },
    Round: {
      keyFields: ['roundNumber'],
    },
    AppFeatured: {
      keyFields: [],
    },
    Boxscore: {
      keyFields: ['contestId'],
      fields: {
        visitorBoxscore: {
          read: (_, { readField }): BoxscoreDetails | null => {
            const teams = readField('teamBoxscore') as BoxscoreDetails[];
            return filterByFieldValue(teams, 'isHome', false);
          },
        },
        homeBoxscore: {
          read: (_, { readField }): BoxscoreDetails | null => {
            const teams = readField('teamBoxscore') as BoxscoreDetails[];
            return filterByFieldValue(teams, 'isHome', true);
          },
        },
      },
    },
    MMLEvent: {
      keyFields: ['bracketId'],
      fields: {
        bracketId: {
          read: (bracketId): StatusCode => {
            return bracketId;
          },
        },
      },
    },
    ChampionshipGame: {
      keyFields: ['bracketId'],
      fields: {
        gameStateCode: {
          read: (gameStatus): StatusCode => {
            return gameStatus;
          },
        },
        formattedClock: {
          read: (_, { readField }): string => {
            const clock = readField<string>('contestClock') ?? '';
            const period = readField<string>('currentPeriod') ?? '';
            return [clock, period].filter(Boolean).join(' - ');
          },
        },
        formattedRound: {
          read: (_, { readField }): string => {
            return createformattedRoundTitle(readField);
          },
        },
        formattedVenue: {
          read: (_, { readField }): string => {
            return createVenueTitle(readField);
          },
        },
        homeTeamLogo: {
          read: (teams, { readField }): TeamLogoConfig => {
            const liveTeams = liveTeamStore();
            const team = pluckReadFieldValue<ChampionshipTeam>(readField, 'teams', 'isHome', true) ?? null;
            if (team && team?.ncaaOrgId && !liveTeams[team.ncaaOrgId]) {
              liveTeams[team.ncaaOrgId] = team;
              liveTeamStore(liveTeams);
            }
            return teamLogoFactory(team ?? DEFAULT_CHAMPIONSHIP_TEAM);
          },
        },
        visitorTeamLogo: {
          read: (teams, { readField }): TeamLogoConfig => {
            const liveTeams = liveTeamStore();
            const team = pluckReadFieldValue<ChampionshipTeam>(readField, 'teams', 'isHome', false) ?? null;
            if (team && team?.ncaaOrgId && !liveTeams[team.ncaaOrgId]) {
              liveTeams[team.ncaaOrgId] = team;
              liveTeamStore(liveTeams);
            }
            return teamLogoFactory(team ?? DEFAULT_CHAMPIONSHIP_TEAM);
          },
        },
        topChampionshipTeam: {
          read: (teams, { readField }): ChampionshipTeam | null => {
            return pluckReadFieldValue<ChampionshipTeam>(readField, 'teams', 'isTop', true) ?? null;
          },
        },

        bottomChampionshipTeam: {
          read: (teams, { readField }): ChampionshipTeam | null => {
            return pluckReadFieldValue<ChampionshipTeam>(readField, 'teams', 'isTop', false) ?? null;
          },
        },
        homeChampionshipTeam: {
          read: (teams, { readField }): ChampionshipTeam | null => {
            return pluckReadFieldValue<ChampionshipTeam>(readField, 'teams', 'isHome', true) ?? null;
          },
        },
        visitorChampionshipTeam: {
          read: (teams, { readField }): ChampionshipTeam | null => {
            return pluckReadFieldValue<ChampionshipTeam>(readField, 'teams', 'isHome', false) ?? null;
          },
        },
        homeSeasonAverage: {
          read: (teams, { readField }): TeamStatsBasketball | null => {
            const team = readField('homeChampionshipTeam') as ChampionshipTeam;
            const stat = (team?.seasonAverage as TeamStatsBasketball) ?? null;
            return stat;
          },
        },
        visitorSeasonAverage: {
          read: (teams, { readField }): TeamStatsBasketball | null => {
            const team = readField('visitorChampionshipTeam') as ChampionshipTeam;
            const stat = (team?.seasonAverage as TeamStatsBasketball) ?? null;
            // LOG.info(' visit stat > ', stat);
            return stat;
          },
        },
        teamsOnDeck(teamsOnDeck: TeamsOnDeck, { readField }): TeamsOnDeck {
          return getTeamsOnDeck(readField<ChampionshipTeam[]>('teams')?.length ?? 0);
        },
        startTime(startTime: string, { readField }): string {
          const hasStartTime = readField('hasStartTime');
          if (hasStartTime) {
            const convertTime = readField('startTimeEpoch');
            if (typeof convertTime == 'number') {
              return getFormattedGameTime(convertTime);
            } else {
              return '';
            }
          } else {
            return 'TBA';
          }
        },
      },
    },
  },
});
